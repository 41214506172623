import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Field from "../../../Components/Field";
import IpInput from "../../../../../../Components/common/IpInput/IpInput";

const MlChecker = () => {
  const [checker, setChecker] = useState(false);
  const [demand, setDemand] = useState(false);
  const [Static, setStatic] = useState(false);
  const [sbox, setSbox] = useState(false);

  const [procDump, setProcDump] = useState(true);
  const [all, setAll] = useState(true);
  const [ipPrefix, setIpPrefix] = useState("C8:58:C0:");
  const [macPrefix, setMacPrefix] = useState("");

  return (
    <div className="border processing__content">
      <Toggle
        active={checker}
        label="ML checker"
        setActive={setChecker}
        parentClassName="m-b-20"
        type
      />
      <div className="flex flex-align-items-center m-b-20">
        <Toggle active={demand} label="On demand" setActive={setDemand} parentClassName="p-r-40" />
        <Toggle
          active={Static}
          label="Static"
          setActive={setStatic}
          parentClassName="p-r-40"
          tooltipText="Analyze binary payloads"
        />
        <Toggle
          active={sbox}
          label="SBOX"
          setActive={setSbox}
          parentClassName="p-r-40"
          tooltipText="Analyze SBOX payloads"
        />
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          active={procDump}
          label="ProcDump"
          setActive={setProcDump}
          parentClassName="p-r-30"
          tooltipText="Analyze ProcDump"
        />
        <Toggle active={all} label="All" setActive={setAll} />
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <Field
          label="IP prefix"
          placeholder="C8:58:C0:"
          parentClassName="p-r-25"
          value={ipPrefix}
          width="131px"
          onChange={(e) => setIpPrefix(e.target.value)}
        />
        <IpInput
          label="Mac prefix"
          placeholder="192.168.122."
          value={macPrefix}
          width="131px"
          onChange={(e) => setMacPrefix(e.target.value)}
        />
      </div>

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default MlChecker;
