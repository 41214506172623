import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";

const ProcessingTab = () => {
  const [curtain, setCurtain] = useState(false);
  const [sysmon, setSysmon] = useState(false);
  const [analysisInfo, setAnalysisInfo] = useState(false);

  const [flareCapa, setFlareCapa] = useState(false);
  const [demand, setDemand] = useState(false);
  const [Static, setStatic] = useState(false);
  const [sbox, setSbox] = useState(false);
  const [procDump, setProcDump] = useState(false);

  return (
    <div className="border processing__content">
      <h3>Processing</h3>
      <p className="processing__description m-b-20">
        Enable or disable the available processing modules [on/off]. If you add a custom processing
        module to your Cfsbox setup, you have to add a dedicated entry in this file, or it won't be
        executed. You can also add additional options under the section of your module and they will
        be available in your Python class.
      </p>
      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          active={curtain}
          label="Curtain"
          setActive={setCurtain}
          parentClassName="p-r-40"
          tooltipWidth={"900px"}
        />
        <Toggle active={sysmon} label="Sysmon" setActive={setSysmon} parentClassName="p-r-40" />
        <Toggle active={analysisInfo} label="Analysis info" setActive={setAnalysisInfo} />
      </div>

      <h3 className="m-b-10">FLARE capa</h3>
      <div className="flex flex-align-items-center m-b-30">
        <Toggle
          active={flareCapa}
          label="FLARE capa"
          setActive={setFlareCapa}
          tooltipText="FLARE capa -> to update rules utils/community.py -cr githunlink"
          parentClassName="p-r-40"
        />
        <Toggle active={demand} label="On demand" setActive={setDemand} parentClassName="p-r-40" />
        <Toggle
          active={Static}
          label="Static"
          setActive={setStatic}
          parentClassName="p-r-40"
          tooltipText="Analyze binary payload"
        />
        <Toggle
          active={sbox}
          label="SBOX"
          setActive={setSbox}
          parentClassName="p-r-40"
          tooltipText="Analyze SBOX payloads"
        />
        <Toggle
          active={procDump}
          label="ProcDump"
          setActive={setProcDump}
          tooltipText="Analyze ProcDump"
        />
      </div>

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default ProcessingTab;
