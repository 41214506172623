import React from "react";

const SortIcon = ({ color = "var(--header-search-text-color)", width = 18, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 24 24">
      <path d="M17 14a1 1 0 01.836 1.55l-.068.09-5 6a1 1 0 01-1.452.09l-.084-.09-5-6a1 1 0 01.655-1.634L7 14h10zM11.232 2.36a1 1 0 011.452-.09l.084.09 5 6a1 1 0 01-.655 1.634L17 10H7a1 1 0 01-.836-1.55l.068-.09 5-6z"></path>
    </svg>
  );
};

export default SortIcon;
