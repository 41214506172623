// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smtp {
  border-radius: 0 4px 4px 4px;
}
.smtp .field {
  height: 40px;
  width: 100%;
}
.smtp .label {
  width: 312px;
}
.smtp .field::placeholder {
  font-size: 14px;
}
.smtp__label {
  font-size: 12px;
  color: var(--text-color-card);
  font-family: Nunito, serif;
}
.smtp__host {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 558px;
}
.smtp__address {
  color: var(--settings-article-value);
}
.smtp .label {
  color: var(--text-color-card) !important;
}
.smtp .custom-table {
  padding-bottom: 30px;
}
.smtp .custom-table td {
  padding: 10px 15px;
}
.smtp .custom-table th {
  padding: 10px 15px;
  background: var(--settings-table-header);
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/Notifications/Tabs/SMTP/styles.scss"],"names":[],"mappings":"AAEA;EACE,4BAAA;AADF;AAGE;EACE,YAAA;EACA,WAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,eAAA;AAHJ;AAME;EACE,eAAA;EACA,6BAAA;EACA,0BAAA;AAJJ;AAOE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;AALJ;AAQE;EACE,oCAAA;AANJ;AASE;EACE,wCAAA;AAPJ;AAUE;EACE,oBAAA;AARJ;AAUI;EACE,kBAAA;AARN;AAWI;EACE,kBAAA;EACA,wCAAA;EACA,YAAA;AATN","sourcesContent":["@import \"../../../../../../../styles/colors\";\n\n.smtp {\n  border-radius: 0 4px 4px 4px;\n\n  .field {\n    height: 40px;\n    width: 100%;\n  }\n\n  .label {\n    width: 312px;\n  }\n\n  .field::placeholder {\n    font-size: 14px;\n  }\n\n  &__label {\n    font-size: 12px;\n    color: var(--text-color-card);\n    font-family: Nunito, serif;\n  }\n\n  &__host {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 558px;\n  }\n\n  &__address {\n    color: var(--settings-article-value);\n  }\n\n  .label {\n    color: var(--text-color-card) !important;\n  }\n\n  .custom-table {\n    padding-bottom: 30px;\n\n    td {\n      padding: 10px 15px;\n    }\n\n    th {\n      padding: 10px 15px;\n      background: var(--settings-table-header);\n      border: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
