import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersRequest,
  updateUserRequest,
  publicKeyCheckRequest,
} from "../../../store/actions/usersActions";
import { convertNewSystemRequest } from "../../../store/actions/analysisReportActions";
import Backdrop from "../../../Components/UI/Backdrop/Backdrop";
import ProfileAccount from "../../../Components/common/ProfileAccount/ProfileAccount";
import UserCard from "../../../Components/common/UserCard/UserCard";
import ProfilePicture from "../../../Components/Icon-SVG/ProfilePicture";
import Warning from "../../../Components/common/Warning/Warning";
import "./styles.scss";
import CustomTable from "../../../Components/common/Table/CustomTable";
import { dashboardHeader } from "../../../utils/constants/tableHeaders";

const CloudProfile = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.users.users);
  const loadingConvert = useSelector((state) => state?.analysisReports.loadingConvert);
  const defaultEmail = useSelector((state) => state?.users?.user?.email);
  const defaultUserName = useSelector((state) => state?.users?.user?.username);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(null);

  const [userNameUpdate, setUserNameUpdate] = useState("");
  const [passwordUpdate, setPasswordUpdate] = useState("");
  const [confirmPasswordUpdate, setConfirmPasswordUpdate] = useState("");
  const [emailUpdate, setEmailUpdate] = useState("");

  useEffect(() => {
    dispatch(getAllUsersRequest());
    dispatch(publicKeyCheckRequest());
  }, [dispatch]);

  if (!users) {
    return <Backdrop show={!users} />;
  }

  const userSelect = users.filter((user) => (defaultEmail === user.email ? user : null));

  const updateUser = async () => {
    if (emailUpdate && userNameUpdate && passwordUpdate && confirmPasswordUpdate) {
      if (passwordUpdate === confirmPasswordUpdate) {
        const pushUser = {
          id: userSelect[0]._id.$oid,
          username: userNameUpdate,
          email: emailUpdate,
          password: passwordUpdate,
        };
        dispatch(updateUserRequest(pushUser));
      } else {
        setError("Make sure your passwords are the same");
        setShow(true);
      }
    } else {
      setError("Please fill in all fields!");
      setShow(true);
    }
  };

  return (
    <div>
      <div className="cloudProfile">
        <div className="cloudProfile__header">
          <h1>User Profile</h1>
          <p>Welcome {defaultUserName}</p>
        </div>
        <div className="cloudProfile__body border">
          <div>
            <UserCard
              name={defaultUserName}
              mail={defaultEmail}
              profileImg={<ProfilePicture width={150} height={150} />}
            />
          </div>
          <hr className="cloudProfile__body__divider" />
          <div className="cloudProfile__body__content">
            <ProfileAccount
              setUserNameUpdate={setUserNameUpdate}
              userNameUpdate={defaultUserName}
              setEmailUpdate={setEmailUpdate}
              emailUpdate={defaultEmail}
              setPasswordUpdate={setPasswordUpdate}
              setConfirmPasswordUpdate={setConfirmPasswordUpdate}
              button={updateUser}
            />
          </div>
          <hr className="cloudProfile__body__divider" />
          <div className="cloudProfile__body__content">
            <div className="profile__card-header">
              <p>Latest Submissions</p>
            </div>
            <CustomTable
              exportFile={true}
              column={dashboardHeader}
              tableName={"latest"}
              setActive={"Latest"}
              leftAction={false}
              isSetUrl
              paginationButtons={true}
              className=""
              minBottom="5px"
              customPageSize={10}
              minHeightRow="72px"
              filterByTime={true}
              selectedTimeFilter={selectedTimeFilter}
              setSelectedTimeFilter={setSelectedTimeFilter}
              defaultFilterIndex={2}
              manualPagination={true}
            />
          </div>
          <hr className="cloudProfile__body__divider" />
        </div>
      </div>
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
      {loadingConvert && (
        <Backdrop
          absolute={true}
          show={loadingConvert}
          message="Please do not refresh the page or close the window!"
        />
      )}
    </div>
  );
};

export default CloudProfile;
