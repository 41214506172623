// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
}
.tooltip__modal {
  position: absolute;
  top: 0;
  left: 30px;
  width: 242px;
  z-index: 100000;
  color: var(--settings-article-value);
  background: var(--tooltip-modal-background);
  padding: 10px 20px 10px 10px;
  border-radius: 4px;
  border: 1px solid var(--border);
  font-size: 12px;
  font-family: Nunito, serif;
}
.tooltip__modal-button {
  position: absolute;
  top: 5px;
  right: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--settings-article-value);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/Tooltip/styles.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAAF;AAEE;EACE,kBAAA;EACA,MAAA;EACA,UAAA;EACA,YAAA;EACA,eAAA;EACA,oCAAA;EACA,2CAAA;EACA,4BAAA;EACA,kBAAA;EACA,+BAAA;EACA,eAAA;EACA,0BAAA;AAAJ;AAEI;EACE,kBAAA;EACA,QAAA;EACA,QAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,oCAAA;AAAN","sourcesContent":["@import \"../../../styles/colors\";\n.tooltip {\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n  position: relative;\n\n  &__modal {\n    position: absolute;\n    top: 0;\n    left: 30px;\n    width: 242px;\n    z-index: 100000;\n    color: var(--settings-article-value);\n    background: var(--tooltip-modal-background);\n    padding: 10px 20px 10px 10px;\n    border-radius: 4px;\n    border: 1px solid var(--border);\n    font-size: 12px;\n    font-family: Nunito, serif;\n\n    &-button {\n      position: absolute;\n      top: 5px;\n      right: 0;\n      background: transparent;\n      border: none;\n      outline: none;\n      cursor: pointer;\n      color: var(--settings-article-value);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
