// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.web__email {
  padding: 0 20px;
  border-radius: 0 4px 4px 4px;
}
.web__email__text {
  color: var(--text-color-card);
  font-size: 12px;
}
.web__email .label {
  width: 350px;
  color: var(--text-color-card) !important;
}
.web__email .label:first-child {
  margin-right: 25px;
}
.web__email .field {
  height: 40px;
  width: 100%;
}
.web__email .toggle-button {
  margin: 5px 10px 5px 0;
}
.web__email .select-box--box {
  margin: 6px 25px 0 0;
}
.web__email .toggle__article {
  color: var(--text-color-card);
  font-size: 12px;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/WebServiceConfiguration/Tabs/Email/styles.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,4BAAA;AADF;AAGE;EACE,6BAAA;EACA,eAAA;AADJ;AAIE;EACE,YAAA;EACA,wCAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAME;EACE,YAAA;EACA,WAAA;AAJJ;AAOE;EACE,sBAAA;AALJ;AAQE;EACE,oBAAA;AANJ;AASE;EACE,6BAAA;EACA,eAAA;EACA,kBAAA;AAPJ","sourcesContent":["@import \"../../../../../../../styles/colors.scss\";\n\n.web__email {\n  padding: 0 20px;\n  border-radius: 0 4px 4px 4px;\n\n  &__text {\n    color: var(--text-color-card);\n    font-size: 12px;\n  }\n\n  .label {\n    width: 350px;\n    color: var(--text-color-card) !important;\n\n    &:first-child {\n      margin-right: 25px;\n    }\n  }\n\n  .field {\n    height: 40px;\n    width: 100%;\n  }\n\n  .toggle-button {\n    margin: 5px 10px 5px 0;\n  }\n\n  .select-box--box {\n    margin: 6px 25px 0 0;\n  }\n\n  .toggle__article {\n    color: var(--text-color-card);\n    font-size: 12px;\n    margin-bottom: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
