import React, { useState } from "react";
import FileFormatIconSet from "../../../../common/FileFormatIconSet/FileFormatIconSet";

const TabEvent = (props) => {
  const hashIcon = props.row.original.hashIcon;
  const [fileNameHover, setFileNameHover] = useState(false);

  return (
    <div style={{ width: "20px" }}>
      <p style={{ display: "flex", float: "left", marginRight: "10px", pointerEvents: "all" }}>
        <span
          title="Copy SHA256 Hash"
          onMouseOver={() => setFileNameHover(true)}
          onMouseOut={() => setFileNameHover(false)}>
          <FileFormatIconSet
            width={30}
            height={30}
            color={fileNameHover ? "white" : "var(--color-blue)"}
            extension={hashIcon.fileType}
          />
        </span>
      </p>
    </div>
  );
};

export default TabEvent;
