import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Select from "../../../Components/Select";
import Field from "../../../Components/Field";

const VPN = () => {
  const [vpn, setVpn] = useState(false);
  const [randomVpn, setRandomVpn] = useState(false);

  const [vpns, setVpns] = useState("vpn0");
  const [name, setName] = useState("vpn0");
  const [description, setDescription] = useState("openvpn_tunnel");

  const [Interface, setInterFace] = useState("tun0");
  const [rtTable, setRtTable] = useState("tun0");

  const [socks, setSocks] = useState(false);
  const [randomSocks, setRandomSocks] = useState(false);
  const [proxies, setProxies] = useState({});
  const [nameSocks, setNameSocks] = useState("vpn0");
  const [descriptionSocks, setDescriptionSocks] = useState("ch_socks");
  const [proxyPort, setProxyPort] = useState("5008");
  const [dnsPort, setDnsPort] = useState("10053");

  return (
    <div className="border routing__content">
      <h3>VPN</h3>
      <div className="flex flex-align-items-center m-b-20 m-t-10">
        <Toggle
          active={vpn}
          setActive={setVpn}
          label="VPN"
          parentClassName="p-r-40"
          type
          tooltipText="By default we disable VPN support as it requires running utils/rooter.py as root next to cfsbox.py (which should run as regular user)."
        />
        <Toggle
          active={randomVpn}
          setActive={setRandomVpn}
          label="Random VPN"
          parentClassName="p-r-40"
          tooltipText="Select one of the configured vpns randomly"
          type
        />
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <Field
          width="131px"
          parentClassName="p-r-20"
          onChange={(e) => setVpns(e.target.value)}
          value={vpns}
          tooltipText="Comma-separated list of the available VPNs."
          label="VPNS"
          placeholder="vpn0"
        />
        <Field
          width="131px"
          parentClassName="p-r-20"
          onChange={(e) => setName(e.target.value)}
          value={name}
          tooltipText={`Name of this VPN. The name is represented by the filepath to the configuration file, e.g., cfsbox would represent /etc/openvpn/cfsbox.conf Note that you can't assign the names "none" and "internet" as those would conflict with the routing section in cfsbox.conf.`}
          label="Name"
        />
        <Field
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          tooltipText="The description of this VPN which will be displayed in the web interface. Can be used to for example describe the country where this VPN ends up."
          label="Description"
          width="256px"
        />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <Field
          width="131px"
          parentClassName="p-r-20"
          onChange={(e) => setInterFace(e.target.value)}
          value={Interface}
          tooltipText='The tun device hardcoded for this VPN. Each VPN *must* be configured to use a hardcoded/persistent tun device by explicitly adding the line "dev tunX" to its configuration (e.g., /etc/openvpn/vpn1.conf) where X in tunX is a unique number between 0 and your lucky number of choice.'
          label="Interface"
        />
        <Field
          width="131px"
          parentClassName="p-r-20"
          onChange={(e) => setRtTable(e.target.value)}
          value={rtTable}
          tooltipText='Routing table name/id for this VPN. If table name is used it *must* be added to /etc/iproute2/rt_tables as "<id> <name>" line (e.g., "201 tun0"). ID and name must be unique across the system (refer /etc/iproute2/rt_tables for existing names and IDs).'
          label="RT Table"
        />
      </div>

      <h3>Socks5</h3>
      <div className="flex flex-align-items-center m-b-20 m-t-10">
        <Toggle
          active={socks}
          setActive={setSocks}
          parentClassName="p-r-40"
          label="Socks5"
          tooltipText="By default we disable socks5 support as it requires running utils/rooter.py as root next to cfsbox.py (which should run as regular user)."
          type
        />
        <Toggle
          active={randomSocks}
          setActive={setRandomSocks}
          label="Random socks5"
          tooltipText="Select one of the configured socks5 proxies randomly"
          type
        />
      </div>

      <Select
        label="Proxies"
        tooltipText="Comma-separated list of the available proxies."
        width="131px"
        select={proxies}
        setSelect={setProxies}
        parentClassName="m-b-20 m-t-10"
      />
      <h3>Socks ch</h3>
      <div className="flex flex-align-items-center m-b-10 m-t-10">
        <Field
          parentClassName="p-r-20"
          label="Name"
          width="131px"
          value={nameSocks}
          onChange={(e) => setNameSocks(e.target.value)}
        />
        <Field
          label="Description"
          width="256px"
          value={descriptionSocks}
          onChange={(e) => setDescriptionSocks(e.target.value)}
        />
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <Field
          parentClassName="p-r-20"
          label="Proxy port"
          width="131px"
          type="number"
          value={proxyPort}
          onChange={(e) => setProxyPort(e.target.value)}
        />
        <Field
          label="DNS port"
          width="131px"
          type="number"
          value={dnsPort}
          onChange={(e) => setDnsPort(e.target.value)}
        />
      </div>

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default VPN;
