// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simplebar-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  border-radius: 7px;
}
@media screen and (min-width: 1400px) {
  .simplebar-scrollbar {
    width: 9px;
  }
}
.simplebar-scrollbar::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 7px;
  border-radius: 4px;
  background-color: var(--scroll-bar-color);
  -webkit-box-shadow: 0 0 1px var(--scroll-bar-color);
}
@media screen and (min-width: 1400px) {
  .simplebar-scrollbar::before {
    width: 9px;
  }
}
.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

.simplebar-track.simplebar-vertical {
  width: 10px;
}
@media screen and (min-width: 1400px) {
  .simplebar-track.simplebar-vertical {
    width: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/common/CustomScrollbar/styles.scss"],"names":[],"mappings":"AAEA;EACE,wBAAA;EACA,UAAA;EACA,kBAAA;AADF;AAEE;EAJF;IAKI,UAAA;EACF;AACF;AACE;EACE,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,UAAA;EACA,kBAAA;EACA,yCAAA;EACA,mDAAA;AACJ;AAAI;EATF;IAUI,UAAA;EAGJ;AACF;AACI;EACE,UAAA;AACN;;AAKE;EACE,WAAA;AAFJ;AAGI;EAFF;IAGI,WAAA;EAAJ;AACF","sourcesContent":["@import \"../../../styles/colors\";\n\n.simplebar-scrollbar {\n  -webkit-appearance: none;\n  width: 7px;\n  border-radius: 7px;\n  @media screen and (min-width: 1400px) {\n    width: 9px;\n  }\n\n  &::before {\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    width: 7px;\n    border-radius: 4px;\n    background-color: var(--scroll-bar-color);\n    -webkit-box-shadow: 0 0 1px var(--scroll-bar-color);\n    @media screen and (min-width: 1400px) {\n      width: 9px;\n    }\n  }\n\n  &.simplebar-visible {\n    &::before {\n      opacity: 1;\n    }\n  }\n}\n\n.simplebar-track {\n  &.simplebar-vertical {\n    width: 10px;\n    @media screen and (min-width: 1400px) {\n      width: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
