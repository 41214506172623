import * as React from "react";

const LinuxOsIcon = ({ width = 18, height = 18 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 622 686">
    <path
      fill="#35cbfe"
      d="M233.5-.5h44q150.457 18.723 214.5 156 15.446 37.78 19.5 78v44q-1.46 3.672-1 8a2.43 2.43 0 0 1-2-.5 56930 56930 0 0 0-201-200.5q-21.087-27.18-55-24-33.656 4.454-48.5 35-4.75 11.256-4.5 23.5a465 465 0 0 1 4 35.5q-.41 27.657-16.5 50-43.588 43.514-35.5 105-3.92 9.192-12 15.5l-19 9q-8.937 9.938-9.5 23.5a73.4 73.4 0 0 1-10 18q-2.4 13.198 9.5 19-.658-.098-1 .5a18323 18323 0 0 0 113 114.5Q102.647 491.73 37 390.5 5.455 338.114-.5 277.5v-44Q18.223 83.043 155.5 19q37.78-15.445 78-19.5"
      opacity="0.992"></path>
    <path
      fill="#343434"
      d="M252.5 60.5v72h-1q-6.255-.25-12 2 4.395-17.715-12.5-24-8.712 1.71-12 10-3.184 9.67 1 19 2.572 4.825 7.5 7a64.7 64.7 0 0 0-12 15q3.381 7.379 10 12-26.827 37.167-38.5 82a180 180 0 0 0-6.5 36 12.86 12.86 0 0 0 1.5 6q20.548 23.57 48 38 1.858 12.17-10.5 14.5a37.1 37.1 0 0 1-12 1.5 4012 4012 0 0 0-32-49.5q-5.072-3.368-11-2-5.862 3.609-9 9.5-8.088-61.486 35.5-105 16.09-22.343 16.5-50a465 465 0 0 0-4-35.5q-.25-12.244 4.5-23.5 14.844-30.546 48.5-35"></path>
    <path
      fill="#010101"
      d="M307.5 84.5q7.701 11.41 10.5 25-.24 21.995 0 44 2.276 19.547 13 36a385 385 0 0 1 30 36q20.361 34.937 15.5 75 9.403 16.744 1 34-19.539 19.795-42 3.5-15.435-18.928-1-38.5 1.438-.679 2.5-2-1.243-32.987-13-64-12.222-34.96-34.5-64a19.2 19.2 0 0 1 2.5-5q1-2.5 0-5a78 78 0 0 0-13.5-13q12.94-9.136 8.5-25-8.75-19.208-23-4-4.043 9.216-1.5 19l-10-4v-72q33.913-3.18 55 24"></path>
    <path
      fill="#02b9fe"
      d="M307.5 84.5a56930 56930 0 0 1 201 200.5 2.43 2.43 0 0 0 2 .5q-21.962 145.179-155 206.5-37.319 15.204-77 19.5h-45a256 256 0 0 0-11-2 18323 18323 0 0 1-113-114.5q.342-.598 1-.5a10692 10692 0 0 0 90 16q13.589.712 20-11a108.8 108.8 0 0 1 32-4q12.09.014 24 2 5.855 12.304 19.5 13a1341 1341 0 0 0 95.5-18.5q6.993-6.208 5.5-15.5-4.479-7.952-9-16a80 80 0 0 1-4-17q-2.465-5.192-6.5-9 8.403-17.256-1-34 4.861-40.063-15.5-75a385 385 0 0 0-30-36q-10.724-16.453-13-36-.24-22.005 0-44-2.799-13.59-10.5-25"></path>
    <path
      fill="#dedede"
      d="M239.5 134.5q-2.427 2.724-6 4 .55-6.7-1.5-13-7.057-8.616-11 2a30.5 30.5 0 0 0 0 11q1.188 3.909 4.5 6-.5 1.5-2 2-4.928-2.175-7.5-7-4.184-9.33-1-19 3.288-8.29 12-10 16.895 6.285 12.5 24"></path>
    <path
      fill="#d9d9d9"
      d="M278.5 146.5q-2.29.11-3-2 3.006-1.76 4.5-5a42.5 42.5 0 0 0 0-13q-3.934-8.002-10-2-2.655 7.943-.5 16a36 36 0 0 1-7-4q-2.543-9.784 1.5-19 14.25-15.208 23 4 4.44 15.864-8.5 25"></path>
    <path
      fill="#0b0c0a"
      d="m233.5 138.5-8 6q-3.312-2.091-4.5-6a30.5 30.5 0 0 1 0-11q3.943-10.616 11-2 2.05 6.3 1.5 13"></path>
    <path
      fill="#040503"
      d="M275.5 144.5q-3.442-1.462-6-4-2.155-8.057.5-16 6.066-6.002 10 2a42.5 42.5 0 0 1 0 13q-1.494 3.24-4.5 5"></path>
    <path
      fill="#fb9812"
      d="M251.5 132.5a474 474 0 0 1 1 43 29.5 29.5 0 0 1-14-2.5 120.7 120.7 0 0 1-19-12.5 19.9 19.9 0 0 0-4 3.5q15.06 16.03 37 17.5v5h-3q-15.546-3.274-28-13-6.619-4.621-10-12a64.7 64.7 0 0 1 12-15q1.5-.5 2-2l8-6q3.573-1.276 6-4 5.745-2.25 12-2"></path>
    <path
      fill="#e0800d"
      d="M251.5 132.5h1l10 4a36 36 0 0 0 7 4q2.558 2.538 6 4 .71 2.11 3 2a78 78 0 0 1 13.5 13q1 2.5 0 5a19.2 19.2 0 0 0-2.5 5q-14.133 10.57-31 16.5a18.5 18.5 0 0 1-6 .5v-5q20.438-1.157 33-17.5a10.5 10.5 0 0 0-5-3.5 183 183 0 0 1-12 10.5 37.4 37.4 0 0 1-16 4.5 474 474 0 0 0-1-43"></path>
    <path
      fill="#a5462c"
      d="M252.5 175.5a37.4 37.4 0 0 0 16-4.5 183 183 0 0 0 12-10.5 10.5 10.5 0 0 1 5 3.5q-12.562 16.343-33 17.5-21.94-1.47-37-17.5a19.9 19.9 0 0 1 4-3.5 120.7 120.7 0 0 0 19 12.5 29.5 29.5 0 0 0 14 2.5"></path>
    <path
      fill="#bfc1c0"
      d="M289.5 169.5q22.278 29.04 34.5 64 11.757 31.013 13 64-1.062 1.321-2.5 2-4.762.005-8.5 3a103938 103938 0 0 1-50.5 79 98.7 98.7 0 0 1-23 2q-3.353.442-6-1h6v-195q-1.937.356-3-1h3a18.5 18.5 0 0 0 6-.5q16.867-5.93 31-16.5"></path>
    <path
      fill="#fefefe"
      d="M221.5 173.5q12.454 9.726 28 13 1.063 1.356 3 1v195h-6a87 87 0 0 1-29-9 238 238 0 0 1-14-22 37.1 37.1 0 0 0 12-1.5q12.358-2.33 10.5-14.5-27.452-14.43-48-38a12.86 12.86 0 0 1-1.5-6 180 180 0 0 1 6.5-36q11.673-44.833 38.5-82"></path>
    <path
      fill="#fc9a0f"
      d="M203.5 351.5a238 238 0 0 0 14 22q10.004 12.044 3 26-6.411 11.712-20 11a10692 10692 0 0 1-90-16q-11.9-5.802-9.5-19a73.4 73.4 0 0 0 10-18q.563-13.562 9.5-23.5l19-9q8.08-6.308 12-15.5 3.138-5.891 9-9.5 5.928-1.368 11 2a4012 4012 0 0 1 32 49.5"></path>
    <path
      fill="#dc7e11"
      d="M334.5 299.5q-14.435 19.572 1 38.5 22.461 16.295 42-3.5 4.035 3.808 6.5 9a80 80 0 0 0 4 17q4.521 8.048 9 16 1.493 9.292-5.5 15.5a1341 1341 0 0 1-95.5 18.5q-13.645-.696-19.5-13-3.278-7.792-1-16 25.213-39.403 50.5-79 3.738-2.995 8.5-3"></path>
    <path
      fill="#343434"
      d="M217.5 373.5a87 87 0 0 0 29 9q2.647 1.442 6 1v12a108.8 108.8 0 0 0-32 4q7.004-13.956-3-26"></path>
    <path
      fill="#010101"
      d="M275.5 381.5q-2.278 8.208 1 16a146.7 146.7 0 0 0-24-2v-12a98.7 98.7 0 0 0 23-2"></path>
  </svg>
);

export default LinuxOsIcon;
