// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table-row {
  background: var(--attack-map-bg);
  color: var(--attack-map-card-text-color);
}
.custom-table-row td {
  word-break: break-all;
}
.custom-table-row--malicious {
  border-left: 2px solid rgb(255, 49, 49);
}
.custom-table-row--suspicious {
  border-left: 2px solid var(--network-blocks-snort-url-border);
}
.custom-table-row--clean {
  border-left: 2px solid rgb(57, 255, 20);
}
.custom-table-row--DomainBlock {
  border-left: 3px solid var(--network-blocks-domain-border);
}
.custom-table-row--UrlBlock {
  border-left: 3px solid var(--network-blocks-snort-url-border);
}
.custom-table-row--SnortBlock {
  border-left: 3px solid var(--network-blocks-snort-url-border);
}
.custom-table-row--SboxBlock {
  border-left: 3px solid var(--network-blocks-sbox-border);
}
.custom-table-row--SboxSubmitLog {
  border-left: 3px solid var(--network-blocks-sbox-submit-border);
}
.custom-table-row--dynamic:hover {
  background-color: var(--analysis-reports-table-header);
  cursor: pointer;
}

.custom-table-row-expansion {
  background: var(--attack-map-bg);
  color: var(--attack-map-card-text-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/Table/Components/Row/styles.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,wCAAA;AACF;AACE;EACE,qBAAA;AACJ;AAEE;EACE,uCAAA;AAAJ;AAGE;EACE,6DAAA;AADJ;AAIE;EACE,uCAAA;AAFJ;AAKE;EACE,0DAAA;AAHJ;AAME;EACE,6DAAA;AAJJ;AAOE;EACE,6DAAA;AALJ;AAQE;EACE,wDAAA;AANJ;AASE;EACE,+DAAA;AAPJ;AAWI;EACE,sDAAA;EACA,eAAA;AATN;;AAcA;EACE,gCAAA;EACA,wCAAA;AAXF","sourcesContent":[".custom-table-row {\n  background: var(--attack-map-bg);\n  color: var(--attack-map-card-text-color);\n\n  td {\n    word-break: break-all;\n  }\n\n  &--malicious {\n    border-left: 2px solid rgb(255, 49, 49);\n  }\n\n  &--suspicious {\n    border-left: 2px solid var(--network-blocks-snort-url-border);\n  }\n\n  &--clean {\n    border-left: 2px solid rgb(57, 255, 20);\n  }\n\n  &--DomainBlock {\n    border-left: 3px solid var(--network-blocks-domain-border);\n  }\n\n  &--UrlBlock {\n    border-left: 3px solid var(--network-blocks-snort-url-border);\n  }\n\n  &--SnortBlock {\n    border-left: 3px solid var(--network-blocks-snort-url-border);\n  }\n\n  &--SboxBlock {\n    border-left: 3px solid var(--network-blocks-sbox-border);\n  }\n\n  &--SboxSubmitLog {\n    border-left: 3px solid var(--network-blocks-sbox-submit-border);\n  }\n\n  &--dynamic {\n    &:hover {\n      background-color: var(--analysis-reports-table-header);\n      cursor: pointer;\n    }\n  }\n}\n\n.custom-table-row-expansion {\n  background: var(--attack-map-bg);\n  color: var(--attack-map-card-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
