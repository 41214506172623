import React, { useState } from "react";
import Select from "../../../Components/Select";
import Win7 from "../Components/WIN7";
import Checker from "../Components/Checker";
import Field from "../../../Components/Field";
import { machinesVariant } from "../../../../../../utils/constants";
import Warning from "../../../../../../Components/common/Warning/Warning";

const Kvm = () => {
  const [machines, setMachines] = useState({});
  const [label, setLabel] = useState("");
  const [interFace, setInterFace] = useState("");
  const [show, setShow] = useState(false);

  return (
    <div className="border guest-images__content-kvm">
      <div className="flex flex-align-items-center flex-wrap flex-justify-content-start m-b-30">
        <h3 style={{ width: "55px" }}>KVM</h3>
        <p className="analysis-images__description">
          Below is a list if virtual machine “Guest Images” currently loaded on this system. It uses
          multiple instances of these guest images test traffic and software for malicious activity.
        </p>
      </div>
      <div className="flex flex-align-items-center m-t-10 m-b-10">
        <Select
          parentClassName="m-b-5 p-r-30"
          tooltipText="Specify a comma-separated list of available machines to be used. For each specified ID you have to define a dedicated section containing the details on the respective machine. (E.g. cfsbox1,cfsbox2,cfsbox3)"
          width="178px"
          select={machines}
          setSelect={setMachines}
          label="Machines"
          placeholder="win7"
          data={machinesVariant}
        />
        <Field
          parentClassName="p-r-30"
          width="178px"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          label="Label"
          placeholder="virbr0"
          tooltipText="Specify the label name of the current machine as specified in your libvirt configuration."
        />
        <Field
          width="178px"
          value={interFace}
          onChange={(e) => setInterFace(e.target.value)}
          label="Interface"
          placeholder="virbr0"
          tooltipText="(Optional) Specify the name of the network interface that should be used when dumping network traffic from this machine with tcpdump. If specified, overrides the default interface specified in auxiliary.conf Example (virbr0 is the interface name): interface = virbr0"
        />
      </div>
      {machines.id === "win7" ? <Win7 /> : machines.id === "mlchecker" && <Checker />}
      <button className="primary-button guest-images__button" onClick={() => setShow(true)}>
        Add new image
      </button>
      <Warning
        show={show}
        setShow={setShow}
        onClickOk={() => setShow(false)}
        article="Add new Image"
        text="Are you sure you want to create a new image?"
      />
    </div>
  );
};

export default Kvm;
