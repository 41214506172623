import React from "react";
import UserAccounts from "./Tabs/UserAccounts/UserAccounts";
import DateTime from "./Tabs/DateTime/DateTime";
import YaraRules from "./Tabs/YaraRules/YaraRules";
import Notifications from "./Tabs/Notifications/Notifications";
import GuestImages from "./Tabs/GuestImages/GuestImages";
import ApplianceBackupRestore from "./Tabs/ApplianceBackupRestore/ApplianceBackupRestore";
import WebServiceConfiguration from "./Tabs/WebServiceConfiguration/WebServiceConfiguration.";
import CFSBOX from "./Tabs/CFSBOX/CFSBOX";
import Processing from "./Tabs/Processing/Processing";
import Reporting from "./Tabs/Reporting/Reporting";
import Routing from "./Tabs/Routing/Routing";
import DataRetention from "./Tabs/DataRetention/DataRetention";
import Mailing from "./Tabs/Mailing/Mailing";
import DumpAndRestore from "./Tabs/DumpAndRestore/DumpAndRestore";
// import Syslog from "./Tabs/Syslog/Syslog";

import "./styles.scss";

const Content = ({ active, collapsed }) => {
  return (
    <>
      {active === "User Accounts" ? (
        <UserAccounts collapsed={collapsed} />
      ) : active === "Date and Time" ? (
        <DateTime />
      ) : active === "Notifications" ? (
        <Notifications />
      ) : active === "Yara Rules" ? (
        <YaraRules />
      ) : active === "Guest Images" ? (
        <GuestImages />
      ) : active === "Appliance Backup & Restore" ? (
        <ApplianceBackupRestore />
      ) : active === "CFSBOX" ? (
        <CFSBOX />
      ) : active === "Processing" ? (
        <Processing />
      ) : active === "Reporting" ? (
        <Reporting />
      ) : active === "Routing" ? (
        <Routing />
      ) : active === "Data Retention" ? (
        <DataRetention />
      ) : active === "Mail Notifications" ? (
        <Mailing />
      ) : active === "Backup & Restore" ? (
        <DumpAndRestore />
      ) : (
        <WebServiceConfiguration />
      )}
    </>
  );
};

export default Content;
