// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info_info-icon-container__Kmdoy {
  float: right;
  position: relative;
}

.info_info-icon__8\\+OmQ {
  cursor: pointer;
}

.info_info-popup__qOF4Y {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 10px;
  background-color: #41414e;
  color: var(--header-search-text-color);
  width: 200px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Info/info.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,aAAa;EACb,yBAAyB;EACzB,sCAAsC;EACtC,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb","sourcesContent":[".info-icon-container {\n  float: right;\n  position: relative;\n}\n\n.info-icon {\n  cursor: pointer;\n}\n\n.info-popup {\n  position: absolute;\n  top: 20px;\n  left: 0;\n  padding: 10px;\n  background-color: #41414e;\n  color: var(--header-search-text-color);\n  width: 200px;\n  border-radius: 4px;\n  font-size: 14px;\n  z-index: 99;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-icon-container": `info_info-icon-container__Kmdoy`,
	"info-icon": `info_info-icon__8+OmQ`,
	"info-popup": `info_info-popup__qOF4Y`
};
export default ___CSS_LOADER_EXPORT___;
