import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ToggleButton from "../../../Components/common/ToggleButton/ToggleButton";
import "./styles.scss";
import { Modal } from "@mui/material";
import Login from "../Login/Login";

const Pricing = () => {
  const [isMonthly, setIsMonthly] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [userCheck, setUserCheck] = useState(false);
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    if (user) {
      setUserCheck(true);
    } else {
      setUserCheck(false);
    }
  }, [user]);

  const loginCall = () => {
    if (userCheck) {
      setIsLoginOpen(false);
    } else {
      setIsLoginOpen(true);
    }
  };

  function handleToggle(value) {
    setIsMonthly(value);
  }

  return (
    <div className="pricing">
      <h1>Our Pricing</h1>

      <div className="pricing__selector">
        <ToggleButton
          leftText="Annually"
          rightText="Monthly"
          active={isMonthly}
          onClick={handleToggle}
        />
      </div>

      <div id="cards" className="pricing__cards">
        <div className="pricing__cards-left">
          <h2>Basic</h2>
          {isMonthly ? <h3 className="monthly">$4.99</h3> : <h4 className="annually">$49.99</h4>}

          <hr />
          <p>Core features</p>
          <hr />
          <p>Team management</p>
          <hr />
          <p>API/EXPORTS</p>
          <hr />
          <button onClick={() => loginCall()}>Learn More</button>
        </div>
        <div className="pricing__cards-middle">
          <h2>Professional</h2>
          {isMonthly ? <h3 className="monthly">$9.99</h3> : <h4 className="annually">$99.99</h4>}
          <hr />
          <p>Core features</p>
          <hr />
          <p>Team management</p>
          <hr />
          <p>API/EXPORTS</p>
          <hr />
          <button onClick={() => loginCall()}>Learn More</button>
        </div>
        <div className="pricing__cards-right">
          <h2>Master</h2>
          {isMonthly ? <h3 className="monthly">$19.99</h3> : <h4 className="annually">$199.99</h4>}
          <hr />
          <p>Core features</p>
          <hr />
          <p>Team management</p>
          <hr />
          <p>API/EXPORTS</p>
          <hr />
          <button onClick={() => loginCall()}>Learn More</button>
        </div>
      </div>
      {/* Cloud Modal Login */}
      <Modal open={isLoginOpen} onClose={() => setIsLoginOpen(false)}>
        <div className="cloudmodal-login">
          <Login />
        </div>
      </Modal>
    </div>
  );
};

export default Pricing;
