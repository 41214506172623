import React from "react";
import DragAndDrop from "../../../../../../../Components/UI/DragAndDrop/DragAndDrop";
import CustomTable from "../../../../../../../Components/common/Table/CustomTable";
import { settingsBackup } from "../../../../../../../utils/constants/tableHeaders";
import { backupFixture } from "../../../../../../../fixture";
import "./styles.scss";

const Backup = () => {
  return (
    <div className="backup__content border">
      <div className="flex flex-align-items-center flex-wrap flex-justify-content-start ">
        <h3 className="settings-article" style={{ width: "190px" }}>
          Upload Backup File
        </h3>
        <p className="analysis-images__description">
          Please use USB backup/restore for large backups
        </p>
      </div>
    </div>
  );
};

export default Backup;
