// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-cl {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  background: var(--layout-bg);
}
@media screen and (max-width: 1200px) {
  .layout-cl {
    min-width: 1200px;
  }
}

.main-block-cl {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
}
@media screen and (max-width: 1300px) {
  .main-block-cl {
    margin-top: 0;
  }
}

.header-cl {
  height: 56px;
  background: var(--header-background);
  position: fixed;
  z-index: 10000;
  top: 0;
}
@media screen and (max-width: 1300px) {
  .header-cl {
    position: initial;
    width: 100% !important;
  }
}

.content-cl {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--content-background);
}

#scroll-target-cl {
  position: absolute;
  top: -100px;
}`, "",{"version":3,"sources":["webpack://./src/CloudModel/CloudUI/CloudLayout/styles.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,eAAA;EACA,4BAAA;AADF;AAGE;EAPF;IAQI,iBAAA;EAAF;AACF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAAF;AACE;EAJF;IAKI,aAAA;EAEF;AACF;;AACA;EACE,YAAA;EACA,oCAAA;EACA,eAAA;EACA,cAAA;EACA,MAAA;AAEF;AAAE;EAPF;IAQI,iBAAA;IACA,sBAAA;EAGF;AACF;;AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,qCAAA;AAGF;;AAAA;EACE,kBAAA;EACA,WAAA;AAGF","sourcesContent":["@import \"../../../styles/colors\";\n\n.layout-cl {\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-wrap: wrap;\n  background: var(--layout-bg);\n\n  @media screen and (max-width: 1200px) {\n    min-width: 1200px;\n  }\n}\n\n.main-block-cl {\n  display: flex;\n  flex-direction: column;\n  margin-top: 56px;\n  @media screen and (max-width: 1300px) {\n    margin-top: 0;\n  }\n}\n\n.header-cl {\n  height: 56px;\n  background: var(--header-background);\n  position: fixed;\n  z-index: 10000;\n  top: 0;\n\n  @media screen and (max-width: 1300px) {\n    position: initial;\n    width: 100% !important;\n  }\n}\n\n.content-cl {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background: var(--content-background);\n}\n\n#scroll-target-cl {\n  position: absolute;\n  top: -100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
