import React, { useState } from "react";
import CheckBox from "../../../../common/CheckBox/CheckBox";

const Encrypt = (props) => {
  const encrypt = props.encrypt || props.row?.original?.encrypt;
  const [checked, setChecked] = useState(encrypt);
  return (
    <div>
      <CheckBox id={props.row.id} checked={checked} disabled setChecked={setChecked} />
    </div>
  );
};

export default Encrypt;
