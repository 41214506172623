import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Select from "../../../Components/Select";
import Field from "../../../Components/Field";
import { getHours } from "../../../../../../utils/function";
import { run_mode } from "../../../../../../utils/constants";

const Suricata = () => {
  const [suricata, setSuricata] = useState(true);
  const [runMode, setRunMode] = useState();

  const [evelog, setEvelog] = useState("eva.json");
  const [filesLog, setFilesLog] = useState("files-json.log");
  const [filesDir, setFilesDir] = useState("files");

  const [buffer, setBuffer] = useState("");
  const [ZBin, setZBin] = useState("files-json.log");
  const [zipPass, setZipPass] = useState();

  const [bin, setBin] = useState("/usr/bin/suricata");
  const [conf, setConf] = useState("/etc/suricata/suricata.yaml");

  return (
    <div className="border processing__content">
      <div className="m-b-20">
        <h3>Suricata</h3>
        <p className="processing__description">
          Notes on getting this to work check install_suricata function:
        </p>
        <p className="processing__description">
          https://github.com/doomedraven/Tools/blob/master/Sandbox/cape2.sh
        </p>
      </div>
      <Toggle
        label="Suricata"
        active={suricata}
        setActive={setSuricata}
        parentClassName="m-b-20"
        type
      />

      <div className="m-b-10">
        <h3>Per protocol log files</h3>
        <p className="processing__description">
          alertlog = alert.json / httplog = http.json / tlslog = tls.json / sshlog = ssh.json /
          dnslog = dns.json
        </p>
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <Field
          onChange={(e) => setEvelog(e.target.value)}
          value={evelog}
          label="Eva log"
          parentClassName="p-r-20"
          width="236px"
        />
        <Field
          onChange={(e) => setFilesLog(e.target.value)}
          value={filesLog}
          label="Files log"
          parentClassName="p-r-20"
          width="236px"
        />
        <Field
          onChange={(e) => setFilesDir(e.target.value)}
          value={filesDir}
          label="Files dir"
          parentClassName="p-r-20"
          width="236px"
        />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <Field
          onChange={(e) => setBuffer(e.target.value)}
          value={buffer}
          label="Buffer"
          parentClassName="p-r-20"
          width="101px"
          placeholder="3144704"
          tooltipText="Amount of text to carve from plaintext files (bytes)"
        />
        <Field
          onChange={(e) => setZBin(e.target.value)}
          value={ZBin}
          label="7zbin"
          parentClassName="p-r-20"
          width="236px"
          tooltipText="Used for creating an archive of extracted files"
        />
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <Field
          onChange={(e) => setBin(e.target.value)}
          value={bin}
          label="Bin"
          parentClassName="p-r-20"
          width="236px"
        />
        <Field
          onChange={(e) => setConf(e.target.value)}
          value={conf}
          label="conf"
          parentClassName="p-r-20"
          width="236px"
        />
      </div>

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Suricata;
