import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import CustomSelect from "../../Components/common/CustomSelect/CustomSelect";
import CustomTable from "../../Components/common/Table/CustomTable";
import ListPopup from "../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";

import { unblockedMailsHeader } from "../../utils/constants/tableHeaders";
import {
  RequestUnblockedEmails,
  RequestDistinctHosts,
} from "../../store/actions/mailGatewayActions";

import "./styles.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Details = ({ row, history }) => {
  const submissions = row?.original?.files || [];
  const detail = row?.original?.detail || "";

  const seeReportHandler = (groupID) => {
    history.push(`/mailgateway/unblocked-emails?data=${groupID}&table=unblocked-emails`);
  };

  if (submissions?.length) {
    return (
      <div className="unblocked-emails__table__submissions">
        {detail ? (
          <p className="unblocked-emails__table__detail">
            <span>Detail: </span>
            {detail}
          </p>
        ) : null}
        {submissions.map((submission, i) => (
          <div key={i} className="unblocked-emails__table__submission">
            <p title={submission.file_name}>
              <span>{submission.api_resp} file found: </span>
              {submission.file_name}
            </p>
            <p>
              <span>File Hash: </span>
              {submission?.file_hash || "-"}
            </p>
            <button
              disabled={submission.group_id === "-"}
              onClick={() => seeReportHandler(submission.group_id)}>
              See Report
            </button>
          </div>
        ))}
      </div>
    );
  }

  if (detail) {
    return (
      <div className="unblocked-emails__table__details">
        <p>{detail}</p>
      </div>
    );
  }
};

const UnblockedEmails = () => {
  const [selectedHost, setSelectedHost] = useState(null);
  const [listPopupActive, setListPopupActive] = useState(false);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(null);

  const dispatch = useDispatch();

  const distinctHosts = useSelector((state) => state.mailGateway.distinctHosts);
  const distinctHostsLoading = useSelector((state) => state.mailGateway.distinctHostsLoading);
  const unblockedEmails = useSelector((state) => state.mailGateway.unblockedEmails);
  const unblockedEmailsLoading = useSelector((state) => state.mailGateway.unblockedEmailsLoading);

  let query = useQuery();

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);

  useEffect(() => {
    dispatch(RequestDistinctHosts());
  }, [dispatch]);

  useEffect(() => {
    if (selectedHost?.id && selectedTimeFilter) {
      dispatch(RequestUnblockedEmails({ host: selectedHost.id, timeFilter: selectedTimeFilter }));
    }
  }, [selectedHost, selectedTimeFilter]);

  return (
    <div className="unblocked-emails">
      <div className="unblocked-emails__header">
        <h1>Unblocked Emails</h1>
        <p>Below list is displaying unblocked emails of selected host:</p>
        <div className="unblocked-emails__header__select">
          <p>Select host: </p>
          <CustomSelect
            data={distinctHosts.length ? distinctHosts : []}
            select={selectedHost}
            setSelected={setSelectedHost}
            width={220}
            disabled={distinctHosts.length === 0}
            placeholder={distinctHostsLoading ? "Fetching data..." : "No data."}
          />
        </div>
      </div>
      <div className="unblocked-emails__table border">
        <CustomTable
          leftAction={true}
          renderRowSubComponent={Details}
          exportFile={true}
          dataTable={unblockedEmails}
          loading={unblockedEmailsLoading}
          tableName="unblockedEmails"
          column={unblockedMailsHeader}
          paginationButtons={true}
          className=""
          customPageSize={10}
          minHeight="585px"
          minBottom="5px"
          defaultPosition="relative"
          selectedHost={selectedHost?.id}
          filterByTime={true}
          selectedTimeFilter={selectedTimeFilter}
          setSelectedTimeFilter={setSelectedTimeFilter}
          defaultFilterIndex={2}
        />
      </div>
      <ListPopup
        onClickOk={() => setListPopupActive(false)}
        show={listPopupActive}
        groupID={query.get("data")}
        tableType={query.get("table")}
        tableType2="unblocked-emails"
      />
    </div>
  );
};

export default UnblockedEmails;
