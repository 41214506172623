import React, { useState } from "react";
import TabButton from "../../../../../Components/common/TabButton/TabButton";
import Summary from "./Tabs/Summary/Summary";
import Rsyslog from "./Tabs/RSYSLOG/RSYSLOG";
import Smtp from "./Tabs/SMTP/SMTP";
import Snmp from "./Tabs/SNMP/SNMP";
import Http from "./Tabs/HTTP/HTTP";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";
import "./styles.scss";

const Notifications = () => {
  const [active, setActive] = useState("Summary");

  return (
    <div className="notifications">
      <h1 className=" m-t-5 m-b-70">Notifications</h1>
      <div className="notifications__body">
        <DemoDiv styleClass={active} />
        <div className="button-block">
          <TabButton title="Summary" onClick={setActive} active={active} />
          <TabButton title="RSYSLOG" onClick={setActive} active={active} />
          <TabButton title="SMTP" onClick={setActive} active={active} />
          <TabButton title="SNMP" onClick={setActive} active={active} />
          <TabButton title="HTTP" onClick={setActive} active={active} />
        </div>
        <div style={{ background: "var(--card-background-color)" }}>
          {active === "Summary" ? (
            <Summary />
          ) : active === "RSYSLOG" ? (
            <Rsyslog />
          ) : active === "SMTP" ? (
            <Smtp />
          ) : active === "SNMP" ? (
            <Snmp />
          ) : (
            <Http />
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
