// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-fields {
  width: 286px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  background: var(--tags-fields-bg);
  border: 1px solid var(--tags-fields-border);
  color: var(--tags-fields-color);
  font-family: Nunito, serif;
  margin-bottom: 15px;
}
.tag-fields__label {
  color: var(--tags-filed-label);
  font-size: 12px;
  display: flex;
  align-items: center;
  min-height: 20px;
}
.tag-fields__tag {
  background: var(--tags-fields-bg-tags);
  color: var(--tags-tag-color);
  padding: 5px;
  margin: 10px 10px 10px 0;
  border-radius: 4px;
}
.tag-fields__button {
  background: transparent;
  outline: none;
  border: none;
  color: var(--tags-fields-color);
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
}
.tag-fields__suggestions {
  color: var(--tags-fields-color);
  cursor: pointer;
  margin: 0;
}

.tag-fields::placeholder {
  color: var(--tags-fields-placeholder);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/TagField/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,iCAAA;EACA,2CAAA;EACA,+BAAA;EACA,0BAAA;EACA,mBAAA;AACF;AACE;EACE,8BAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAEE;EACE,sCAAA;EACA,4BAAA;EACA,YAAA;EACA,wBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,uBAAA;EACA,aAAA;EACA,YAAA;EACA,+BAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;AADJ;AAIE;EACE,+BAAA;EACA,eAAA;EACA,SAAA;AAFJ;;AAMA;EACE,qCAAA;AAHF","sourcesContent":[".tag-fields {\n  width: 286px;\n  height: 40px;\n  outline: none;\n  border-radius: 4px;\n  padding: 10px;\n  background: var(--tags-fields-bg);\n  border: 1px solid var(--tags-fields-border);\n  color: var(--tags-fields-color);\n  font-family: Nunito, serif;\n  margin-bottom: 15px;\n\n  &__label {\n    color: var(--tags-filed-label);\n    font-size: 12px;\n    display: flex;\n    align-items: center;\n    min-height: 20px;\n  }\n\n  &__tag {\n    background: var(--tags-fields-bg-tags);\n    color: var(--tags-tag-color);\n    padding: 5px;\n    margin: 10px 10px 10px 0;\n    border-radius: 4px;\n  }\n\n  &__button {\n    background: transparent;\n    outline: none;\n    border: none;\n    color: var(--tags-fields-color);\n    cursor: pointer;\n    font-size: 16px;\n    padding: 5px;\n  }\n\n  &__suggestions {\n    color: var(--tags-fields-color);\n    cursor: pointer;\n    margin: 0;\n  }\n}\n\n.tag-fields::placeholder {\n  color: var(--tags-fields-placeholder);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
