import React from "react";
import Tooltip from "../../../../Components/common/Tooltip/Tooltip";
import ToggleButton from "../../../../Components/common/ToggleButton/ToggleButton";

const Toggle = ({
  active,
  setActive,
  tooltipText,
  label,
  parentClassName,
  tooltipWidth = "242px",
  type,
}) => {
  return (
    <div className={parentClassName}>
      <p className="settings__label m-b-5">
        {label}
        {tooltipText && <Tooltip text={tooltipText} tooltipWidth={tooltipWidth} />}
      </p>
      <ToggleButton
        rightText={`${type ? (active ? "Enabled" : "Disabled") : active ? "Yes" : "No"}`}
        active={active}
        onClick={setActive}
      />
    </div>
  );
};

export default Toggle;
