import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import CloudBackdrop from "../../CloudUI/CloudBackdrop/CloudBackdrop";
import FileCard from "../../../Components/PagesComponents/Dashboard/FileCard/FileCard";

import { RequestVms } from "../../../store/actions/dashboardActions";
import { licenceCheckRequest } from "../../../store/actions/usersActions";

import "./styles.scss";
import LogoIcon from "../../../Components/Icon-SVG/LogoIcon";

const Home = () => {
  const dispatch = useDispatch();
  const submissionsLoading = useSelector((state) => state.dashboard.submissionsLoading);
  const currentFile = useSelector((state) => state.dashboard.currentFile);

  useEffect(() => {
    dispatch(licenceCheckRequest());
    dispatch(RequestVms());
  }, [dispatch]);

  return (
    <div className="cloud-home">
      <div className="cloud-home__header">
        <div className="cloud-home__logo">
          <div className="cloud-home__logo__icon">
            <LogoIcon width={93} height={93} color="#1082b4" />
          </div>
          <div className="cloud-home__logo__text">
            <strong className="cloud-home__logo__text-cyber">Cyber</strong>
            <strong className="cloud-home__logo__text-fortress">Fortress</strong>
          </div>
        </div>
        <h2 className="cloud-home__header-text">
          Inspect suspicious files and links to uncover hidden threats. CyberFortress MA360
          streamlines analysis and strengthens your security defenses.
        </h2>
      </div>
      <div className="cloud-home__body">
        <div className="cloud-home__body__item">
          <FileCard />
        </div>
      </div>
      <CloudBackdrop
        absolute={false}
        show={submissionsLoading}
        message={`Uploading file "${currentFile}", please wait...`}
      />
    </div>
  );
};
export default Home;
