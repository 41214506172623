// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  color: var(--settings-article-value);
  font-size: 24px;
  font-weight: 700 !important;
  line-height: 33px;
  margin-bottom: 5px;
}

.settings-article {
  padding: 15px 20px;
  color: var(--settings-article-value);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.settings__label {
  color: var(--settings-header-text);
  font-size: 12px;
  display: flex;
  align-items: center;
  min-height: 20px;
}

.settings__field {
  width: 176px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color);
  color: var(--settings-article-value);
  font-family: Nunito, serif;
}

.settings__multi-line-field {
  width: 300px;
  min-height: 200px;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color);
  color: var(--settings-article-value);
  font-family: Nunito, serif;
}

.settings__field::placeholder {
  color: var(--settings-header-text);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/styles.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,eAAA;EACA,2BAAA;EACA,iBAAA;EACA,kBAAA;AADF;;AAIA;EACE,kBAAA;EACA,oCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADF;;AAIA;EACE,kCAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,oCAAA;EACA,oDAAA;EACA,oCAAA;EACA,0BAAA;AADF;;AAIA;EACE,YAAA;EACA,iBAAA;EAEA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,oCAAA;EACA,oDAAA;EACA,oCAAA;EACA,0BAAA;AAFF;;AAKA;EACE,kCAAA;AAFF","sourcesContent":["@import \"../../../styles/colors\";\n\nh1 {\n  color: var(--settings-article-value);\n  font-size: 24px;\n  font-weight: 700 !important;\n  line-height: 33px;\n  margin-bottom: 5px;\n}\n\n.settings-article {\n  padding: 15px 20px;\n  color: var(--settings-article-value);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22px;\n}\n\n.settings__label {\n  color: var(--settings-header-text);\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n  min-height: 20px;\n}\n\n.settings__field {\n  width: 176px;\n  height: 40px;\n  outline: none;\n  border-radius: 4px;\n  padding: 10px;\n  margin: 5px 0;\n  background: var(--settings-field-bg);\n  border: 1px solid var(--settings-field-border-color);\n  color: var(--settings-article-value);\n  font-family: Nunito, serif;\n}\n\n.settings__multi-line-field {\n  width: 300px;\n  min-height: 200px;\n  // font-size: 12px;\n  outline: none;\n  border-radius: 4px;\n  padding: 10px;\n  margin: 5px 0;\n  background: var(--settings-field-bg);\n  border: 1px solid var(--settings-field-border-color);\n  color: var(--settings-article-value);\n  font-family: Nunito, serif;\n}\n\n.settings__field::placeholder {\n  color: var(--settings-header-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
