import React, { useState } from "react";
import TabButton from "../../../../../Components/common/TabButton/TabButton";
import VirusTotal from "./Tabs/VirusTotal";
import MlChecker from "./Tabs/MLChecker";
import Detections from "./Tabs/Detections";
import Network from "./Tabs/Network";
import Static from "./Tabs/Static";
import Suricata from "./Tabs/Suricata";
import MaliciousMacRobot from "./Tabs/MaliciousMacRobot";
import Sbox from "./Tabs/SBOX";
import ProcessingTab from "./Tabs/Processing";
import Fti from "./Tabs/FTI";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";
import "./styles.scss";

const Processing = () => {
  const [active, setActive] = useState("Processing");

  return (
    <div className="processing">
      <h1>Processing</h1>
      <div className="processing__body">
        <DemoDiv
          styleClass={
            active === "Processing"
              ? "process"
              : active === "ML Checker"
              ? "mlchecker"
              : active === "Virus total"
              ? "virustotal"
              : active === "Malicious mac robot"
              ? "maliciousmacrobot"
              : active
          }
        />
        <div className="button-block">
          <TabButton title="Processing" onClick={setActive} active={active} />
          <TabButton title="ML Checker" onClick={setActive} active={active} />
          <TabButton title="FTI" onClick={setActive} active={active} />
          <TabButton title="Detections" onClick={setActive} active={active} />
          <TabButton title="Network" onClick={setActive} active={active} />
          <TabButton title="Static" onClick={setActive} active={active} />
          <TabButton title="Virus total" onClick={setActive} active={active} />
          <TabButton title="Suricata" onClick={setActive} active={active} />
          <TabButton title="SBOX" onClick={setActive} active={active} />
          <TabButton title="Malicious mac robot" onClick={setActive} active={active} />
        </div>
        <div style={{ background: "var(--card-background-color)" }}>
          {active === "Processing" ? (
            <ProcessingTab />
          ) : active === "ML Checker" ? (
            <MlChecker />
          ) : active === "FTI" ? (
            <Fti />
          ) : active === "Detections" ? (
            <Detections />
          ) : active === "Network" ? (
            <Network />
          ) : active === "Static" ? (
            <Static />
          ) : active === "Virus total" ? (
            <VirusTotal />
          ) : active === "Suricata" ? (
            <Suricata />
          ) : active === "SBOX" ? (
            <Sbox />
          ) : (
            <MaliciousMacRobot />
          )}
        </div>
      </div>
    </div>
  );
};

export default Processing;
