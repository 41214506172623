import React from "react";
import GuestImagesTable from "../../../../../../Components/PagesComponents/Settings/GuestImagesTable/GuestImagesTable";
import { settingGuestImagesFixture } from "../../../../../../fixture";
import { settingsGuestImages } from "../../../../../../utils/constants/tableHeaders";

const Images = () => {
  return (
    <div className="border guest-images__content">
      <div className="flex flex-align-items-center flex-wrap flex-justify-content-start">
        <h3 style={{ width: "210px" }} className="settings-article">
          Analysis Images (KVM)
        </h3>
        <p className="analysis-images__description">
          Below is a list if virtual machine “Guest Images” currently loaded on this system. It uses
          multiple instances of these guest images test traffic and software for malicious activity.
        </p>
      </div>
      <GuestImagesTable
        tableName="Guest-images"
        dataset={settingGuestImagesFixture}
        column={settingsGuestImages}
        className=""
      />
    </div>
  );
};

export default Images;
