// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary {
  border-radius: 0 4px 4px 4px;
}
.summary h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 14px 0;
}
.summary__dot {
  color: var(--settings-text-table);
  font-size: 20px;
  margin: 0 15px;
}
.summary .send-test {
  border-bottom: 1px solid var(--border);
  padding: 5px 20px 20px 20px;
}
.summary .send-test__type {
  color: var(--text-color-card);
  font-family: Nunito, serif;
  font-size: 12px;
}
.summary .digest {
  padding: 5px 20px 20px 20px;
}
.summary__article {
  color: var(--settings-article-value);
  font-size: 16px;
  line-height: 22px;
  margin: 14px 0;
}
.summary .select-box--box {
  margin-top: 5px;
}
.summary .label {
  color: var(--text-color-card) !important;
}
.summary .form-group input:checked + label:after {
  top: 2px;
}
.summary .toggle-button {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/Notifications/Tabs/Summary/styles.scss"],"names":[],"mappings":"AAEA;EACE,4BAAA;AADF;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIE;EACE,iCAAA;EACA,eAAA;EACA,cAAA;AAFJ;AAKE;EACE,sCAAA;EACA,2BAAA;AAHJ;AAKI;EACE,6BAAA;EACA,0BAAA;EACA,eAAA;AAHN;AAOE;EACE,2BAAA;AALJ;AAQE;EACE,oCAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AANJ;AASE;EACE,eAAA;AAPJ;AAUE;EACE,wCAAA;AARJ;AAWE;EACE,QAAA;AATJ;AAYE;EACE,cAAA;AAVJ","sourcesContent":["@import \"../../../../../../../styles/colors\";\n\n.summary {\n  border-radius: 0 4px 4px 4px;\n\n  h3 {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 22px;\n    margin: 14px 0;\n  }\n\n  &__dot {\n    color: var(--settings-text-table);\n    font-size: 20px;\n    margin: 0 15px;\n  }\n\n  .send-test {\n    border-bottom: 1px solid var(--border);\n    padding: 5px 20px 20px 20px;\n\n    &__type {\n      color: var(--text-color-card);\n      font-family: Nunito, serif;\n      font-size: 12px;\n    }\n  }\n\n  .digest {\n    padding: 5px 20px 20px 20px;\n  }\n\n  &__article {\n    color: var(--settings-article-value);\n    font-size: 16px;\n    line-height: 22px;\n    margin: 14px 0;\n  }\n\n  .select-box--box {\n    margin-top: 5px;\n  }\n\n  .label {\n    color: var(--text-color-card) !important;\n  }\n\n  .form-group input:checked+label:after {\n    top: 2px;\n  }\n\n  .toggle-button {\n    margin-left: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
