// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin: 15px 0 5px 0;
  position: relative;
  color: var(--text-color-label);
  font-family: Nunito, serif;
}

.field {
  width: 350px;
  height: 48px;
  outline: none;
  border-radius: 4px;
  padding: 10px 50px 10px 10px;
  margin: 5px 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color);
  color: var(--settings-article-value);
  font-family: Nunito, serif;
}

.field::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: var(--text-color-card);
  font-family: Nunito, serif;
}

.password__eye {
  position: absolute;
  top: 35px;
  right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/TextField/styles.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,8BAAA;EACA,0BAAA;AADF;;AAIA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,4BAAA;EACA,aAAA;EACA,oCAAA;EACA,oDAAA;EACA,oCAAA;EACA,0BAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,6BAAA;EACA,0BAAA;AADF;;AAIA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AADF","sourcesContent":["@import \"../../../styles/colors\";\n\n.label {\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 16px;\n  letter-spacing: 0;\n  text-align: left;\n  margin: 15px 0 5px 0;\n  position: relative;\n  color: var(--text-color-label);\n  font-family: Nunito, serif;\n}\n\n.field {\n  width: 350px;\n  height: 48px;\n  outline: none;\n  border-radius: 4px;\n  padding: 10px 50px 10px 10px;\n  margin: 5px 0;\n  background: var(--settings-field-bg);\n  border: 1px solid var(--settings-field-border-color);\n  color: var(--settings-article-value);\n  font-family: Nunito, serif;\n}\n\n.field::placeholder {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 22px;\n  letter-spacing: 0;\n  text-align: left;\n  color: var(--text-color-card);\n  font-family: Nunito, serif;\n}\n\n.password__eye {\n  position: absolute;\n  top: 35px;\n  right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
