import React from "react";
import DemoIcon from "../../Icon-SVG/DemoIcon";

import "./styles.scss";

const DemoDiv = ({ styleClass }) => {
  return (
    <div className={styleClass}>
      <DemoIcon />
      <p>
        <br />
      </p>
      <p>This feature is not available in the demo version.</p>
    </div>
  );
};

export default DemoDiv;
