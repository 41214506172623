// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  background-color: rgba(91, 98, 105, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Overlay/styles.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;AACF","sourcesContent":[".overlay {\n  background-color: rgba(91, 98, 105, 0.3);\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  z-index: 999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
