// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.web-config__body {
  position: relative;
  margin-top: 65px;
  background: var(--settings-background-page);
}
.web-config__body .border {
  border-radius: 0 4px 4px 4px;
}
.web-config__button {
  margin-left: 30px;
}

.toggle__article {
  color: var(--text-color-card);
  font-size: 12px;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/WebServiceConfiguration/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,gBAAA;EACA,2CAAA;AAAJ;AAEI;EACE,4BAAA;AAAN;AAIE;EACE,iBAAA;AAFJ;;AAMA;EACE,6BAAA;EACA,eAAA;EACA,mBAAA;AAHF","sourcesContent":[".web-config {\n  &__body {\n    position: relative;\n    margin-top: 65px;\n    background: var(--settings-background-page);\n\n    .border {\n      border-radius: 0 4px 4px 4px;\n    }\n  }\n\n  &__button {\n    margin-left: 30px;\n  }\n}\n\n.toggle__article {\n  color: var(--text-color-card);\n  font-size: 12px;\n  margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
