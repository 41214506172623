// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guest-images__content {
  border-radius: 0 4px 4px 4px;
  background: var(--card-background-color);
  padding-bottom: 20px;
}
.guest-images__content-kvm {
  border-radius: 0 4px 4px 4px;
  background: var(--card-background-color);
  padding: 15px;
}
.guest-images__body {
  position: relative;
  margin-top: 64px;
}
.guest-images__body .custom-table th {
  background: var(--settings-table-header);
  padding: 10px 30px;
}
@media screen and (max-width: 1300px) {
  .guest-images__body .custom-table th {
    padding: 10px 0 10px 15px;
  }
}
.guest-images__body .custom-table td {
  padding: 10px 30px;
}
@media screen and (max-width: 1300px) {
  .guest-images__body .custom-table td {
    padding: 10px 0 10px 15px;
  }
}
.guest-images .guest-images__button {
  margin: 30px 20px auto auto;
}
.guest-images .actions__button {
  font-size: 12px;
  margin-right: 15px;
  color: var(--settings-article-value);
}

.analysis-images__description {
  border-left: 1px solid var(--settings-header-text);
  padding-left: 15px;
  flex: 1 1;
  font-size: 12px;
  color: var(--settings-header-text);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/GuestImages/styles.scss"],"names":[],"mappings":"AAGE;EACE,4BAAA;EACA,wCAAA;EACA,oBAAA;AAFJ;AAKE;EACE,4BAAA;EACA,wCAAA;EACA,aAAA;AAHJ;AAME;EACE,kBAAA;EACA,gBAAA;AAJJ;AAMI;EACE,wCAAA;EACA,kBAAA;AAJN;AAKM;EAHF;IAII,yBAAA;EAFN;AACF;AAKI;EACE,kBAAA;AAHN;AAIM;EAFF;IAGI,yBAAA;EADN;AACF;AAKE;EACE,2BAAA;AAHJ;AAME;EACE,eAAA;EACA,kBAAA;EACA,oCAAA;AAJJ;;AAQA;EACE,kDAAA;EACA,kBAAA;EACA,SAAA;EACA,eAAA;EACA,kCAAA;AALF","sourcesContent":["@import \"../../../../../styles/colors.scss\";\n\n.guest-images {\n  &__content {\n    border-radius: 0 4px 4px 4px;\n    background: var(--card-background-color);\n    padding-bottom: 20px;\n  }\n\n  &__content-kvm {\n    border-radius: 0 4px 4px 4px;\n    background: var(--card-background-color);\n    padding: 15px;\n  }\n\n  &__body {\n    position: relative;\n    margin-top: 64px;\n\n    .custom-table th {\n      background: var(--settings-table-header);\n      padding: 10px 30px;\n      @media screen and (max-width: 1300px) {\n        padding: 10px 0 10px 15px;\n      }\n    }\n\n    .custom-table td {\n      padding: 10px 30px;\n      @media screen and (max-width: 1300px) {\n        padding: 10px 0 10px 15px;\n      }\n    }\n  }\n\n  .guest-images__button {\n    margin: 30px 20px auto auto;\n  }\n\n  .actions__button {\n    font-size: 12px;\n    margin-right: 15px;\n    color: var(--settings-article-value);\n  }\n}\n\n.analysis-images__description {\n  border-left: 1px solid var(--settings-header-text);\n  padding-left: 15px;\n  flex: 1;\n  font-size: 12px;\n  color: var(--settings-header-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
