// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__menu-cl {
  position: absolute;
  bottom: -120px;
  right: -15px;
  background: var(--header-background);
  border: 1px solid var(--border);
  width: 220px;
  border-radius: 4px;
  padding: 10px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  z-index: 99999;
}
.header__menu-cl__first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__menu-cl__first-row span {
  font-size: 12px;
}
.header__menu-cl p {
  font-weight: 900;
  font-size: 14px;
  color: var(--menu-color);
}
.header__menu-cl-button {
  display: flex;
  background: transparent;
  color: var(--text-color-card);
  outline: none;
  border: none;
  cursor: pointer;
  font-family: Nunito, serif;
  font-size: 14px;
  padding: 8px 0;
  text-decoration: none;
}
.header__menu-cl-button span {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/CloudModel/CloudUI/CloudMenu/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,YAAA;EACA,oCAAA;EACA,+BAAA;EACA,YAAA;EACA,kBAAA;EACA,2BAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AACI;EACE,eAAA;AACN;AAGE;EACE,gBAAA;EACA,eAAA;EACA,wBAAA;AADJ;AAIE;EACE,aAAA;EACA,uBAAA;EACA,6BAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,0BAAA;EACA,eAAA;EACA,cAAA;EACA,qBAAA;AAFJ;AAII;EACE,iBAAA;AAFN","sourcesContent":[".header__menu-cl {\n  position: absolute;\n  bottom: -120px;\n  right: -15px;\n  background: var(--header-background);\n  border: 1px solid var(--border);\n  width: 220px;\n  border-radius: 4px;\n  padding: 10px 15px 5px 15px;\n  display: flex;\n  flex-direction: column;\n  z-index: 99999;\n\n  &__first-row {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    span {\n      font-size: 12px;\n    }\n  }\n\n  p {\n    font-weight: 900;\n    font-size: 14px;\n    color: var(--menu-color);\n  }\n\n  &-button {\n    display: flex;\n    background: transparent;\n    color: var(--text-color-card);\n    outline: none;\n    border: none;\n    cursor: pointer;\n    font-family: Nunito, serif;\n    font-size: 14px;\n    padding: 8px 0;\n    text-decoration: none;\n\n    span {\n      margin-left: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
