import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Field from "../../../Components/Field";
import Tooltip from "../../../../../../Components/common/Tooltip/Tooltip";

const MaliciousMacRobot = () => {
  const [macRobot, setMacRobot] = useState(false);
  const [xlsdeobf, setXlsdeobf] = useState(true);

  const [benignPath, setBenignPath] = useState("/opt/cfsbox/data/mmbot/benign");
  const [maliciousPath, setMaliciousPath] = useState("/opt/cfsbox/data/mmbot/malicious");
  const [modelPath, setModelPath] = useState("/opt/cfsbox/data/mmbot/model");

  const [boxjs, setBoxJs] = useState(false);

  const [timeout, setTimeout] = useState("60");
  const [url, setUrl] = useState("modules/processing/parsers/mwcp/");

  const [mwcp, setMwcp] = useState(false);
  const [ratDecoders, setRatDecoders] = useState(true);

  const [mwcpPath, setMwcpPath] = useState("modules/processing/parsers/mwcp/");
  const [ratPath, setRatPath] = useState("modules/processing/parsers/RATDecoders/");

  const [malduck, setMalduck] = useState(false);
  const [sboxExtractors, setSboxExtractors] = useState(true);

  const [malduckPath, setMalduckPath] = useState("modules/processing/parsers/malduck/");
  const [sboxPath, setSboxPath] = useState("modules_path = modules/processing/parsers/SBOX/");

  return (
    <div className="border processing__content">
      <h3 className="m-b-20">
        Malicious mac robot
        <Tooltip
          text="https://maliciousmacrobot.readthedocs.io Install mmbot sudo pip3 install mmbot Create/Set required paths githunlink githunlink Create modeldata.pickle with your maldocs (this does not append to the model, it overwrites it) mmb = MaliciousMacroBot(benign_path, malicious_path, model_path, retain_sample_contents=False) result = mmb.mmb_init_model(modelRebuild=True) Copy your model file and vocab.txt to your model_path"
          tooltipWidth="920px"
        />
      </h3>
      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          label="Malicious mac robot"
          active={macRobot}
          setActive={setMacRobot}
          parentClassName="m-r-40"
          type
        />
        <Toggle label="xlsdeobf" setActive={setXlsdeobf} active={xlsdeobf} type />
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <Field
          value={benignPath}
          onChange={(e) => setBenignPath(e.target.value)}
          width="256px"
          label="Benign path"
          parentClassName="p-r-20"
        />
        <Field
          value={maliciousPath}
          onChange={(e) => setMaliciousPath(e.target.value)}
          width="256px"
          label="Malicious path"
          parentClassName="p-r-20"
        />
        <Field
          value={modelPath}
          onChange={(e) => setModelPath(e.target.value)}
          width="256px"
          label="Model path"
        />
      </div>
      <Toggle label="boxjs" type parentClassName={"m-b-20"} active={boxjs} setActive={setBoxJs} />
      <div className="flex flex-align-items-center m-b-20">
        <Field
          value={timeout}
          onChange={(e) => setTimeout(e.target.value)}
          width="131px"
          label="Timeout"
          parentClassName="p-r-20"
          type="number"
        />
        <Field value={url} onChange={(e) => setUrl(e.target.value)} width="256px" label="url" />
      </div>

      <h3 className="m-b-10">Extractors</h3>
      <div className="flex flex-align-items-center m-b-20">
        <Toggle label="mwcp" type parentClassName={"m-r-40"} active={mwcp} setActive={setMwcp} />
        <Toggle label="boxjs" type active={ratDecoders} setActive={setRatDecoders} />
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <Field
          value={mwcpPath}
          onChange={(e) => setMwcpPath(e.target.value)}
          width="256px"
          label="modules_path"
          parentClassName="p-r-60"
        />
        <Field
          value={ratPath}
          onChange={(e) => setRatPath(e.target.value)}
          width="256px"
          label="modules_path"
        />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          label="malduck"
          type
          parentClassName={"m-r-40"}
          active={malduck}
          setActive={setMalduck}
        />
        <Toggle
          label="SBOX extractors"
          type
          active={sboxExtractors}
          setActive={setSboxExtractors}
        />
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <Field
          value={malduckPath}
          onChange={(e) => setMalduckPath(e.target.value)}
          width="256px"
          label="modules_path"
          parentClassName="p-r-60"
        />
        <Field
          value={sboxPath}
          onChange={(e) => setSboxPath(e.target.value)}
          width="256px"
          label="modules_path"
        />
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default MaliciousMacRobot;
