const CustomSignaturesIcon = ({ color = "var(--border)", width = 24, height = 24 }) => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   id="Layer_1"
    //   data-name="Layer 1"
    //   viewBox="0 0 24 24"
    //   width={width}
    //   height={height}>
    //   <path fill={color} d="m12.274,9.934l-3.785,3.728c-.226.226-.523.338-.821.338s-.599-.113-.826-.341l-1.889-1.832.696-.718,1.894,1.837c.062.061.186.062.241.005l3.787-3.73.702.713Zm-2.274,9.066h8v-1h-8v1Zm-4,0h2v-1h-2v1Zm4-13h8v-1h-8v1Zm-2-1h-2v1h2v-1Zm14-2.5v21.5H2V2.5c0-1.378,1.122-2.5,2.5-2.5h15c1.378,0,2.5,1.122,2.5,2.5Zm-1,0c0-.827-.673-1.5-1.5-1.5H4.5c-.827,0-1.5.673-1.5,1.5v20.5h18V2.5Zm-9,10.051h6v-1h-4.979l-1.021,1Z" />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}>
      <path
        fill={color}
        d="m17,0H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5ZM7.5,4.5c.828,0,1.5.672,1.5,1.5s-.672,1.5-1.5,1.5-1.5-.672-1.5-1.5.672-1.5,1.5-1.5Zm0,15c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Zm1.365-5.979c-.319.319-.741.479-1.165.479-.427,0-.855-.162-1.182-.487l-.681-.655c-.398-.382-.411-1.016-.028-1.414.383-.399,1.017-.41,1.414-.028l.472.454,1.866-1.815c.396-.385,1.029-.377,1.414.02.385.396.376,1.029-.02,1.414l-2.091,2.034Zm8.135,5.479h-5c-.552,0-1-.448-1-1s.448-1,1-1h5c.552,0,1,.448,1,1s-.448,1-1,1Zm0-6h-3c-.552,0-1-.448-1-1s.448-1,1-1h3c.552,0,1,.448,1,1s-.448,1-1,1Zm0-6h-5c-.552,0-1-.448-1-1s.448-1,1-1h5c.552,0,1,.448,1,1s-.448,1-1,1Z"
      />
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   id="Layer_1"
    //   data-name="Layer 1"
    //   viewBox="0 0 24 24"
    //   width={width}
    //   height={height}>
    //   <path
    //     fill={color}
    //     d="m11.696,11.718l-1.862,1.805c-.318.318-.74.478-1.163.478-.427,0-.856-.163-1.183-.489l-.681-.654c-.398-.383-.411-1.016-.028-1.414.383-.398,1.017-.41,1.414-.028l.473.455,1.638-1.588c.396-.383,1.029-.375,1.414.022.384.396.375,1.029-.022,1.414Zm4.304,5.282h-3c-.552,0-1,.448-1,1s.448,1,1,1h3c.552,0,1-.448,1-1s-.448-1-1-1Zm0-12h-3c-.552,0-1,.448-1,1s.448,1,1,1h3c.552,0,1-.448,1-1s-.448-1-1-1Zm0,6h-2c-.552,0-1,.448-1,1s.448,1,1,1h2c.552,0,1-.448,1-1s-.448-1-1-1Zm-7.5,5.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm0-9c.828,0,1.5-.672,1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5,1.5.672,1.5,1.5,1.5Zm13.5-2.5v14c0,2.757-2.243,5-5,5H7c-2.757,0-5-2.243-5-5V5C2,2.243,4.243,0,7,0h10c2.757,0,5,2.243,5,5Zm-2,0c0-1.654-1.346-3-3-3H7c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h10c1.654,0,3-1.346,3-3V5Z"
    //   />
    // </svg>
  );
};

export default CustomSignaturesIcon;
