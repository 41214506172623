import React, { useState } from "react";
import TabButton from "../../../../../Components/common/TabButton/TabButton";
import ReportingTab from "./Tabs/ReportingTab";
import JsonDump from "./Tabs/JsonDump";
import Maec41 from "./Tabs/MAEC41";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";
import "./styles.scss";

const Reporting = () => {
  const [active, setActive] = useState("Reporting");
  return (
    <div className="reporting">
      <h1>Reporting</h1>
      <div className="reporting__body">
        <DemoDiv styleClass={active} />
        <div className="button-block">
          <TabButton title="Reporting" onClick={setActive} active={active} />
          <TabButton title="JsonDump" onClick={setActive} active={active} />
          <TabButton title="MAEC41" onClick={setActive} active={active} />
        </div>
        <div style={{ background: "var(--card-background-color)" }}>
          {active === "Reporting" ? (
            <ReportingTab />
          ) : active === "JsonDump" ? (
            <JsonDump />
          ) : (
            <Maec41 />
          )}
        </div>
      </div>
    </div>
  );
};

export default Reporting;
