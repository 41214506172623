import React, { useState } from "react";
import EditIcon from "../../../../Icon-SVG/EditIcon";
import TrashBucketIcon from "../../../../Icon-SVG/TrashBucketIcon";
import Warning from "../../../../common/Warning/Warning";
import Win7 from "../../../../../Pages/Settings/Content/Tabs/GuestImages/Components/WIN7";
import Select from "../../../../../Pages/Settings/Content/Components/Select";
import Field from "../../../../../Pages/Settings/Content/Components/Field";
import Checker from "../../../../../Pages/Settings/Content/Tabs/GuestImages/Components/Checker";
import { machinesVariant } from "../../../../../utils/constants";

const Row = ({ row, setShow, setEditable }) => {
  const [edit, setEdit] = useState(false);
  const [machines, setMachines] = useState(machinesVariant[0]);
  const [label, setLabel] = useState("");
  const [Interface, setInterface] = useState("");
  const [showDelete, setShowDelete] = useState(false);

  return (
    <>
      <div className="tr" {...row.getRowProps()}>
        {row.cells.map((cell) => (
          <div {...cell.getCellProps()} className={`td ${cell.column.rowClassName}`}>
            {cell.column.id !== "action" ? (
              cell.render("Cell")
            ) : (
              <>
                <div className="flex flex-align-items-center">
                  <div
                    className="flex flex-align-items-center m-r-15"
                    onClick={() => {
                      setEditable(!edit);
                      setEdit(!edit);
                    }}
                    style={{ cursor: "pointer" }}>
                    <EditIcon />
                    <p className="actions__button m-l-10">Edit</p>
                  </div>

                  <div
                    className="flex flex-align-items-center"
                    onClick={() => setShowDelete(true)}
                    style={{ cursor: "pointer" }}>
                    <TrashBucketIcon />
                    <p className="actions__button m-l-10">Delete</p>
                  </div>
                </div>
                <Warning
                  onClickOk={() => setShowDelete(false)}
                  show={showDelete}
                  setShow={setShowDelete}
                  text="Are you sure, you want to delete image?"
                  article="Confirm Delete"
                />
              </>
            )}
          </div>
        ))}
      </div>
      {edit ? (
        <div className=" p-l-20 p-t-10 p-b-10">
          <div className="flex flex-align-items-center m-b-20">
            <Select
              label="Machines"
              width="178px"
              data={machinesVariant}
              setSelect={setMachines}
              select={machines}
              parentClassName="p-r-20 m-b-5"
              placeholder="win7"
              tooltipText="Specify a comma-separated list of available machines to be used. For each specified ID you have to define a dedicated section containing the details on the respective machine. (E.g. cfsbox1,cfsbox2,cfsbox3)"
            />
            <Field
              label="Label"
              width="178px"
              onChange={(e) => setLabel(e.target.value)}
              value={label}
              placeholder="win7"
              parentClassName="p-r-20"
              tooltipText="Specify the label name of the current machine as specified in your libvirt configuration."
            />
            <Field
              label="Interface"
              width="178px"
              onChange={(e) => setInterface(e.target.value)}
              value={Interface}
              placeholder="virbr0"
              tooltipText="(Optional) Specify the name of the network interface that should be used when dumping network traffic from this machine with tcpdump. If specified, overrides the default interface specified in auxiliary.conf Example (virbr0 is the interface name): interface = virbr0"
            />
          </div>
          {machines.id === "win7" ? <Win7 /> : machines.id === "mlchecker" && <Checker />}
          <div className="flex flex-align-items-center">
            <button className="success-button m-r-20" onClick={() => setShow(row)}>
              Save
            </button>
            <button
              className="default-button"
              onClick={() => {
                setEditable(false);
                setEdit(false);
              }}>
              Cancel
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Row;
