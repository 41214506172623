// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  display: block;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-family: Nunito, serif;
  color: var(--text-color-checkbox);
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: var(--checkbox-background);
  border: 1px solid var(--checkbox-border);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: -1px;
}

.form-group input:checked + label:before {
  background: var(--color-blue);
  border: 1px solid var(--color-blue);
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 7px;
  height: 12px;
  border: solid var(--header-search-text-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/CheckBox/styles.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;;AAIA;EACE,UAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;AADF;;AAIA;EACE,kBAAA;EACA,eAAA;EACA,0BAAA;EACA,iCAAA;AADF;;AAIA;EACE,WAAA;EACA,wBAAA;EACA,4CAAA;EACA,wCAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AADF;;AAIA;EACE,6BAAA;EACA,mCAAA;AADF;;AAIA;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,6CAAA;EACA,yBAAA;EACA,wBAAA;AADF","sourcesContent":["@import \"../../../styles/colors\";\n\n.form-group {\n  display: block;\n}\n\n.form-group input {\n  padding: 0;\n  height: initial;\n  width: initial;\n  margin-bottom: 0;\n  display: none;\n  cursor: pointer;\n}\n\n.form-group label {\n  position: relative;\n  cursor: pointer;\n  font-family: Nunito, serif;\n  color: var(--text-color-checkbox);\n}\n\n.form-group label:before {\n  content: \"\";\n  -webkit-appearance: none;\n  background-color: var(--checkbox-background);\n  border: 1px solid var(--checkbox-border);\n  padding: 8px;\n  display: inline-block;\n  position: relative;\n  vertical-align: middle;\n  cursor: pointer;\n  border-radius: 4px;\n  margin-right: 10px;\n  margin-top: -1px;\n}\n\n.form-group input:checked+label:before {\n  background: var(--color-blue);\n  border: 1px solid var(--color-blue);\n}\n\n.form-group input:checked+label:after {\n  content: \"\";\n  display: block;\n  position: absolute;\n  top: 1px;\n  left: 6px;\n  width: 7px;\n  height: 12px;\n  border: solid var(--header-search-text-color);\n  border-width: 0 2px 2px 0;\n  transform: rotate(45deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
