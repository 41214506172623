// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
  padding-left: 1px;
}
.content-container__header {
  color: var(--text-color-card);
  margin-bottom: 10px;
}
.content-container__inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 24px;
}
.content-container__text {
  color: white;
  margin-bottom: 24px;
}
.content-container__list-item {
  color: white;
}
.content-container__list-item::before {
  display: inline-block;
  background-color: var(--text-color-card);
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 8px;
}

.integration-modal__input-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.integration-modal__label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: var(--text-color-label);
}
.integration-modal__input {
  width: 220px;
  height: 40px;
  background-color: var(--submit-popup-background);
  border: 1px solid var(--border);
  color: var(--file-card-input-color);
  font-size: 14px;
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/IntegrationsMarket/IntegrationModalContent/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACE;EACE,6BAAA;EACA,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,eAAA;EACA,QAAA;EACA,mBAAA;AAAJ;AAGE;EACE,YAAA;EACA,mBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAII;EACE,qBAAA;EACA,wCAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;AAFN;;AAQE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AALJ;AAQE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,8BAAA;AANJ;AASE;EACE,YAAA;EACA,YAAA;EACA,gDAAA;EACA,+BAAA;EACA,mCAAA;EACA,eAAA;EACA,aAAA;AAPJ","sourcesContent":[".content-container {\n  padding-left: 1px;\n\n  &__header {\n    color: var(--text-color-card);\n    margin-bottom: 10px;\n  }\n\n  &__inputs {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 8px;\n    margin-bottom: 24px;\n  }\n\n  &__text {\n    color: white;\n    margin-bottom: 24px;\n  }\n\n  &__list-item {\n    color: white;\n\n    &::before {\n      display: inline-block;\n      background-color: var(--text-color-card);\n      content: \"\";\n      width: 8px;\n      height: 8px;\n      border-radius: 50%;\n      vertical-align: middle;\n      margin-right: 8px;\n    }\n  }\n}\n\n.integration-modal {\n  &__input-group {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n  }\n\n  &__label {\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 16px;\n    letter-spacing: 0;\n    color: var(--text-color-label);\n  }\n\n  &__input {\n    width: 220px;\n    height: 40px;\n    background-color: var(--submit-popup-background);\n    border: 1px solid var(--border);\n    color: var(--file-card-input-color);\n    font-size: 14px;\n    padding: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
