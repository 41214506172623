// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email {
  display: flex;
  flex-direction: column;
  margin: 0 30px 70px 40px;
}
@media screen and (max-width: 1280px) {
  .email {
    margin: 0 30px 10px 20px;
  }
}
.email__header {
  margin-left: 0.5%;
}
.email__header h1 {
  color: var(--dashboard-value-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 5px;
  margin-top: 20px;
}
.email__header p {
  color: var(--dashboard-text-color);
  margin: 10px 0 20px 0;
}
.email__body {
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  gap: 12px;
}
.email__body__item {
  display: flex;
}
.email__body__card {
  flex: 1.5 1;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.email__body table {
  flex: 1 1;
  padding: 20px;
  text-align: center;
  color: var(--dashboard-text-color);
  border-radius: 4px 4px 4px 4px;
  width: 20%;
  background: var(--card-background-color);
}
.email__body table thead {
  color: white;
  font-size: 14px;
  font-weight: bolder;
}

.bg {
  background: var(--card-background-color);
}`, "",{"version":3,"sources":["webpack://./src/Pages/IntegratedAnalysis/UsbTransfer/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,wBAAA;AADF;AAEE;EAJF;IAKI,wBAAA;EACF;AACF;AACE;EACE,iBAAA;AACJ;AACI;EACE,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AACN;AAEI;EACE,kCAAA;EACA,qBAAA;AAAN;AAGE;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,SAAA;AADJ;AAGI;EACE,aAAA;AADN;AAII;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAFN;AAKI;EACE,SAAA;EACA,aAAA;EACA,kBAAA;EAMA,kCAAA;EACA,8BAAA;EACA,UAAA;EACA,wCAAA;AARN;AAAM;EACE,YAAA;EACA,eAAA;EACA,mBAAA;AAER;;AAOA;EACE,wCAAA;AAJF","sourcesContent":["@import \"../../../styles/colors\";\n\n.email {\n  display: flex;\n  flex-direction: column;\n  margin: 0 30px 70px 40px;\n  @media screen and (max-width: 1280px) {\n    margin: 0 30px 10px 20px;\n  }\n\n  &__header {\n    margin-left: 0.5%;\n\n    h1 {\n      color: var(--dashboard-value-color);\n      font-size: 24px;\n      font-weight: 700;\n      line-height: 33px;\n      margin-bottom: 5px;\n      margin-top: 20px;\n    }\n\n    p {\n      color: var(--dashboard-text-color);\n      margin: 10px 0 20px 0;\n    }\n  }\n  &__body {\n    display: flex;\n    width: 100%;\n    margin-bottom: 60px;\n    gap: 12px;\n\n    &__item {\n      display: flex;\n    }\n\n    &__card {\n      flex: 1.5;\n      max-width: 270px;\n      display: flex;\n      flex-direction: column;\n      gap: 12px;\n    }\n\n    table {\n      flex: 1;\n      padding: 20px;\n      text-align: center;\n      thead {\n        color: white;\n        font-size: 14px;\n        font-weight: bolder;\n      }\n      color: var(--dashboard-text-color);\n      border-radius: 4px 4px 4px 4px;\n      width: 20%;\n      background: var(--card-background-color);\n    }\n  }\n}\n.bg {\n  background: var(--card-background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
