// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guest-table {
  position: relative;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  font-family: Nunito, sans-serif;
  width: 100%;
  margin: 0 auto;
}
.guest-table .thead .th {
  display: flex;
  align-items: center;
  width: 20%;
}
.guest-table .thead .th-sort {
  display: flex;
  align-items: center;
}
.guest-table .tr {
  display: flex;
  align-items: center;
  background: var(--analysis-reports-table-header) !important;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  padding: 2px 0 2px 20px;
  color: var(--collapse-content-text-color);
}
.guest-table .tbody .tr {
  background: var(--attack-map-bg) !important;
  color: var(--attack-map-card-text-color);
}
.guest-table .tbody .tr:last-child {
  border: none;
}
.guest-table .tbody .tr:hover {
  background-color: var(--analysis-reports-table-header);
}
.guest-table .tbody .td {
  width: 20%;
  color: var(--collapse-content-text-color);
}
.guest-table .th,
.guest-table .td {
  display: flex;
  padding: 8px;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/Settings/GuestImagesTable/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,+BAAA;EACA,WAAA;EACA,cAAA;AACF;AAEI;EACE,aAAA;EACA,mBAAA;EACA,UAAA;AAAN;AAGI;EACE,aAAA;EACA,mBAAA;AADN;AAKE;EACE,aAAA;EACA,mBAAA;EACA,2DAAA;EACA,eAAA;EACA,8BAAA;EACA,sCAAA;EACA,uBAAA;EACA,yCAAA;AAHJ;AAOI;EACE,2CAAA;EACA,wCAAA;AALN;AAOM;EACE,YAAA;AALR;AASI;EACE,sDAAA;AAPN;AAUI;EACE,UAAA;EACA,yCAAA;AARN;AAYE;;EAEE,aAAA;EACA,YAAA;EACA,eAAA;AAVJ","sourcesContent":[".guest-table {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  border-top-left-radius: 4px;\n  font-family: Nunito, sans-serif;\n  width: 100%;\n  margin: 0 auto;\n\n  .thead {\n    .th {\n      display: flex;\n      align-items: center;\n      width: 20%;\n    }\n\n    .th-sort {\n      display: flex;\n      align-items: center;\n    }\n  }\n\n  .tr {\n    display: flex;\n    align-items: center;\n    background: var(--analysis-reports-table-header) !important;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    border-bottom: 1px solid var(--border);\n    padding: 2px 0 2px 20px;\n    color: var(--collapse-content-text-color);\n  }\n\n  .tbody {\n    .tr {\n      background: var(--attack-map-bg) !important;\n      color: var(--attack-map-card-text-color);\n\n      &:last-child {\n        border: none;\n      }\n    }\n\n    .tr:hover {\n      background-color: var(--analysis-reports-table-header);\n    }\n\n    .td {\n      width: 20%;\n      color: var(--collapse-content-text-color);\n    }\n  }\n\n  .th,\n  .td {\n    display: flex;\n    padding: 8px;\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
