// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gap {
  gap: 50px;
}

.logo-settings {
  width: 100%;
  padding: 0 0 0 15px;
  min-height: 200px;
}
.logo-settings-inner {
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.logo-settings-header {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  text-align: left;
  color: var(--profile-name-text-color);
  margin: 20px 0 10px 0;
}
.logo-settings-inner__text {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  text-align: left;
  color: var(--profile-name-text-color);
  margin: 30px 0 0 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/LogoSettings/styles.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;AADF;;AAIA;EACE,WAAA;EACA,mBAAA;EACA,iBAAA;AADF;AAGE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAIE;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,qCAAA;EACA,qBAAA;AAFJ;AAKE;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,qCAAA;EACA,kBAAA;AAHJ","sourcesContent":["@import \"../../../styles/colors\";\n\n.gap {\n  gap: 50px;\n}\n\n.logo-settings {\n  width: 100%;\n  padding: 0 0 0 15px;\n  min-height: 200px;\n\n  &-inner {\n    align-items: center;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n  }\n\n  &-header {\n    font-size: 20px;\n    font-style: normal;\n    font-weight: bold;\n    line-height: 16px;\n    text-align: left;\n    color: var(--profile-name-text-color);\n    margin: 20px 0 10px 0;\n  }\n\n  &-inner__text {\n    font-size: 16px;\n    font-style: normal;\n    font-weight: bold;\n    line-height: 16px;\n    text-align: left;\n    color: var(--profile-name-text-color);\n    margin: 30px 0 0 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
