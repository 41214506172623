// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.url-analysis__content {
  padding: 14px 14px 0 14px;
}
.url-analysis__tabs {
  color: var(--file-card-tab-text-non-active);
  display: inline-flex;
}
.url-analysis__tabs div {
  cursor: pointer;
  margin-left: 18px;
  margin-bottom: 12px;
}
.url-analysis__tabs div:first-child {
  margin-left: 0;
}
.url-analysis__tabs--active {
  color: var(--file-card-tab-text-active);
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/Dashboard/FileCard/UrlAnalysis/styles.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;AAEE;EACE,2CAAA;EACA,oBAAA;AAAJ;AAEI;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAAN;AAEM;EACE,cAAA;AAAR;AAGI;EACE,uCAAA;AADN","sourcesContent":[".url-analysis {\n  &__content {\n    padding: 14px 14px 0 14px;\n  }\n  &__tabs {\n    color: var(--file-card-tab-text-non-active);\n    display: inline-flex;\n\n    div {\n      cursor: pointer;\n      margin-left: 18px;\n      margin-bottom: 12px;\n\n      &:first-child {\n        margin-left: 0;\n      }\n    }\n    &--active {\n      color: var(--file-card-tab-text-active);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
