import React from "react";
import StaticOsIcon from "../../../../Icon-SVG/StaticOsIcon";
import Win7OsIcon from "../../../../Icon-SVG/Win7OsIcon";
import Win10OsIcon from "../../../../Icon-SVG/Win10OsIcon";
import Win11OsIcon from "../../../../Icon-SVG/Win11OsIcon";
import LinuxOsIcon from "../../../../Icon-SVG/LinuxOsIcon";

export const OSCloud = (props) => {
  const groupCounter = props.row.original.groupCounter;
  const isStatic = props.row.original.isStatic;

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {isStatic ? (
        <div className="dashboard__table__cloudOS" style={{ float: "left" }}>
          <StaticOsIcon width={40} height={40} />
        </div>
      ) : null}
      {groupCounter.includes("win7") ? (
        <div className="dashboard__table__cloudOS" style={{ float: "left" }}>
          <Win7OsIcon width={40} height={40} />
        </div>
      ) : null}
      {groupCounter.includes("win10") ? (
        <div className="dashboard__table__cloudOS">
          <Win10OsIcon width={40} height={40} />
        </div>
      ) : null}
      {groupCounter.includes("win11") ? (
        <div className="dashboard__table__cloudOS">
          <Win11OsIcon width={50} height={50} />
        </div>
      ) : null}
      {groupCounter.includes("linux22") ? (
        <div className="dashboard__table__cloudOS">
          <LinuxOsIcon width={50} height={50} />
        </div>
      ) : null}
    </div>
  );
};

export default OSCloud;
