import React from "react";
import UserIcon from "../../../Components/Icon-SVG/UserIcon";
import LogoutIcon from "../../../Components/Icon-SVG/LogoutIcon";
import { Link } from "react-router-dom";
import ArrowBottomIcon from "../../../Components/Icon-SVG/ArrowBottomIcon";
import ArrowTopIcon from "../../../Components/Icon-SVG/ArrowTopIcon";
import packageJson from "../../../../package.json";
import "./styles.scss";

const CloudMenu = ({ show, setShow, username }) => {
  const logoutHandler = (e) => {
    e.stopPropagation();
    setShow(true);
  };
  return (
    <>
      {show ? (
        <>
          <ArrowTopIcon width={21} height={8} margin="0 5px" />
          <div className="header__menu-cl">
            <div className="header__menu-cl__first-row">
              <p>Welcome {username}</p>
              <span>{packageJson.version}</span>
            </div>
            <Link to="/profile" className="header__menu-cl-button">
              <UserIcon width={12} height={12} />
              <span>Profile</span>
            </Link>
            <button className="header__menu-cl-button" onClick={logoutHandler}>
              <LogoutIcon width={12} height={12} />
              <span>Logout</span>
            </button>
          </div>
        </>
      ) : (
        <ArrowBottomIcon width={21} height={9} margin="0 5px" />
      )}
    </>
  );
};

export default CloudMenu;
