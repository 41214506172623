import { Link, useLocation } from "react-router-dom";
import ExpandOrShrinkIcon from "../DropDown/ExpandOrShrinkIcon";

const SidebarItem = ({
  collapsed,
  item,
  clickedExpandableItem = "",
  expandableItemClickHandler = () => {},
  clickedExpandableSubItem = "",
  expandableSubItemClickHandler = () => {},
}) => {
  const location = useLocation();
  const pathname = location.pathname;

  const isItemExpanded = clickedExpandableItem === item.text;
  const isSubItemExpanded = (subItemText) => clickedExpandableSubItem === subItemText;

  return (
    <>
      <Link
        to={item.to}
        className={`sidebar-item ${pathname === item.to ? "sidebar-item--active" : ""} ${
          collapsed ? "sidebar-item--collapsed" : ""
        }`}
        onClick={(e) => expandableItemClickHandler(e, item.to, item.text)}>
        {item.icon(pathname)}
        {collapsed ? (
          <p className="sidebar-item__text">{item.shortText || item.text}</p>
        ) : (
          <p className="sidebar-item__text">{item.text}</p>
        )}
        {item?.subItems?.length ? (
          <ExpandOrShrinkIcon expanded={isItemExpanded} collapsed={collapsed} color="#848ca3" />
        ) : null}
      </Link>
      {isItemExpanded
        ? item?.subItems?.map((subItem, i) => (
            <div key={i}>
              <Link
                to={subItem.to}
                className={`sidebar-subitem ${
                  pathname === subItem.to ? "sidebar-subitem--active" : ""
                } ${collapsed ? "sidebar-subitem--collapsed" : ""}`}
                onClick={(e) => expandableSubItemClickHandler(e, subItem.to, subItem.text)}>
                {subItem.icon(pathname, subItem.to)}
                {collapsed ? (
                  <p className="sidebar-subitem__text">{subItem.shortText || subItem.text}</p>
                ) : (
                  <p className="sidebar-subitem__text">{subItem.text}</p>
                )}
                {subItem?.subItems?.length ? (
                  <ExpandOrShrinkIcon
                    expanded={isSubItemExpanded(subItem.text)}
                    collapsed={collapsed}
                    color="var(--sidebar-item-secondary)"
                  />
                ) : null}
              </Link>
              {isSubItemExpanded(subItem.text)
                ? subItem?.subItems?.map((grandSubItem, i) => (
                    <Link
                      key={i}
                      to={grandSubItem.to}
                      className={`sidebar-grandsubitem ${
                        pathname === grandSubItem.to ? "sidebar-grandsubitem--active" : ""
                      } ${collapsed ? "sidebar-grandsubitem--collapsed" : ""}`}>
                      {grandSubItem.icon(pathname, grandSubItem.to)}
                      {collapsed ? (
                        <p className="sidebar-grandsubitem__text">
                          {grandSubItem.shortText || grandSubItem.text}
                        </p>
                      ) : (
                        <p className="sidebar-grandsubitem__text">{grandSubItem.text}</p>
                      )}
                    </Link>
                  ))
                : null}
            </div>
          ))
        : null}
    </>
  );
};

export default SidebarItem;
