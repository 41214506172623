// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auxilary {
  padding: 20px 15px;
  border-radius: 0 4px 4px 4px;
}
.auxilary .toggle-button {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/WebServiceConfiguration/Tabs/Auxilary/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,4BAAA;AACF;AACE;EACE,cAAA;AACJ","sourcesContent":[".auxilary {\n  padding: 20px 15px;\n  border-radius: 0 4px 4px 4px;\n\n  .toggle-button {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
