import { all } from "redux-saga/effects";
import history from "../utils/history";
import historySagas from "./sagas/historySagas";
import usersSagas from "./sagas/usersSagas";
import themeSagas from "./sagas/themeSagas";
import dashboardSagas from "./sagas/dashbordSagas";
import analysisReportsSagas from "./sagas/analysisReportsSagas";
import integrationsSagas from "./sagas/integrationsSagas";
import settingsSagas from "./sagas/settingsSagas";
import networkDefenderSagas from "./sagas/networkDefenderSagas";
import healthCheckSagas from "./sagas/healthCheckSagas";
import mailGatewaySagas from "./sagas/mailGatewaySagas";
import cloudSagas from "./sagas/cloudSagas";
import containerSagas from "./sagas/containerSagas";

export default function* rootSaga() {
  yield all([
    ...historySagas(history),
    ...usersSagas,
    ...themeSagas,
    ...dashboardSagas,
    ...analysisReportsSagas,
    ...integrationsSagas,
    ...settingsSagas,
    ...networkDefenderSagas,
    ...healthCheckSagas,
    ...mailGatewaySagas,
    ...cloudSagas,
    ...containerSagas,
  ]);
}
