import React, { useState } from "react";
import Field from "../../../../Components/Field";
import Toggle from "../../../../Components/Toggle";
import "./styles.scss";

const Auxilary = () => {
  const [curtain, setCurtain] = useState(false);
  const [sysmon, setSysmon] = useState(false);
  const [PROCMON, setPROCMON] = useState(false);
  const [EVTX, setEVTX] = useState(false);

  const [root, setRoot] = useState("root");
  const [rooterPassword, setPassword] = useState("684567dfsfsd897");
  const [agent, setAgent] = useState("");
  const [sniffer, setSniffer] = useState(false);

  return (
    <div className="auxilary border">
      <h3>Auxiliar modules</h3>
      <p className="processing__description m-b-10">
        Modules to be enabled or not inside of the VM
      </p>
      <div className="flex flex-align-items-center m-b-20">
        <Toggle parentClassName="p-r-30" label="Curtain" active={curtain} setActive={setCurtain} />
        <Toggle parentClassName="p-r-30" label="Sysmon" active={sysmon} setActive={setSysmon} />
        <Toggle parentClassName="p-r-30" label="PROCMON" active={PROCMON} setActive={setPROCMON} />
        <Toggle label="EVTX" active={EVTX} setActive={setEVTX} />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <Field
          label="root"
          value={root}
          onChange={(e) => setRoot(e.target.value)}
          width="214px"
          parentClassName="m-r-20"
        />
        <Field
          label="Rooter password"
          value={rooterPassword}
          onChange={(e) => setPassword(e.target.value)}
          width="214px"
        />
      </div>
      <h3 className="m-b-5">Rooter</h3>
      <Field
        label="Upload max size"
        value={agent}
        onChange={(e) => setAgent(e.target.value)}
        placeholder="165035224"
        width="156px"
        parentClassName="m-b-20"
        tooltipText="Agent iso"
        type="number"
      />
      <Toggle
        label="Sniffer"
        parentClassName="m-b-20"
        setActive={setSniffer}
        active={sniffer}
        tooltipText="Enable or disable the use of an external sniffer (tcpdump) [yes/no]"
      />
      <div className="flex flex-align-items-center m-b-20">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Auxilary;
