import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Field from "../../../Components/Field";

const Network = () => {
  const [network, setNetwork] = useState(true);
  const [sortSCAP, setSortSCAP] = useState(false);
  const [loopDetection, setLoopDetection] = useState(false);
  const [dnswhitelist, setDnswhitelist] = useState(true);
  const [ipswhitelist, setIpswhitelist] = useState(true);
  const [dnswhitelistFile, setDnswhitelistFile] = useState("extra/whitelist_domains.txt");
  const [ipswhitelistFile, setIpswhitelistFile] = useState("extra/whitelist_ips.txt");

  return (
    <div className="border processing__content">
      <h3>Network</h3>
      <Toggle
        active={network}
        label="Network"
        setActive={setNetwork}
        parentClassName="m-b-20 m-t-20"
        type
      />
      <Toggle
        active={sortSCAP}
        label="Sort SCAP"
        setActive={setSortSCAP}
        parentClassName="m-b-20"
      />

      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          active={dnswhitelist}
          label="DNS whitelist"
          setActive={setDnswhitelist}
          parentClassName="p-r-40"
          tooltipText="DNS whitelisting to ignore domains/IPs configured in network.py This should be disabled when utilizing InetSim/Remnux as we end up resolving the IP from fakedns which would then remove all domains associated with that resolved IP"
        />
        <Toggle
          active={ipswhitelist}
          label="Ip whitelist"
          setActive={setIpswhitelist}
          parentClassName="p-r-40"
        />
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <Field
          width="286px"
          label="DNS whitelist file"
          parentClassName="p-r-20"
          tooltipText="additional entries"
          value={dnswhitelistFile}
          onChange={(e) => setDnswhitelistFile(e.target.value)}
        />
        <Field
          width="286px"
          label="Ip whitelist file"
          value={ipswhitelistFile}
          onChange={(e) => setIpswhitelistFile(e.target.value)}
        />
      </div>
      <h3>Loop detection</h3>
      <Toggle
        active={loopDetection}
        label="Loop detection"
        tooltipText="Should the server use a compressed version of behavioural logs? This helps in saving space in Mongo, accelerates searchs and reduce the size of the final JSON report."
        setActive={setLoopDetection}
        parentClassName="p-r-40 m-b-20 m-t-10"
        type
      />
      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Network;
