import React, { useState } from "react";
import TextField from "../../../../../../../Components/common/TextField/TextField";
import CheckBox from "../../../../../../../Components/common/CheckBox/CheckBox";
import "./styles.scss";
import Toggle from "../../../../Components/Toggle";

const Main = () => {
  const [overviewGuest, setOverviewGuest] = useState(true);
  const [overviewLoggedIn, setOverviewLoggedIn] = useState(false);
  const [overviewPrivileged, setOverviewPrivileged] = useState(true);
  const [overviewModerator, setOverviewModerator] = useState(false);
  const [overviewAdmin, setOverviewAdmin] = useState(false);

  const [apiGuest, setApiGuest] = useState(false);
  const [apiLoggedIn, setApiLoggedIn] = useState(true);
  const [apiPrivileged, setApiPrivileged] = useState(false);
  const [apiModerator, setApiModerator] = useState(false);
  const [apiAdmin, setApiAdmin] = useState(true);

  const [guestAccess, setGuestAccess] = useState(true);
  const [freeService, setFreeService] = useState(false);
  const [limitedService, setLimitedService] = useState(true);
  const [limitedSignatures, setLimitedSignatures] = useState(true);
  const [limitedLinux, setLimitedLinux] = useState(false);

  const [sslGuest, setSslGuest] = useState(true);
  const [sslLoggedIn, setSslLoggedIn] = useState(false);
  const [sslPrivileged, setSslPrivileged] = useState(false);
  const [sslModerator, setSslModerator] = useState(true);
  const [sslAdmin, setSslAdmin] = useState(false);

  return (
    <div className="main border">
      <div className="flex-align-items-center flex">
        <TextField
          labelText={"Results folder base path"}
          placeholder="/home/mayonnaise/VxStream/VxAnalysisResults/"
        />
        <TextField labelText={"Sample dir"} placeholder="www/sample/" />
        <TextField labelText={"Sample dir public"} placeholder="/sample/" />
      </div>
      <h5 className="main__text">Enable sample overview page</h5>
      <div className="flex flex-align-items-center m-b-20">
        <CheckBox
          id="overviewGuest"
          checked={overviewGuest}
          setChecked={setOverviewGuest}
          className="main__text m-r-40"
          text={"Guest"}
        />
        <CheckBox
          id="overviewLoggedIn"
          checked={overviewLoggedIn}
          setChecked={setOverviewLoggedIn}
          className="main__text m-r-40"
          text="Logged In"
        />
        <CheckBox
          id="overviewPrivileged"
          checked={overviewPrivileged}
          setChecked={setOverviewPrivileged}
          className="main__text m-r-40"
          text="Privileged"
        />
        <CheckBox
          id="overviewModerator"
          checked={overviewModerator}
          setChecked={setOverviewModerator}
          className="main__text m-r-40"
          text="Moderator"
        />
        <CheckBox
          id="overviewAdmin"
          checked={overviewAdmin}
          setChecked={setOverviewAdmin}
          className="main__text"
          text="Administrator"
        />
      </div>

      <h5 className="main__text">Allow utilizing the api/scan API</h5>
      <div className="flex flex-align-items-center m-b-20">
        <CheckBox
          id="apiGuest"
          checked={apiGuest}
          setChecked={setApiGuest}
          className="main__text m-r-40"
          text="Guest"
        />
        <CheckBox
          id="apiLoggedIn"
          checked={apiLoggedIn}
          setChecked={setApiLoggedIn}
          className="main__text m-r-40"
          text="Logged In"
        />
        <CheckBox
          id="apiPrivileged"
          checked={apiPrivileged}
          setChecked={setApiPrivileged}
          className="main__text m-r-40"
          text="Privileged"
        />
        <CheckBox
          id="apiModerator"
          checked={apiModerator}
          setChecked={setApiModerator}
          className="main__text m-r-40"
          text="Moderator"
        />
        <CheckBox
          id="apiAdmin"
          checked={apiAdmin}
          setChecked={setApiAdmin}
          className="main__text "
          text="Administrator"
        />
      </div>

      <div className="flex flex-align-items-center m-b-30 m-t-30">
        <Toggle
          label="Allow guest access"
          active={guestAccess}
          setActive={setGuestAccess}
          parentClassName=" p-r-40"
        />
        <Toggle
          label="Free service"
          active={freeService}
          setActive={setFreeService}
          parentClassName=" p-r-40"
        />
        <Toggle
          label="Limited service"
          active={limitedService}
          setActive={setLimitedService}
          parentClassName=" p-r-40"
        />

        <Toggle
          label="Limited signatures"
          active={limitedSignatures}
          setActive={setLimitedSignatures}
          parentClassName=" p-r-40"
        />
        <Toggle
          label="Limited linux signatures"
          active={limitedLinux}
          setActive={setLimitedLinux}
        />
      </div>

      <h5 className="main__text">Enable SSL traffic display</h5>
      <div className="flex flex-align-items-center">
        <div className="flex flex-align-items-center m-b-20">
          <CheckBox
            id={"sslGuest"}
            checked={sslGuest}
            setChecked={setSslGuest}
            className="main__text m-r-40"
            text="Guest"
          />
          <CheckBox
            id="sslLoggedIn"
            checked={sslLoggedIn}
            setChecked={setSslLoggedIn}
            className="main__text m-r-40"
            text="Logged In"
          />
          <CheckBox
            id="sslPrivileged"
            checked={sslPrivileged}
            setChecked={setSslPrivileged}
            className="main__text m-r-40"
            text="Privileged"
          />
          <CheckBox
            id="sslModerator"
            checked={sslModerator}
            setChecked={setSslModerator}
            className="main__text m-r-40"
            text="Moderator"
          />
          <CheckBox
            id="sslAdmin"
            checked={sslAdmin}
            setChecked={setSslAdmin}
            className="main__text"
            text="Administrator"
          />
        </div>
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Main;
