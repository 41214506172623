// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.integration-market {
  flex-direction: column;
  margin: 20px;
}
@media screen and (max-width: 1280px) {
  .integration-market {
    margin: 20px;
  }
}
.integration-market__header {
  margin-left: 0.5%;
}
.integration-market__header h1 {
  color: var(--dashboard-value-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 5px;
  margin-top: 20px;
}
.integration-market__header p {
  color: var(--dashboard-text-color);
  margin: 10px 0 20px 0;
}
.integration-market__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Kartları sola daya */
  margin: 0 auto;
}
.integration-market__images {
  height: 60px;
  width: 60px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/IntegrationMarket/styles.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,sBAAA;EACA,YAAA;AAEF;AAAE;EAJF;IAKI,YAAA;EAGF;AACF;AADE;EACE,iBAAA;AAGJ;AADI;EACE,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AAGN;AAAI;EACE,kCAAA;EACA,qBAAA;AAEN;AAEE;EACE,aAAA;EACA,eAAA;EACA,2BAAA,EAAA,uBAAA;EACA,cAAA;AAAJ;AAGE;EACE,YAAA;EACA,WAAA;EACA,0CAAA;EACA,kBAAA;AADJ","sourcesContent":[".integration-market {\n  flex-direction: column;\n  margin: 20px;\n\n  @media screen and (max-width: 1280px) {\n    margin: 20px;\n  }\n\n  &__header {\n    margin-left: 0.5%;\n\n    h1 {\n      color: var(--dashboard-value-color);\n      font-size: 24px;\n      font-weight: 700;\n      line-height: 33px;\n      margin-bottom: 5px;\n      margin-top: 20px;\n    }\n\n    p {\n      color: var(--dashboard-text-color);\n      margin: 10px 0 20px 0;\n    }\n  }\n\n  &__cards {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-start; /* Kartları sola daya */\n    margin: 0 auto;\n  }\n\n  &__images {\n    height: 60px;\n    width: 60px;\n    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);\n    border-radius: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
