// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button:hover {
  background-color: var(--hover-item-color);
}

button:active:not(:disabled) {
  background-color: var(--hover-item-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/DroppedFileList/components/TypeCard/styles.scss"],"names":[],"mappings":"AAEA;EACE,yCAAA;AADF;;AAGA;EACE,oDAAA;AAAF","sourcesContent":["@import \"../../../../../styles/colors\";\n\nbutton:hover {\n  background-color: var(--hover-item-color);\n}\nbutton:active:not(:disabled) {\n  background-color: var(--hover-item-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
