// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.malwarestats {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 4px;
  width: 100%;
}
.malwarestats__item-container {
  width: 100%;
  height: 75px;
  border: 1px solid var(--border);
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8px;
}
@media screen and (max-width: 1405px) {
  .malwarestats__item-container {
    height: 70px;
  }
}
.malwarestats__numbers-container {
  display: flex;
  align-items: center;
  gap: 6px;
}
.malwarestats__item-percent {
  font-size: 16px;
}
.malwarestats__item-count {
  padding-left: 6px;
  border-left: 1px solid #dddddd;
  line-height: 1;
  font-size: 12px;
  color: #dddddd;
}
.malwarestats__item-name {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/Dashboard/MalwareStats/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,QAAA;EACA,eAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,+BAAA;EACA,iBAAA;EACA,4BAAA;EACA,+BAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,cAAA;AACJ;AACI;EAbF;IAcI,YAAA;EAEJ;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,iBAAA;EACA,8BAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;AADJ;AAIE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAFJ","sourcesContent":[".malwarestats {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  gap: 8px;\n  margin-top: 4px;\n  width: 100%;\n\n  &__item-container {\n    width: 100%;\n    height: 75px;\n    border: 1px solid var(--border);\n    border-left: none;\n    border-top-right-radius: 4px;\n    border-bottom-right-radius: 4px;\n    color: white;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 0 8px;\n\n    @media screen and (max-width: 1405px) {\n      height: 70px;\n    }\n  }\n\n  &__numbers-container {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n  }\n\n  &__item-percent {\n    font-size: 16px;\n  }\n\n  &__item-count {\n    padding-left: 6px;\n    border-left: 1px solid #dddddd;\n    line-height: 1;\n    font-size: 12px;\n    color: #dddddd;\n  }\n\n  &__item-name {\n    font-size: 13px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
