// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary__table {
  border-bottom: 1px solid var(--border);
}
.summary__table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--settings-table-header);
  padding: 10px 20px;
}
.summary__table-header p {
  width: 16.6%;
  display: flex;
  color: var(--settings-text-table);
}
.summary__table-body {
  padding: 0 20px;
}
.summary__table-body-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
  padding: 10px 0;
  border-bottom: 1px solid var(--border);
}
.summary__table-body-item:last-child {
  border: none;
}
.summary__table-body-item p {
  width: 16.6%;
  display: flex;
  color: var(--settings-header-text);
}
.summary__table-body-item p:nth-child(1) {
  color: var(--settings-text-table);
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/Settings/Notifications/SummaryTable/styles.scss"],"names":[],"mappings":"AAEA;EACE,sCAAA;AADF;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,wCAAA;EACA,kBAAA;AADJ;AAGI;EACE,YAAA;EACA,aAAA;EACA,iCAAA;AADN;AAKE;EACE,eAAA;AAHJ;AAKI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,eAAA;EACA,sCAAA;AAHN;AAKM;EACE,YAAA;AAHR;AAMM;EACE,YAAA;EACA,aAAA;EACA,kCAAA;AAJR;AAMQ;EACE,iCAAA;AAJV","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.summary__table {\n  border-bottom: 1px solid var(--border);\n\n  &-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background: var(--settings-table-header);\n    padding: 10px 20px;\n\n    p {\n      width: 16.6%;\n      display: flex;\n      color: var(--settings-text-table);\n    }\n  }\n\n  &-body {\n    padding: 0 20px;\n\n    &-item {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      flex-wrap: wrap;\n      margin: 5px 0;\n      padding: 10px 0;\n      border-bottom: 1px solid var(--border);\n\n      &:last-child {\n        border: none;\n      }\n\n      p {\n        width: 16.6%;\n        display: flex;\n        color: var(--settings-header-text);\n\n        &:nth-child(1) {\n          color: var(--settings-text-table);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
