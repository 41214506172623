// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maintenance {
  background-color: var(--layout-bg);
  color: var(--header-search-text-color);
  font-family: Arial, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

p {
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ErrorPage/styles.scss"],"names":[],"mappings":"AAGA;EACE,kCAAA;EACA,sCAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,SAAA;AAFF;;AAKA;EACE,eAAA;EACA,mBAAA;AAFF;;AAKA;EACE,mBAAA;AAFF","sourcesContent":["@import \"../../styles/colors.scss\";\n\n\n.maintenance {\n  background-color: var(--layout-bg);\n  color: var(--header-search-text-color);\n  font-family: Arial, sans-serif;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  margin: 0;\n}\n\nh1 {\n  font-size: 36px;\n  margin-bottom: 20px;\n}\n\np {\n  margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
