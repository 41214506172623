import { useParams } from "react-router-dom";

import SignatureList from "./components/SignatureList";
import UploadSignature from "./components/UploadSignature";

import "./styles.scss";

const getSignatureTypeWordings = (signatureType) => {
  switch (signatureType) {
    case "yara":
      return {
        capitalized: "Yara",
        normal: "Yara",
        camelCase: "yara",
        lowerCase: "yara",
        slug: signatureType,
      };
    case "clamav":
      return {
        capitalized: "Clamav",
        normal: "Clamav",
        camelCase: "clamav",
        lowerCase: "clamav",
        slug: signatureType,
      };
    default:
      return { capitalized: "", normal: "", camelCase: "", lowerCase: "", slug: "" };
  }
};

const CustomSignatures = () => {
  const { signatureType } = useParams();

  return (
    <div className="custom-signatures">
      <UploadSignature signatureTypeWordings={getSignatureTypeWordings(signatureType)} />
      <SignatureList signatureTypeWordings={getSignatureTypeWordings(signatureType)} />
    </div>
  );
};

export default CustomSignatures;
