// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button:hover {
  background-color: var(--hover-item-color);
}

.tab-number {
  position: absolute;
  color: var(--tabs-button-number-text);
  font-size: 16px;
  font-weight: bold;
  background-color: var(--tabs-button-number-bg);
  width: 25px;
  height: 25px;
  top: -10px;
  right: -10px;
  border-radius: 100px;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/TabButton/styles.scss"],"names":[],"mappings":"AAEA;EACE,yCAAA;AADF;;AAIA;EACE,kBAAA;EACA,qCAAA;EACA,eAAA;EACA,iBAAA;EACA,8CAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;EACA,oBAAA;AADF","sourcesContent":["@import \"../../../styles/colors\";\n\nbutton:hover {\n  background-color: var(--hover-item-color);\n}\n\n.tab-number {\n  position: absolute;\n  color: var(--tabs-button-number-text);\n  font-size: 16px;\n  font-weight: bold;\n  background-color: var(--tabs-button-number-bg);\n  width: 25px;\n  height: 25px;\n  top: -10px;\n  right: -10px;\n  border-radius: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
