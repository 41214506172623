import { createSlice } from "@reduxjs/toolkit";

export const name = "container";

export const containerSlice = createSlice({
  name,
  initialState: {
    containerService: [],
    containerServiceLoading: false,
    liveResponsibles: { mailGateway: [], networkDefender: [] },
    liveResponsiblesLoading: false,
    containerServices: [],
    containerServicesLoading: false,
    updatePodName: "",
    updatePodLoading: false,
    restartPodName: "",
    restartPodLoading: false,
  },
  reducers: {
    RequestContainerService: (state) => {
      state.containerServiceLoading = true;
    },
    SuccessContainerService: (state, action) => {
      state.containerService = action.payload;
      state.containerServiceLoading = false;
    },
    FailureContainerService: (state) => {
      state.containerService = [];
      state.containerServiceLoading = false;
    },
    RequestLiveResponsibles: (state) => {
      state.liveResponsiblesLoading = true;
    },
    SuccessLiveResponsibles: (state, action) => {
      state.liveResponsiblesLoading = false;
      state.liveResponsibles = action.payload;
    },
    FailureLiveResponsibles: (state) => {
      state.liveResponsiblesLoading = false;
      state.liveResponsibles = { mailGateway: [], networkDefender: [] };
    },
    RequestContainerServices: (state) => {
      state.containerServicesLoading = true;
    },
    SuccessContainerServices: (state, action) => {
      state.containerServices = action.payload;
      state.containerServicesLoading = false;
    },
    FailureContainerServices: (state) => {
      state.containerServices = [];
      state.containerServicesLoading = false;
    },
    RequestPodUpdate: (state, action) => {
      state.updatePodName = action.payload.podName;
      state.updatePodLoading = true;
    },
    SuccessPodUpdate: (state) => {
      state.updatePodName = "";
      state.updatePodLoading = false;
    },
    FailurePodUpdate: (state) => {
      state.updatePodName = "";
      state.updatePodLoading = false;
    },
    RequestPodRestart: (state, action) => {
      state.restartPodName = action.payload.podName;
      state.restartPodLoading = true;
    },
    SuccessPodRestart: (state) => {
      state.restartPodName = "";
      state.restartPodLoading = false;
    },
    FailurePodRestart: (state) => {
      state.restartPodName = "";
      state.restartPodLoading = false;
    },
  },
});

export default containerSlice;
