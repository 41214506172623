// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiselect__container {
  position: relative;
  width: 178px;
  max-width: 300px;
}
.multiselect__select {
  position: relative;
  padding: 4px 8px;
  border: 1px solid var(--checkbox-border);
  border-radius: 4px;
  background-color: var(--layout-bg);
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.multiselect__select__expand-arrow {
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  height: max-content;
}
.multiselect__select:hover {
  border-color: aliceblue;
}
.multiselect__select--active {
  border-color: var(--datepicker-date-bg);
}
.multiselect__text {
  width: 94%;
}
.multiselect__text--extralight {
  color: white;
}
.multiselect__text--light {
  color: rgba(255, 255, 255, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/CustomMultiSelect/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,gBAAA;EACA,wCAAA;EACA,kBAAA;EACA,kCAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAGI;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,2BAAA;EACA,YAAA;EACA,mBAAA;AADN;AAII;EACE,uBAAA;AAFN;AAKI;EACE,uCAAA;AAHN;AAOE;EACE,UAAA;AALJ;AAOI;EACE,YAAA;AALN;AAQI;EACE,+BAAA;AANN","sourcesContent":[".multiselect {\n  &__container {\n    position: relative;\n    width: 178px;\n    max-width: 300px;\n  }\n\n  &__select {\n    position: relative;\n    padding: 4px 8px;\n    border: 1px solid var(--checkbox-border);\n    border-radius: 4px;\n    background-color: var(--layout-bg);\n    height: 40px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n\n    &__expand-arrow {\n      position: absolute;\n      right: 3%;\n      top: 50%;\n      transform: translateY(-50%);\n      color: white;\n      height: max-content;\n    }\n\n    &:hover {\n      border-color: aliceblue;\n    }\n\n    &--active {\n      border-color: var(--datepicker-date-bg);\n    }\n  }\n\n  &__text {\n    width: 94%;\n\n    &--extralight {\n      color: white;\n    }\n\n    &--light {\n      color: rgba(255, 255, 255, 0.6);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
