import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Field from "../../../Components/Field";

const Static = () => {
  const [Static, setStatic] = useState(true);
  const [signature, setSignature] = useState(true);
  const [whols, setWhols] = useState(true);
  const [path, setPath] = useState("");
  const [trid, setTrid] = useState(true);
  const [identifier, setIdentifier] = useState("");
  const [definitions, setDefinitions] = useState("");

  const [strings, setStrings] = useState(true);
  const [terminated, setTerminated] = useState(false);
  const [minchars, setMinchars] = useState("");
  const [targetInfo, setTargetInfo] = useState(true);

  return (
    <div className="border processing__content">
      <div className="processing-static__header">
        <h3>Strings</h3>
        <h3>Strings</h3>
      </div>
      <div className="processing-static__body">
        <div className="processing-static__body-item">
          <Toggle
            active={Static}
            setActive={setStatic}
            parentClassName="m-b-20 m-t-10"
            label="Static"
            type
          />
          <div className="flex flex-align-items-center m-b-20">
            <Toggle
              active={signature}
              setActive={setSignature}
              parentClassName="m-r-20"
              label="User DB signature"
            />
            <Toggle active={whols} setActive={setWhols} label="Whols" />
          </div>
          <Field
            label="Procyon path"
            placeholder="data/procyon.jar"
            value={path}
            onChange={(e) => setPath(e.target.value)}
            width="286px"
            parentClassName="m-b-20"
          />
          <h3>Trid</h3>
          <Toggle
            active={trid}
            setActive={setTrid}
            label="Trid"
            parentClassName="m-t-10 m-b-20"
            type
          />
          <Field
            label="Identifier"
            onChange={(e) => setIdentifier(e.target.value)}
            value={identifier}
            parentClassName="m-b-10"
            width={"186px"}
            placeholder="data/trid/trid"
          />
          <Field
            width={"186px"}
            value={definitions}
            onChange={(e) => setDefinitions(e.target.value)}
            parentClassName="m-b-20"
            label="Definitions"
            placeholder="data/trid/triddefs.trd"
          />
        </div>
        <div className="processing-static__body-item">
          <Toggle
            active={strings}
            setActive={setStrings}
            parentClassName="m-b-20 m-t-10"
            label="Strings"
            type
          />
          <Toggle
            active={terminated}
            setActive={setTerminated}
            label="null terminated only"
            parentClassName="m-b-20"
          />
          <Field
            value={minchars}
            onChange={(e) => setMinchars(e.target.value)}
            width="100px"
            label="Minchars"
            placeholder="5"
            parentClassName="m-b-20"
            type="number"
          />
          <h3>Target info</h3>
          <Toggle
            label="Target info"
            setActive={setTargetInfo}
            active={targetInfo}
            parentClassName="m-t-10"
          />
        </div>
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Static;
