import React, { useState } from "react";
import ToggleButton from "../../../../../../../Components/common/ToggleButton/ToggleButton";
import CustomTable from "../../../../../../../Components/common/Table/CustomTable";
import TextField from "../../../../../../../Components/common/TextField/TextField";
import CustomSelect from "../../../../../../../Components/common/CustomSelect/CustomSelect";
import CheckBox from "../../../../../../../Components/common/CheckBox/CheckBox";
import { settings_SMTP_SNMP_Fixture, settingsSummary } from "../../../../../../../fixture";
import { settings_SMTP_SNMP } from "../../../../../../../utils/constants/tableHeaders";
import "./styles.scss";

const Smtp = () => {
  const [showServer, setShowServers] = useState(true);
  const [address] = useState("do-not-reply@sec.zom");

  const [domain, setDomain] = useState("");
  const [format, setFormat] = useState("");
  const [server, setServer] = useState("");
  const [defaultSend, setDefaultSend] = useState("");
  const [port, setPort] = useState("");
  const [delivery, setDelivery] = useState("");
  const [hostName, setHostName] = useState(false);
  const [userName, setUserName] = useState("");

  const [table, setTable] = useState([]);

  const removerServer = () => {
    //console.log(table);
  };

  return (
    <div className="smtp border">
      <div className="border-bottom p-t-15 p-b-15 p-l-15">
        <h3>SMTP settings</h3>
        <div className="flex flex-align-items-center">
          <TextField
            placeholder="Type a domain..."
            labelText="Domain"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
          <div className="m-l-30 m-t-10 m-b-10">
            <span className="smtp__label"> Default format</span>
          </div>
        </div>

        <div className="flex flex-align-items-center">
          <TextField
            placeholder="Type a server name..."
            labelText="SMPT Server"
            value={server}
            onChange={(e) => setServer(e.target.value)}
          />
          <div className="m-l-30 m-t-10 m-b-10">
            <span className="smtp__label">Default send us</span>
          </div>
        </div>

        <div className="flex flex-align-items-center">
          <TextField
            labelText="SMTP Server Port"
            value={port}
            onChange={(e) => setPort(e.target.value)}
          />
          <div className="m-l-30 m-t-10 m-b-10">
            <span className="smtp__label">Default Delivery</span>
          </div>
        </div>

        <div className="smtp__host">
          <div className="flex flex-align-items-center">
            <CheckBox
              setChecked={setHostName}
              checked={hostName}
              text="Return hostname"
              className="smtp__label"
            />
          </div>
          <p className="smtp__label">
            Return address: <span className="smtp__address">{address}</span>
          </p>
        </div>
        <TextField
          placeholder={"Type a server name..."}
          labelText="Return username"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <button className="m-t-20 m-b-40 primary-button">Apply Settings</button>
      </div>

      <div className="flex flex-align-items-center flex-justify-content-between p-l-15 p-t-15 p-b-15">
        <div className="flex flex-align-items-center">
          <h3>View and add Rsyslog Servers</h3>
          <ToggleButton
            rightText={!showServer ? "Disable" : "Enable"}
            active={showServer}
            onClick={setShowServers}
          />
        </div>

        {showServer && (
          <div className="flex flex-align-items-center m-b-10">
            <button className="primary-button m-r-25" onClick={removerServer}>
              Remove
            </button>
            <button className="primary-button m-r-15">Add RSYSLOG Server </button>
          </div>
        )}
      </div>
      {showServer && (
        <CustomTable
          paginationButtons={true}
          className=""
          refresh={false}
          setCheckbox={setTable}
          minHeight={0}
          checkbox
          column={settings_SMTP_SNMP}
          dataset={settings_SMTP_SNMP_Fixture}
          customPageSize={settings_SMTP_SNMP_Fixture.length}
          noDataText="No SMTP recipients defined."
        />
      )}
    </div>
  );
};

export default Smtp;
