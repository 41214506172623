// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-wrapper-cl {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-left: 2em;
  margin-right: 2em;
}
.search-wrapper-cl .search-input-cl {
  position: relative;
}
.search-wrapper-cl .search-input-cl input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  transition: box-shadow 0.3s ease;
  background-color: var(--card-background-color);
  color: #ddd;
}
.search-wrapper-cl .search-input-cl input:focus {
  box-shadow: 0 0 10px #55a0e6;
  outline: none;
  border: none;
}
.search-wrapper-cl .search-input-cl__loading {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.search-wrapper-cl .search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--header-background);
  border: 1px solid var(--header-search-border-color);
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  z-index: 10;
  list-style: none;
}
.search-wrapper-cl .search-results li {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.search-wrapper-cl .search-results li:hover {
  background-color: #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/CloudModel/CloudCommon/SearchInputCloud/styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AADF;AAGE;EACE,kBAAA;AADJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,gCAAA;EACA,8CAAA;EACA,WAAA;AAAN;AAEM;EACE,4BAAA;EACA,aAAA;EACA,YAAA;AAAR;AAII;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;AAFN;AAME;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,0CAAA;EACA,mDAAA;EACA,kBAAA;EACA,yCAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;AAJJ;AAMI;EACE,YAAA;EACA,eAAA;EACA,sCAAA;AAJN;AAMM;EACE,yBAAA;AAJR","sourcesContent":["@import \"../../../styles/colors\";\n\n.search-wrapper-cl {\n  position: relative;\n  width: 100%;\n  margin: 0 auto;\n  margin-left: 2em;\n  margin-right: 2em;\n\n  .search-input-cl {\n    position: relative;\n    input {\n      width: 100%;\n      padding: 5px;\n      border-radius: 5px;\n      border: none;\n      font-size: 16px;\n      transition: box-shadow 0.3s ease;\n      background-color: var(--card-background-color);\n      color: #ddd;\n\n      &:focus {\n        box-shadow: 0 0 10px $main--blue;\n        outline: none;\n        border: none;\n      }\n    }\n\n    &__loading {\n      position: absolute;\n      right: 10px;\n      top: 50%;\n      transform: translateY(-50%);\n    }\n  }\n\n  .search-results {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    background-color: var(--header-background);\n    border: 1px solid var(--header-search-border-color);\n    border-radius: 5px;\n    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);\n    overflow-y: auto;\n    z-index: 10;\n    list-style: none;\n\n    li {\n      padding: 8px;\n      cursor: pointer;\n      transition: background-color 0.3s ease;\n\n      &:hover {\n        background-color: #f0f0f0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
