// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yara-rules-table {
  background: var(--card-background-color);
  margin-top: 20px;
  min-height: 300px;
  position: relative;
  padding-bottom: 80px;
  border-radius: 4px;
}
.yara-rules-table__button {
  position: absolute;
  bottom: 24px;
  right: 24px;
}
.yara-rules-table .custom-table th {
  background: var(--settings-table-header);
  padding: 10px;
}
.yara-rules-table .custom-table td {
  padding: 10px 30px;
}
.yara-rules-table h3 {
  font-size: 16px;
  font-weight: 400;
  color: var(--settings-article-value);
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/YaraRules/styles.scss"],"names":[],"mappings":"AAEA;EACE,wCAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,oBAAA;EACA,kBAAA;AADF;AAGE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AADJ;AAIE;EACE,wCAAA;EACA,aAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,oCAAA;EACA,aAAA;AAJJ","sourcesContent":["@import \"../../../../../styles/colors.scss\";\n\n.yara-rules-table {\n  background: var(--card-background-color);\n  margin-top: 20px;\n  min-height: 300px;\n  position: relative;\n  padding-bottom: 80px;\n  border-radius: 4px;\n\n  &__button {\n    position: absolute;\n    bottom: 24px;\n    right: 24px;\n  }\n\n  .custom-table th {\n    background: var(--settings-table-header);\n    padding: 10px;\n  }\n\n  .custom-table td {\n    padding: 10px 30px;\n  }\n\n  h3 {\n    font-size: 16px;\n    font-weight: 400;\n    color: var(--settings-article-value);\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
