import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Field from "../../../Components/Field";

const VirusTotal = () => {
  const [virusTotal, setVirusTotal] = useState(true);
  const [timeout, setTimeout] = useState("60");
  const [empty, setEmpty] = useState(true);
  const [virusFileLookup, setVirusFileLookup] = useState(true);
  const [virusUrlLookup, setVirusUrlLookup] = useState(true);
  const [key, setKey] = useState("");
  const [urlScrub, setUrlScrub] = useState("");

  const [metaDefender, setMetaDefender] = useState(true);
  const [timeoutMeta, setTimeoutMeta] = useState("60");
  const [emptyMeta, setEmptyMeta] = useState(true);
  const [keyMeta, setKeyMeta] = useState("");
  const [metaFileLookup, setMetaFileLookup] = useState(true);
  const [metaUrlLookup, setMetaUrlLookup] = useState(true);
  const [urlScrubMeta, setUrlScrubMeta] = useState("");

  return (
    <div className="border processing__content">
      <div className="processing-static__header m-b-20">
        <h3>Virus total</h3>
      </div>
      <div className="m-b-20">
        <Toggle
          label="Virus Total"
          active={virusTotal}
          setActive={setVirusTotal}
          parentClassName="m-b-20"
          type
        />
        <Field
          label="Timeout"
          onChange={(e) => setTimeout(e.target.value)}
          value={timeout}
          width={"131px"}
          parentClassName="m-b-20"
          type="number"
        />
        <div className="flex flex-align-items-center m-b-20">
          <Toggle
            active={empty}
            setActive={setEmpty}
            label="Remove empty"
            tooltipText="Remove empty detections"
            parentClassName="p-r-40"
            type
          />
          <Toggle
            parentClassName="p-r-40"
            active={virusFileLookup}
            setActive={setVirusFileLookup}
            label="Do file lookup"
          />
          <Toggle active={virusUrlLookup} setActive={setVirusUrlLookup} label="Do URL lookup" />
        </div>

        <div className="flex flex-align-items-center m-b-20">
          <Field
            label="Key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            tooltipText="Add your VirusTotal API key here. The default API key, kindly provided by the VirusTotal team, should enable you with a sufficient throughput and while being shared with all our users, it shouldn't affect your use. key = a0283a2c3d55728300d064874239b5346fb991317e8449fe43c902879d758088"
            placeholder="9722e8700e2bdf030ba7bbd980187f8d71f72605f1df1f6f3ac2690378677beb"
            width="464px"
            parentClassName="m-r-20"
          />

          <Field
            label="URL scrub"
            value={urlScrub}
            onChange={(e) => setUrlScrub(e.target.value)}
            placeholder="(^http:\/\/serw\.clicksor\.com\/redir\.php\?url=|&InjectedParam=.+$)"
            width="464px"
          />
        </div>
      </div>
      <div className="m-b-20">
        <h3 className="m-b-20">Meta defender</h3>
        <Toggle
          label="Meta Defender"
          active={metaDefender}
          setActive={setMetaDefender}
          parentClassName="m-b-20"
          type
        />
        <Field
          label="Timeout"
          onChange={(e) => setTimeoutMeta(e.target.value)}
          value={timeoutMeta}
          width={"131px"}
          parentClassName="m-b-20"
          type="number"
        />

        <div className="flex flex-align-items-center m-b-20">
          <Toggle
            active={emptyMeta}
            setActive={setEmptyMeta}
            label="Remove empty"
            tooltipText="Remove empty detections"
            type
            parentClassName="p-r-40"
          />
          <Toggle
            parentClassName="p-r-40"
            active={metaFileLookup}
            setActive={setMetaFileLookup}
            label="Do file lookup"
          />
          <Toggle active={metaUrlLookup} setActive={setMetaUrlLookup} label="Do URL lookup" />
        </div>
        <div className="flex flex-align-items-center m-b-20">
          <Field
            label="Key"
            value={keyMeta}
            onChange={(e) => setKeyMeta(e.target.value)}
            tooltipText="Add your VirusTotal API key here. The default API key, kindly provided by the VirusTotal team, should enable you with a sufficient throughput and while being shared with all our users, it shouldn't affect your use. key = a0283a2c3d55728300d064874239b5346fb991317e8449fe43c902879d758088"
            placeholder="9722e8700e2bdf030ba7bbd980187f8d71f72605f1df1f6f3ac2690378677beb"
            width="464px"
            parentClassName="m-r-20"
            tooltipWidth="600px"
          />
          <Field
            label="URL scrub"
            value={urlScrubMeta}
            onChange={(e) => setUrlScrubMeta(e.target.value)}
            placeholder="(^http:\/\/serw\.clicksor\.com\/redir\.php\?url=|&InjectedParam=.+$)"
            width="464px"
            tooltipWidth="600px"
          />
        </div>
      </div>

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default VirusTotal;
