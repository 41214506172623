import * as React from "react";

const Win11OsIcon = ({ width = 18, height = 18 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 622 686">
    <path fill="#0d71bf" d="M79.5 20.5h175a7659 7659 0 0 1-1 175v-174q-87.106-.494-174 1z"></path>
    <path
      fill="#0078d3"
      d="M253.5 195.5a7952 7952 0 0 0-174-1v-172a7614 7614 0 0 1 174-1zM444.5 21.5q-.25 84.002.5 168a2522 2522 0 0 0 1.5-51 7175 7175 0 0 1 0-117 7058 7058 0 0 1 1 168q-4.533 6.418-12 2.5a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-6.138 2.067-12 0a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-6.138 2.067-12 0a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-6.138 2.067-12 0a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-6.138 2.067-12 0a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-6.138 2.067-12 0a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-6.138 2.067-12 0a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-6.138 2.067-12 0a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-6.138 2.067-12 0a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-6.138 2.067-12 0a6.8 6.8 0 0 0 3-1.5q-7-2-14 0 3.4 1.221 7 1.5-5.385.466-10 2.5-.926-.166-1.5-1 .102-2.763-.5-5v-167z"></path>
    <path
      fill="#0176cd"
      d="M444.5 21.5h2a7175 7175 0 0 0 0 117 2522 2522 0 0 1-1.5 51q-.75-83.998-.5-168"></path>
    <path
      fill="#1573c0"
      d="M273.5 21.5a7745 7745 0 0 1 176-1 7659 7659 0 0 1-1 175h-1v-6q.498-84.254-1-168h-171v167h-1a6975 6975 0 0 0-1-167"></path>
    <path
      fill="#237fc3"
      d="M273.5 21.5a6975 6975 0 0 1 1 167q-.384 3.438 1 6.5 84.499.75 169 .5h4a7659 7659 0 0 1-175 1z"></path>
    <path
      fill="#0177d6"
      d="M447.5 189.5v6h-3a6975 6975 0 0 0-167-1q4.615-2.034 10-2.5a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q5.862 2.067 12 0a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q5.862 2.067 12 0a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q5.862 2.067 12 0a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q5.862 2.067 12 0a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q5.862 2.067 12 0a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q5.862 2.067 12 0a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q5.862 2.067 12 0a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q5.862 2.067 12 0a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q5.862 2.067 12 0a26.7 26.7 0 0 1-7-1.5q7-2 14 0a6.8 6.8 0 0 1-3 1.5q7.467 3.918 12-2.5"></path>
    <path
      fill="#207cc4"
      d="M79.5 20.5v174q87.02-.452 174 1a3808 3808 0 0 1-174.5 0 5110 5110 0 0 1 .5-175"></path>
    <path
      fill="#0e78cb"
      d="M274.5 188.5h1q.602 2.237.5 5 .574.834 1.5 1 83.754-.498 167 1-84.501.25-169-.5-1.384-3.062-1-6.5"></path>
    <path
      fill="#1e7cc6"
      d="M79.5 215.5a3808 3808 0 0 1 174.5 0q1.248 87.752-.5 175v-1q.25-86.001-.5-172a4743 4743 0 0 0-168.5-.5q1.655 2.52-1 4.5-1.5-.5-2-2a6.85 6.85 0 0 0 1-4z"></path>
    <path
      fill="#0078d2"
      d="M79.5 215.5h3a6.85 6.85 0 0 1-1 4q.5 1.5 2 2 2.655-1.98 1-4.5a4743 4743 0 0 1 168.5.5q.75 85.999.5 172h-174z"></path>
    <path
      fill="#257dc5"
      d="M448.5 390.5v-174h-21q-75.507-.5-151 1 1.458 3.204 5 3.5a12.9 12.9 0 0 1-5 .5q.16-2.818-1.5-1-.75 84.499-.5 169v1a7747 7747 0 0 1-1-176q88.272-.482 175.5 1 1.248 87.752-.5 175"></path>
    <path
      fill="#0678d7"
      d="M427.5 216.5h21v4l-2 169v-167q.338-3.324-2.5-5a33.4 33.4 0 0 1-10 2q-4.077-.096-6.5-3"></path>
    <path
      fill="#0275d3"
      d="M276.5 221.5a85 85 0 0 0 .5 13q3.571-1.402 7.5-1v10h-8v78q16 1 0 2v30q16 1 0 2v33a3488 3488 0 0 1 0-167"></path>
    <path
      fill="#0078d2"
      d="M427.5 216.5q2.423 2.904 6.5 3a33.4 33.4 0 0 0 10-2q2.838 1.676 2.5 5-1.232 4.712-1.5 10-1 76.5 0 153 .279-3.804 1.5-7v11h-2a7058 7058 0 0 0-168-1v-33q16-1 0-2v-30q16-1 0-2v-78h8v-10q-3.929-.402-7.5 1a85 85 0 0 1-.5-13 12.9 12.9 0 0 0 5-.5q-3.542-.296-5-3.5 75.493-1.5 151-1"></path>
    <path
      fill="#017ad0"
      d="M446.5 222.5v156q-1.221 3.196-1.5 7-1-76.5 0-153 .268-5.288 1.5-10"></path>
    <path
      fill="#0776ce"
      d="M276.5 221.5a3488 3488 0 0 0 0 167q84.254-.498 168 1h-170q-.25-84.501.5-169 1.66-1.818 1.5 1"></path>
    <path fill="#0b6dba" d="M79.5 215.5v174h174v1h-175a7657 7657 0 0 1 1-175"></path>
    <path fill="#066bbe" d="M448.5 220.5v170h-174v-1h172z"></path>
    <path
      fill="#1776bf"
      d="M70.5 446.5h-12a20.7 20.7 0 0 1-1 5h-1a9.1 9.1 0 0 1 1-5q6.726-1.966 13 0"></path>
    <path
      fill="#0577d2"
      d="M27.5 447.5v1q-.412 7.323 2 14a54.7 54.7 0 0 0-2 9h-3a4.93 4.93 0 0 1-.5 3q-.3-2.73-2.5-4v-1q.585-4.58-2-8-1.356-1.063-1-3a76 76 0 0 0-2-12q5.667 1.176 11 1"></path>
    <path
      fill="#1d76be"
      d="M27.5 447.5q-5.333.176-11-1a76 76 0 0 1 2 12 1511 1511 0 0 1-4-12l6.5-1q3.915.05 6.5 2"></path>
    <path
      fill="#1c78bf"
      d="M109.5 451.5v-5a21.1 21.1 0 0 0-9 1q-.621 3.629-3 6a51 51 0 0 1 2-7.5 24.55 24.55 0 0 1 12 .5q-.791 2.754-2 5"></path>
    <path
      fill="#1479c7"
      d="M129.5 452.5q-1.144-1.152-3-1.5 1.712-1.112 0-2.5-1.783-1.393-4-1 .18 4.058-3 2-2.022 3.167 1 5.5 2.96.743 6 .5-10.773 3.49-9.5-8 7.257-8.304 13 1 1.147 2.349-.5 4"></path>
    <path
      fill="#0b76c6"
      d="M500.5 444.5v4q-1.473.961-3 2a4.46 4.46 0 0 1-1.5-2q-1.067 2.8 1.5 4.5-1.278 2.592-3 .5-3.672-1.46-8-1 6.988-3.995 14-8"></path>
    <path
      fill="#0677d7"
      d="M70.5 446.5v2q-.426 2.872 1 5v5q-2.188.1-4-1a13.1 13.1 0 0 0-1-7q-2.208 3.174-3.5 7a34.2 34.2 0 0 0-1.5-8q-1.195.686-1 2-2.146.572-2.5 3a4.93 4.93 0 0 1-.5-3 20.7 20.7 0 0 0 1-5z"></path>
    <path
      fill="#0579d2"
      d="M129.5 452.5q0 3-3 3a18.5 18.5 0 0 1-6-.5q-3.022-2.333-1-5.5 3.18 2.058 3-2 2.217-.393 4 1 1.712 1.388 0 2.5 1.856.348 3 1.5"></path>
    <path fill="#187bbc" d="M459.5 447.5q-3.022 6.102-10 5 4.934-2.487 10-5"></path>
    <path fill="#1a75b3" d="M70.5 448.5q1.556 2.145 2 5h-1q-1.426-2.128-1-5"></path>
    <path fill="#0475d6" d="M485.5 452.5h1q4.328-.46 8 1 .044 4.91-4 2-3.965 3.786-5-2z"></path>
    <path
      fill="#0775cb"
      d="M500.5 448.5v7h-5v55h-1v-52q-.626-.936-2-1l-6 1.5 1 1q-2.428.354-3 2.5a5.73 5.73 0 0 0-3 1q-1.853-6.429 4-10 1.035 5.786 5 2 4.044 2.91 4-2 1.722 2.092 3-.5-2.567-1.7-1.5-4.5a4.46 4.46 0 0 0 1.5 2 186 186 0 0 1 3-2"></path>
    <path
      fill="#0d78cb"
      d="M71.5 453.5h1l3 12a14.55 14.55 0 0 1-5-4 11.4 11.4 0 0 0-2 2.5q5.15 3.451 2.5 8.5-4.32-12.27-3.5 1-1.75-5.502-5-10-.329-1.763 1-3a12.4 12.4 0 0 1 2.5 4 17.8 17.8 0 0 0 1.5-7q1.812 1.1 4 1z"></path>
    <path
      fill="#1376c3"
      d="M449.5 452.5q-1.18 2.406-4 2a13.1 13.1 0 0 0 1 7 14.8 14.8 0 0 0 3.5-4q1.665 2.305 4.5 2-4.945 3.152-11 4a41 41 0 0 1 .5-9 18.2 18.2 0 0 1 5.5-2"></path>
    <path
      fill="#1574b9"
      d="M56.5 451.5a29.2 29.2 0 0 1-1 7.5q-.276 2.064-2 2.5 1.065-5.287 3-10"></path>
    <path
      fill="#0d70bb"
      d="M485.5 452.5v1q-5.853 3.571-4 10-1.897-1.521-2-4.5-.21-6.156 6-6.5"></path>
    <path
      fill="#1b7abf"
      d="M494.5 458.5q-4.945 1.967-10 4 .572-2.146 3-2.5l-1-1 6-1.5q1.374.064 2 1"></path>
    <path
      fill="#0177dc"
      d="M495.5 460.5v-5h5v-8q.926-.166 1.5-1a662 662 0 0 1-.5 63l-2-50a6.85 6.85 0 0 1-4 1"></path>
    <path
      fill="#0678d1"
      d="M109.5 451.5a2964 2964 0 0 1-7.5 16q-1.565-1.684-2-4-1.328 4.557 1.5 8-.422 6.804-2 14h-1q-5.008 3.232-1 7.5-2.76 4.24-2 9.5v1q-.925.166-1.5 1a71 71 0 0 0-1.5 9v3a98.4 98.4 0 0 1-14-.5q-1.32-2.034-1-4.5.563-8.116-3-15 .322-2.518-2-3-1.91-3.739-2-8-.678-5.495-1.5-11-.575-.834-1.5-1-.82-13.27 3.5-1 2.65-5.049-2.5-8.5a11.4 11.4 0 0 1 2-2.5 14.55 14.55 0 0 0 5 4 19.2 19.2 0 0 1 1 6q-.712 3.873 2 6v1a41 41 0 0 0 .5 9 49 49 0 0 0 4.5 11l2 3q3.126-2.365 6-5.5-2.687-3.159-2-7.5a27.6 27.6 0 0 1 2-10q1.812-1.1 4-1 .23-9.517 1-19a20.7 20.7 0 0 1 1-5q2.379-2.371 3-6a21.1 21.1 0 0 1 9-1z"></path>
    <path
      fill="#0378d2"
      d="M234.5 515.5v-5q-2.688-1.214-6-1.5a9.2 9.2 0 0 0 2-4.5q3.17-5 4-11 .99-2.196 1-5-.084-3.21-2-5v-2q-.175-2.975 2-5-2.063-2.495 1-4l-.5-1q-4.376 2.84-9.5 1.5-2.52-4.216 2-5.5h1q.792 5.27 5.5 3 .75-13.991.5-28h8v72a41.7 41.7 0 0 0-9 1"></path>
    <path fill="#1e76ba" d="M30.5 460.5q1.935 4.713 3 10l-2-1a41.7 41.7 0 0 1-1-9"></path>
    <path fill="#1f77c0" d="M19.5 461.5q2.585 3.42 2 8-1.902-3.608-2-8"></path>
    <path
      fill="#0f79cb"
      d="M109.5 451.5a107 107 0 0 1-4 14q-1.237 3.465-4 6-2.828-3.443-1.5-8 .434 2.316 2 4a2964 2964 0 0 0 7.5-16"></path>
    <path fill="#1b78bb" d="M275.5 465.5q2.706-.993 6-1 5.425-.153 10 2a162 162 0 0 0-16-1"></path>
    <path
      fill="#1f73b5"
      d="M321.5 467.5q-4.842-1.37-10-1v3a19.6 19.6 0 0 1-2-3.5l6-.5q3.678.059 6 2"></path>
    <path fill="#1e79b6" d="M412.5 464.5q3.765.64 7 2a18.5 18.5 0 0 1-6-.5q-.834-.574-1-1.5"></path>
    <path
      fill="#0f75c3"
      d="M412.5 464.5q.166.926 1 1.5 2.96.743 6 .5.507 5.694-1 9v-8a334 334 0 0 1-19-1q6.067-2.516 13-2"></path>
    <path
      fill="#1479c5"
      d="M215.5 465.5q-1.892 4.674-6.5 3a15.4 15.4 0 0 1-4.5 2q4.118-5.306 11-5"></path>
    <path
      fill="#0771c6"
      d="M464.5 444.5h4v71h-8q3.161-1.452 7-1v-69a19.2 19.2 0 0 0-6 1q.71-2.11 3-2"></path>
    <path
      fill="#0378d1"
      d="M500.5 444.5h4a1343 1343 0 0 0-1 69q-3.619 2.326-8 2v-55a6.85 6.85 0 0 0 4-1l2 50a662 662 0 0 0 .5-63q-.574.834-1.5 1z"></path>
    <path
      fill="#0a77cc"
      d="M56.5 451.5h1a4.93 4.93 0 0 0 .5 3q.354-2.428 2.5-3-.68 3.96-3.5 7-1.183 5.387.5 10.5-3.702 3.343-3.5 8.5-1.67-1.817-3.5 0a34.2 34.2 0 0 0-1.5 8 81 81 0 0 1-2.5 3 206 206 0 0 1 7-27q1.724-.436 2-2.5.99-3.693 1-7.5"></path>
    <path
      fill="#0478d1"
      d="M343.5 466.5h10a49.4 49.4 0 0 1-1 11q-1.983-.255-3.5 1-1.992-6.933-2.5 2 .1 2.188-1 4-.975.456-2 1a14.7 14.7 0 0 1-1.5-5q-.574.834-1.5 1a254 254 0 0 1 3-15"></path>
    <path fill="#137cc1" d="M505.5 453.5v63h-11v-6h1v5q4.381.326 8-2z"></path>
    <path
      fill="#0477ce"
      d="M27.5 448.5q2.403 5.608 3 12a41.7 41.7 0 0 0 1 9 59.3 59.3 0 0 0-1.5 16q-1.006 1.129-2.5 1-1.334-4.905-4-9l-2-7q2.2 1.27 2.5 4 .725-1.422.5-3h3a54.7 54.7 0 0 1 2-9q-2.412-6.677-2-14"></path>
    <path
      fill="#1973b8"
      d="M152.5 467.5q-4.842-1.37-10-1v49h10q-5.193 1.473-11 1v-51q6.224-.87 11 2"></path>
    <path
      fill="#0778cf"
      d="M185.5 478.5v37q-5.493.699-10-2v-4q.925-.166 1.5-1a292 292 0 0 0-1-31 9.2 9.2 0 0 0-4.5-2q-11.686-5.788-17.5 6a212 212 0 0 0-1.5 34h-10v-49q5.158-.37 10 1v4q5.905-4.086 13-6.5 17.015-3.263 20 13.5"></path>
    <path
      fill="#0579d3"
      d="M215.5 465.5a13.1 13.1 0 0 1 7 1q-1.237 1.329-3 1a19.2 19.2 0 0 1-1 6q-4.24 1.379-7 5h-3q.282-2.405-1.5-4a29.1 29.1 0 0 0 .5 9 4.93 4.93 0 0 0-3 .5q3.732 3.94.5 8.5l-1-1a357 357 0 0 1-2.5-15.5q2.159-1.486 3-4-.343-.598-1-.5 0-1 1-1a15.4 15.4 0 0 0 4.5-2q4.608 1.674 6.5-3"></path>
    <path
      fill="#1579c3"
      d="M105.5 465.5a168 168 0 0 1-5 20h-1q1.578-7.196 2-14 2.763-2.535 4-6"></path>
    <path
      fill="#0277ca"
      d="M119.5 466.5h3q2.747 3.518 3.5 8a204.7 204.7 0 0 1-.5 35 6.8 6.8 0 0 1-1.5-3 148 148 0 0 1 .5-29 33.8 33.8 0 0 0-4.5-9q-.75 23.495-.5 47a301 301 0 0 1 0-49"></path>
    <path
      fill="#1776c4"
      d="M374.5 466.5q4.687-1.469 10-1-1.13 9.537-5 18-.426-4.915 1-9.5-2.487-1.088-1.5-3.5a4.46 4.46 0 0 0 1.5 2q1.095-2.762 2-5.5a32.5 32.5 0 0 0-8-.5"></path>
    <path
      fill="#0978ce"
      d="M321.5 467.5q1.201 2.313 1 5 .35 9.308 3 18-3.876-.822-3-5h-3a209 209 0 0 0 2 18 320 320 0 0 1-10-34v-3q5.158-.37 10 1"></path>
    <path
      fill="#0378d4"
      d="m31.5 469.5 2 1v3q.42 5.68 0 11 4.563 5.576.5 11-1.665-2.305-4.5-2 .57-3.297-2-5v-2q1.494.129 2.5-1a59.3 59.3 0 0 1 1.5-16"></path>
    <path
      fill="#0c78ca"
      d="M215.5 465.5q6.928-2.32 13 2-4.52 1.284-2 5.5 5.124 1.34 9.5-1.5l.5 1q-3.063 1.505-1 4-2.175 2.025-2 5-4.612-9.057-15-8a19.2 19.2 0 0 0 1-6q1.763.329 3-1a13.1 13.1 0 0 0-7-1"></path>
    <path
      fill="#1476c1"
      d="M234.5 515.5a41.7 41.7 0 0 1 9-1v-72h-8q.25 14.009-.5 28-4.708 2.27-5.5-3 1.98 2.163 5 2v-28h11q.25 37.004-.5 74-5.401 1.98-10.5 0"></path>
    <path fill="#237aad" d="M392.5 471.5q.11 2.29-2 3-.11-2.29 2-3"></path>
    <path fill="#0977c6" d="M500.5 444.5q2.128-1.426 5-1v10l-2 60q-.386-34.525 1-69z"></path>
    <path
      fill="#0478d4"
      d="M67.5 457.5a17.8 17.8 0 0 1-1.5 7 12.4 12.4 0 0 0-2.5-4q-1.329 1.237-1 3a31.7 31.7 0 0 1-2 6q-1.724.436-2 2.5a36 36 0 0 0-1 9.5v1q-2.7-1.46-5.5-3a8.44 8.44 0 0 0-.5 4 4.93 4.93 0 0 1 3 .5 73.4 73.4 0 0 0-1.5 11.5 20.1 20.1 0 0 1-1.5-6h-3q.29 5.27-2 10-1.19-5.43 0-11a81 81 0 0 0 2.5-3 34.2 34.2 0 0 1 1.5-8q1.83-1.817 3.5 0-.202-5.157 3.5-8.5-1.683-5.113-.5-10.5 2.82-3.04 3.5-7-.195-1.314 1-2a34.2 34.2 0 0 1 1.5 8q1.292-3.826 3.5-7a13.1 13.1 0 0 1 1 7"></path>
    <path fill="#1570b6" d="M76.5 471.5q1.437 2.703 2 6-2.712-2.127-2-6"></path>
    <path
      fill="#1376be"
      d="M412.5 473.5q-6.736-.923-12 3-.255-1.983 1-3.5 5.787-1.215 11 .5"></path>
    <path fill="#1376c5" d="M96.5 458.5a333 333 0 0 0-1 19q-2.188-.1-4 1a260 260 0 0 1 5-20"></path>
    <path
      fill="#0676d7"
      d="M150.5 472.5q7.87.21 3 6.5-2.452.81-4-1.5a3.65 3.65 0 0 0-1.5 1q-1-2.5 0-5 2.191 2.074 2.5-1"></path>
    <path
      fill="#0878cd"
      d="M275.5 465.5q8.03.103 16 1 3.426.713 6 3v1a128 128 0 0 0-8.5-3 12.2 12.2 0 0 0-4 3 14.3 14.3 0 0 0-5.5-2.5l4-1a40.4 40.4 0 0 0-14 .5 9.1 9.1 0 0 1-1 5q1.633 2.151 4 3-3.823 4.67-6 10-.507-3.985 2-7-1.783-1.393-4-1v8h-4q.249-5.81-3-2v-5q5.232-11.625 18-13"></path>
    <path
      fill="#1179c7"
      d="M343.5 466.5q5.193-1.473 11-1a129 129 0 0 1 2 11q-1.083-.626-1.5-2a22.1 22.1 0 0 1-4 7 12.9 12.9 0 0 0-.5 5q-1.208-2.246-2-5-.686-1.197-2-1 .508-8.933 2.5-2 1.517-1.255 3.5-1a49.4 49.4 0 0 0 1-11z"></path>
    <path
      fill="#0378d2"
      d="M374.5 466.5a32.5 32.5 0 0 1 8 .5 100 100 0 0 1-2 5.5 4.46 4.46 0 0 1-1.5-2q-.987 2.412 1.5 3.5-1.426 4.585-1 9.5v2q-2.514 1.025-2 4h-6a90.4 90.4 0 0 1 2-18 20.7 20.7 0 0 1 1-5"></path>
    <path fill="#217fc7" d="M464.5 444.5q2.128-1.426 5-1 .497 36.258-1 72v-71z"></path>
    <path fill="#1677c2" d="M60.5 469.5q-.948 6.303-3 12a36 36 0 0 1 1-9.5q.276-2.064 2-2.5"></path>
    <path
      fill="#2987c7"
      d="M119.5 465.5v1a301 301 0 0 0 0 49v1q-1.997-12.208-2-25.5.003-13.292 2-25.5"></path>
    <path
      fill="#1579bf"
      d="M297.5 469.5q5.76 4.771 8 12-2.146-.873-4-2.5l1-1a29.6 29.6 0 0 0-5-7.5z"></path>
    <path fill="#1e79bd" d="M23.5 477.5q2.665 4.095 4 9v4q-2.555-6.175-4-13"></path>
    <path
      fill="#0479d4"
      d="M122.5 466.5h5v49h-8q-.25-23.505.5-47a33.8 33.8 0 0 1 4.5 9 148 148 0 0 0-.5 29 6.8 6.8 0 0 0 1.5 3 204.7 204.7 0 0 0 .5-35q-.753-4.482-3.5-8"></path>
    <path fill="#297fb6" d="M173.5 478.5q2.514 1.025 2 4-1.267-1.757-2-4"></path>
    <path
      fill="#1676c2"
      d="M322.5 472.5a281 281 0 0 1 8 32 65 65 0 0 1-4-7 13.1 13.1 0 0 1-1-7q-2.65-8.692-3-18"></path>
    <path fill="#1c7ac0" d="M343.5 466.5a254 254 0 0 0-3 15l-2 1q1.6-8.431 5-16"></path>
    <path fill="#1f7abc" d="M67.5 473.5q.925.166 1.5 1 .822 5.505 1.5 11-2.618-5.55-3-12"></path>
    <path fill="#1977ba" d="M257.5 478.5v5l-2 2q-.135-3.978 2-7"></path>
    <path
      fill="#1277bd"
      d="M390.5 475.5q-.21 2.436 2 3.5-1.61 4.668 0 9.5-1.935-.837-2.5-3-1.212-5.29.5-10"></path>
    <path
      fill="#0978ce"
      d="M203.5 471.5q.657-.098 1 .5-.841 2.514-3 4a357 357 0 0 0 2.5 15.5l1 1q3.232-4.56-.5-8.5a4.93 4.93 0 0 1 3-.5 29.1 29.1 0 0 1-.5-9q1.782 1.595 1.5 4h3q-3.883 6.884-4 15v4q-6.306 1.24-3 7 .005 4.653-1.5 1-1.326 3.211 1.5 5 1.329-1.237 1-3 1.763-.329 3 1a9.1 9.1 0 0 0-1 5 16.3 16.3 0 0 0-5-2q-4.06-4.12-5-10a161 161 0 0 1 1-21q.642-5.616 5-9"></path>
    <path fill="#0d70b7" d="M233.5 483.5q1.916 1.79 2 5-.01 2.804-1 5 .37-5.158-1-10"></path>
    <path
      fill="#0d78cb"
      d="M345.5 484.5a825 825 0 0 1-9 31q-5.79-.462-11-2h6q-.442-3.352 1-6 4-1.5 0-3 .599-5.424 3-10a60.5 60.5 0 0 1 3-12l2-1q.926-.166 1.5-1a14.7 14.7 0 0 0 1.5 5q1.025-.544 2-1"></path>
    <path
      fill="#0677d1"
      d="M399.5 466.5q9.483.77 19 1v8q-3.429-.143-6-2-5.213-1.715-11-.5-1.255 1.517-1 3.5-.335 6.854 6 9a19.6 19.6 0 0 0 5 7.5q2.452.81 4-1.5 7.086 5.736 6 15a5.73 5.73 0 0 1-1 3q-6.177-1.878-7 5-8.67 2.595-18 2a9.1 9.1 0 0 1-5-1 21.1 21.1 0 0 1-1-9q8.309 6.09 17 2h6q.804-4.797-2-8-1.61-2.077-4-3.5-8.297-2.386-14-8.5-1.61-4.832 0-9.5-2.21-1.064-2-3.5v-1q2.11-.71 2-3 2.239-4.235 7-5"></path>
    <path fill="#1177c8" d="M98.5 485.5v6q-.833 5.845-3 11-.76-5.26 2-9.5-4.008-4.268 1-7.5"></path>
    <path fill="#1072bf" d="M119.5 466.5v-1h9v51h-9v-1h8v-49h-8"></path>
    <path fill="#1571b4" d="M185.5 478.5a362 362 0 0 1 1 38h-11v-3q4.507 2.699 10 2z"></path>
    <path fill="#207db7" d="M98.5 485.5h2q-.563 3.297-2 6z"></path>
    <path fill="#227abc" d="M255.5 485.5q.103 8.03 1 16-2.153-4.575-2-10 .007-3.294 1-6"></path>
    <path
      fill="#1574be"
      d="M78.5 478.5a219 219 0 0 1 5 20 49 49 0 0 1-4.5-11 41 41 0 0 1-.5-9"></path>
    <path
      fill="#137ac8"
      d="M373.5 471.5a90.4 90.4 0 0 0-2 18h6q-.514-2.975 2-4a176 176 0 0 1-6 18q-.438-4.898 2-9l-1-2a12 12 0 0 1-6 1q1.381-11.428 5-22"></path>
    <path
      fill="#1579c6"
      d="M406.5 485.5q7.773 2.262 13.5 8 3.79 6.363 1.5 13 1.086-9.264-6-15-1.548 2.31-4 1.5a19.6 19.6 0 0 1-5-7.5"></path>
    <path
      fill="#0c76c4"
      d="M171.5 475.5q2.505.377 4.5 2a292 292 0 0 1 1 31q-.575.834-1.5 1v-27q.514-2.975-2-4z"></path>
    <path
      fill="#0878d1"
      d="M297.5 470.5a29.6 29.6 0 0 1 5 7.5l-1 1q1.854 1.627 4 2.5 4.6 19.379-10 32.5a43 43 0 0 1-5 1.5q-11.683-.515 0-3.5l-4-1a48 48 0 0 1 6.5-4.5 12.9 12.9 0 0 0 .5-5q2.278-4.539 2-10-.005-3.788-1-7-6.505-14.9-22-9-2.367-.849-4-3a9.1 9.1 0 0 0 1-5 40.4 40.4 0 0 1 14-.5l-4 1a14.3 14.3 0 0 1 5.5 2.5 12.2 12.2 0 0 1 4-3q4.342 1.363 8.5 3"></path>
    <path
      fill="#0477d3"
      d="M356.5 476.5q1.7 4.305 2 9a34.2 34.2 0 0 1 .5 13q4.33 3.378 4 9 3.09-1.892 4.5-5a31 31 0 0 0 2-2 11.64 11.64 0 0 1 2 5v4q-1.763-.329-3 1a6.85 6.85 0 0 1 1 4 50.5 50.5 0 0 1-10-.5q5.687-3.375 0-7.5a10.3 10.3 0 0 0-1.5 4 64.3 64.3 0 0 1-3.5-13.5q-.504-1.545-2-1.5a33.7 33.7 0 0 1-2-9 12.9 12.9 0 0 1 .5-5 22.1 22.1 0 0 0 4-7q.417 1.374 1.5 2"></path>
    <path
      fill="#0478d3"
      d="m460.5 515.5-2-57q-2.188-.1-4 1-2.835.305-4.5-2a14.8 14.8 0 0 1-3.5 4 13.1 13.1 0 0 1-1-7q2.82.406 4-2 6.978 1.102 10-5 .512-1.258 2-1a19.2 19.2 0 0 1 6-1v69q-3.839-.452-7 1"></path>
    <path fill="#1774bc" d="M198.5 480.5a161 161 0 0 0-1 21q-.997-4.221-1-9-.333-6.446 2-12"></path>
    <path
      fill="#0b79ce"
      d="M33.5 473.5q1.668 3.149 2 7-.31 4.493 2 8 1.94 6.332 4 13a233 233 0 0 0 5-13q-1.19 5.57 0 11 2.29-4.73 2-10h3a20.1 20.1 0 0 0 1.5 6 73.4 73.4 0 0 1 1.5-11.5 4.93 4.93 0 0 0-3-.5 8.44 8.44 0 0 1 .5-4q2.8 1.54 5.5 3a4989 4989 0 0 1-4 14q-3.71 5.652-4 13-2.322-.482-2-3h-3a4.93 4.93 0 0 0-.5-3h-7q-.883-3.544-4.5-3v4h-1q-2.858-6.595-4-14v-2q2.57 1.703 2 5 2.835-.305 4.5 2 4.063-5.424-.5-11 .42-5.32 0-11"></path>
    <path fill="#1d7aba" d="M35.5 480.5q1.902 3.608 2 8-2.31-3.507-2-8"></path>
    <path
      fill="#0477d5"
      d="M335.5 494.5q2.001-2.488 5-4l1 1.5a12.2 12.2 0 0 0-3 4q1.364 2.684.5 5.5-1.948-.699-4.5 0a51.6 51.6 0 0 0 1-7"></path>
    <path fill="#1a78ba" d="M72.5 493.5q2.322.482 2 3v4q-2.136-3.022-2-7"></path>
    <path
      fill="#1379c5"
      d="M89.5 488.5q-.687 4.341 2 7.5-2.874 3.135-6 5.5a203 203 0 0 0 4-13"></path>
    <path
      fill="#0679d0"
      d="m373.5 503.5-2 2a11.64 11.64 0 0 0-2-5 31 31 0 0 1-2 2v-6q-.356-1.937 1-3a12 12 0 0 0 6-1l1 2q-2.438 4.102-2 9"></path>
    <path fill="#267dbe" d="M294.5 484.5q.995 3.212 1 7 .278 5.461-2 10a105 105 0 0 0 1-17"></path>
    <path
      fill="#0577d3"
      d="M325.5 490.5a13.1 13.1 0 0 0 1 7 65 65 0 0 0 4 7h2q4 1.5 0 3-.412-3.387-3-1l-2 5q-2.571-1.663-2 2a32.5 32.5 0 0 0-4-9v-1a209 209 0 0 1-2-18h3q-.876 4.178 3 5"></path>
    <path
      fill="#0677d3"
      d="M266.5 485.5v12a41 41 0 0 0 .5 9q3.495-4.023 1.5 1a18 18 0 0 0 2 3.5 30 30 0 0 0-3 3.5q-1.488.257-2-1-.822-1.782-1-3.5a43 43 0 0 0-5-1.5v-1q-.382-3.7-3-6a162 162 0 0 1-1-16l2-2q3.249-3.81 3 2h4v-8q2.217-.393 4 1-2.507 3.015-2 7"></path>
    <path
      fill="#0476d4"
      d="M207.5 497.5q-.703 4.34 3 6v6a4.93 4.93 0 0 1 3 .5q-1.32 2.034-1 4.5h-4q-1 0-1-1a9.1 9.1 0 0 1 1-5q-1.237-1.329-3-1 .329 1.763-1 3-2.826-1.789-1.5-5 1.505 3.653 1.5-1-3.306-5.76 3-7"></path>
    <path fill="#2979c2" d="M207.5 493.5a92 92 0 0 0 3 10q-3.703-1.66-3-6z"></path>
    <path
      fill="#0377d6"
      d="M49.5 509.5v1q-2.317 1.095-2.5 4-2.157-3.447-2.5-6.5-4.086-.405-8-1.5a11.64 11.64 0 0 0-2 5l-2-7v-4q3.617-.544 4.5 3h7q.725 1.422.5 3h3q-.322 2.518 2 3"></path>
    <path fill="#1676c1" d="M74.5 496.5q3.563 6.884 3 15-2.728-5.016-3-11z"></path>
    <path fill="#1b76bf" d="M256.5 501.5q2.618 2.3 3 6-3.058-2.116-3-6"></path>
    <path fill="#1a79bc" d="M53.5 496.5q-1.1 7.418-4 14v-1q.29-7.348 4-13"></path>
    <path
      fill="#0078dc"
      d="M332.5 507.5q-1.442 2.648-1 6h-6q-.571-3.663 2-2l2-5q2.588-2.387 3 1"></path>
    <path
      fill="#1879c5"
      d="M358.5 485.5a176 176 0 0 1 5.5 18l1 1a128 128 0 0 1 2.5-8v6q-1.41 3.108-4.5 5 .33-5.622-4-9a34.2 34.2 0 0 0-.5-13"></path>
    <path
      fill="#0678d0"
      d="M49.5 510.5q.2 2.688-1 5a85 85 0 0 1-13-.5q-1.255-1.517-1-3.5a11.64 11.64 0 0 1 2-5q3.914 1.095 8 1.5.343 3.053 2.5 6.5.183-2.905 2.5-4"></path>
    <path
      fill="#1876c0"
      d="M406.5 508.5q-8.691 4.09-17-2a2.43 2.43 0 0 1 .5-2q7.466 5.084 16.5 4"></path>
    <path fill="#1979c3" d="M410.5 500.5q2.804 3.203 2 8h-5q4.894-2.78 3-8"></path>
    <path
      fill="#1a76bc"
      d="M321.5 504.5a32.5 32.5 0 0 1 4 9q5.21 1.538 11 2-5.776 1.963-12 0a45 45 0 0 1-3-11"></path>
    <path
      fill="#1479c5"
      d="M420.5 509.5q-3.888 5.957-11 7.5-7.278 1.222-14-.5 9.33.595 18-2 .823-6.878 7-5"></path>
    <path
      fill="#0b77cb"
      d="M266.5 497.5q5.575 14.963 21 9.5 3.785-2.04 6-5.5a12.9 12.9 0 0 1-.5 5 48 48 0 0 0-6.5 4.5l4 1q-11.683 2.985 0 3.5-4.539 2.278-10 2-7.149.55-13-3a30 30 0 0 1 3-3.5 18 18 0 0 1-2-3.5q1.995-5.023-1.5-1a41 41 0 0 1-.5-9"></path>
    <path fill="#247abe" d="M95.5 503.5q-.6 5.424-3 10a71 71 0 0 1 1.5-9q.575-.834 1.5-1"></path>
    <path
      fill="#1077cc"
      d="M210.5 503.5q4.873 6.169 13 5-2.827 2.33-3 6 4.242-1.5 8-2-.472-2.974-3-4a803 803 0 0 0 5-4 9.2 9.2 0 0 1-2 4.5q3.312.286 6 1.5-11.624 9.656-26 5v-1h4q-.32-2.466 1-4.5a4.93 4.93 0 0 0-3-.5z"></path>
    <path fill="#0677d4" d="M223.5 508.5h2q2.528 1.026 3 4-3.758.5-8 2 .173-3.67 3-6"></path>
    <path fill="#1e79c0" d="M259.5 508.5a43 43 0 0 1 5 1.5q.178 1.718 1 3.5-3.884-1.379-6-5"></path>
    <path fill="#257cbe" d="M202.5 511.5a16.3 16.3 0 0 1 5 2q0 1 1 1v1q-3.53-1.26-6-4"></path>
    <path
      fill="#1177c7"
      d="M352.5 495.5q1.496-.045 2 1.5a64.3 64.3 0 0 0 3.5 13.5 10.3 10.3 0 0 1 1.5-4q5.687 4.125 0 7.5 4.975.747 10 .5a6.85 6.85 0 0 0-1-4q1.237-1.329 3-1v-4l2-2a39.7 39.7 0 0 1-3 12 24.55 24.55 0 0 1-12 .5 134 134 0 0 1-6-20.5"></path>
    <path
      fill="#217abf"
      d="M31.5 504.5h1l2 7q-.255 1.983 1 3.5a85 85 0 0 0 13 .5q-6.722 1.722-14 .5a32.6 32.6 0 0 1-3-11.5"></path>
    <path fill="#1773bb" d="m458.5 458.5 2 57h8q-4.687 1.469-10 1z"></path>
  </svg>
);

export default Win11OsIcon;
