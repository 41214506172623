import React, { useState } from "react";
import Field from "../../../Components/Field";
import Toggle from "../../../Components/Toggle";

const Processing = () => {
  const [size, setSize] = useState("165035224");
  const [call, setCall] = useState("0");
  const [reverse, setReverse] = useState(true);
  const [resolve, setResolve] = useState(true);
  const [sortPCAP, setSortPCAP] = useState(true);

  return (
    <div className="border cfsbox__content">
      <div className="flex flex-align-items-stretch m-b-20">
        <Field
          parentClassName="m-r-15"
          label="Analysis size limit"
          tooltipText="Set the maximum size of analyses generated files to process. This is used to avoid the processing of big files which may take a lot of processing time. The value is expressed in bytes, by default 200MB."
          value={size}
          width="156px"
          onChange={(e) => setSize(e.target.value)}
          type="number"
        />

        <Field
          parentClassName="m-l-15"
          label="Analysis call limit"
          tooltipText="The number of calls per process to process. 0 switches the limit off. 10000 api calls should be processed in less than 2 minutes"
          value={call}
          width="156px"
          onChange={(e) => setCall(e.target.value)}
          type="number"
        />
      </div>

      <div className="flex flex-align-items-center m-b-30">
        <Toggle
          label="Resolve DNS"
          parentClassName="p-r-40"
          tooltipText="Enable or disable DNS lookups."
          active={resolve}
          setActive={setResolve}
        />
        <Toggle
          active={reverse}
          setActive={setReverse}
          parentClassName="p-r-40"
          label="Reverse DNS"
          tooltipText="Enable or disable reverse DNS lookups. This information currently is not displayed in the web interface"
        />
        <Toggle
          label="Sort PCAP"
          tooltipText="Enable PCAP sorting, needed for the connection content view in the web interface."
          active={sortPCAP}
          setActive={setSortPCAP}
        />
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Processing;
