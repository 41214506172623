import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Select from "../../../Components/Select";

const Maec41 = () => {
  const [maec41, setMaec41] = useState(false);
  const [mode, setMode] = useState();
  const [processtree, setProcesstree] = useState(false);
  const [handles, setHandles] = useState(true);
  const [Static, setStatic] = useState(false);
  const [strings, setStrings] = useState(true);
  const [virusTotal, setVirusTotal] = useState(false);
  const [deDuplicate, setDeDuplicate] = useState(false);
  const [maec5, setMaec5] = useState(true);

  return (
    <div className="border reporting__content">
      <h3>MAEC41</h3>
      <Toggle
        active={maec41}
        setActive={setMaec41}
        parentClassName="m-b-20 m-t-10"
        label="MAEC41"
        type
      />

      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          active={processtree}
          setActive={setProcesstree}
          parentClassName="p-r-40"
          label="Processtree"
          type
        />
        <Toggle
          active={handles}
          setActive={setHandles}
          parentClassName="p-r-40"
          label="Output handles"
          type
        />
        <Toggle
          active={Static}
          setActive={setStatic}
          parentClassName="p-r-40"
          label="Static"
          type
        />
        <Toggle active={strings} setActive={setStrings} label="Strings" type />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          active={virusTotal}
          setActive={setVirusTotal}
          parentClassName="p-r-40"
          label="Virus Total"
          type
        />
        <Toggle active={deDuplicate} setActive={setDeDuplicate} label="DeDuplicate" type />
      </div>

      <h3>MAEC5</h3>
      <Toggle
        active={maec5}
        setActive={setMaec5}
        parentClassName="m-b-20 m-t-10"
        label="MAEC5"
        type
      />

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Maec41;
