import React from "react";

const OClockIcon = ({ color = "var(--border)", width, height }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none">
        <path
          d="M8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0ZM7.5 3C7.36739 3 7.24021 3.05268 7.14645 3.14645C7.05268 3.24021 7 3.36739 7 3.5V8.5L7.008 8.59C7.02907 8.70511 7.08984 8.80919 7.17974 8.88411C7.26964 8.95903 7.38297 9.00004 7.5 9H10.5L10.59 8.992C10.7129 8.96974 10.8231 8.90225 10.8988 8.80283C10.9745 8.70341 11.0102 8.57926 10.9989 8.45482C10.9876 8.33039 10.9301 8.21469 10.8378 8.13051C10.7455 8.04633 10.6249 7.99977 10.5 8H8V3.5L7.992 3.41C7.97093 3.29489 7.91016 3.19081 7.82026 3.11589C7.73036 3.04097 7.61703 2.99996 7.5 3Z"
          fill={color || "var(--settings-sidebar-icon)"}
        />
      </svg>
    </div>
  );
};

export default OClockIcon;
