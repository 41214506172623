// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analyze-url__input {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  height: 240px;
  max-height: 240px;
  background-color: var(--file-card-input-background-color);
  border: 1px dashed var(--border);
  color: var(--file-card-input-color);
  padding: 10px;
  margin-top: 0px;
}
.analyze-url__input:focus {
  outline: none;
}
.analyze-url__button-block {
  display: flex;
  align-items: center;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/Dashboard/FileCard/UrlAnalysis/AnalyzeUrl/styles.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,iBAAA;EACA,yDAAA;EACA,gCAAA;EACA,mCAAA;EACA,aAAA;EACA,eAAA;AAAJ;AAEI;EACE,aAAA;AAAN;AAIE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".analyze-url {\n  &__input {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    justify-content: flex-end;\n    height: 240px;\n    max-height: 240px;\n    background-color: var(--file-card-input-background-color);\n    border: 1px dashed var(--border);\n    color: var(--file-card-input-color);\n    padding: 10px;\n    margin-top: 0px;\n\n    &:focus {\n      outline: none;\n    }\n  }\n\n  &__button-block {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
