// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-button__outer {
  position: relative;
  width: 30px;
  cursor: pointer;
}
.close-button__inner {
  width: inherit;
  text-align: center;
}
.close-button__inner:before, .close-button__inner:after {
  position: absolute;
  content: "";
  height: 1px;
  width: inherit;
  background: var(--dark-theme-element);
  left: 0;
  transition: all 0.3s ease-in;
}
.close-button__inner:before {
  top: 50%;
  transform: rotate(45deg);
}
.close-button__inner:after {
  bottom: 50%;
  transform: rotate(-45deg);
}
.close-button__outer:hover label {
  opacity: 1;
}
.close-button__outer:hover .close-button__inner:before, .close-button__outer:hover .close-button__inner:after {
  transform: rotate(0);
}
.close-button__outer:hover .close-button__inner:before {
  top: 0;
}
.close-button__outer:hover .close-button__inner:after {
  bottom: 0;
}
.close-button__close {
  font-size: 0.55em;
  line-height: 4em;
  text-transform: uppercase;
  color: var(--dark-theme-element);
  transition: all 0.3s ease-in;
  opacity: 0;
  font-weight: bold;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/CloseButton/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,WAAA;EACA,eAAA;AAAJ;AAGE;EACE,cAAA;EACA,kBAAA;AADJ;AAIE;EAEE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,cAAA;EACA,qCAAA;EACA,OAAA;EACA,4BAAA;AAHJ;AAME;EACE,QAAA;EACA,wBAAA;AAJJ;AAOE;EACE,WAAA;EACA,yBAAA;AALJ;AAQE;EACE,UAAA;AANJ;AASE;EAEE,oBAAA;AARJ;AAWE;EACE,MAAA;AATJ;AAYE;EACE,SAAA;AAVJ;AAaE;EACE,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,gCAAA;EACA,4BAAA;EACA,UAAA;EACA,iBAAA;EACA,eAAA;AAXJ","sourcesContent":[".close-button {\n  &__outer {\n    position: relative;\n    width: 30px;\n    cursor: pointer;\n  }\n\n  &__inner {\n    width: inherit;\n    text-align: center;\n  }\n\n  &__inner:before,\n  &__inner:after {\n    position: absolute;\n    content: \"\";\n    height: 1px;\n    width: inherit;\n    background: var(--dark-theme-element);\n    left: 0;\n    transition: all 0.3s ease-in;\n  }\n\n  &__inner:before {\n    top: 50%;\n    transform: rotate(45deg);\n  }\n\n  &__inner:after {\n    bottom: 50%;\n    transform: rotate(-45deg);\n  }\n\n  &__outer:hover label {\n    opacity: 1;\n  }\n\n  &__outer:hover &__inner:before,\n  &__outer:hover &__inner:after {\n    transform: rotate(0);\n  }\n\n  &__outer:hover &__inner:before {\n    top: 0;\n  }\n\n  &__outer:hover &__inner:after {\n    bottom: 0;\n  }\n\n  &__close {\n    font-size: 0.55em;\n    line-height: 4em;\n    text-transform: uppercase;\n    color: var(--dark-theme-element);\n    transition: all 0.3s ease-in;\n    opacity: 0;\n    font-weight: bold;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
