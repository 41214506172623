// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
  z-index: 100;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_active {
  opacity: 1;
  pointer-events: all;
}

.submit-popup {
  overflow: visible;
  padding: 0 5px;
  max-width: 984px;
  width: 100%;
  max-height: 90vh;
  background-color: var(--submit-popup-background);
}

.modal__content {
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  min-height: 400px;
  min-width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,oCAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AACA;EACE,UAAA;EACA,mBAAA;AAEF;;AACA;EACE,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,gDAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AAEF","sourcesContent":[".backdrop {\n  z-index: 100;\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: fixed;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.modal_active {\n  opacity: 1;\n  pointer-events: all;\n}\n\n.submit-popup {\n  overflow: visible;\n  padding: 0 5px;\n  max-width: 984px;\n  width: 100%;\n  max-height: 90vh;\n  background-color: var(--submit-popup-background);\n}\n\n.modal__content {\n  padding: 20px;\n  border-radius: 12px;\n  background-color: white; // // NEED TO CHANGE\n  min-height: 400px;\n  min-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
