import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserIcon from "../../Components/Icon-SVG/UserIcon";
import OClockIcon from "../../Components/Icon-SVG/OClockIcon";
import ShieldIcon from "../../Components/Icon-SVG/ShieldIcon";
import ImageIcon from "../../Components/Icon-SVG/ImageIcon";
import NotificationPointIcon from "../../Components/Icon-SVG/NotificationPointIcon";
import CloudArrowIcon from "../../Components/Icon-SVG/CloudArrowIcon";
import TabIcon from "../../Components/Icon-SVG/TabIcon";
import Content from "./Content/Content";
import useResizeWindow from "../../utils/hook/useResizeWindows";
import CfsboxIcon from "../../Components/Icon-SVG/CFSBOXIcon";
import RoutingIcon from "../../Components/Icon-SVG/RoutingIcon";
import ReportingIcon from "../../Components/Icon-SVG/ReportingIcon";
import ProcessingIcon from "../../Components/Icon-SVG/ProcessingIcon";
import { getAllUsersRequest } from "../../store/actions/usersActions";
import Backdrop from "../../Components/UI/Backdrop/Backdrop";

import "./styles.scss";
import DataRetentionIcon from "../../Components/Icon-SVG/DataRetentionIcon";
import EmailIntegrationIcon from "../../Components/Icon-SVG/EmailIntegrationIcon";
import DumpAndRestoreIcon from "../../Components/Icon-SVG/DumpAndRestoreIcon";
// import SyslogIcon from "../../Components/Icon-SVG/SyslogIcon";

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const width = useResizeWindow();
  const users = useSelector((state) => state?.users.users);
  const defaultRole = useSelector((state) => state?.users?.user?.role);
  const [state, setState] = useState("User Accounts");
  const [collapsed, setCollapsed] = useState(true);
  const collapsedWidth = "calc(100% - 140px)";
  const sidebarWidth = width < 1300 ? "calc(100% - 360px)" : "calc(100% - 465px)";

  useEffect(() => {
    dispatch(getAllUsersRequest());
  }, [dispatch]);

  useEffect(() => {
    if (users) {
      if (btoa(defaultRole) === "QURNSU4=") {
        history.push("/settings");
      } else {
        history.push("/dashboard");
      }
    }
  }, [users, history]);

  if (!users) {
    return <Backdrop show={!users} />;
  }

  const navigation = [
    {
      name: "User Accounts",
      icon: (
        <UserIcon
          width={18}
          height={18}
          color={state === "User Accounts" && "var(--sidebar-active-icon)"}
        />
      ),
    },
    {
      name: "Data Retention",
      icon: (
        <DataRetentionIcon
          width={22}
          height={22}
          color={state === "Data Retention" && "var(--sidebar-active-icon)"}
        />
      ),
    },
    {
      name: "Backup & Restore",
      icon: (
        <DumpAndRestoreIcon
          width={22}
          height={22}
          color={state === "Backup & Restore" && "var(--sidebar-active-icon)"}
        />
      ),
    },
    // {
    //   name: "Date and Time",
    //   icon: (
    //     <OClockIcon
    //       width={18}
    //       height={18}
    //       color={state === "Date and Time" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    // {
    //   name: "Webservice Configuration",
    //   icon: (
    //     <TabIcon
    //       width={18}
    //       height={18}
    //       color={state === "Webservice Configuration" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    // {
    //   name: "Notifications",
    //   icon: (
    //     <NotificationPointIcon
    //       width={18}
    //       height={18}
    //       color={state === "Notifications" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    // {
    //   name: "Yara Rules",
    //   icon: (
    //     <ShieldIcon
    //       width={18}
    //       height={18}
    //       color={state === "Yara Rules" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    // {
    //   name: "Guest Images",
    //   icon: (
    //     <ImageIcon
    //       width={18}
    //       height={18}
    //       color={state === "Guest Images" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    // {
    //   name: "Appliance Backup & Restore",
    //   icon: (
    //     <CloudArrowIcon
    //       width={18}
    //       height={18}
    //       color={state === "Appliance Backup & Restore" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    // {
    //   name: "CFSBOX",
    //   icon: (
    //     <CfsboxIcon
    //       width={21}
    //       height={21}
    //       color={state === "CFSBOX" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    // {
    //   name: "Processing",
    //   icon: (
    //     <ProcessingIcon
    //       width={19}
    //       height={19}
    //       color={state === "Processing" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    // {
    //   name: "Reporting",
    //   icon: (
    //     <ReportingIcon
    //       width={21}
    //       height={21}
    //       color={state === "Reporting" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    // {
    //   name: "Routing",
    //   icon: (
    //     <RoutingIcon
    //       width={21}
    //       height={21}
    //       color={state === "Routing" && "var(--sidebar-active-icon)"}
    //     />
    //   ),
    // },
    {
      name: "Mail Notifications",
      icon: (
        <EmailIntegrationIcon
          width={21}
          height={21}
          color={state === "Mailing" ? "var(--sidebar-active-icon)" : "var(--border)"}
        />
      ),
    },
    // {
    //   name: "Syslog",
    //   icon: (
    //     <SyslogIcon
    //       width={21}
    //       height={21}
    //       color={state === "Syslog" ? "var(--sidebar-active-icon)" : "var(--border)"}
    //     />
    //   ),
    // },
  ];

  return (
    <div className="flex" style={{ height: "100%" }}>
      <div
        className={`settings-sidebar ${!collapsed ? "settings-sidebar-open" : ""}`}
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}>
        <nav className={`nav ${!collapsed ? "nav-open" : ""}`}>
          {navigation.map(({ name, icon }) => (
            <div
              className={`nav__item ${state === name ? "nav__item__active" : ""} ${
                collapsed ? "nav__item-hidden" : "nav__item-open"
              } `}
              onClick={() => setState(name)}
              key={name}>
              {icon}
              {!collapsed && <p className="menu__item-text">{name}</p>}
            </div>
          ))}
        </nav>
      </div>
      <div
        className="settings__body"
        style={{
          width: collapsed ? collapsedWidth : sidebarWidth,
        }}>
        <Content active={state} collapsed={collapsed} />
      </div>
    </div>
  );
};

export default Settings;
