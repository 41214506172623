import React from "react";

const NotificationPointIcon = ({ color = "var(--border)", width, height }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 15 15"
        fill="none">
        <path
          d="M6.67267 14.8535C6.95382 14.847 7.22362 14.7414 7.43434 14.5551C7.64507 14.3689 7.78315 14.1142 7.82415 13.8359H5.47656C5.51873 14.1217 5.66326 14.3824 5.88329 14.5696C6.10331 14.7568 6.38383 14.8577 6.67267 14.8535Z"
          fill={color || "var(--settings-sidebar-icon)"}
        />
        <path
          d="M13.3 12.1082L13.1483 11.9743C12.7178 11.5907 12.341 11.1509 12.028 10.6666C11.6863 9.99828 11.4814 9.26842 11.4255 8.51986V6.31509C11.4237 6.04728 11.3998 5.78008 11.3541 5.5162C10.5981 5.36081 9.91904 4.94895 9.43186 4.35034C8.94467 3.75173 8.67932 3.00316 8.68072 2.23136V1.95019C8.21474 1.72087 7.71409 1.57007 7.19897 1.50388V0.941526C7.19897 0.783504 7.1362 0.631954 7.02446 0.520216C6.91272 0.408477 6.76117 0.345703 6.60315 0.345703C6.44512 0.345703 6.29357 0.408477 6.18184 0.520216C6.0701 0.631954 6.00732 0.783504 6.00732 0.941526V1.52619C4.85393 1.68889 3.79835 2.26338 3.03545 3.14359C2.27255 4.02379 1.85385 5.15028 1.85665 6.31509V8.51986C1.80075 9.26842 1.5959 9.99828 1.25413 10.6666C0.94657 11.1497 0.575826 11.5895 0.151745 11.9743L0 12.1082V13.3668H13.3V12.1082Z"
          fill={color || "var(--settings-sidebar-icon)"}
        />
        <path
          d="M12.0284 4.46309C13.2609 4.46309 14.26 3.464 14.26 2.23155C14.26 0.999097 13.2609 0 12.0284 0C10.796 0 9.79688 0.999097 9.79688 2.23155C9.79688 3.464 10.796 4.46309 12.0284 4.46309Z"
          fill={color || "var(--settings-sidebar-icon)"}
        />
      </svg>
    </div>
  );
};

export default NotificationPointIcon;
