// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snort-suricata {
  background: var(--attack-map-bg);
  height: 100%;
  border: 1px solid var(--border);
  border-radius: 0 4px 4px 4px;
  position: relative;
}
.snort-suricata__no-data__header {
  border-bottom: 1px solid var(--text-color-card);
  margin: 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.snort-suricata__no-data__item {
  color: var(--map-text-color);
  font-size: 12px;
  line-height: 16px;
  padding: 12px 5px;
}
.snort-suricata__no-data__item:nth-child(1) {
  width: 19%;
}
.snort-suricata__no-data__item:nth-child(2) {
  width: 19%;
}
.snort-suricata__no-data__item:nth-child(3) {
  width: 6%;
}
.snort-suricata__no-data__item:nth-child(4) {
  width: 6%;
}
.snort-suricata__no-data__item:nth-child(5) {
  width: 47%;
}
.snort-suricata .custom-table__footer-pagination {
  bottom: 0px;
  margin-left: 0;
  width: 100%;
}
.snort-suricata__table-block {
  flex: 1 1;
}
.snort-suricata__table-block-signature {
  width: 47%;
}
.snort-suricata__table-block-ip {
  width: 19%;
}
.snort-suricata__table-block-protocol {
  width: 6%;
}
@media screen and (max-width: 1600px) {
  .snort-suricata .custom-table th,
  .snort-suricata td {
    font-size: 11px;
  }
}
@media screen and (max-width: 1250px) {
  .snort-suricata .custom-table th,
  .snort-suricata td {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/AnalysisReports/Content/Tabs/SnortSuricata/styles.scss"],"names":[],"mappings":"AAEA;EACE,gCAAA;EACA,YAAA;EACA,+BAAA;EACA,4BAAA;EACA,kBAAA;AADF;AAII;EACE,+CAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAFN;AAKI;EACE,4BAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AAHN;AAKM;EACE,UAAA;AAHR;AAMM;EACE,UAAA;AAJR;AAOM;EACE,SAAA;AALR;AAQM;EACE,SAAA;AANR;AASM;EACE,UAAA;AAPR;AAYE;EACE,WAAA;EACA,cAAA;EACA,WAAA;AAVJ;AAaE;EACE,SAAA;AAXJ;AAaI;EACE,UAAA;AAXN;AAcI;EACE,UAAA;AAZN;AAeI;EACE,SAAA;AAbN;AAmBI;EAFF;;IAGI,eAAA;EAfJ;AACF;AAiBI;EANF;;IAOI,eAAA;EAbJ;AACF","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.snort-suricata {\n  background: var(--attack-map-bg);\n  height: 100%;\n  border: 1px solid var(--border);\n  border-radius: 0 4px 4px 4px;\n  position: relative;\n\n  &__no-data {\n    &__header {\n      border-bottom: 1px solid var(--text-color-card);\n      margin: 0 18px;\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n    }\n\n    &__item {\n      color: var(--map-text-color);\n      font-size: 12px;\n      line-height: 16px;\n      padding: 12px 5px;\n\n      &:nth-child(1) {\n        width: 19%;\n      }\n\n      &:nth-child(2) {\n        width: 19%;\n      }\n\n      &:nth-child(3) {\n        width: 6%;\n      }\n\n      &:nth-child(4) {\n        width: 6%;\n      }\n\n      &:nth-child(5) {\n        width: 47%;\n      }\n    }\n  }\n\n  .custom-table__footer-pagination {\n    bottom: 0px;\n    margin-left: 0;\n    width: 100%;\n  }\n\n  &__table-block {\n    flex: 1;\n\n    &-signature {\n      width: 47%;\n    }\n\n    &-ip {\n      width: 19%;\n    }\n\n    &-protocol {\n      width: 6%;\n    }\n  }\n\n  .custom-table th,\n  td {\n    @media screen and (max-width: 1600px) {\n      font-size: 11px;\n    }\n\n    @media screen and (max-width: 1250px) {\n      font-size: 12px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
