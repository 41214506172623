import React from "react";

const Vm = (props) => {
  const Vm = props?.row?.original.Vm;
  return (
    <a href={Vm.url} rel="noreferrer" className="dashboard__table__linkVm">
      <div className="dashboard__table__linkVmDiv">{Vm.name}</div>{" "}
    </a>
  );
};

export default Vm;
