// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  padding: 0 20px;
}
.main .field {
  width: 100%;
  height: 40px;
  padding-right: 0;
}
.main .field::placeholder {
  font-size: 13px;
}
.main .label {
  margin-right: 25px;
  max-width: 200px;
}
.main .label:first-child {
  max-width: 100%;
}
.main .label:last-child {
  margin-right: 0;
}
.main__text {
  color: var(--settings-header-text);
  font-size: 12px;
}
.main h5 {
  margin: 10px 0;
}
.main .toggle-button {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/WebServiceConfiguration/Tabs/Main/styles.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,kBAAA;EACA,gBAAA;AAHJ;AAKI;EACE,eAAA;AAHN;AAMI;EACE,eAAA;AAJN;AAQE;EACE,kCAAA;EACA,eAAA;AANJ;AASE;EACE,cAAA;AAPJ;AAUE;EACE,cAAA;AARJ","sourcesContent":["@import \"../../../../../../../styles/colors.scss\";\n\n.main {\n  padding: 0 20px;\n\n  .field {\n    width: 100%;\n    height: 40px;\n    padding-right: 0;\n  }\n\n  .field::placeholder {\n    font-size: 13px;\n  }\n\n  .label {\n    margin-right: 25px;\n    max-width: 200px;\n\n    &:first-child {\n      max-width: 100%;\n    }\n\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n\n  &__text {\n    color: var(--settings-header-text);\n    font-size: 12px;\n  }\n\n  h5 {\n    margin: 10px 0;\n  }\n\n  .toggle-button {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
