import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import LoadingIcon from "../../../../Icon-SVG/LoadingIcon";
import RemoteConnectionIcon from "../../../../Icon-SVG/RemoteConnectionIcon";
import RefreshIcon from "../../../../Icon-SVG/RefreshIcon";
import ErrorIcon from "../../../../Icon-SVG/ErrorIcon";
import QueueIcon from "../../../../Icon-SVG/QueueIcon";
import TrashBucketIcon from "../../../../Icon-SVG/TrashBucketIcon";
import { desktopVmOptions } from "../../../../common/SubmitPopup/constants";
import {
  RequestDeleteSubmissionFromList,
  RequestResubmitSample,
} from "../../../../../store/actions/dashboardActions";
import { isCompressedItself } from "../../../../../utils/function";
import { addNotification } from "../../../../../store/actions/notifierActions";
import axiosApi from "../../../../../utils/axios";
import Spinner from "../../../../common/Spinner/Spinner";
import Modal from "../../../../UI/Modal/Modal";
import SubmitPopup from "../../../../common/SubmitPopup/SubmitPopup";
import Warning from "../../../../common/Warning/Warning";
import { prepareSubmitData } from "../../../../common/SubmitPopup/utils";
import Content from "../../../../common/SubmitPopup/Content/Content";

const SeverityStatus = (props) => {
  const dispatch = useDispatch();
  const fileName = props.row.original.file.name;
  const type = props.row.original.severity || props.type;
  const vm = props.row.original.VmDashboard;
  const progress = props?.row?.original.progress || props.progress;
  const fileHash = props?.row?.original?.file?.hash || "-";
  const rowFileName = props?.row?.original?.file?.name || "-";
  const fileID = props.row.original.id;
  const { isApt, aptClasses } = props.row.original.apt || { isApt: false, aptClasses: [] };
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);
  const [submitPopupActive, setSubmitPopupActive] = useState(false);
  const [submitFile, setSubmitFile] = useState([]);
  const [refreshHover, setRefreshHover] = useState(false);
  const [trashBucketHover, setTrashBucketHover] = useState(false);
  const [isRemoteConnectionReady, setIsRemoteConnectionReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const vms = useSelector((state) => state.dashboard.vms);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const history = useHistory();

  const checkResubmitHandler = async () => {
    if (!desktopVmOptions.some((vmOption) => vms.includes(vmOption.id))) {
      setSubmitPopupActive(false);
      setError("No vm available. Refresh the page and try again.");
      setShow(true);
      return;
    }
    if (!licenceCheck?.key) {
      dispatch(
        addNotification({
          message: "Your license is not active, please update your license key.",
          options: { variant: "error" },
        }),
      );
      return;
    } else if (vms.length === 0) {
      setError("No vm available. Refresh the page and try again.");
      setShow(true);
      return;
    }
    try {
      const response = await axiosApi.get(`api/files/submission/check-resubmit/${fileHash}`);
      const checkSample = response.data;
      if (!checkSample) {
        dispatch(
          addNotification({
            message: "Sample not found",
            options: { variant: "error" },
          }),
        );
        return;
      }
      setSubmitPopupActive(true);
      setSubmitFile([
        {
          fileName: rowFileName,
          fileHash,
        },
      ]);
    } catch (e) {
      console.log(e);
      dispatch(
        addNotification({
          message: "Sample not found",
          options: { variant: "error" },
        }),
      );
    }
  };

  const checkRemoteConnection = async () => {
    const { compressedID, compressedIDfull, fileIDfull } = props.row.original.file;
    if (
      (compressedID &&
        compressedIDfull &&
        fileIDfull &&
        isCompressedItself(compressedID, compressedIDfull, fileIDfull)) ||
      loading
    )
      return;

    setLoading(true);
    try {
      const response = await axiosApi.get(`/api/files/getVm/${fileID}`);
      if (response.data === "False" || response.data === false) {
        setIsRemoteConnectionReady(false);
        dispatch(
          addNotification({
            message: "Remote connection is not ready",
            options: { variant: "error" },
          }),
        );
      } else {
        setIsRemoteConnectionReady(true);
      }
    } catch (error) {
      setIsRemoteConnectionReady(false);
      dispatch(
        addNotification({
          message: "Remote connection is not ready",
          options: { variant: "error" },
        }),
      );
    }
    setLoading(false);
  };

  const remoteConnectionHandler = async () => {
    const { compressedID, compressedIDfull, fileIDfull } = props.row.original.file;
    if (
      compressedID &&
      compressedIDfull &&
      fileIDfull &&
      isCompressedItself(compressedID, compressedIDfull, fileIDfull)
    )
      return;

    await checkRemoteConnection();

    if (isRemoteConnectionReady) {
      history.push(vm.url, { isFreeRun: fileName === "freerun.txt" });
    } else {
      return;
    }
  };

  const deleteFromListHandler = (e) => {
    e.stopPropagation();
    if (fileID) {
      dispatch(RequestDeleteSubmissionFromList(fileID));
      history.replace("dashboard");
    }
  };

  const closePopupHandler = () => {
    setSubmitPopupActive(false);
  };

  const submitHandler = (selectedFiles) => {
    const preparedSubmitData = prepareSubmitData(selectedFiles, desktopVmOptions);
    dispatch(RequestResubmitSample(preparedSubmitData));
  };

  const renderContent = (
    currentFileIndex,
    activeOs,
    selectedFiles,
    checkboxInputChangeHandler,
    enforceRuntimeChangeHandler,
    otherInputsChangeHandler,
  ) => (
    <Content
      currentFileIndex={currentFileIndex}
      activeOs={activeOs}
      selectedFiles={selectedFiles}
      onCheckboxInputChange={checkboxInputChangeHandler}
      onEnforceRuntimeChange={enforceRuntimeChangeHandler}
      onOtherInputsChange={otherInputsChangeHandler}
    />
  );

  return (
    <div
      className={`severity-status severity-status__${
        progress === "Error Analyser"
          ? "error"
          : progress === "Error Reporter"
          ? "error"
          : isApt && type !== "loading"
          ? "apt"
          : type === "not suspicious"
          ? "success"
          : type === "malicious"
          ? "secondary"
          : type === "suspicious"
          ? "suspicious"
          : "loading"
      }`}>
      {progress === "Error Reporter" || progress === "Error Analyser" ? (
        <ErrorIcon color="var(--network-blocks-sbox-border)" width={20.5} height={20.5} />
      ) : progress === "In Queue" ? (
        <QueueIcon width={20} height={20} />
      ) : progress === "Running" && !isRemoteConnectionReady ? (
        <button className="severity-status__check" onClick={checkRemoteConnection}>
          {loading ? <Spinner width={14} height={14} /> : "Check Remote"}
        </button>
      ) : progress === "Running" && isRemoteConnectionReady ? (
        <span className="severity-status__icon" onClick={remoteConnectionHandler}>
          <RemoteConnectionIcon />
        </span>
      ) : type === "loading" ? (
        <LoadingIcon color={"var(--text-color-card)"} />
      ) : isApt || type === "malicious" ? (
        aptClasses.length > 0 ? (
          aptClasses
            .slice(0, 2)
            .map((className) => (
              <p key={className}>
                {className.length > 11 ? `#${className.slice(0, 11)}...` : `#${className}`}
              </p>
            ))
        ) : (
          type
        )
      ) : (
        type
      )}
      {progress === "Error Reporter" || progress === "Error Analyser" ? (
        <a onClick={checkResubmitHandler} style={{ pointerEvents: "all" }}>
          <span
            style={{ marginLeft: "8px", display: "flex" }}
            onMouseOver={() => setRefreshHover(true)}
            onMouseOut={() => setRefreshHover(false)}>
            <RefreshIcon
              color={
                refreshHover
                  ? "var(--dashboard-table-resubmit-icon-hover)"
                  : "var(--dashboard-table-resubmit-icon)"
              }
              width={25}
              height={25}
            />
          </span>
        </a>
      ) : null}
      {progress === "Error Reporter" || progress === "Error Analyser" || progress === "In Queue" ? (
        <button
          className="severity-status__delete-from-list"
          onClick={(e) => deleteFromListHandler(e)}
          onMouseOver={() => setTrashBucketHover(true)}
          onMouseOut={() => setTrashBucketHover(false)}
          title="Delete from queue">
          <TrashBucketIcon
            color={
              trashBucketHover
                ? "var(--dashboard-table-resubmit-icon)"
                : "var(--dashboard-table-resubmit-icon-hover)"
            }
            width="20"
            height="20"
          />
        </button>
      ) : null}
      {submitPopupActive ? (
        <Modal active={submitPopupActive} setActive={setSubmitPopupActive} type="submit-popup">
          <SubmitPopup
            selectedFiles={submitFile}
            setSelectedFiles={setSubmitFile}
            vmOptions={desktopVmOptions.filter((vmOption) => vms.includes(vmOption.id))}
            onClose={closePopupHandler}
            onSubmit={submitHandler}
            renderContent={renderContent}
            showApplyAll={false}
            showFileList={false}
          />
        </Modal>
      ) : null}
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </div>
  );
};

export default SeverityStatus;
