const ContainersIcon = ({ width = 18, height = 18, color = "var(--text-color-card)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ enableBackground: "new 0 0 24 24" }}
      xmlSpace="preserve"
      width={width}
      height={height}>
      <g>
        <path
          fill={color}
          d="M23.765,9.894c-0.064-0.05-0.672-0.511-1.955-0.511c-0.339,0-0.675,0.03-1.011,0.087c-0.249-1.7-1.653-2.53-1.717-2.567   l-0.343-0.198l-0.225,0.326c-0.282,0.44-0.49,0.924-0.611,1.431c-0.228,0.971-0.091,1.881,0.403,2.661   c-0.595,0.333-1.552,0.413-1.743,0.42H0.75c-0.413,0-0.749,0.336-0.749,0.746c-0.017,1.384,0.215,2.761,0.692,4.061   c0.544,1.428,1.354,2.479,2.409,3.124c1.182,0.722,3.101,1.135,5.278,1.135c0.984,0.003,1.965-0.087,2.929-0.265   c1.344-0.245,2.637-0.716,3.823-1.391c0.978-0.568,1.861-1.29,2.61-2.137c1.253-1.418,1.999-2.997,2.553-4.401   c0.074,0,0.148,0,0.222,0c1.371,0,2.214-0.548,2.681-1.008c0.309-0.292,0.551-0.652,0.705-1.048L24,10.072L23.765,9.894z"
        />
        <path
          fill={color}
          d="M2.215,11.079h2.12c0.101,0,0.185-0.084,0.185-0.185l0,0V9.007c0-0.101-0.081-0.185-0.185-0.185l0,0h-2.12   c-0.101,0-0.185,0.084-0.185,0.185l0,0v1.888C2.03,10.996,2.114,11.079,2.215,11.079L2.215,11.079"
        />
        <path
          fill={color}
          d="M5.134,11.079h2.12c0.101,0,0.185-0.084,0.185-0.185l0,0V9.007c0-0.101-0.081-0.185-0.185-0.185l0,0h-2.12   c-0.104,0-0.185,0.084-0.185,0.185v1.888C4.949,10.996,5.033,11.079,5.134,11.079"
        />
        <path
          fill={color}
          d="M8.1,11.079h2.12c0.101,0,0.185-0.084,0.185-0.185l0,0V9.007c0-0.101-0.081-0.185-0.185-0.185l0,0H8.1   c-0.101,0-0.185,0.084-0.185,0.185l0,0v1.888C7.915,10.996,7.996,11.079,8.1,11.079L8.1,11.079"
        />
        <path
          fill={color}
          d="M11.03,11.079h2.12c0.101,0,0.185-0.084,0.185-0.185V9.007c0-0.104-0.084-0.185-0.185-0.185l0,0h-2.12   c-0.101,0-0.185,0.084-0.185,0.185l0,0v1.888C10.845,10.996,10.925,11.079,11.03,11.079L11.03,11.079"
        />
        <path
          fill={color}
          d="M5.134,8.365h2.12c0.101,0,0.185-0.084,0.185-0.185V6.292c0-0.101-0.084-0.185-0.185-0.185l0,0h-2.12   c-0.101,0-0.185,0.084-0.185,0.185V8.18C4.949,8.281,5.033,8.365,5.134,8.365"
        />
        <path
          fill={color}
          d="M8.1,8.365h2.12c0.101,0,0.185-0.084,0.185-0.185V6.292c0-0.101-0.084-0.185-0.185-0.185l0,0H8.1   c-0.101,0-0.185,0.084-0.185,0.185l0,0V8.18C7.915,8.281,7.996,8.365,8.1,8.365"
        />
        <path
          fill={color}
          d="M11.03,8.365h2.12c0.104,0,0.185-0.084,0.185-0.185V6.292c0-0.101-0.084-0.185-0.185-0.185h-2.12   c-0.101,0-0.185,0.084-0.185,0.185l0,0V8.18C10.845,8.281,10.925,8.365,11.03,8.365"
        />
        <path
          fill={color}
          d="M11.03,5.647h2.12c0.101,0,0.185-0.084,0.185-0.185V3.575c0-0.101-0.084-0.185-0.185-0.185h-2.12   c-0.101,0-0.185,0.084-0.185,0.185l0,0v1.888C10.845,5.567,10.925,5.647,11.03,5.647"
        />
        <path
          fill={color}
          d="M13.982,11.079h2.12c0.101,0,0.185-0.084,0.185-0.185V9.007c0-0.104-0.084-0.185-0.185-0.185l0,0h-2.12   c-0.101,0-0.185,0.084-0.185,0.185l0,0v1.888C13.798,10.996,13.882,11.079,13.982,11.079"
        />
      </g>
    </svg>
  );
};

export default ContainersIcon;
