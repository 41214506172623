import React from "react";

const ImageIcon = ({ color = "var(--border)", width, height }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none">
        <path
          d="M1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 1.30628 15.8127 0.854097 15.4793 0.520699C15.1459 0.187301 14.6937 0 14.2222 0H1.77778C1.30628 0 0.854097 0.187301 0.520699 0.520699C0.187301 0.854097 0 1.30628 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16ZM4.44444 9.77778L6.54489 11.8782L9.77778 7.11111L14.2222 13.3333H1.77778L4.44444 9.77778Z"
          fill={color || "var(--settings-sidebar-icon)"}
        />
      </svg>
    </div>
  );
};

export default ImageIcon;
