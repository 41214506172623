import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SearchInput from "../../common/SearchInput/SearchInput";
import NotificationsIcon from "../../Icon-SVG/NotificationsIcon";
//import ToggleButtonChangeTheme from "../../common/ToggleButtonChangeTheme/ToggleButtonChangeTheme";
import SidebarClose from "../../Icon-SVG/SidebarClose";
import SideBarOpen from "../../Icon-SVG/SideBarOpen";
import { useDispatch, useSelector } from "react-redux";
import { RequestGetUILogo } from "../../../store/actions/dashboardActions";
import { logoutRequest } from "../../../store/actions/usersActions";
import Warning from "../../common/Warning/Warning";
import { useOnClickOutside } from "../../../utils/hook/useOnClickOutside";
import Menu from "../Menu/Menu";
import LogoutIcon from "../../Icon-SVG/LogoutIcon";
import LogoIcon from "../../Icon-SVG/LogoIcon";
import Backdrop from "../Backdrop/Backdrop";
import "./styles.scss";
import UserIcon from "../../Icon-SVG/UserIcon";
import MalwareAnalyzerIcon from "../../Icon-SVG/MalwareAnalyzerIcon";

const Header = ({ collapsed, setCollapsed, fullyCollapsed, setFullyCollapsed }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.users.user);
  const uiLogo = useSelector((state) => state.dashboard.uiLogo);
  const [bannerLogo, setBannerLogo] = useState("");
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState(false);
  const ref = useRef();

  useEffect(() => {
    dispatch(RequestGetUILogo());
  }, []);

  useEffect(() => {
    if (uiLogo) {
      setBannerLogo("data:image/png;base64," + uiLogo);
    }
  }, [uiLogo]);

  useOnClickOutside(ref, () => setMenu(false));

  const logout = () => {
    dispatch(logoutRequest());
  };

  if (!users) {
    return <Backdrop show={!users} />;
  }

  return (
    <div className="header__inner">
      <div className="header__inner-block">
        <div
          className={`header__inner-block__sidebar ${
            fullyCollapsed
              ? "header__inner-block__sidebar--single-expand-icon"
              : collapsed
              ? "header__inner-block__sidebar--double-icon"
              : ""
          }`}>
          {collapsed ? (
            <div onClick={() => setFullyCollapsed(!fullyCollapsed)}>
              {fullyCollapsed ? (
                <SideBarOpen width={16} height={16} />
              ) : (
                <SidebarClose width={16} height={16} />
              )}
            </div>
          ) : null}
          {/* <div onClick={() => setFullyCollapsed(!fullyCollapsed)}>
            {collapsed && !fullyCollapsed && <SidebarClose width={16} height={16} />}
            {collapsed && fullyCollapsed && <SideBarOpen width={16} height={16} />}
          </div> */}
          {collapsed ? (
            fullyCollapsed ? null : (
              <div onClick={() => setCollapsed(!collapsed)}>
                <SideBarOpen width={16} height={16} />
              </div>
            )
          ) : (
            <div onClick={() => setCollapsed(!collapsed)}>
              <SidebarClose width={16} height={16} />
            </div>
          )}
          {/* <div onClick={() => setCollapsed(!collapsed)}>
            {collapsed && !fullyCollapsed && <SideBarOpen width={16} height={16} />}
            {!collapsed && <SidebarClose width={16} height={16} />}
          </div> */}
        </div>
      </div>
      <SearchInput uilogo={uiLogo} />
      <div className="header__inner-block">
        <div className="header__inner-block-uilogo">
          <img
            alt=""
            id="tableBanner"
            className="logo__icon--img"
            style={{
              height: "40px",
              border: "none",
              outline: "none",
            }}
            src={
              currentPath === "/mailgateway/analyzed-emails" ||
              currentPath === "/mailgateway/blocked-emails" ||
              currentPath === "/mailgateway/unblocked-emails"
                ? "/BrandIcons/MailGateway.png"
                : currentPath === "/network-detections"
                ? "/BrandIcons/NetworkDefender.png"
                : uiLogo
                ? bannerLogo
                : "/BrandIcons/DefaultLogoIcon.png"
            }
          />
        </div>
        <div className="header__inner-block">
          <div className="header__inner-block__notification">
            <NotificationsIcon />
          </div>
          {/**
          !!!!!!!!!TOGGLE BUTTON CHANGE THEME DISABLED FOR DEMO!!!!!!!!
          <div className="header__inner-block__toggle">
            <ToggleButtonChangeTheme />
          </div>
          **/}
        </div>
        <div className="header__inner-block__profile" onClick={() => setMenu(!menu)} ref={ref}>
          <p className="m-r-5">{users.username}</p>
          <UserIcon width={15} height={15} color={"var(--text-color-card)"} />
          <Menu show={menu} setShow={setShow} username={users.username} />
        </div>

        <Warning
          onClickOk={() => logout()}
          setShow={setShow}
          show={show}
          text="Do you really want to logout?"
          article="Logout"
          icon={
            <LogoutIcon
              width={50}
              height={50}
              color={"var(--text-color-card)"}
              padding="20px 10px 10px 10px"
            />
          }
        />
      </div>
    </div>
  );
};

export default Header;
