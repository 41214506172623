import React, { useState } from "react";
import CheckBox from "../../../../../../../Components/common/CheckBox/CheckBox";
import TextField from "../../../../../../../Components/common/TextField/TextField";
import Toggle from "../../../../Components/Toggle";
import "./styles.scss";

const Search = () => {
  const [guestAccess, setGuestAccess] = useState(true);

  const [guest, setGuest] = useState(true);
  const [loggedIn, setLoggedIn] = useState(true);
  const [privileged, setPrivileged] = useState(true);
  const [moderator, setModerator] = useState(false);
  const [admin, setAdmin] = useState(true);

  const [path, setPath] = useState("");
  const [dir, setDir] = useState("");
  const [dirPublic, setDirPublic] = useState("");

  const [caching, setCaching] = useState(true);
  const [regexps, setRegexps] = useState(false);
  const [buttons, setButtons] = useState(true);

  return (
    <div className="search border p-b-30">
      <Toggle
        active={guestAccess}
        setActive={setGuestAccess}
        label="Allow guest access"
        parentClassName="m-t-20 m-b-20"
      />
      <h5 className="main__text m-b-10">Disable advanced search queries</h5>
      <div className="flex flex-align-items-center m-b-20">
        <CheckBox
          id="guest"
          checked={guest}
          setChecked={setGuest}
          className="main__text m-r-40"
          text={"Guest"}
        />
        <CheckBox
          id="loggedIn"
          checked={loggedIn}
          setChecked={setLoggedIn}
          className="main__text m-r-40"
          text={"Logged In"}
        />
        <CheckBox
          id="privileged"
          checked={privileged}
          setChecked={setPrivileged}
          className="main__text m-r-40"
          text="Privileged"
        />
        <CheckBox
          id="moderator"
          checked={moderator}
          setChecked={setModerator}
          className="main__text m-r-40"
          text={"Moderator"}
        />
        <CheckBox
          id="admin"
          checked={admin}
          setChecked={setAdmin}
          className="main__text"
          text="Administrator"
        />
      </div>

      <div className="flex flex-column">
        <TextField
          labelText={"Results folder base path"}
          onChange={(e) => setPath(e.target.value)}
          value={path}
        />
        <TextField labelText={"Sample dir"} onChange={(e) => setDir(e.target.value)} value={dir} />
        <TextField
          labelText={"Sample dir public"}
          onChange={(e) => setDirPublic(e.target.value)}
          value={dirPublic}
        />
      </div>

      <div className="flex flex-align-items-center">
        <Toggle
          parentClassName="m-t-20 m-b-30 m-r-40"
          label="Enable search results caching"
          active={caching}
          setActive={setCaching}
        />
        <Toggle
          parentClassName="m-t-20 m-b-30 m-r-40"
          label="Enable search regexps"
          active={regexps}
          setActive={setRegexps}
          tooltipText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit est mattis suspendisse laoreet arcu viverra quis aliquet."
        />
        <Toggle
          parentClassName="m-t-20 m-b-30"
          label="Enable context samples search buttons"
          active={buttons}
          setActive={setButtons}
        />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Search;
