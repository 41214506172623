import React from "react";

const CloudArrowIcon = ({ width, height, color = "var(--border)" }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18 14"
        fill="none">
        <path
          d="M14.7273 6.125C14.7273 2.73875 12.1664 0 9 0C7.746 0.000667391 6.52696 0.442093 5.53054 1.25633C4.53411 2.07056 3.81555 3.21246 3.48545 4.50625C2.49497 4.74976 1.61098 5.3453 0.977899 6.19558C0.344821 7.04585 8.13129e-05 8.1006 0 9.1875C0 11.8475 2.01273 14 4.5 14H14.3182C16.3555 13.9913 18 12.2237 18 10.045C18 8.00625 16.5682 6.3525 14.7273 6.125ZM9.81818 7.875V9.625C9.81818 10.1062 9.45 10.5 9 10.5C8.55 10.5 8.18182 10.1062 8.18182 9.625V7.875H6.71727C6.34909 7.875 6.16909 7.4025 6.43091 7.13125L8.71364 4.69C8.87727 4.515 9.13091 4.515 9.29455 4.69L11.5773 7.13125C11.6333 7.1924 11.6714 7.2698 11.6867 7.35386C11.702 7.43792 11.6939 7.52493 11.6634 7.60412C11.633 7.68331 11.5814 7.75119 11.5152 7.79934C11.449 7.84749 11.371 7.8738 11.2909 7.875H9.81818Z"
          fill={color || "var(--settings-sidebar-icon)"}
        />
      </svg>
    </div>
  );
};

export default CloudArrowIcon;
