import React, { useState } from "react";
import Field from "../../../Components/Field";
import Select from "../../../Components/Select";
import TagField from "../../../../../../Components/common/TagField/TagField";
import { operatingSystem, operatingSystemSelect } from "../../../../../../utils/constants";
import IpInput from "../../../../../../Components/common/IpInput/IpInput";

const Win7 = () => {
  const [label, setLabel] = useState("");
  const [platform, setPlatform] = useState({});
  const [ip, setIp] = useState("");
  const [basePath, SetBasePath] = useState("");

  const [ipPrefix, setIpPrefix] = useState("");
  const [macPrefix, setMacPrefix] = useState("");
  const [winType, setWinType] = useState();
  const [memory, setMemory] = useState("");
  const [cpu, setCPU] = useState("");
  const [tags, setTags] = useState([...operatingSystem]);

  const [snapshot, setSnapshot] = useState("");
  const [Interface, setInterface] = useState("");
  const [resultServer, setResultServer] = useState("");
  const [serverPort, setServerPort] = useState("");
  const [arch, setArch] = useState(operatingSystemSelect[0]);

  return (
    <div>
      <h3 className="m-b-20">Win7</h3>
      <div className="flex flex-align-items-center m-b-20">
        <Field
          width="178px"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          tooltipText="Specify the label name of the current machine as specified in your libvirt configuration."
          label="Label"
          parentClassName="p-r-25"
          placeholder="win7"
        />
        <Select
          width="178px"
          select={platform}
          setSelect={setPlatform}
          tooltipText="Specify the operating system platform used by current machine [windows/darwin/linux]."
          label="Platform"
          parentClassName="p-r-25 m-b-5"
        />
        <IpInput
          width="131px"
          value={ip}
          onChange={(value) => setIp(value)}
          tooltipText="Specify the IP address of the current virtual machine. Make sure that the IP address is valid and that the host machine is able to reach it. If not, the analysis will fail. You may want to configure your network settings in /etc/libvirt/<hypervisor>/networks/"
          label="Ip"
          parentClassName="p-r-25"
        />
        <Field
          width="215px"
          value={basePath}
          onChange={(e) => SetBasePath(e.target.value)}
          tooltipText="Image paths"
          label="Basepath"
          placeholder="/var/lib/libvirt/images/"
        />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <IpInput
          width="131px"
          value={ipPrefix}
          onChange={(value) => setIpPrefix(value)}
          tooltipText="Ip prefix"
          label="Ip prefix"
          parentClassName="p-r-25"
        />
        <Field
          width="131px"
          value={macPrefix}
          onChange={(e) => setMacPrefix(e.target.value)}
          tooltipText="Mac prefix"
          label="Mac prefix"
          parentClassName="p-r-25"
          placeholder="C8:58:C0:"
        />
        <Select
          width="131px"
          select={winType}
          setSelect={setWinType}
          tooltipText="Type"
          label="Win type"
          parentClassName="p-r-25 m-b-5"
        />
        <Field
          width="100px"
          value={memory}
          onChange={(e) => setMemory(e.target.value)}
          tooltipText="Memory"
          label="Memory"
          parentClassName="p-r-25"
          placeholder="3144704"
          type="number"
        />
        <Field
          width="100px"
          value={cpu}
          onChange={(e) => setCPU(e.target.value)}
          tooltipText="CPU"
          label="CPU"
          placeholder="4"
        />
      </div>
      <TagField
        tags={tags}
        setTags={setTags}
        label="Tags"
        parentClassName="m-b-30"
        tooltipText="x64 binaries requires tags=x64 (Optional but IMPORTANT) Set your own tags. These are comma separated and help to identify specific VMs. You can run samples on VMs with tag you require. Note that the 64_bit tag is currently special. For submitted 64-bit PE files, the 64_bit tag will automatically be added, forcing them to be run on a 64-bit VM. For this reason, make sure all 64-bit VMs have the 64_bit tag. tags = windows_xp_sp3,32_bit,acrobat_reader_6"
      />

      <div className="flex flex-align-items-center m-b-20">
        <Field
          width="178px"
          value={snapshot}
          onChange={(e) => setSnapshot(e.target.value)}
          tooltipText="(Optional) Specify the snapshot name to use. If you do not specify a snapshot name, the KVM MachineManager will use the current snapshot. Example (Snapshot1 is the snapshot name):"
          label="Snapshot"
          parentClassName="p-r-25"
          placeholder="Snapshot1"
        />
        <Field
          width="178px"
          value={Interface}
          onChange={(e) => setInterface(e.target.value)}
          label="Interface"
          tooltipText="(Optional) Specify the name of the network interface that should be used when dumping network traffic from this machine with tcpdump. If specified, overrides the default interface specified in auxiliary.conf Example (virbr0 is the interface name): interface = virbr0"
          parentClassName="p-r-25"
          placeholder="virbr0"
        />

        <IpInput
          width="178px"
          value={resultServer}
          onChange={(value) => setResultServer(value)}
          tooltipText="(Optional) Specify the IP of the Result Server, as your virtual machine sees it. The Result Server will always bind to the address and port specified in cfsbox.conf, however you could set up your virtual network to use NAT/PAT, so you can specify here the IP address for the Result Server as your machine sees it. If you don't specify an address here, the machine will use the default value from cfsbox.conf. NOTE: if you set this option you have to set result server IP to 0.0.0.0 in cfsbox.conf. Example: 10.42.0.71"
          label="Result server IP"
          parentClassName="p-r-25"
        />

        <Field
          width="178px"
          value={serverPort}
          onChange={(e) => setServerPort(e.target.value)}
          tooltipText="(Optional) Specify the port for the Result Server, as your virtual machine sees it. The Result Server will always bind to the address and port specified in cfsbox.conf, however you could set up your virtual network to use NAT/PAT, so you can specify here the port for the Result Server as your machine sees it. If you don't specify a port here, the machine will use the default value from cfsbox.conf. Example: 3043"
          label="Result server port"
          parentClassName="p-r-25"
          placeholder="3043"
        />
      </div>

      <Select
        width="178px"
        data={operatingSystemSelect}
        setSelect={setArch}
        select={arch}
        label="Arch"
        parentClassName="m-b-30"
      />
    </div>
  );
};

export default Win7;
