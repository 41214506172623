const BlockedEmailsAction = (props) => {
  const action = props?.row?.original?.action || "-";

  return (
    <span
      className={`blocked-emails__table__action--${
        action === "Block"
          ? "block"
          : action === "Waiting"
          ? "waiting"
          : action === "Released"
          ? "released"
          : "empty"
      }`}>
      {action}
    </span>
  );
};

export default BlockedEmailsAction;
