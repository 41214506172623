import React from "react";
import Tooltip from "../../../../Components/common/Tooltip/Tooltip";
import CustomSelect from "../../../../Components/common/CustomSelect/CustomSelect";
import { getHours } from "../../../../utils/function";

const Select = ({
  select,
  setSelect,
  tooltipText,
  label,
  parentClassName,
  data,
  width,
  tooltipWidth,
  placeholder,
}) => {
  return (
    <div className={parentClassName}>
      <p className="settings__label m-b-5">
        {label}
        {tooltipText && <Tooltip tooltipWidth={tooltipWidth} text={tooltipText} />}
      </p>
      <CustomSelect
        width={width}
        data={data || getHours()}
        select={select}
        setSelected={setSelect}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Select;
