import React, { useEffect, useState } from "react";
import CustomSelect from "../../../../../Components/common/CustomSelect/CustomSelect";
import { getDays, getHours, getMinutes, getYears } from "../../../../../utils/function";
import { mouths, time } from "../../../../../utils/constants";
import { dateTimeList } from "../../../../../fixture";
import CheckBox from "../../../../../Components/common/CheckBox/CheckBox";
import moment from "moment";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";

import "./styles.scss";

const DateTime = () => {
  const [dynamicTime, setTime] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [type, setType] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const secTimer = setInterval(() => {
      setTime(moment(new Date()).format("l LTS"));
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  return (
    <div className="date-time">
      <div className="flex flex-align-items-center flex-justify-content-between">
        <h1>Date and Time</h1>
        {/**
        <div className="flex flex-align-items-center ">
          <button className="default-button">Reset</button>
          <button className="primary-button">Save</button>
        </div>*/}
      </div>
      <div className="date-time__body">
        <DemoDiv styleClass={"dateTime"} />
        <div className="date-time__body-header">
          <div className="flex flex-align-items-center flex-justify-content-start flex-wrap">
            <h3 className="date-time__article">Date and Times Setting</h3>
            <p className="analysis-images__description">
              Current Tine: <span>{dynamicTime}</span>
            </p>
          </div>
          <p className="date-time__description">
            Manually set date, time and time zone. Or, optionally enter an existing NTP server for
            dme synchronization
          </p>
        </div>
        <div className="flex flex-column ">
          <div className="date-time__body-item">
            <h3 className="settings-article">Set Date</h3>
            <div className="date-time__content">
              <div className="flex flex-align-items-center">
                <CustomSelect
                  width={"150px"}
                  data={mouths}
                  color="var(--settings-text-table)"
                  setSelected={setMonth}
                  select={month}
                />
                <CustomSelect
                  width={"80px"}
                  data={getDays(2022, 5)}
                  color="var(--settings-text-table)"
                  setSelected={setDay}
                  select={day}
                />
                <CustomSelect
                  width={"100px"}
                  data={getYears()}
                  color="var(--settings-text-table)"
                  setSelected={setYear}
                  select={year}
                />
              </div>
            </div>
          </div>
          <div className="date-time__body-item">
            <h3 className="settings-article">Set Time</h3>
            <div className="date-time__content">
              <div className="flex flex-align-items-center">
                <CustomSelect
                  width={"80px"}
                  data={getHours()}
                  color="var(--settings-text-table)"
                  select={hour}
                  setSelected={setHour}
                />
                <p className="date-time__dot">:</p>
                <CustomSelect
                  width={"80px"}
                  data={getMinutes()}
                  color="var(--settings-text-table)"
                  select={minute}
                  setSelected={setMinute}
                />
                <CustomSelect
                  width={"100px"}
                  data={time}
                  color="var(--settings-text-table)"
                  select={type}
                  setSelected={setType}
                />
              </div>
              <div className="date-time__dropdown">
                <p className="date-time__type">User Name</p>
                <CustomSelect
                  width={"180px"}
                  data={getHours()}
                  color="var(--settings-text-table)"
                  select={userName}
                  setSelected={setUserName}
                />
              </div>
            </div>
          </div>
          <div className="date-time__body-item">
            <div className="flex flex-align-items-center">
              <h3 className="settings-article">Enable NTP</h3>
              <p className="date-time__link">Add NTP Server</p>
            </div>
            <p className="date-time__description">NTP Server listing</p>
            <div className="date-time__list">
              <div className="date-time__list-header">
                <p className="date-time__list-header-item" />
                <p className="date-time__list-header-item">NTP Server</p>
                <p className="date-time__list-header-item">Update time</p>
              </div>
              <div className="date-time__list-body">
                {dateTimeList.map((item) => {
                  return (
                    <div className="date-time__list-body-item" key={item.id}>
                      <div className="date-time__list-body-item__value">
                        <CheckBox id={item.id} />
                      </div>
                      <p className="date-time__list-body-item__value">{item.server}</p>
                      <p className="date-time__list-body-item__value">{item.time}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTime;
