// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
  border-radius: 43px;
  padding: 3px 5px;
  color: var(--header-search-text-color);
  text-align: center;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  font-family: Nunito, serif;
  text-transform: capitalize;
}
.status__enabled {
  background: #28c30f;
}
.status__disabled {
  background: var(--network-blocks-sbox-border);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/Cells/Status/styles.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,gBAAA;EACA,sCAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,0BAAA;AADF;AAGE;EACE,mBCCI;ADFR;AAIE;EACE,6CAAA;AAFJ","sourcesContent":["@import \"../../../../styles/colors\";\n\n.status {\n  border-radius: 43px;\n  padding: 3px 5px;\n  color: var(--header-search-text-color);\n  text-align: center;\n  display: inline-block;\n  font-size: 12px;\n  line-height: 16px;\n  font-family: Nunito, serif;\n  text-transform: capitalize;\n\n  &__enabled {\n    background: $green;\n  }\n\n  &__disabled {\n    background: var(--network-blocks-sbox-border);\n  }\n}","$main--blue: #55a0e6;\n$grey--text: #a6b0cf;\n$grey-text-dark: #7c849d;\n$grey--text-dark: #7c849d;\n$white: #ffffff;\n$black: #000000;\n$alert-red: #924040;\n$dark--bg: #222736;\n$dark--gray: #303546;\n$dark--element: #313748;\n$dark-text: #32373c;\n$light--bg: #f4f5f7;\n$light--label: #495057;\n$dark--gray-text: #32373c;\n$red: #ff3d00;\n$green: #28c30f;\n$orange: #ffb800;\n$blue: blue;\n$yellow: yellow;\n$dark-yellow: cyan;\n$purple: purple;\n$pink: pink;\n$dark--element2: #0e8bff;\n\n$main--orange: #e56c4d;\n$df-grey--text: #a6b0cf;\n$df-alert-red: #e56c4d;\n$df-dark--bg: #000000;\n$df-dark--gray: #282828;\n$df-dark--element: #111111;\n$df-dark-text: #0d0d0d;\n$df-light--label: #262626;\n$df-dark--gray-text: #262626;\n$df-grey--text-dark: #2e2620;\n\n// $main--orange: #ff4a1c;\n// $df-grey--text: #a6b0cf;\n// $df-alert-red: #ff4a1c;\n// $df-dark--bg: #2e3e45;\n// $df-dark--gray: #3a4f57;\n// $df-dark--element: #27353a;\n// $df-dark-text: #33464e;\n// $df-light--label: #3a4f57;\n// $df-dark--gray-text: #3a4f57;\n// $df-grey--text-dark: #7c849d;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
