import React, { useState } from "react";
import Field from "../../../Components/Field";

const Timeout = () => {
  const [defaultAnalysis, setDefaultAnalysis] = useState("10");
  const [virtualStatus, setVirtualStatus] = useState("5");
  const [critical, setCritical] = useState("5");

  return (
    <div className="border cfsbox__content">
      <div className="flex flex-align-items-stretch m-b-20">
        <Field
          parentClassName="m-r-15"
          label="Default analysis timeout"
          className="cfsbox__field"
          tooltipText="Set the default analysis timeout expressed in seconds. This value will be used to define after how many seconds the analysis will terminate unless otherwise specified at submission."
          value={defaultAnalysis}
          onChange={(e) => setDefaultAnalysis(e.target.value)}
          width="176px"
          type="number"
        />

        <Field
          parentClassName="m-l-15"
          label="Virtual machine status"
          tooltipText="Set the critical timeout expressed in (relative!) seconds. It will be added to the default timeout above and after this timeout is hit Cfsbox will consider the analysis failed and it will shutdown the machine no matter what. When this happens the analysis results will most likely be lost."
          value={virtualStatus}
          onChange={(e) => setVirtualStatus(e.target.value)}
          width="176px"
          type="number"
        />
      </div>

      <Field
        parentClassName="m-b-20"
        label="Critical timeout"
        tooltipText="Maximum time to wait for virtual machine status change. For example when shutting down a vm. Default is 300 seconds."
        value={critical}
        onChange={(e) => setCritical(e.target.value)}
        width="126px"
        type="number"
      />
      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Timeout;
