import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  requestDumpStatus,
  dumpDatabaseRequest,
} from "../../../../../../store/actions/settingsActions";

import { addNotification } from "../../../../../../store/actions/notifierActions";
import axiosApi from "../../../../../../utils/axios";
import Backdrop from "../../../../../../Components/UI/Backdrop/Backdrop";
import Warning from "../../../../../../Components/common/Warning/Warning";
import DownloadMessage from "../../../../../../Components/common/DownloadMessage/DownloadMessage";

const Dump = () => {
  const dispatch = useDispatch();
  const restoreStatus = useSelector((state) => state.settings.restoreStatus);
  const dumpStatus = useSelector((state) => state.settings.dumpStatus);
  const dumpDate = useSelector((state) => state.settings.dumpDate);
  const [downloadOptions, setDownloadOptions] = useState({
    loaded: 0,
    total: 0,
    progress: 0,
    downloadStarted: false,
  });
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(requestDumpStatus());
  }, [dispatch]);

  const dumpButtonClickHandler = () => {
    setShow(true);
  };

  const dumpDBHandler = () => {
    dispatch(dumpDatabaseRequest());
    setShow(false);
  };

  const downloadDumpHandler = async () => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total, progress } = progressEvent;
        setDownloadOptions({ loaded, total, progress: progress * 100, downloadStarted: true });
      },
    };
    try {
      const response = await axiosApi.get("/api/settings/dump", {
        responseType: "blob",
        ...options,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "backup.gz");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      console.log(err);
      dispatch(addNotification({ message: "Error", options: { variant: "error" } }));
    } finally {
      setTimeout(() => {
        setDownloadOptions((options) => ({ ...options, downloadStarted: false }));
      }, 2000);
    }
  };

  const lastDumpInfo = () => {
    if (dumpStatus === "success")
      return (
        <p className="dump__body__info dump__body__info--success">
          Last backup operation on {dumpDate} was successfull. Click on "Download" button to
          download that backup file.
        </p>
      );
    else if (dumpStatus === "error")
      return (
        <p className="dump__body__info dump__body__info--error">
          Last backup operation on {dumpDate} was failed. Click on "Backup" button to backup
          database.
        </p>
      );
    else return "";
  };

  return (
    <div className="dump">
      <h1 className="dump__header">Backup</h1>
      <div className="dump__body">
        <div className="dump__body__header">
          <h3>Backup</h3>
          <p>
            NOTE: Click on "Backup" button to backup database. Only report related data is included
            in backup. Artifacts, files and integration configs are not included in backup. Actions
            during dump process will not be included in dump operation. Backuping database can take
            a while. Please revisit this page to check backuping is completed. Click on "Download"
            button to download backup file.
          </p>
        </div>
        <div className="dump__body__content">
          {restoreStatus !== "restoring" &&
            (dumpStatus === "success" || dumpStatus === "error") &&
            lastDumpInfo()}
          <div className="dump__body__actions">
            <button
              className="primary-button"
              onClick={dumpButtonClickHandler}
              disabled={dumpStatus === "dumping" || restoreStatus === "restoring"}>
              {restoreStatus === "restoring"
                ? "Restoring..."
                : dumpStatus === "dumping"
                ? "Backuping..."
                : "Backup"}
            </button>
            <button
              className="primary-button"
              onClick={downloadDumpHandler}
              disabled={dumpStatus !== "success" || restoreStatus === "restoring"}>
              Download
            </button>
          </div>
        </div>
      </div>
      <Backdrop
        show={downloadOptions.downloadStarted}
        message={
          <DownloadMessage
            fileName="backup.gz"
            loaded={downloadOptions.loaded}
            total={downloadOptions.total}
            progress={downloadOptions.progress}
          />
        }
      />
      <Warning
        onClickOk={dumpDBHandler}
        show={show}
        setShow={setShow}
        text={
          "If you click yes, you will lose your submissions with the running and reporting status. Do you still want to continue ?"
        }
        article="Warning"
        cancelDisable={false}
      />
    </div>
  );
};

export default Dump;
