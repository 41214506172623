import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MobileIcon from "../../../../../Icon-SVG/MobileIcon";

import Warning from "../../../../../common/Warning/Warning";
import FileCardButton from "../../../../../UI/FileCardButton/FileCardButton";
import Modal from "../../../../../UI/Modal/Modal";
import SubmitPopup from "../../../../../common/SubmitPopup/SubmitPopup";
import MobileContent from "../../../../../common/SubmitPopup/Content/MobileContent";

import { mobileVmOptions } from "../../../../../common/SubmitPopup/constants";
import { prepareSubmitData } from "../../../../../common/SubmitPopup/utils";
import { RequestNewFile } from "../../../../../../store/actions/dashboardActions";

import "./styles.scss";

const Mobile = ({ selectedFiles, setSelectedFiles }) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  // const vms = useSelector((state) => state.dashboard.vms);

  const emptyFile = () => {
    setActive(false);
    setError("Please select at least one file");
    setShow(true);
  };

  // const noVm = () => {
  //   if (mobileVmOptions.some((vmOption) => vms.includes(vmOption.id))) return;

  //   setActive(false);
  //   setError("No vm available. Refresh the page and try again.");
  //   setShow(true);
  // };

  const closePopupHandler = () => {
    setActive(false);
  };

  const submitHandler = (selectedFiles) => {
    const preparedSubmitData = prepareSubmitData(selectedFiles, mobileVmOptions);
    dispatch(RequestNewFile(preparedSubmitData));
    console.log(preparedSubmitData);
  };

  const renderContent = (currentFileIndex, activeOs, selectedFiles, checkboxInputChangeHandler) => (
    <MobileContent
      currentFileIndex={currentFileIndex}
      activeOs={activeOs}
      selectedFiles={selectedFiles}
      onCheckboxInputChange={checkboxInputChangeHandler}
    />
  );

  return (
    <>
      <FileCardButton setActive={setActive}>
        <MobileIcon width={20} height={20} />
        <p>Mobile</p>
      </FileCardButton>
      {active ? (selectedFiles.length === 0 ? emptyFile() : null) : null}
      {/* {active ? noVm() : null} */}
      {active && (
        <Modal active={active} setActive={setActive} type="submit-popup">
          <SubmitPopup
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            vmOptions={mobileVmOptions}
            onClose={closePopupHandler}
            onSubmit={submitHandler}
            renderContent={renderContent}
          />
        </Modal>
      )}
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </>
  );
};

export default Mobile;
