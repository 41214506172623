// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-button {
  width: 40px;
  height: 20px;
  border-radius: 55px;
  display: flex;
  align-items: center;
  padding-left: 1px;
  padding-right: 2px;
  cursor: pointer;
  background: var(--toggle-background);
  margin: 0 10px;
  transition: all 0.4s ease-in-out;
}
.toggle-button__active {
  background: var(--color-blue);
  transition: all 0.4s ease-in-out;
}
.toggle-button__text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--analysis-reports-information-value);
}
@media screen and (max-width: 1370px) {
  .toggle-button__text {
    font-size: 12px;
  }
}
.toggle-button-icon {
  z-index: 100;
  opacity: 100%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--header-search-text-color);
  transition: all 0.4s;
  transform: translateX(0);
}
.toggle-button-icon__active {
  background: var(--header-search-text-color);
  transform: translateX(110%);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/ToggleButton/styles.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,oCAAA;EAEA,cAAA;EACA,gCAAA;AAFF;AAIE;EACE,6BAAA;EACA,gCAAA;AAFJ;AAKE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gDAAA;AAHJ;AAKI;EARF;IASI,eAAA;EAFJ;AACF;AAKE;EACE,YAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,2CAAA;EACA,oBAAA;EACA,wBAAA;AAHJ;AAME;EACE,2CAAA;EACA,2BAAA;AAJJ","sourcesContent":["@import \"../../../styles/colors\";\n\n.toggle-button {\n  width: 40px;\n  height: 20px;\n  border-radius: 55px;\n  display: flex;\n  align-items: center;\n  padding-left: 1px;\n  padding-right: 2px;\n  cursor: pointer;\n  background: var(--toggle-background);\n\n  margin: 0 10px;\n  transition: all 0.4s ease-in-out;\n\n  &__active {\n    background: var(--color-blue);\n    transition: all 0.4s ease-in-out;\n  }\n\n  &__text {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 22px;\n    text-align: left;\n    color: var(--analysis-reports-information-value);\n\n    @media screen and (max-width: 1370px) {\n      font-size: 12px;\n    }\n  }\n\n  &-icon {\n    z-index: 100;\n    opacity: 100%;\n    width: 18px;\n    height: 18px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: var(--header-search-text-color);\n    transition: all 0.4s;\n    transform: translateX(0);\n  }\n\n  &-icon__active {\n    background: var(--header-search-text-color);\n    transform: translateX(110%);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
