import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomTable from "../../../Components/common/Table/CustomTable";
import { cloudSubmissionsHeader } from "../../../utils/constants/tableHeaders";
import { useLocation } from "react-router-dom";
import ListPopup from "../../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";
import "./styles.scss";
import { Modal } from "@mui/material";
import Login from "../Login/Login";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SubmissionList = () => {
  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();
  const [listPopupActive, setListPopupActive] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [userCheck, setUserCheck] = useState(false);
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    if (user) {
      setUserCheck(true);
    } else {
      setUserCheck(false);
    }
  }, [user]);

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);

  const loginCall = () => {
    if (userCheck) {
      setIsLoginOpen(false);
    } else {
      setIsLoginOpen(true);
    }
  };

  return (
    <div>
      <div className="cloudsubmission">
        <div className="cloudsubmission__header">
          <h1>Submission List</h1>
          <p>Below list is displaying submission list:</p>
        </div>
        <div className="cloudsubmission__body">
          <div
            className="dashboard__table border"
            style={{ width: "98%", minHeight: "763px", marginBottom: "50px" }}
            onClick={() => loginCall()}>
            <CustomTable
              exportFile={true}
              column={cloudSubmissionsHeader}
              tableName={"cloud"}
              leftAction={false}
              isSetUrl
              paginationButtons={true}
              className=""
              minBottom="-43px"
              customPageSize={10}
              minHeightRow="72px"
              paginationHeight="36px"
              paginationBackColor="var(--card-background-color)"
              paginationBorder={true}
              manualPagination={true}
            />
          </div>
        </div>
      </div>
      <ListPopup
        onClickOk={() => setListPopupActive(false)}
        show={listPopupActive}
        groupID={query.get("data")}
        tableType={query.get("table")}
        tableType2="cloud"
      />
      {/* Cloud Modal Login */}
      <Modal open={isLoginOpen} onClose={() => setIsLoginOpen(false)}>
        <div className="cloudmodal-login">
          <Login />
        </div>
      </Modal>
    </div>
  );
};

export default SubmissionList;
