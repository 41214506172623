import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axiosApi from "../../../../../../utils/axios";
import Backdrop from "../../../../../../Components/UI/Backdrop/Backdrop";
import FileUpload from "../../../../../../Components/common/FileUpload/FileUpload";
import { addNotification } from "../../../../../../store/actions/notifierActions";
import { customFormData } from "../../../../../../utils/customFormData";
import { formatBytes } from "../../../../../../utils/function";

import { requestRestoreStatus } from "../../../../../../store/actions/settingsActions";

const CHUNK_SIZE = 1024 * 1024 * 3;

const Restore = () => {
  const dispatch = useDispatch();

  const restoreStatus = useSelector((state) => state.settings.restoreStatus);
  const dumpStatus = useSelector((state) => state.settings.dumpStatus);

  const [file, setFile] = useState(null);
  const [totalBytesUploaded, setTotalBytesUploaded] = useState(0);
  const [restoreFileError, setRestoreFileError] = useState("");
  const [uploadStatus, setUploadStatus] = useState(""); // "", uploading, success, error

  const progress = file ? ((totalBytesUploaded / file.size) * 100).toFixed(0) : 0;

  useEffect(() => {
    dispatch(requestRestoreStatus());
  }, [dispatch]);

  const uploadAndRestoreHandler = async () => {
    try {
      await axiosApi.post("/api/settings/restore/clear-files");
    } catch (err) {
      console.log(err);
    }
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let chunkCount = 0;
    let start = 0;
    let end = CHUNK_SIZE;
    const uploadPromises = [];

    while (start < file.size) {
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append("file", chunk);
      formData.append("file_name", file.name);
      formData.append("chunk_count", chunkCount);

      const uploadPromise = axiosApi.post("/api/settings/restore-file/upload-chunk", formData, {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.loaded >= progressEvent.total) {
            setTotalBytesUploaded((value) => value + progressEvent.loaded);
          }
        },
      });
      uploadPromises.push(uploadPromise);

      start = end;
      end = start + CHUNK_SIZE;
      if (end > file.size) {
        end = file.size;
      }
      chunkCount++;
    }

    try {
      setUploadStatus("uploading");
      await Promise.all(uploadPromises);

      const combineChunksFormData = customFormData({
        file_name: file.name,
        total_chunks: totalChunks,
      });
      await axiosApi.post("/api/settings/restore-file/combine-chunks", combineChunksFormData);
      setUploadStatus("success");

      const restoreFormData = customFormData({ file_name: file.name });
      await axiosApi.post("/api/settings/restore", restoreFormData);
      dispatch(requestRestoreStatus());
    } catch (error) {
      setUploadStatus("error");
      addNotification({ message: error?.response?.data?.message, options: { variant: "error" } });
    } finally {
      setUploadStatus("");
      setFile(null);
    }
  };

  const uploadMessage = file && (
    <div className="upload-message">
      <p className="upload-message__info">Uploading. This may take a while, please wait...</p>
      <div className="upload-message__header">
        <span className="upload-message__file-name">{file.name}</span>
        <div className="upload-message__info">
          <span className="upload-message__loaded">{formatBytes(totalBytesUploaded, 0)} </span>/
          <span className="upload-message__total"> {formatBytes(file.size, 0)}</span>
        </div>
      </div>
      <div className="upload-item__progress">{progress < 100 ? progress : 100.0}%</div>
    </div>
  );

  return (
    <div className="restore">
      <h1 className="restore__header">Restore</h1>
      <div className="restore__body">
        <div className="restore__body__header">
          <h3>Restore</h3>
          <p>
            NOTE: Please upload the .gz file you download to restore your system. Restoring database
            can take a while. Please revisit this page to check restoring is completed.
          </p>
        </div>
        <div className="restore__body__content">
          {restoreFileError && <p>{restoreFileError}</p>}
          <div className="restore__body__actions">
            <FileUpload
              file={file}
              setFile={setFile}
              setFileError={setRestoreFileError}
              allowedExtensions={["gz"]}
              errorMessage={`Please select .gz file that you downloaded from our system.`}
            />
            <button
              className="primary-button"
              onClick={uploadAndRestoreHandler}
              disabled={!file || restoreStatus === "restoring" || dumpStatus === "dumping"}>
              {restoreStatus === "restoring"
                ? "Restoring..."
                : dumpStatus === "dumping"
                ? "Backuping..."
                : "Upload & Restore"}
            </button>
          </div>
        </div>
      </div>
      <Backdrop
        show={uploadStatus === "uploading"}
        message={uploadMessage}
        setRestoreFileError={setRestoreFileError}
      />
    </div>
  );
};

export default Restore;
