// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.routing__body {
  position: relative;
  margin-top: 64px;
}
.routing__content {
  border-radius: 0 4px 4px 4px;
  background: var(--card-background-color);
  padding: 15px;
}
.routing .toggle-button {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/Routing/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,4BAAA;EACA,wCAAA;EACA,aAAA;AADJ;AAIE;EACE,cAAA;AAFJ","sourcesContent":[".routing {\n  &__body {\n    position: relative;\n    margin-top: 64px;\n  }\n\n  &__content {\n    border-radius: 0 4px 4px 4px;\n    background: var(--card-background-color);\n    padding: 15px;\n  }\n\n  .toggle-button {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
