import React from "react";

const ShieldIcon = ({ color = "var(--border)", width, height }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={height}
        height={width}
        viewBox="0 0 14 17"
        fill="none">
        <path
          d="M12.8333 0H1.16667C0.857346 0.000321468 0.560779 0.128358 0.342054 0.356011C0.123328 0.583664 0.000312862 0.892336 4.00039e-06 1.21429V9.10714C-0.00129597 10.318 0.314257 11.5064 0.912791 12.5447C1.51132 13.583 2.37024 14.432 3.39734 15.0007L7 17L10.6027 15.0007C11.6298 14.432 12.4887 13.583 13.0872 12.5447C13.6857 11.5064 14.0013 10.318 14 9.10714V1.21429C13.9997 0.892336 13.8767 0.583664 13.6579 0.356011C13.4392 0.128358 13.1427 0.000321468 12.8333 0ZM11.0833 9.71429H2.91667V8.5H11.0833V9.71429ZM11.0833 6.07143H2.91667V4.85714H11.0833V6.07143Z"
          fill={color || "var(--settings-sidebar-icon)"}
        />
      </svg>
    </div>
  );
};

export default ShieldIcon;
