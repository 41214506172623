import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../../../../Components/common/Spinner/Spinner";

import {
  RequestCustomSignatures,
  RequestDeleteCustomSignature,
  RequestEditCustomSignature,
} from "../../../../../../store/actions/settingsActions";

const HEADERS = ["File Name", "Status", "Actions"];

const SPINNER_SIZE = { width: 22, height: 22 };

const List = ({ data, signatureTypeWordings }) => {
  const dispatch = useDispatch();

  const deleteId = useSelector((state) => state.settings.deleteCustomSignatureId);
  const deleting = useSelector((state) => state.settings.deleteCustomSignatureLoading);
  const editId = useSelector((state) => state.settings.editCustomSignatureId);
  const editing = useSelector((state) => state.settings.editCustomSignatureLoading);

  const toggleSignatureHandler = (customSignatureId) => {
    dispatch(
      RequestEditCustomSignature({
        customSignatureId,
        signatureType: signatureTypeWordings.slug,
      }),
    );
  };

  const deleteSignatureHandler = (customSignatureId) => {
    dispatch(
      RequestDeleteCustomSignature({
        customSignatureId,
        signatureType: signatureTypeWordings.slug,
      }),
    );
  };

  return (
    <table>
      <thead>
        <tr>
          {HEADERS.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.length > 0 ? (
          data.map((item, i) => (
            <tr key={i}>
              <td>{item.file_name}</td>
              <td className={item.active ? "enabled" : "disabled"}>
                {item.active ? "Enabled" : "Disabled"}
              </td>
              <td>
                <button
                  className="primary-button"
                  onClick={() => toggleSignatureHandler(item._id.$oid)}>
                  {editing && editId === item._id.$oid ? (
                    <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
                  ) : item.active ? (
                    "Disable"
                  ) : (
                    "Enable"
                  )}
                </button>
                <button
                  className="primary-button"
                  onClick={() => deleteSignatureHandler(item._id.$oid)}>
                  {deleting && deleteId === item._id.$oid ? (
                    <Spinner width={SPINNER_SIZE.width} height={SPINNER_SIZE.height} />
                  ) : (
                    "Delete"
                  )}
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td>No {signatureTypeWordings.lowerCase}.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const SignatureList = ({ signatureTypeWordings }) => {
  const dispatch = useDispatch();

  const customSignatures = useSelector((state) => state.settings.customSignatures);

  useEffect(() => {
    dispatch(RequestCustomSignatures(signatureTypeWordings.slug));
  }, [dispatch, signatureTypeWordings.slug]);

  return (
    <div className="signature-list">
      <h1 className="signature-list__header">{signatureTypeWordings.capitalized} List</h1>
      <div className="signature-list__body">
        <div className="signature-list__body__header">
          <h3>{signatureTypeWordings.capitalized} List</h3>
          <p>
            NOTE: You can view, edit and delete the {signatureTypeWordings.lowerCase} uploaded to
            the system. Disabled ones will not be used during analysis process.
          </p>
        </div>
        <div className="signature-list__body__content">
          <List data={customSignatures} signatureTypeWordings={signatureTypeWordings} />
        </div>
      </div>
    </div>
  );
};

export default SignatureList;
