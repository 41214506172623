import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dropdown from "../../../Components/common/Dropdown/Dropdown";

import { RequestDistinctLiveHosts } from "../../../store/actions/mailGatewayActions";

const ProductsSidebar = ({ onItemSelect, selectedHost }) => {
  const mailGatewayHosts = useSelector((state) => state.mailGateway.distinctLiveHosts);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RequestDistinctLiveHosts());
  }, [dispatch]);

  useEffect(() => {
    if (mailGatewayHosts.length > 0 && !selectedHost) {
      onItemSelect("Mail Gateway", mailGatewayHosts[0]);
    }
  }, [mailGatewayHosts, selectedHost, onItemSelect]);

  return (
    <div className="products-sidebar">
      <Dropdown
        title="Mail Gateway"
        selectedItem={selectedHost}
        items={mailGatewayHosts}
        onItemSelect={onItemSelect}
      />
    </div>
  );
};

export default ProductsSidebar;
