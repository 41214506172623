import React, { useState } from "react";
import TextField from "../../../../common/TextField/TextField";
import "./styles.scss";

const FileNamePrefix = (props) => {
  const file_name = props.file_name || props.row?.original?.file_name;
  const [state, setState] = useState(file_name);
  return (
    <div className="filename">
      <TextField onChange={(e) => setState(e.target.value)} value={state} />
    </div>
  );
};

export default FileNamePrefix;
