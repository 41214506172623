// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiselect__options {
  border: 1px solid var(--checkbox-border);
  border-radius: 4px;
  padding: 4px 8px;
  position: absolute;
  width: 100%;
  top: 44px;
  background-color: var(--layout-bg);
  z-index: 9999;
}
.multiselect__option {
  padding: 2px 4px;
  border-radius: 4px;
}
.multiselect__option label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  cursor: pointer;
}
.multiselect__option:hover {
  background-color: var(--checkbox-border);
}
.multiselect__option input {
  accent-color: var(--dark-theme-element) !important;
}
.multiselect__select-all {
  border-bottom: 1px solid var(--checkbox-border);
  margin-bottom: 10px;
  padding-bottom: 4px;
}
.multiselect__single-option-area {
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/CustomMultiSelect/CustomMultiSelectOptions/styles.scss"],"names":[],"mappings":"AACE;EACE,wCAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,kCAAA;EACA,aAAA;AAAJ;AAGE;EACE,gBAAA;EACA,kBAAA;AADJ;AAGI;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;AADN;AAII;EACE,wCAAA;AAFN;AAKI;EACE,kDAAA;AAHN;AAOE;EACE,+CAAA;EACA,mBAAA;EACA,mBAAA;AALJ;AAQE;EACE,cAAA;AANJ","sourcesContent":[".multiselect {\n  &__options {\n    border: 1px solid var(--checkbox-border);\n    border-radius: 4px;\n    padding: 4px 8px;\n    position: absolute;\n    width: 100%;\n    top: 44px;\n    background-color: var(--layout-bg);\n    z-index: 9999;\n  }\n\n  &__option {\n    padding: 2px 4px;\n    border-radius: 4px;\n\n    label {\n      display: flex;\n      align-items: center;\n      gap: 8px;\n      color: white;\n      cursor: pointer;\n    }\n\n    &:hover {\n      background-color: var(--checkbox-border);\n    }\n\n    input{\n      accent-color: var(--dark-theme-element) !important;\n    }\n  }\n\n  &__select-all {\n    border-bottom: 1px solid var(--checkbox-border);\n    margin-bottom: 10px;\n    padding-bottom: 4px;\n  }\n\n  &__single-option-area {\n    overflow: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
