import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import TableSpinnerIcon from "../../../Icon-SVG/TableSpinnerIcon";

import { RequestContainerServices } from "../../../../store/actions/containerActions";

import "./styles.scss";

const ContainerServices = () => {
  const dispatch = useDispatch();

  const containerServices = useSelector((state) => state.container.containerServices);
  const loading = useSelector((state) => state.container.containerServicesLoading);

  useEffect(() => {
    dispatch(RequestContainerServices());
  }, [dispatch]);

  const refreshHandler = () => {
    dispatch(RequestContainerServices());
  };

  return (
    <div className="container-services">
      <div className="container-services__header">
        <h2 className="container-services__header__heading">C. Services</h2>
        <div className="container-services__header__refresh" onClick={refreshHandler}>
          <TableSpinnerIcon
            className="container-services__spinner"
            customStyle={{ margin: "0", height: "20px" }}
            spin={loading}
          />
        </div>
      </div>
      {containerServices?.map((containerService, index) => (
        <div className="container-services__block border" key={index}>
          {/* <div className="container-services__block--icon">
            {service.renderIcon("white", ICON_SIZE.width, ICON_SIZE.height)}
          </div> */}
          <p className="container-services__block--text" style={{ color: "white" }}>
            {containerService.name}
          </p>
          <div className="container-services__block--signal">
            <b
              className={`container-services__block--signal--status ${containerService.status}`}></b>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContainerServices;
