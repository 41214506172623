import React, { useState } from "react";
import TabButton from "../../../../../Components/common/TabButton/TabButton";
import RoutingTab from "./Tabs/RoutingTab";
import VPN from "./Tabs/VPN";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";
import "./styles.scss";

const Routing = () => {
  const [active, setActive] = useState("Routing");
  return (
    <div className="routing">
      <h1>Routing</h1>
      <div className="routing__body">
        <DemoDiv styleClass={active} />
        <div className="button-block">
          <TabButton title="Routing" onClick={setActive} active={active} />
          <TabButton title="VPN" onClick={setActive} active={active} />
        </div>
        <div style={{ background: "var(--card-background-color)" }}>
          {active === "Routing" ? <RoutingTab /> : <VPN />}
        </div>
      </div>
    </div>
  );
};

export default Routing;
