// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smtp {
  border-radius: 0 4px 4px 4px;
}
.smtp__download {
  display: flex;
  align-items: center;
  color: var(--settings-article-value);
  font-size: 12px;
  cursor: pointer;
}
.smtp .select-box--box {
  margin-top: 5px;
}
.smtp .label {
  color: var(--text-color-card) !important;
}
.smtp .custom-table {
  padding-bottom: 30px;
}
.smtp .custom-table td {
  padding: 10px 15px;
}
.smtp .custom-table th {
  padding: 10px 15px;
  background: var(--settings-table-header);
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/Notifications/Tabs/SNMP/styles.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,eAAA;EACA,eAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,wCAAA;AADJ;AAIE;EACE,oBAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAKI;EACE,kBAAA;EACA,wCAAA;EACA,YAAA;AAHN","sourcesContent":[".smtp {\n  border-radius: 0 4px 4px 4px;\n\n  &__download {\n    display: flex;\n    align-items: center;\n    color: var(--settings-article-value);\n    font-size: 12px;\n    cursor: pointer;\n  }\n\n  .select-box--box {\n    margin-top: 5px;\n  }\n\n  .label {\n    color: var(--text-color-card) !important;\n  }\n\n  .custom-table {\n    padding-bottom: 30px;\n\n    td {\n      padding: 10px 15px;\n    }\n\n    th {\n      padding: 10px 15px;\n      background: var(--settings-table-header);\n      border: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
