// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backup .custom-table .url .label {
  margin: 0;
}
.backup .custom-table .url .field {
  width: 100% !important;
  padding: 10px;
  margin: 0;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/Settings/BackupCells/Remote%20URL/styles.scss","webpack://./src/Components/PagesComponents/Settings/BackupCells/Remote URL/styles.scss"],"names":[],"mappings":"AACE;EACE,SAAA;ACAJ;ADEE;EACE,sBAAA;EACA,aAAA;EACA,SAAA;EACA,YAAA;ACAJ","sourcesContent":[".backup .custom-table .url {\n  .label {\n    margin: 0;\n  }\n  .field {\n    width: 100% !important;\n    padding: 10px;\n    margin: 0;\n    height: 40px;\n  }\n}\n",".backup .custom-table .url .label {\n  margin: 0;\n}\n.backup .custom-table .url .field {\n  width: 100% !important;\n  padding: 10px;\n  margin: 0;\n  height: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
