// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-block {
  display: flex;
  flex-direction: column;
}
.details-block__row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid var(--dropped--file-lists-sidebar-border-color);
}
.details-block__row-item {
  font-size: 12px;
  line-height: 16px;
  padding: 6px;
  color: var(--details-text-active-color);
  overflow-wrap: break-word;
}
@media screen and (max-width: 1300px) {
  .details-block__row-item {
    font-size: 11px;
  }
}
.details-block__row-item:nth-child(1) {
  width: 20%;
}
.details-block__row-item:nth-child(2) {
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/DroppedFileList/Tab/Details/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,wEAAA;AADJ;AAIE;EACE,eAAA;EACA,iBAAA;EACA,YAAA;EACA,uCAAA;EACA,yBAAA;AAFJ;AAII;EAPF;IAQI,eAAA;EADJ;AACF;AAGI;EACE,UAAA;AADN;AAGI;EACE,UAAA;AADN","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.details-block {\n  display: flex;\n  flex-direction: column;\n\n  &__row {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    border-bottom: 1px solid var(--dropped--file-lists-sidebar-border-color);\n  }\n\n  &__row-item {\n    font-size: 12px;\n    line-height: 16px;\n    padding: 6px;\n    color: var(--details-text-active-color);\n    overflow-wrap: break-word;\n\n    @media screen and (max-width: 1300px) {\n      font-size: 11px;\n    }\n\n    &:nth-child(1) {\n      width: 20%;\n    }\n    &:nth-child(2) {\n      width: 80%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
