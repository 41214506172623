// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backup__body {
  position: relative;
  margin-top: 65px;
  background: var(--settings-background-page);
}
.backup__body .border {
  border-radius: 0 4px 4px 4px;
}
.backup__drag-drop {
  width: 65%;
  padding: 0 20px;
}
.backup__content {
  min-height: 300px;
}
.backup .custom-table {
  min-height: 300px !important;
}
.backup .custom-table th {
  background: var(--settings-table-header);
  padding: 10px 30px;
}
@media screen and (max-width: 1300px) {
  .backup .custom-table th {
    padding: 10px;
  }
}
.backup .custom-table td {
  padding: 10px 30px;
}
@media screen and (max-width: 1300px) {
  .backup .custom-table td {
    padding: 10px;
  }
}
.backup .primary-button {
  padding: 6px 10px;
}
@media screen and (max-width: 1300px) {
  .backup .primary-button {
    font-size: 13px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/ApplianceBackupRestore/Tabs/Backup/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,gBAAA;EACA,2CAAA;AAAJ;AAEI;EACE,4BAAA;AAAN;AAIE;EACE,UAAA;EACA,eAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;AAME;EACE,4BAAA;AAJJ;AAOE;EACE,wCAAA;EACA,kBAAA;AALJ;AAOI;EAJF;IAKI,aAAA;EAJJ;AACF;AAOE;EACE,kBAAA;AALJ;AAOI;EAHF;IAII,aAAA;EAJJ;AACF;AAOE;EACE,iBAAA;AALJ;AAOI;EAHF;IAII,eAAA;EAJJ;AACF","sourcesContent":[".backup {\n  &__body {\n    position: relative;\n    margin-top: 65px;\n    background: var(--settings-background-page);\n\n    .border {\n      border-radius: 0 4px 4px 4px;\n    }\n  }\n\n  &__drag-drop {\n    width: 65%;\n    padding: 0 20px;\n  }\n\n  &__content {\n    min-height: 300px;\n  }\n\n  .custom-table {\n    min-height: 300px !important;\n  }\n\n  .custom-table th {\n    background: var(--settings-table-header);\n    padding: 10px 30px;\n\n    @media screen and (max-width: 1300px) {\n      padding: 10px;\n    }\n  }\n\n  .custom-table td {\n    padding: 10px 30px;\n\n    @media screen and (max-width: 1300px) {\n      padding: 10px;\n    }\n  }\n\n  .primary-button {\n    padding: 6px 10px;\n\n    @media screen and (max-width: 1300px) {\n      font-size: 13px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
