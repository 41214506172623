// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-doughnut {
  position: relative;
  cursor: pointer;
}
.chart-doughnut__value {
  position: absolute;
  top: 49%;
  left: 50%;
  color: var(--analysis-reports-information-value);
  font-size: 14.5px;
  text-align: center;
  transform: translate(-50%, -25%);
  text-decoration: none;
}
.chart-doughnut__sub-value {
  font-size: 10px;
}

.chart-radar {
  position: relative;
  min-width: 230px;
  margin-top: 4px;
}

.report-chart-radar {
  position: relative;
  min-width: 230px;
  margin-top: 4px;
  background-color: var(--layout-bg);
  border-radius: 100%;
}

.chart-bar {
  float: right;
  display: table-cell;
  max-width: 457px;
  margin-top: 5px;
  height: 100%;
  width: 80em;
  background-color: var(--dashboard-card-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/Chart/styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,eAAA;AADF;AAGE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gDAAA;EACA,iBAAA;EACA,kBAAA;EACA,gCAAA;EACA,qBAAA;AADJ;AAIE;EACE,eAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;AAHF;;AAMA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,kCAAA;EACA,mBAAA;AAHF;;AAMA;EACE,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,6CAAA;AAHF","sourcesContent":["@import \"../../../styles/colors\";\n\n.chart-doughnut {\n  position: relative;\n  cursor: pointer;\n\n  &__value {\n    position: absolute;\n    top: 49%;\n    left: 50%;\n    color: var(--analysis-reports-information-value);\n    font-size: 14.5px;\n    text-align: center;\n    transform: translate(-50%, -25%);\n    text-decoration: none;\n  }\n\n  &__sub-value {\n    font-size: 10px;\n  }\n}\n\n.chart-radar {\n  position: relative;\n  min-width: 230px;\n  margin-top: 4px;\n}\n\n.report-chart-radar {\n  position: relative;\n  min-width: 230px;\n  margin-top: 4px;\n  background-color: var(--layout-bg);\n  border-radius: 100%;\n}\n\n.chart-bar {\n  float: right;\n  display: table-cell;\n  max-width: 457px;\n  margin-top: 5px;\n  height: 100%;\n  width: 80em;\n  background-color: var(--dashboard-card-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
