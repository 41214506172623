import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Field from "../../../Components/Field";
import Select from "../../../Components/Select";

const JsonDump = () => {
  const [jsondump, setJsondump] = useState(false);
  const [jsondumpLatin, setJsondumpLatin] = useState("latin-1");
  const [ramBoost, setRamBoost] = useState(false);
  const [indent, setIndent] = useState("");
  const [encoding, setEncoding] = useState();

  const [reportHtml, setReportHtml] = useState(false);
  const [reportHtmlSummary, setReportHtmlSummary] = useState(false);
  const [reportPdf, setReportPdf] = useState(false);

  return (
    <div className="border reporting__content">
      <h3>Jsondump</h3>
      <Toggle
        label="Jsondump"
        setActive={setJsondump}
        active={jsondump}
        parentClassName="m-b-20 m-t-10"
        type
      />
      <Field
        label="Jsondump"
        width="178px"
        value={jsondumpLatin}
        onChange={(e) => setJsondumpLatin(e.target.value)}
        parentClassName="m-b-20"
        placeholder="latin-1"
      />
      <Toggle
        label="Ram boost"
        setActive={setRamBoost}
        active={ramBoost}
        parentClassName="m-b-20"
        tooltipText="use the c-optimized JSON encoder, requires fitting entire JSON results in memory"
        type
      />
      <div className="flex flex-align-items-center m-b-20">
        <Field
          label="indent"
          width="101px"
          value={indent}
          onChange={(e) => setIndent(e.target.value)}
          type="number"
          parentClassName="m-r-30"
          placeholder="4"
        />
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <div className="p-r-90">
          <h3>Report html</h3>
          <Toggle
            label="Report html"
            setActive={setReportHtml}
            active={reportHtml}
            parentClassName="m-t-10"
            tooltipText="required for the WSGI interface"
            type
          />
        </div>
        <div className="p-r-90">
          <h3>Report html summary</h3>
          <Toggle
            label="Report html summary"
            setActive={setReportHtmlSummary}
            active={reportHtmlSummary}
            parentClassName="m-t-10"
            tooltipText="much smaller, faster report generation, omits API logs and is non-interactive"
            type
          />
        </div>
        <div className="p-r-90">
          <h3>Report pdf</h3>
          <Toggle
            label="Report pdf"
            setActive={setReportPdf}
            active={reportPdf}
            parentClassName=" m-t-10"
            tooltipText="Note that this requires reporthtmlsummary to be enabled above as well"
            type
          />
        </div>
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default JsonDump;
