import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FileUpload from "../../../../../../Components/common/FileUpload/FileUpload";
import Spinner from "../../../../../../Components/common/Spinner/Spinner";

import {
  RequestCustomSignatureUpload,
  SetCustomSignatureFile,
} from "../../../../../../store/actions/settingsActions";

const ALLOWED_EXTENSIONS = {
  yara: ["yara", "yar"],
};

const UploadSignature = ({ signatureTypeWordings }) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [uploadFileError, setUploadFileError] = useState("");

  const uploading = useSelector((state) => state.settings.customSignatureUploading);
  const customSignatureFile = useSelector((state) => state.settings.customSignatureFile);

  const uploadSignatureHandler = () => {
    dispatch(RequestCustomSignatureUpload({ file, signatureType: signatureTypeWordings.slug }));
  };

  const onFileSelected = (selectedFile) => {
    dispatch(SetCustomSignatureFile(selectedFile));
    setFile(selectedFile);
  };

  useEffect(() => {
    if (!customSignatureFile) {
      setFile(null);
    }
  }, [customSignatureFile]);

  // This useEffect ensures that page or tab changes removes selected file
  useEffect(() => {
    return () => {
      setFile(null);
      dispatch(SetCustomSignatureFile(null));
    };
  }, [signatureTypeWordings.slug, dispatch]);

  return (
    <div className="upload-signature">
      <h1 className="upload-signature__header">Upload {signatureTypeWordings.capitalized}</h1>
      <div className="upload-signature__body">
        <div className="upload-signature__body__header">
          <h3>Upload {signatureTypeWordings.capitalized}</h3>
          <p>
            NOTE: You can upload a {signatureTypeWordings.lowerCase} file using drag and drop area
            below.
          </p>
        </div>
        <div className="upload-signature__body__content">
          {uploadFileError && <p>{uploadFileError}</p>}
          <div className="upload-signature__body__actions">
            <FileUpload
              file={file}
              setFile={onFileSelected}
              setFileError={setUploadFileError}
              allowedExtensions={ALLOWED_EXTENSIONS[signatureTypeWordings.camelCase]}
              errorMessage={`Please select ${ALLOWED_EXTENSIONS[
                signatureTypeWordings.camelCase
              ].join(" or ")} file.`}
            />
            <button className="primary-button" onClick={uploadSignatureHandler} disabled={!file}>
              {uploading ? <Spinner width={22} height={22} /> : "Upload"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadSignature;
