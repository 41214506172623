import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";

const Fti = () => {
  const [ftiEnabled, setFtiEnabled] = useState(false);
  const [demand, setDemand] = useState(false);
  const [Static, setStatic] = useState(false);
  const [sbox, setSbox] = useState(false);

  const [procDump, setProcDump] = useState(false);
  const [file, setFile] = useState(false);
  const [url, setUrl] = useState(false);

  const [decompression, setDecompression] = useState(false);
  const [dumptls, setDumptls] = useState(false);
  const [behavior, setBehavior] = useState(false);
  const [debug, setDebug] = useState(false);

  return (
    <div className="border processing__content">
      <Toggle
        active={ftiEnabled}
        label="FTI"
        setActive={setFtiEnabled}
        parentClassName="m-b-20"
        type
      />
      <div className="flex flex-align-items-center m-b-20">
        <Toggle active={demand} label="On demand" setActive={setDemand} parentClassName="p-r-40" />
        <Toggle
          active={Static}
          label="Static"
          setActive={setStatic}
          parentClassName="p-r-40"
          tooltipText="Analyze binary payloads"
        />
        <Toggle
          active={sbox}
          label="SBOX"
          setActive={setSbox}
          parentClassName="p-r-40"
          tooltipText="Analyze SBOX payloads"
        />
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          active={procDump}
          label="ProcDump"
          setActive={setProcDump}
          parentClassName="p-r-40"
          tooltipText="Analyze ProcDump"
        />
        <Toggle active={file} label="File" setActive={setFile} parentClassName="p-r-40" />
        <Toggle active={url} label="Url" setActive={setUrl} parentClassName="p-r-40" />
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          active={decompression}
          label="Decompression"
          setActive={setDecompression}
          parentClassName="p-r-40"
          type
        />
        <Toggle
          active={dumptls}
          label="DUMPTLS"
          setActive={setDumptls}
          parentClassName="p-r-40"
          type
        />

        <Toggle
          active={behavior}
          label="Behavior"
          setActive={setBehavior}
          parentClassName="p-r-40"
          type
        />
        <Toggle active={debug} label="Debug" setActive={setDebug} parentClassName="p-r-40" type />
      </div>

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Fti;
