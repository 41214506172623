import React from "react";

const Score = (props) => {
  const score = props.row.original.score;

  return (
    <div
      className={`table__body-column__score table__body-column__score-${
        score === 1
          ? "one"
          : score === 2
          ? "two"
          : score === 3
          ? "three"
          : score === 4
          ? "four"
          : "five"
      }`}>
      {score}/5
    </div>
  );
};

export default Score;
