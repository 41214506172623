// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  border: none;
  background: none;
  font-size: 2em;
  cursor: pointer;
  color: white;
}

.modal-close:hover {
  color: #55a0e6;
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/CloudModel/CloudPages/Terms/styles.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AADF;;AAIA;EACE,8BAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;AADF;;AAIA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,YAAA;AADF;;AAIA;EACE,cAAA;EACA,uBAAA;AADF","sourcesContent":["@import \"../../../styles/colors\";\n\n.modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n}\n\n.modal-content {\n  background: rgba(0, 0, 0, 0.5);\n  padding: 20px;\n  border-radius: 8px;\n  max-width: 600px;\n  width: 90%;\n  position: relative;\n}\n\n.modal-close {\n  position: absolute;\n  top: 10px;\n  right: 20px;\n  border: none;\n  background: none;\n  font-size: 2em;\n  cursor: pointer;\n  color: white;\n}\n\n.modal-close:hover {\n  color: #55a0e6;\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
