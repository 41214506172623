// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.http {
  border-radius: 0 4px 4px 4px;
}
.http .select-box--box {
  margin-top: 5px;
}
.http .label {
  color: var(--text-color-card) !important;
}
.http .custom-table {
  padding-bottom: 30px;
}
.http .custom-table td {
  padding: 10px 15px;
}
.http .custom-table th {
  padding: 10px 15px;
  background: var(--settings-table-header);
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/Notifications/Tabs/HTTP/styles.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,wCAAA;AAAJ;AAGE;EACE,oBAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAII;EACE,kBAAA;EACA,wCAAA;EACA,YAAA;AAFN","sourcesContent":[".http {\n  border-radius: 0 4px 4px 4px;\n\n  .select-box--box {\n    margin-top: 5px;\n  }\n\n  .label {\n    color: var(--text-color-card) !important;\n  }\n\n  .custom-table {\n    padding-bottom: 30px;\n\n    td {\n      padding: 10px 15px;\n    }\n\n    th {\n      padding: 10px 15px;\n      background: var(--settings-table-header);\n      border: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
