// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search {
  padding: 0 20px;
}
.search .toggle-button {
  margin-left: 0;
}
.search .field {
  width: 100%;
  height: 40px;
  padding-right: 0;
}
.search .label {
  max-width: 400px;
  color: var(--text-color-card) !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/WebServiceConfiguration/Tabs/Search/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,wCAAA;AADJ","sourcesContent":[".search {\n  padding: 0 20px;\n\n  .toggle-button {\n    margin-left: 0;\n  }\n\n  .field {\n    width: 100%;\n    height: 40px;\n    padding-right: 0;\n  }\n\n  .label {\n    max-width: 400px;\n    color: var(--text-color-card) !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
