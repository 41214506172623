// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  width: 100%;
}
.dropdown__title {
  color: white;
  background-color: var(--dashboard-table-tabs);
  outline: none;
  border: 1px solid var(--border);
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  font-family: Nunito, sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown__title:hover {
  background-color: var(--hover-item-color);
}
.dropdown__items {
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-left: 1px solid var(--border);
  margin: 8px 0 8px 12px;
}
.dropdown__item {
  padding: 4px 8px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  position: relative;
}
.dropdown__item__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 3px;
}
.dropdown__item__active {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: var(--details-text-active-color);
  border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/Dropdown/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,YAAA;EACA,6CAAA;EACA,aAAA;EACA,+BAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,+BAAA;EACA,eAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AACI;EACE,yCAAA;AACN;AAGE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,oCAAA;EACA,sBAAA;AADJ;AAIE;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;AAFJ;AAII;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,iBAAA;AAFN;AAKI;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kDAAA;EACA,uBAAA;AAHN","sourcesContent":[".dropdown {\n  width: 100%;\n\n  &__title {\n    color: white;\n    background-color: var(--dashboard-table-tabs);\n    outline: none;\n    border: 1px solid var(--border);\n    cursor: pointer;\n    padding: 8px;\n    border-radius: 4px;\n    font-family: Nunito, sans-serif;\n    font-size: 13px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    &:hover {\n      background-color: var(--hover-item-color);\n    }\n  }\n\n  &__items {\n    display: flex;\n    flex-direction: column;\n    gap: 2px;\n    border-left: 1px solid var(--border);\n    margin: 8px 0 8px 12px;\n  }\n\n  &__item {\n    padding: 4px 8px;\n    color: white;\n    font-size: 12px;\n    cursor: pointer;\n    position: relative;\n\n    &__name {\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      margin-right: 3px;\n    }\n\n    &__active {\n      position: absolute;\n      right: 6px;\n      top: 50%;\n      transform: translateY(-50%);\n      width: 11px;\n      height: 11px;\n      border-radius: 100%;\n      background-color: var(--details-text-active-color);\n      border: 1px solid black;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
