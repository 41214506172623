import React from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { operatingSystem } from "../../../utils/constants";
import Tooltip from "../Tooltip/Tooltip";
import "./styles.scss";

const TagField = ({ tags, setTags, label, parentClassName, tooltipText }) => {
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  return (
    <div className={parentClassName}>
      <p className="tag-fields__label">
        {label}
        <Tooltip text={tooltipText} />
      </p>
      <ReactTags
        tags={tags}
        suggestions={operatingSystem}
        delimiters={[188, 13]}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        inputFieldPosition="top"
        allowDragDrop={false}
        placeholder="Add a tag and press Enter"
        autocomplete
        minQueryLength={1}
        classNames={{
          tagInputField: "tag-fields",
          selected: "selectedClass",
          tag: "tag-fields__tag",
          remove: "tag-fields__button",
          suggestions: "tag-fields__suggestions",
        }}
      />
    </div>
  );
};

export default TagField;
