import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../Components/common/Spinner/Spinner";
import TableSpinnerIcon from "../../../Components/Icon-SVG/TableSpinnerIcon";

import {
  RequestContainerService,
  RequestPodRestart,
  RequestPodUpdate,
} from "../../../store/actions/containerActions";

const Header = ({ selectedProduct, selectedResponsible, onRefresh, spin }) => {
  return (
    <div className="services-body-header">
      <div className="services-body-header__title">
        <h3>
          {selectedProduct} / <span>{selectedResponsible}</span>
        </h3>
        <div onClick={onRefresh}>
          <TableSpinnerIcon
            className="services-body-header__spinner"
            customStyle={{ margin: "0", height: "20px" }}
            spin={spin}
          />
        </div>
      </div>
      <p className="services-body-header__description">
        <span> NOTE:</span> You can update and restart pods using buttons
      </p>
    </div>
  );
};

const TITLES = ["NAME", "READY", "STATUS", "AGE", "UPDATE", "RESTART"];

const formatTime = (ageString) => {
  if (ageString.includes(",")) {
    return ageString.split(",")[0].trim();
  }

  const timeParts = ageString.split(":");

  if (timeParts.length === 3) {
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2].split(".")[0], 10);

    if (hours > 0) {
      return `${hours} ${hours === 1 ? "hour" : "hours"}`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
    } else {
      return `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
    }
  }

  return ageString;
};

const List = ({ containerService }) => {
  const dispatch = useDispatch();

  const updatePodName = useSelector((state) => state.container.updatePodName);
  const updatePodLoading = useSelector((state) => state.container.updatePodLoading);
  const restartPodName = useSelector((state) => state.container.restartPodName);
  const restartPodLoading = useSelector((state) => state.container.restartPodLoading);

  const updateHandler = (pod) => {
    if (!pod?.image_name) return;
    if (updatePodLoading && updatePodName === pod.name) return;
    if (restartPodLoading && restartPodName === pod.name) return;

    const responsible = pod.responsible;
    const imageName = pod.image_name.split("/")[1];
    const podName = pod.name;
    dispatch(RequestPodUpdate({ responsible, imageName, podName }));
  };

  const restartHandler = (pod) => {
    if (restartPodLoading && restartPodName === pod.name) return;
    if (updatePodLoading && updatePodName === pod.name) return;

    const responsible = pod.responsible;
    const podName = pod.name;
    dispatch(RequestPodRestart({ responsible, podName }));
  };

  return (
    <table>
      <thead>
        <tr>
          {TITLES.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {containerService.map((pod, i) => (
          <tr key={i}>
            <td>{pod.name}</td>
            <td>{pod.ready}</td>
            <td>{pod.status}</td>
            <td>{formatTime(pod.age)}</td>
            <td>
              <button onClick={() => updateHandler(pod, i)} disabled={!pod.update}>
                {(updatePodLoading && updatePodName === pod.name) ||
                (restartPodLoading && restartPodName === pod.name) ? (
                  <Spinner width={12} height={12} />
                ) : (
                  "Update"
                )}
              </button>
            </td>
            <td>
              <button onClick={() => restartHandler(pod, i)}>
                {(restartPodLoading && restartPodName === pod.name) ||
                (updatePodLoading && updatePodName === pod.name) ? (
                  <Spinner width={12} height={12} />
                ) : (
                  "Restart"
                )}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ContainerServicesBody = ({ selectedProduct, selectedResponsible }) => {
  const dispatch = useDispatch();

  const containerService = useSelector((state) => state.container.containerService);
  const containerServiceLoading = useSelector((state) => state.container.containerServiceLoading);

  useEffect(() => {
    if (!selectedResponsible) return;
    dispatch(RequestContainerService(selectedResponsible));
  }, [dispatch, selectedResponsible]);

  const refreshHandler = () => {
    if (containerServiceLoading) return;
    dispatch(RequestContainerService(selectedResponsible));
  };

  if (!selectedResponsible)
    return <p className="services-no-select">Please select a product and a host.</p>;

  return (
    <div className="services-body">
      <Header
        selectedProduct={selectedProduct}
        selectedResponsible={selectedResponsible}
        onRefresh={refreshHandler}
        spin={containerServiceLoading}
      />
      <div className="services-body-content">
        {containerServiceLoading ? (
          <div className="services-body-content__center">
            <Spinner width={24} height={24} />
          </div>
        ) : containerService.length ? (
          <List containerService={containerService} />
        ) : (
          <div className="services-body-content__center">
            <p className="services-body-content__no-data">No data</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContainerServicesBody;
