// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.classcategories__display {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
.classcategories__border {
  border-style: solid;
  border-width: 3px 3px;
  border-radius: 15px;
  white-space: nowrap;
  padding: 5px;
  margin: 5px;
}
.classcategories__border--malicious {
  border-color: #ec374a;
  text-transform: capitalize;
}
.classcategories__border--suspicious {
  border-color: #e69b55;
}
.classcategories__border--nothing {
  border-color: gray;
}
.classcategories__border--pointer {
  cursor: pointer;
}
.classcategories__text {
  font-size: 14px !important;
  color: black;
  font-weight: bold;
  border: 1px solid transparent;
  font-family: Nunito, serif;
}
.classcategories__no-data {
  color: var(--analysis-reports-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/ReportExport/ClassCategories/styles.scss"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;AAFJ;AAIE;EACE,mBAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AAFJ;AAII;EACE,qBAAA;EACA,0BAAA;AAFN;AAII;EACE,qBAAA;AAFN;AAII;EACE,kBAAA;AAFN;AAII;EACE,eAAA;AAFN;AAKE;EACE,0BAAA;EACA,YAAA;EACA,iBAAA;EACA,6BAAA;EACA,0BAAA;AAHJ;AAKE;EACE,oCAAA;AAHJ","sourcesContent":["@import \"../../../../styles/colors\";\n\n.classcategories {\n  &__display {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    gap: 4px;\n    flex-wrap: wrap;\n    align-items: center;\n    height: 100%;\n  }\n  &__border {\n    border-style: solid;\n    border-width: 3px 3px;\n    border-radius: 15px;\n    white-space: nowrap;\n    padding: 5px;\n    margin: 5px;\n\n    &--malicious {\n      border-color: #ec374a;\n      text-transform: capitalize;\n    }\n    &--suspicious {\n      border-color: #e69b55;\n    }\n    &--nothing {\n      border-color: gray;\n    }\n    &--pointer {\n      cursor: pointer;\n    }\n  }\n  &__text {\n    font-size: 14px !important;\n    color: black;\n    font-weight: bold;\n    border: 1px solid transparent;\n    font-family: Nunito, serif;\n  }\n  &__no-data {\n    color: var(--analysis-reports-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
