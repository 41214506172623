import React, { useState } from "react";
import TextField from "../../../../../../../Components/common/TextField/TextField";
import CustomSelect from "../../../../../../../Components/common/CustomSelect/CustomSelect";
import ToggleButton from "../../../../../../../Components/common/ToggleButton/ToggleButton";
import { getHours } from "../../../../../../../utils/function";
import "./styles.scss";

const Restore = () => {
  const [toggle, setToggle] = useState(false);
  const [url, setURL] = useState("");

  return (
    <div className="restore border">
      <h3 className="settings-article">Restore Available Backups</h3>
      <div className="restore__content">
        <div className="flex flex-align-items-center flex-justify-content-start flex-wrap ">
          <div>
            <p>Remote URL or SCP</p>
            <TextField
              placeholder="eg.scp | sftp://user[;pwd]@host/remote_path"
              value={url}
              onChange={(e) => setURL(e.target.value)}
            />
          </div>
          <div style={{ margin: "-20px 0 0 25px" }}>
            <p>Profile to Restore</p>
          </div>
        </div>
        <div className="m-b-20">
          <ToggleButton rightText="Exclude Network Settings?" onClick={setToggle} active={toggle} />
        </div>
        <button className="primary-button">Restore</button>
      </div>
    </div>
  );
};

export default Restore;
