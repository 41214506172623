import React, { useState } from "react";
import TextField from "../../../../../../../Components/common/TextField/TextField";
import CustomSelect from "../../../../../../../Components/common/CustomSelect/CustomSelect";
import { getHours } from "../../../../../../../utils/function";
import Toggle from "../../../../Components/Toggle";
import "./styles.scss";

const Email = () => {
  const [emailFrom, setEmailFrom] = useState("");
  const [emailNotifyDefault, setEmailNotifyDefault] = useState("");

  const [emailSMTPServer, setEmailSMTPServer] = useState("");
  const [secureSMTP, setSecureSMTP] = useState(null);
  const [portSMTP, setPortSMTP] = useState(null);

  const [userNameSMTP, setUserNameSMTP] = useState("");
  const [passwordSMTP, setPasswordSMTP] = useState("");

  const [userNameTransactional, setUserNameTransactional] = useState("");
  const [passwordTransactional, setPasswordTransactional] = useState("");

  const [emailSender, setEmailSender] = useState("");
  const [emailSenderName, setEmailSenderName] = useState("");

  const [emailNotify, setEmailNotify] = useState(true);
  const [emailSMTP, setEmailSMTP] = useState(false);

  return (
    <div className="web__email border p-b-20">
      <div className="flex flex-align-items-center">
        <TextField
          labelText="Email from*"
          value={emailFrom}
          onChange={(e) => setEmailFrom(e.target.value)}
        />
        <TextField
          labelText="Notify default Email*"
          value={emailNotifyDefault}
          onChange={(e) => setEmailNotifyDefault(e.target.value)}
        />
      </div>

      <div className="flex flex-align-items-center">
        <TextField
          labelText="Email SMTP server*"
          value={emailSMTPServer}
          onChange={(e) => setEmailSMTPServer(e.target.value)}
        />
        <div className="flex flex-column">
          <p className="web__email__text">Email SMTP secure*</p>
        </div>
        <div className="flex flex-column">
          <p className="web__email__text">Email SMTP port*</p>
        </div>
      </div>

      <div className="flex flex-align-items-center">
        <TextField
          labelText="Email SMTP username*"
          value={userNameSMTP}
          onChange={(e) => setUserNameSMTP(e.target.value)}
        />
        <TextField
          labelText="Email SMTP password*"
          value={passwordSMTP}
          onChange={(e) => setPasswordSMTP(e.target.value)}
        />
      </div>

      <div className="flex flex-align-items-center">
        <TextField
          labelText="Email SMTP username for transactional Emails"
          value={userNameTransactional}
          onChange={(e) => setUserNameTransactional(e.target.value)}
        />
        <TextField
          labelText="Email SMTP password for transactional Emails"
          value={passwordTransactional}
          onChange={(e) => setPasswordTransactional(e.target.value)}
        />
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <TextField
          labelText="Email sender"
          value={emailSender}
          onChange={(e) => setEmailSender(e.target.value)}
        />
        <TextField
          labelText="Email sender name"
          value={emailSenderName}
          onChange={(e) => setEmailSenderName(e.target.value)}
        />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          parentClassName="p-r-50"
          label="Email notify on completed analysis"
          setActive={setEmailNotify}
          active={emailNotify}
        />
        <Toggle active={emailSMTP} setActive={setEmailSMTP} label="Do Email SMTP authorization" />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Email;
