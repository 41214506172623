// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.files-info {
  width: 42%;
  position: relative;
}
@media screen and (max-width: 1250px) {
  .files-info {
    width: 100%;
    margin-bottom: 40px;
  }
}
.files-info__tabs {
  position: absolute;
  top: -43px;
  left: 0;
  z-index: 100;
}
@media screen and (max-width: 1300px) {
  .files-info__tabs {
    top: -39px;
  }
}
.files-info__tabs button {
  border-bottom: 1px solid var(--border);
}
.files-info__body {
  border: 1px solid var(--border);
  background: var(--matrix-table-color);
  border-radius: 0 4px 4px 4px;
  position: relative;
  height: 100%;
  padding: 15px;
}
.files-info__block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}
.files-info__block p {
  color: var(--analysis-reports-color);
  font-size: 12px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
@media screen and (max-width: 1600px) {
  .files-info__block p {
    font-size: 11px;
  }
}
@media screen and (max-width: 1250px) {
  .files-info__block p {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/AnalysisReports/FilesInfo/styles.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,kBAAA;AADF;AAGE;EAJF;IAKI,WAAA;IACA,mBAAA;EAAF;AACF;AAEE;EACE,kBAAA;EACA,UAAA;EACA,OAAA;EACA,YAAA;AAAJ;AAEI;EANF;IAOI,UAAA;EACJ;AACF;AACI;EACE,sCAAA;AACN;AAGE;EACE,+BAAA;EACA,qCAAA;EACA,4BAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;AADJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AAHJ;AAKI;EACE,oCAAA;EACA,eAAA;EACA,yBAAA;EACA,qBAAA;AAHN;AAKM;EANF;IAOI,eAAA;EAFN;AACF;AAIM;EAVF;IAWI,eAAA;EADN;AACF","sourcesContent":["@import \"../../../../styles/colors\";\n\n.files-info {\n  width: 42%;\n  position: relative;\n\n  @media screen and (max-width: 1250px) {\n    width: 100%;\n    margin-bottom: 40px;\n  }\n\n  &__tabs {\n    position: absolute;\n    top: -43px;\n    left: 0;\n    z-index: 100;\n\n    @media screen and (max-width: 1300px) {\n      top: -39px;\n    }\n\n    button {\n      border-bottom: 1px solid var(--border);\n    }\n  }\n\n  &__body {\n    border: 1px solid var(--border);\n    background: var(--matrix-table-color);\n    border-radius: 0 4px 4px 4px;\n    position: relative;\n    height: 100%;\n    padding: 15px;\n\n  }\n\n  &__block {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    height: 100%;\n\n    p {\n      color: var(--analysis-reports-color);\n      font-size: 12px;\n      overflow-wrap: break-word;\n      word-wrap: break-word;\n\n      @media screen and (max-width: 1600px) {\n        font-size: 11px;\n      }\n\n      @media screen and (max-width: 1250px) {\n        font-size: 12px;\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
