// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-box--container {
  height: 40px;
  padding: 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
  font-family: Nunito, sans-serif;
}

.select-box--box {
  position: relative;
}

.select-box--selected-item {
  width: 100%;
  padding: 4px 12px;
  z-index: 9999;
  font-family: Nunito, sans-serif;
}
.select-box--selected-item .placeholder {
  color: var(--settings-header-text);
}

.select {
  padding: 4px 12px;
  background: var(--settings-field-bg);
  color: var(--settings-article-value);
  cursor: pointer;
  text-transform: capitalize;
  font-family: Nunito, sans-serif;
}
.select:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.select:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.select:hover {
  background-color: var(--checkbox-border);
}

.select-box--arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.select-box--options {
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  z-index: 100000;
  border: 1px solid var(--settings-field-border-color);
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/CustomSelect/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,oCAAA;EACA,oDAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,+BAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,+BAAA;AACF;AACE;EACE,kCAAA;AACJ;;AAGA;EACE,iBAAA;EACA,oCAAA;EACA,oCAAA;EACA,eAAA;EACA,0BAAA;EACA,+BAAA;AAAF;AAEE;EACE,2BAAA;EACA,4BAAA;AAAJ;AAGE;EACE,8BAAA;EACA,+BAAA;AADJ;AAIE;EACE,wCAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;AAHF;;AAMA;EACE,kBAAA;EACA,qBAAA;EACA,OAAA;EACA,eAAA;EACA,oDAAA;EACA,kBAAA;AAHF","sourcesContent":[".select-box--container {\n  height: 40px;\n  padding: 0;\n  background: var(--settings-field-bg);\n  border: 1px solid var(--settings-field-border-color);\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n  position: relative;\n  font-family: Nunito, sans-serif;\n}\n\n.select-box--box {\n  position: relative;\n}\n\n.select-box--selected-item {\n  width: 100%;\n  padding: 4px 12px;\n  z-index: 9999;\n  font-family: Nunito, sans-serif;\n\n  .placeholder {\n    color: var(--settings-header-text);\n  }\n}\n\n.select {\n  padding: 4px 12px;\n  background: var(--settings-field-bg);\n  color: var(--settings-article-value);\n  cursor: pointer;\n  text-transform: capitalize;\n  font-family: Nunito, sans-serif;\n\n  &:first-child {\n    border-top-left-radius: 4px;\n    border-top-right-radius: 4px;\n  }\n\n  &:last-child {\n    border-bottom-left-radius: 4px;\n    border-bottom-right-radius: 4px;\n  }\n\n  &:hover {\n    background-color: var(--checkbox-border);\n  }\n}\n\n.select-box--arrow {\n  position: absolute;\n  right: 10px;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n.select-box--options {\n  position: absolute;\n  top: calc(100% + 3px);\n  left: 0;\n  z-index: 100000;\n  border: 1px solid var(--settings-field-border-color);\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
