// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agent__card {
  display: flex;
  padding: 20px;
  background: var(--card-background-color);
  border-radius: 4px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 400px;
  cursor: pointer;
}
.agent__card-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.agent__card-inner__icon div {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid var(--color-blue);
  display: flex;
  align-items: center;
  justify-content: center;
}
.agent__card-inner__icon div:first-child {
  margin-bottom: 10px;
}
.agent__card-inner__text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.agent__card-inner__text-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: var(--dashboard-text-color);
}
.agent__card-inner__text-title-selected-color {
  color: white !important;
}
.agent__card-inner__text-value {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--dashboard-value-color);
}
.agent__card-inner__text-value b {
  font-size: 16px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/AgentStatus/Card/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,aAAA;EACA,wCAAA;EACA,kBAAA;EACA,6CAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AADF;AAGE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAKI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAHN;AAMI;EACE,mBAAA;AAJN;AAQE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AANJ;AASE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kCAAA;AAPJ;AASI;EACE,uBAAA;AAPN;AAWE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mCAAA;AATJ;AAWI;EACE,eAAA;EACA,gBAAA;AATN","sourcesContent":["@import \"../../../../styles/colors.scss\";\n\n.agent__card {\n  display: flex;\n  padding: 20px;\n  background: var(--card-background-color);\n  border-radius: 4px;\n  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);\n  margin: 20px;\n  width: 400px;\n  cursor: pointer;\n\n  &-inner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 12px;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n  }\n\n  &-inner__icon {\n    div {\n      width: 38px;\n      height: 38px;\n      border-radius: 50%;\n      border: 1px solid var(--color-blue);\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n\n    div:first-child {\n      margin-bottom: 10px;\n    }\n  }\n\n  &-inner__text {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n  }\n\n  &-inner__text-title {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 14px;\n    color: var(--dashboard-text-color);\n\n    &-selected-color {\n      color: white !important;\n    }\n  }\n\n  &-inner__text-value {\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 16px;\n    color: var(--dashboard-value-color);\n\n    b {\n      font-size: 16px;\n      font-weight: 700;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
