import React, { useState } from "react";
import CustomSelect from "../../../../../../../Components/common/CustomSelect/CustomSelect";
import { settings_SMTP_SNMP_Fixture, settingsSummary } from "../../../../../../../fixture";
import DownloadIcon from "../../../../../../../Components/Icon-SVG/DownloadIcon";
import ToggleButton from "../../../../../../../Components/common/ToggleButton/ToggleButton";
import CustomTable from "../../../../../../../Components/common/Table/CustomTable";
import { settings_SMTP_SNMP } from "../../../../../../../utils/constants/tableHeaders";
import "./styles.scss";

const Snmp = () => {
  const [delivery, setDelivery] = useState("");
  const [version, setVersion] = useState("");
  const [showServer, setShowServers] = useState(true);
  const [table, setTable] = useState([]);

  const removerServer = () => {
    //console.log(table);
  };

  return (
    <div className="smtp border">
      <div className="border-bottom p-t-15 p-b-15 p-l-15">
        <h3>SNMP settings</h3>

        <div className="m-t-10 m-b-15">
          <span className="smtp__label"> Default format</span>
          <CustomSelect
            width={"205px"}
            data={settingsSummary}
            select={delivery}
            setSelected={setDelivery}
          />
        </div>

        <div className="m-t-10 m-b-15">
          <span className="smtp__label"> Default format</span>
          <CustomSelect
            width={"205px"}
            data={settingsSummary}
            select={version}
            setSelected={setVersion}
          />
        </div>

        <div className="smtp__download">
          <DownloadIcon width={20} height={20} />
          <p className="m-l-10">MIB File download</p>
        </div>

        <button className="m-t-20 m-b-10 primary-button">Apply Settings</button>
      </div>

      <div className="flex flex-align-items-center flex-justify-content-between p-l-15 p-t-15 p-b-15">
        <div className="flex flex-align-items-center">
          <h3>SNMP Trap Sink Listing</h3>
          <ToggleButton
            rightText={!showServer ? "Disable" : "Enable"}
            active={showServer}
            onClick={setShowServers}
          />
        </div>

        {showServer && (
          <div className="flex flex-align-items-center m-b-10">
            <button className="primary-button m-r-25" onClick={removerServer}>
              Remove{" "}
            </button>
            <button className="primary-button m-r-15">Add RSYSLOG Server </button>
          </div>
        )}
      </div>
      {showServer && (
        <CustomTable
          paginationButtons={true}
          className=""
          refresh={false}
          setCheckbox={setTable}
          minHeight={0}
          checkbox
          column={settings_SMTP_SNMP}
          noDataText="No SNMP trap sinks defined."
          dataset={settings_SMTP_SNMP_Fixture}
        />
      )}
    </div>
  );
};

export default Snmp;
