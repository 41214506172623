import React from "react";

const MalwareAnalyzerIcon = ({ width = 56, height = 56 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="55"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision">
      <path
        fill="#010304"
        d="M19.5 2.5l2 3c1.048 2.433 1.714 2.433 2 0 2.333-1.333 4.667-1.333 7 0v2h-3v33c-1.175-.219-2.175.114-3 1a493.061 493.061 0 013 4v8l-2-1c-.333-1.333-.667-1.333-1 0l-2-1c0-.667-.333-1-1-1 0-.667-.333-1-1-1 0-.667-.333-1-1-1l-2.5-5a8.434 8.434 0 00-.5 4l-2-1a42.618 42.618 0 00-1.5-5c-.667-1.333-1.333-1.333-2 0-1.044-1.123-1.71-2.456-2-4-.383.556-.883.89-1.5 1 0-.667-.333-1-1-1v-1c0-.667-.333-1-1-1v-1c.237-1.291-.096-2.291-1-3v-2-4-3c.838-.342 1.172-1.008 1-2v-1c.667 0 1-.333 1-1a5.728 5.728 0 011-3c.667 0 1-.333 1-1 .333-1 1-1.667 2-2 .768.762 1.268 1.762 1.5 3 .189-4.75.855-9.417 2-14 2.076-1.257 3.91-.924 5.5 1zm2 3h2c-.286 2.433-.952 2.433-2 0zm-4 22c1.13-3.384 2.797-3.384 5 0-1.503.552-3.17.552-5 0z"
        opacity="0.639"></path>
      <path
        fill="#0f81b3"
        d="M27.5 53.5v-8a2.428 2.428 0 002-.5c-.837-1.011-1.17-2.178-1-3.5a32.437 32.437 0 008-.5 40.936 40.936 0 00-9-.5v-17c2.4 2.74 5.4 3.573 9 2.5a36.55 36.55 0 00-9-4.5v-9c2.433-.286 2.433-.952 0-2v-3h3c2.433-1.048 2.433-1.714 0-2-2.333-1.333-4.667-1.333-7 0h-2l-2-3c1.897-1.305 4.23-1.971 7-2l7 .5L35 2.5C36.133.789 37.633.289 39.5 1A25.641 25.641 0 0142 8.5l1-3c9.71 7.796 13.043 17.796 10 30-2.712 5.36-5.38 10.693-8 16-2.267 1.435-4.267 1.102-6-1-.5 1.167-1.333 2-2.5 2.5a40.936 40.936 0 01-9 .5zm20-36h-2c-.359-1.871-1.359-3.371-3-4.5 2.663.152 4.33 1.652 5 4.5zm2 7c1.333 1.667 1.333 3.333 0 5-.667 0-1-.333-1-1 .934-1.068 1.268-2.401 1-4zm-16 9c5.753.362 6.086 1.362 1 3-.886-.825-1.219-1.825-1-3zm12 4c.667 0 1 .333 1 1 .073 1.527-.594 2.527-2 3-.268-1.599.066-2.932 1-4z"
        opacity="0.511"></path>
      <path
        fill="#1082b4"
        d="M10.5 12.5c-1 .333-1.667 1-2 2-.667 0-1 .333-1 1a5.728 5.728 0 00-1 3c-.667 0-1 .333-1 1v1c-.838.342-1.172 1.008-1 2v3c-1.333 1.333-1.333 2.667 0 4v2c-.237 1.291.096 2.291 1 3v1c.667 0 1 .333 1 1v1c.667 0 1 .333 1 1 1.594 3.264 3.928 5.93 7 8l2 1c.891.61 1.891.943 3 1 .667 0 1 .333 1 1 .667 0 1 .333 1 1 .667 0 1 .333 1 1l2 1h1c-9.035 1.24-16.201-2.093-21.5-10-2.028-3.932-3.528-7.932-4.5-12v-6c1.315-7.622 4.982-13.955 11-19 1.214 2.359 1.214 4.692 0 7z"
        opacity="0.545"></path>
      <path
        d="M58.5 10.5c1.967-.24 3.8.094 5.5 1l3 8a39.43 39.43 0 003.5-8 9.864 9.864 0 016-1v17h-5c.049-5.039-.618-5.206-2-.5-1.634.494-3.3.66-5 .5-1.282-7.873-1.949-7.873-2 0h-4v-17z"
        opacity="0.629"></path>
      <path
        d="M90.5 10.5c3.45-.744 5.116.59 5 4a4.932 4.932 0 013 .5c1.44 5.355 2.772 5.522 4 .5 3.482-1.323 5.315.01 5.5 4a20.509 20.509 0 002.5-4.5c12.177-4.127 17.177-.127 15 12a30.499 30.499 0 01-11 0 26.674 26.674 0 01-1.5-6.5 38.045 38.045 0 01-2.5 6.5c-1.634.494-3.301.66-5 .5-1.114-5.13-1.781-5.13-2 0a12.935 12.935 0 01-5-.5c-2.313-7.462-3.313-7.295-3 .5h-5v-17z"
        opacity="0.563"></path>
      <path
        d="M78.5 14.5c10.386-1.576 14.053 2.757 11 13-3.937.294-7.77-.04-11.5-1-.357-4.01-.19-8.01.5-12z"
        opacity="0.576"></path>
      <path
        d="M127.5 14.5c3.719-.728 6.719.272 9 3 2.778-2.807 6.111-3.64 10-2.5.964 4.066 1.297 8.233 1 12.5a40.939 40.939 0 01-9-.5c-1.393-1.171-2.227-2.671-2.5-4.5-1.676-.561-3.009-1.561-4-3a32.462 32.462 0 00-.5 8h-4v-13z"
        opacity="0.541"></path>
      <path
        d="M45.5 17.5h2c-.172.992.162 1.658 1 2-.237 1.291.096 2.291 1 3v2c.268 1.599-.066 2.932-1 4 0-.667-.333-1-1-1-.734-1.208-1.067-2.541-1-4 1.333-.333 1.333-.667 0-1 .172-.992-.162-1.658-1-2v-3z"
        opacity="0.191"></path>
      <path
        d="M47.5 28.5h1c0 .667.333 1 1 1v3c-.904.709-1.237 1.709-1 3l-2 3c0-.667-.333-1-1-1v-2c1.333-1 1.333-2 0-3v-3c.992.172 1.658-.162 2-1z"
        opacity="0.323"></path>
      <path
        fill="#1081b3"
        d="M151.5 29.5c6.598 1.87 8.265 5.87 5 12-3.437 1.578-6.604 1.245-9.5-1-2.841 2.004-6.007 2.504-9.5 1.5-1-3.333-2-3.333-3 0a32.438 32.438 0 01-8 .5c1.205-4.013 1.205-8.013 0-12h9c-.041 3.743.626 3.91 2 .5l4.5-.5c1.981.13 3.647.796 5 2 1.31-1.397 2.81-2.397 4.5-3zm-1 5c4.549.432 5.049 2.099 1.5 5-1.269-1.405-1.769-3.071-1.5-5z"
        opacity="0.503"></path>
      <path
        fill="#1082b3"
        d="M60.5 30.5c1.478-.262 2.811.071 4 1l3.5 7c.496-1.973.662-3.973.5-6 2.698-.034 5.198.633 7.5 2 2.885-2.385 5.885-2.385 9 0a8.434 8.434 0 00.5-4c5.292.226 8.125 2.893 8.5 8l1-5c.667-1.333 1.333-1.333 2 0a30.499 30.499 0 010 11c-2.38.935-4.88 1.268-7.5 1v-3a593.947 593.947 0 00-17 0c-.333-8-.667-8-1 0-3.194.23-6.194-.436-9-2-1.834 1.255-3.834 1.921-6 2a44.684 44.684 0 014-12z"
        opacity="0.494"></path>
      <path
        fill="#1082b3"
        d="M98.5 32.5c3.185-.48 6.019.186 8.5 2l6-2c2.669-.154 5.503.18 8.5 1v5c-1.624.14-2.79-.527-3.5-2a18.452 18.452 0 00-.5 6 40.939 40.939 0 01-9-.5l-1.5-1.5c-2.456 1.821-5.289 2.488-8.5 2-.322-2.222.345-4.056 2-5.5-1.607-1.049-2.273-2.549-2-4.5z"
        opacity="0.473"></path>
    </svg>
  );
};

export default MalwareAnalyzerIcon;
