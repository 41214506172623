import React from "react";

const RoutingIcon = ({ color = "var(--border)", width, height }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none">
        <path
          d="M6 18C4.9 18 3.95833 17.6083 3.175 16.825C2.39167 16.0417 2 15.1 2 14V5.825C1.41667 5.60833 0.937333 5.24567 0.562 4.737C0.187333 4.229 0 3.65 0 3C0 2.16667 0.291667 1.45833 0.875 0.875C1.45833 0.291667 2.16667 0 3 0C3.83333 0 4.54167 0.291667 5.125 0.875C5.70833 1.45833 6 2.16667 6 3C6 3.65 5.81267 4.229 5.438 4.737C5.06267 5.24567 4.58333 5.60833 4 5.825V14C4 14.55 4.196 15.021 4.588 15.413C4.97933 15.8043 5.45 16 6 16C6.55 16 7.021 15.8043 7.413 15.413C7.80433 15.021 8 14.55 8 14V4C8 2.9 8.39167 1.95833 9.175 1.175C9.95833 0.391667 10.9 0 12 0C13.1 0 14.0417 0.391667 14.825 1.175C15.6083 1.95833 16 2.9 16 4V12.175C16.5833 12.3917 17.0627 12.754 17.438 13.262C17.8127 13.7707 18 14.35 18 15C18 15.8333 17.7083 16.5417 17.125 17.125C16.5417 17.7083 15.8333 18 15 18C14.1667 18 13.4583 17.7083 12.875 17.125C12.2917 16.5417 12 15.8333 12 15C12 14.35 12.1873 13.7667 12.562 13.25C12.9373 12.7333 13.4167 12.375 14 12.175V4C14 3.45 13.8043 2.97933 13.413 2.588C13.021 2.196 12.55 2 12 2C11.45 2 10.9793 2.196 10.588 2.588C10.196 2.97933 10 3.45 10 4V14C10 15.1 9.60833 16.0417 8.825 16.825C8.04167 17.6083 7.1 18 6 18Z"
          fill={color || "var(--settings-sidebar-icon)"}
        />
      </svg>
    </div>
  );
};

export default RoutingIcon;
