import React, { useState } from "react";
import Field from "../../../Components/Field";
import IpInput from "../../../../../../Components/common/IpInput/IpInput";

const Server = () => {
  const [ip, setIp] = useState("0.0.0.0");
  const [maxSize, setMaxSize] = useState("165323232");
  const [port, setPort] = useState("3043");

  return (
    <div className="border cfsbox__content">
      <IpInput
        placeholder="0.0.0.0"
        value={ip}
        onChange={(value) => setIp(value)}
        width={"234px"}
        label="Ip"
        tooltipText="The Result Server is used to receive in real time the behavioral logs produced by the analyzer. Specify the IP address of the host. The analysis machines should be able to contact the host through such address, so make sure it's valid. NOTE: if you set resultserver IP to 0.0.0.0 you have to set the option `resultserver_ip` for all your virtual machines in machinery configuration."
      />

      <div className="flex flex-align-items-center m-b-20 m-t-10">
        <Field
          value={maxSize}
          onChange={(e) => setMaxSize(e.target.value)}
          label="Upload max size"
          width={"156px"}
          tooltipText="Maximum size of uploaded files from VM (screenshots, dropped files, log) The value is expressed in bytes, by default 100MB."
          parentClassName="m-r-30"
          type="number"
        />
        <Field
          value={port}
          onChange={(e) => setPort(e.target.value)}
          width={"102px"}
          label="Port"
          tooltipText="Specify a port number to bind the result server on."
          type="number"
        />
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Server;
