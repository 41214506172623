import { networkTrafficOptions } from "../constants";

const MobileContent = ({
  currentFileIndex,
  activeOs,
  selectedFiles,
  onCheckboxInputChange = () => {},
}) => {
  return (
    <div className="form">
      <p className="form__exp">Will be analyzed dynamically and statically</p>

      <div className="form__item">
        <label className="form__item__label">Network Traffic</label>
        <div className="form__item__options">
          {networkTrafficOptions.map((option) => (
            <div key={option.value} className="form__item__option">
              <input
                id={option.value}
                type="checkbox"
                name="network_traffic"
                value={option.value}
                onChange={onCheckboxInputChange}
                checked={selectedFiles[currentFileIndex][activeOs].network_traffic.includes(
                  option.value,
                )}
              />
              <label htmlFor={option.value}>{option.display}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileContent;
