import settingsSlice from "../slices/settingsSlice";

export const {
  requestTimePeriod,
  successTimePeriod,
  failureTimePeriod,
  updateTimePeriodRequest,
  updateTimePeriodSuccess,
  updateTimePeriodFailure,
  requestGetMailingConfigs,
  successGetMailingConfigs,
  failureGetMailingConfigs,
  requestSetMailingConfigs,
  successSetMailingConfigs,
  failureSetMailingConfigs,
  requestGetMailRecipients,
  successGetMailRecipients,
  failureGetMailRecipients,
  requestAddMailRecipients,
  successAddMailRecipients,
  failureAddMailRecipients,
  requestDeleteMailRecipients,
  successDeleteMailRecipients,
  failureDeleteMailRecipients,
  requestResetMailingConfigs,
  successResetMailingConfigs,
  failureResetMailingConfigs,
  requestUpdateMailingActive,
  successUpdateMailingActive,
  failureUpdateMailingActive,
  requestGetMaliciousSubmissionMailContent,
  successGetMaliciousSubmissionMailContent,
  failureGetMaliciousSubmissionMailContent,
  requestSetMaliciousSubmissionMailContent,
  successSetMaliciousSubmissionMailContent,
  failureSetMaliciousSubmissionMailContent,
  requestResetMaliciousSubmissionMailContent,
  successResetMaliciousSubmissionMailContent,
  failureResetMaliciousSubmissionMailContent,
  dumpDatabaseRequest,
  requestDumpStatus,
  updateDumpStatus,
  requestRestoreStatus,
  updateRestoreStatus,
  requestGetSyslogConfig,
  successGetSyslogConfig,
  failureGetSyslogConfig,
  requestSetSyslogConfig,
  successSetSyslogConfig,
  failureSetSyslogConfig,
  requestDeleteSyslogConfig,
  successDeleteSyslogConfig,
  failureDeleteSyslogConfig,
  requestSetTelegramConfig,
  successSetTelegramConfig,
  failureSetTelegramConfig,
  requestGetTelegramConfig,
  successGetTelegramConfig,
  failureGetTelegramConfig,
  requestSetSlackConfig,
  successSetSlackConfig,
  failureSetSlackConfig,
  requestGetSlackConfig,
  successGetSlackConfig,
  failureGetSlackConfig,
  RequestCustomSignatureUpload,
  SuccessCustomSignatureUpload,
  FailureCustomSignatureUpload,
  SetCustomSignatureFile,
  RequestCustomSignatures,
  SuccessCustomSignatures,
  FailureCustomSignatures,
  RequestDeleteCustomSignature,
  SuccessDeleteCustomSignature,
  FailureDeleteCustomSignature,
  RequestEditCustomSignature,
  SuccessEditCustomSignature,
  FailureEditCustomSignature,
} = settingsSlice.actions;
