// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userProfile {
  display: flex;
  flex-direction: column;
  margin: 0 30px 20px 40px;
}
.userProfile__header {
  margin-left: 0.5%;
}
.userProfile__header h1 {
  color: var(--dashboard-value-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 5px;
  margin-top: 20px;
}
.userProfile__header p {
  color: var(--dashboard-text-color);
  margin: 10px 0 20px 0;
}
.userProfile__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--card-background-color);
  border-radius: 4px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
  margin: 20px 7px 20px 7px;
}
.userProfile__body .password__eye {
  top: 20px;
  right: 14px;
}
.userProfile__body__divider {
  height: 0px;
  border: none;
  border-top: 1px solid;
  width: 90%;
}
.userProfile__body__content {
  width: 90%;
}
.userProfile__body-logo {
  height: 300px;
}
.userProfile__body-logo__content {
  width: 90%;
}
.userProfile .bg {
  background: var(--card-background-color);
}`, "",{"version":3,"sources":["webpack://./src/Pages/UserProfile/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,wBAAA;AADF;AAGE;EACE,iBAAA;AADJ;AAGI;EACE,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AADN;AAII;EACE,kCAAA;EACA,qBAAA;AAFN;AAME;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,wCAAA;EACA,kBAAA;EACA,6CAAA;EACA,yBAAA;AAJJ;AAMI;EACE,SAAA;EACA,WAAA;AAJN;AAOI;EACE,WAAA;EACA,YAAA;EACA,qBAAA;EACA,UAAA;AALN;AAQI;EACE,UAAA;AANN;AAUE;EACE,aAAA;AARJ;AAWI;EACE,UAAA;AATN;AAaE;EACE,wCAAA;AAXJ","sourcesContent":["@import \"../../styles/colors\";\n\n.userProfile {\n  display: flex;\n  flex-direction: column;\n  margin: 0 30px 20px 40px;\n\n  &__header {\n    margin-left: 0.5%;\n\n    h1 {\n      color: var(--dashboard-value-color);\n      font-size: 24px;\n      font-weight: 700;\n      line-height: 33px;\n      margin-bottom: 5px;\n      margin-top: 20px;\n    }\n\n    p {\n      color: var(--dashboard-text-color);\n      margin: 10px 0 20px 0;\n    }\n  }\n\n  &__body {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background: var(--card-background-color);\n    border-radius: 4px;\n    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);\n    margin: 20px 7px 20px 7px;\n\n    .password__eye {\n      top: 20px;\n      right: 14px;\n    }\n\n    &__divider {\n      height: 0px;\n      border: none;\n      border-top: 1px solid;\n      width: 90%;\n    }\n\n    &__content {\n      width: 90%;\n    }\n  }\n\n  &__body-logo {\n    height: 300px;\n\n\n    &__content {\n      width: 90%;\n    }\n  }\n\n  .bg {\n    background: var(--card-background-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
