import React from "react";
import Row from "./Row";
import "./styles.scss";

const Table = ({ data }) => {
  return (
    <div className="summary__table">
      <div className="summary__table-header">
        <p />
        <p>Protocol</p>
        <p>RSYSLOG</p>
        <p>SMTP</p>
        <p>SNMP</p>
        <p>HTTP</p>
      </div>
      <div className="summary__table-body">
        {data.length
          ? data.map((row, index) => <Row key={row.id} row={row} index={index} />)
          : null}
      </div>
    </div>
  );
};

export default Table;
