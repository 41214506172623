// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clamcloudml__display {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
}
.clamcloudml__display--report {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}
.clamcloudml__vt-cursor {
  text-decoration: none;
  cursor: pointer;
}
.clamcloudml__vt-default {
  text-decoration: none;
  cursor: default;
}
.clamcloudml__border {
  border-style: solid;
  border-width: 2px 2px;
  border-radius: 10px;
  white-space: nowrap;
}
.clamcloudml__border--hidden {
  display: none;
}
.clamcloudml__border--na {
  border-color: var(--border);
}
.clamcloudml__border--detected {
  border-color: #ec374a;
}
.clamcloudml__border--undetected {
  border-color: #58e655;
}
.clamcloudml__border--vt {
  border-color: #0b4dda;
}
.clamcloudml__border--pointer {
  cursor: pointer;
}
.clamcloudml__border--below {
  margin-top: 10px;
}
.clamcloudml__text {
  margin: 5px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  border: 1px solid transparent;
  font-family: Nunito, serif;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/AnalysisReports/ClamCloudMlResults/styles.scss"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,QAAA;AAFJ;AAII;EACE,eAAA;EACA,uBAAA;EACA,gBAAA;AAFN;AAMI;EACE,qBAAA;EACA,eAAA;AAJN;AAOI;EACE,qBAAA;EACA,eAAA;AALN;AAQE;EACE,mBAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;AANJ;AAYI;EACE,aAAA;AAVN;AAYI;EACE,2BAAA;AAVN;AAYI;EACE,qBAAA;AAVN;AAYI;EACE,qBAAA;AAVN;AAYI;EACE,qBAAA;AAVN;AAYI;EACE,eAAA;AAVN;AAYI;EACE,gBAAA;AAVN;AAaE;EACE,WAAA;EACA,eAAA;EACA,YAAA;EACA,iBAAA;EACA,6BAAA;EACA,0BAAA;AAXJ","sourcesContent":["@import \"../../../../styles/colors\";\n\n.clamcloudml {\n  &__display {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    gap: 4px;\n\n    &--report {\n      flex-wrap: wrap;\n      justify-content: center;\n      margin-top: 10px;\n    }\n  }\n  &__vt {\n    &-cursor {\n      text-decoration: none;\n      cursor: pointer;\n    }\n\n    &-default {\n      text-decoration: none;\n      cursor: default;\n    }\n  }\n  &__border {\n    border-style: solid;\n    border-width: 2px 2px;\n    border-radius: 10px;\n    white-space: nowrap;\n    // display: flex;\n    // justify-content: center;\n    // align-items: center;\n    // padding: 5px;\n\n    &--hidden {\n      display: none;\n    }\n    &--na {\n      border-color: var(--border);\n    }\n    &--detected {\n      border-color: #ec374a;\n    }\n    &--undetected {\n      border-color: #58e655;\n    }\n    &--vt {\n      border-color: #0b4dda;\n    }\n    &--pointer {\n      cursor: pointer;\n    }\n    &--below {\n      margin-top: 10px;\n    }\n  }\n  &__text {\n    margin: 5px;\n    font-size: 12px;\n    color: white;\n    font-weight: bold;\n    border: 1px solid transparent;\n    font-family: Nunito, serif;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
