import * as React from "react";
const StaticOsIcon = ({ width = 18, height = 18 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 200 200">
    <path fill="#1082b4" d="M-.5-.5h200v200H-.5z"></path>
    <path
      fill="#f6fafc"
      d="M23.5 82.5h20v4q-9.015-.25-18 .5-4 4.5 0 9l14 1q5.376 2.496 5 8.5.376 6.004-5 8.5-10.488.75-21 .5v-4q9.015.249 18-.5 4-4.5 0-9l-14-1q-6.852-3.812-4.5-11.5 2.04-3.784 5.5-6"></path>
    <path fill="#f3f9fb" d="M46.5 82.5h28v4h-11v28h-6v-28h-11z"></path>
    <path
      fill="#f5fafc"
      d="M87.5 82.5h8a440 440 0 0 0 12 31q-3 2-6 0a46 46 0 0 0-3-7 57.2 57.2 0 0 0-15-1 30.1 30.1 0 0 1-3 8.5q-3 1-6 0a1286 1286 0 0 0 13-31.5"></path>
    <path fill="#f3f9fb" d="M107.5 82.5h28v4h-11v28h-6v-28h-11z"></path>
    <path fill="#fefffe" d="M138.5 82.5h5v32h-5z"></path>
    <path
      fill="#f7fafc"
      d="M156.5 82.5h18v4q-22.98-6.247-20.5 17a15.8 15.8 0 0 0 3.5 5.5q8.316 2.106 17 1.5v4q-33.64 5.124-23.5-27a38 38 0 0 1 5.5-5"></path>
    <path
      fill="#2086b6"
      d="M90.5 87.5q3.547 6.092 6 13-5.433 1.738-11 .5a225 225 0 0 0 5-13.5"></path>
  </svg>
);
export default StaticOsIcon;
