import React, { useState } from "react";
import ToggleButton from "../../../../../../../Components/common/ToggleButton/ToggleButton";
import CustomSelect from "../../../../../../../Components/common/CustomSelect/CustomSelect";
import Table from "../../../../../../../Components/PagesComponents/Settings/Notifications/SummaryTable/Table";
import { getHours, getMinutes } from "../../../../../../../utils/function";
import { settingsSummary, summaryFixture } from "../../../../../../../fixture";
import "./styles.scss";

const Summary = () => {
  const [toggle, setToggle] = useState(false);
  const [hours, setHours] = useState(false);
  const [minutes, setMinutes] = useState(false);

  return (
    <div className="summary border">
      <h3 className="settings-article">Select when and how to receive notifications.</h3>
      <Table data={summaryFixture} />
      <div className="send-test">
        <p className="summary__article">Send Test Alert</p>
        <div className="m-t-15 m-b-15">
          <span className="send-test__type">Type</span>
        </div>
        <button className="primary-button">Send</button>
      </div>

      <div className="digest">
        <p className="summary__article">Define daily digest</p>
        <div className="flex flex-align-items-center">
          <ToggleButton onClick={setToggle} active={toggle} />
          <p style={{ color: "var(--dashboard-value-color)" }}>Daily digest at</p>
        </div>
        <div className="flex flex-align-items-center m-t-25 m-b-25"></div>
        <button className="primary-button">Update Digest</button>
      </div>
    </div>
  );
};

export default Summary;
