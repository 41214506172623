import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ThreeDotsIcon from "../../../../Icon-SVG/ThreeDotsIcon";
import StaticAnalysisIcon from "../../../../Icon-SVG/StaticAnalysisIcon";
import DownloadIcon from "../../../../Icon-SVG/DownloadIcon";
import RefreshIcon from "../../../../Icon-SVG/RefreshIcon";
import MiniMenu from "../../../../common/MiniMenu/MiniMenu";
import Backdrop from "../../../../UI/Backdrop/Backdrop";
import DownloadMessage from "../../../../common/DownloadMessage/DownloadMessage";
import Modal from "../../../../UI/Modal/Modal";
import SubmitPopup from "../../../../common/SubmitPopup/SubmitPopup";
import Warning from "../../../../common/Warning/Warning";
import Content from "../../../../common/SubmitPopup/Content/Content";

import { useOnClickOutside } from "../../../../../utils/hook/useOnClickOutside";
import axiosApi from "../../../../../utils/axios";

import { addNotification } from "../../../../../store/actions/notifierActions";
import { desktopVmOptions } from "../../../../common/SubmitPopup/constants";
import { RequestResubmitSample } from "../../../../../store/actions/dashboardActions";
import { prepareSubmitData } from "../../../../common/SubmitPopup/utils";
import useDownloadSample from "../../../../../utils/hook/useDownloadSample";

const ThreeDots = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef();

  const fileHash = props?.row?.original?.file?.hash || "-";
  const rowFileName = props?.row?.original?.file?.name || "-";
  const staticResultLink = props.row.original.staticResultLink;

  const [menuOpen, setMenuOpen] = useState(false);
  const [submitPopupActive, setSubmitPopupActive] = useState(false);
  const [submitFile, setSubmitFile] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  const licenceCheck = useSelector((state) => state?.users.licenceCheck);
  const vms = useSelector((state) => state.dashboard.vms);

  useOnClickOutside(ref, () => setMenuOpen(false));

  const { downloadOptions, fileName, downloadSampleHandler } = useDownloadSample(fileHash);

  const iconOnClickHandler = (event) => {
    event.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  const menuOnClickHandler = (event) => {
    event.stopPropagation();
  };

  const staticResultsOnClickHandler = (event) => {
    event.stopPropagation();

    history.push(staticResultLink);
  };

  const checkResubmitHandler = async () => {
    if (!desktopVmOptions.some((vmOption) => vms.includes(vmOption.id))) {
      setSubmitPopupActive(false);
      setError("No vm available. Refresh the page and try again.");
      setShow(true);
      return;
    }
    if (!licenceCheck?.key) {
      dispatch(
        addNotification({
          message: "Your license is not active, please update your license key.",
          options: { variant: "error" },
        }),
      );
      return;
    } else if (vms.length === 0) {
      setError("No vm available. Refresh the page and try again.");
      setShow(true);
      return;
    }
    try {
      const response = await axiosApi.get(`api/files/submission/check-resubmit/${fileHash}`);
      const checkSample = response.data;
      if (!checkSample) {
        dispatch(
          addNotification({
            message: "Sample not found",
            options: { variant: "error" },
          }),
        );
        return;
      }
      setSubmitPopupActive(true);
      setSubmitFile([
        {
          fileName: rowFileName,
          fileHash,
        },
      ]);
    } catch (e) {
      console.log(e);
      dispatch(
        addNotification({
          message: "Sample not found",
          options: { variant: "error" },
        }),
      );
    }
  };

  const closePopupHandler = () => {
    setSubmitPopupActive(false);
  };

  const submitHandler = (selectedFiles) => {
    const preparedSubmitData = prepareSubmitData(selectedFiles, desktopVmOptions);
    dispatch(RequestResubmitSample(preparedSubmitData));
  };

  const renderContent = (
    currentFileIndex,
    activeOs,
    selectedFiles,
    checkboxInputChangeHandler,
    enforceRuntimeChangeHandler,
    otherInputsChangeHandler,
  ) => (
    <Content
      currentFileIndex={currentFileIndex}
      activeOs={activeOs}
      selectedFiles={selectedFiles}
      onCheckboxInputChange={checkboxInputChangeHandler}
      onEnforceRuntimeChange={enforceRuntimeChangeHandler}
      onOtherInputsChange={otherInputsChangeHandler}
    />
  );

  return (
    <div className="dashboard__table__threedots" ref={ref} onClick={(e) => e.stopPropagation()}>
      <div className="dashboard__table__threedots__icon" onClick={iconOnClickHandler}>
        <ThreeDotsIcon />
      </div>
      <MiniMenu isOpen={menuOpen} className="dashboard__table__threedots__menu">
        <div onClick={staticResultsOnClickHandler}>
          <StaticAnalysisIcon color="white" height={22} width={22} /> Static Results
        </div>
        <div onClick={downloadSampleHandler}>
          <DownloadIcon height={21} width={21} /> Download Sample
        </div>
        <div onClick={checkResubmitHandler}>
          <RefreshIcon width={23} height={23} /> Resubmit Sample
        </div>
      </MiniMenu>
      <Backdrop
        show={downloadOptions.downloadStarted}
        message={
          <DownloadMessage
            fileName={fileName}
            loaded={downloadOptions.loaded}
            total={downloadOptions.total}
            progress={downloadOptions.progress}
          />
        }
      />
      {submitPopupActive ? (
        <Modal active={submitPopupActive} setActive={setSubmitPopupActive} type="submit-popup">
          <SubmitPopup
            selectedFiles={submitFile}
            setSelectedFiles={setSubmitFile}
            vmOptions={desktopVmOptions.filter((vmOption) => vms.includes(vmOption.id))}
            onClose={closePopupHandler}
            onSubmit={submitHandler}
            renderContent={renderContent}
            showApplyAll={false}
            showFileList={false}
          />
        </Modal>
      ) : null}
      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </div>
  );
};

export default ThreeDots;
