// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hosts-and-domains {
  height: 100%;
  position: relative;
  border: 1px solid var(--border);
  border-radius: 0 4px 4px 4px;
  background-color: var(--attack-map-bg);
}
.hosts-and-domains .custom-table__footer-pagination {
  bottom: 0px;
  margin-left: 0;
  width: 100%;
}
.hosts-and-domains__no-data__header {
  border-bottom: 1px solid var(--text-color-card);
  margin: 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hosts-and-domains__no-data__item {
  color: var(--map-text-color);
  font-size: 12px;
  line-height: 16px;
  padding: 12px 5px;
}
.hosts-and-domains__no-data__item:nth-child(1) {
  width: 22%;
}
.hosts-and-domains__no-data__item:nth-child(2) {
  width: 58%;
}
.hosts-and-domains__no-data__item:nth-child(3) {
  width: 20%;
}
.hosts-and-domains__table {
  flex: 1 1;
}
.hosts-and-domains__table__server-ip {
  width: 22%;
}
.hosts-and-domains__table__domain {
  width: 58%;
}
.hosts-and-domains__table__country-name {
  width: 20%;
}
@media screen and (max-width: 1600px) {
  .hosts-and-domains .custom-table th,
  .hosts-and-domains td {
    font-size: 11px;
  }
}
@media screen and (max-width: 1250px) {
  .hosts-and-domains .custom-table th,
  .hosts-and-domains td {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/AnalysisReports/Content/Tabs/HostsAndDomains/styles.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,kBAAA;EACA,+BAAA;EACA,4BAAA;EACA,sCAAA;AADF;AAGE;EACE,WAAA;EACA,cAAA;EACA,WAAA;AADJ;AAKI;EACE,+CAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAHN;AAMI;EACE,4BAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AAJN;AAMM;EACE,UAAA;AAJR;AAOM;EACE,UAAA;AALR;AAQM;EACE,UAAA;AANR;AAWE;EACE,SAAA;AATJ;AAWI;EACE,UAAA;AATN;AAYI;EACE,UAAA;AAVN;AAaI;EACE,UAAA;AAXN;AAiBI;EAFF;;IAGI,eAAA;EAbJ;AACF;AAeI;EANF;;IAOI,eAAA;EAXJ;AACF","sourcesContent":["@import \"../../../../../styles/colors\";\n\n.hosts-and-domains {\n  height: 100%;\n  position: relative;\n  border: 1px solid var(--border);\n  border-radius: 0 4px 4px 4px;\n  background-color: var(--attack-map-bg);\n\n  .custom-table__footer-pagination {\n    bottom: 0px;\n    margin-left: 0;\n    width: 100%;\n  }\n\n  &__no-data {\n    &__header {\n      border-bottom: 1px solid var(--text-color-card);\n      margin: 0 18px;\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n    }\n\n    &__item {\n      color: var(--map-text-color);\n      font-size: 12px;\n      line-height: 16px;\n      padding: 12px 5px;\n\n      &:nth-child(1) {\n        width: 22%;\n      }\n\n      &:nth-child(2) {\n        width: 58%;\n      }\n\n      &:nth-child(3) {\n        width: 20%;\n      }\n    }\n  }\n\n  &__table {\n    flex: 1;\n\n    &__server-ip {\n      width: 22%;\n    }\n\n    &__domain {\n      width: 58%;\n    }\n\n    &__country-name {\n      width: 20%;\n    }\n  }\n\n  .custom-table th,\n  td {\n    @media screen and (max-width: 1600px) {\n      font-size: 11px;\n    }\n\n    @media screen and (max-width: 1250px) {\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
