import React, { useState } from "react";
import CustomSelect from "../../../../../../../Components/common/CustomSelect/CustomSelect";
import ToggleButton from "../../../../../../../Components/common/ToggleButton/ToggleButton";
import { settings_RSYSLOG_Fixture, settingsSummary } from "../../../../../../../fixture";
import CustomTable from "../../../../../../../Components/common/Table/CustomTable";
import { settings_RSYSLOG } from "../../../../../../../utils/constants/tableHeaders";
import "./styles.scss";

const Rsyslog = () => {
  const [defaultFormat, setDefaultFormat] = useState(null);
  const [defaultDelivery, setDefaultDelivery] = useState(null);
  const [defaultSend, setDefaultSend] = useState(null);
  const [showServer, setShowServers] = useState(true);
  const [table, setTable] = useState([]);

  const removerServer = () => {
    //console.log(table);
  };

  return (
    <div className="rsyslog border">
      <div className="p-l-15 border-bottom p-b-25 p-t-15">
        <h3 className="m-b-15">RSYSLOG settings</h3>
        <div className="m-t-15 m-b-15">
          <span className="rsyslog__label">Default format</span>
        </div>

        <button className="primary-button">Apply Settings</button>
      </div>

      <div className="flex flex-align-items-center flex-justify-content-between p-l-15 p-t-15 p-b-15">
        <div className="flex flex-align-items-center">
          <h3>View and add Rsyslog Servers</h3>
          <ToggleButton
            rightText={!showServer ? "Disable" : "Enable"}
            active={showServer}
            onClick={setShowServers}
          />
        </div>

        {showServer && (
          <div className="flex flex-align-items-center m-b-10">
            <button className="primary-button m-r-25" onClick={removerServer}>
              Remove
            </button>
            <button className="primary-button m-r-15">Add RSYSLOG Server </button>
          </div>
        )}
      </div>
      {showServer && (
        <CustomTable
          column={settings_RSYSLOG}
          dataset={settings_RSYSLOG_Fixture}
          customPageSize={settings_RSYSLOG_Fixture.length}
          paginationButtons={true}
          className=""
          refresh={false}
          setCheckbox={setTable}
          minHeight={0}
          checkbox
          noDataText="No RSYSLOG servers defined."
        />
      )}
    </div>
  );
};

export default Rsyslog;
