import React, { useMemo, useState } from "react";
import { useFilters, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import SortArrowIcon from "../../../Icon-SVG/SortArrowIcon";
import Row from "./Row/Row";
import Warning from "../../../common/Warning/Warning";
import "./styles.scss";

const GuestImagesTable = ({ customPageSize, className, tableName, dataset, column }) => {
  const [show, setShow] = useState(null);
  const [editable, setEditable] = useState(false);
  const data = useMemo(() => {
    return dataset;
  }, [dataset]);

  const columns = useMemo(() => {
    return column;
  }, [column]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: customPageSize,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  return (
    <div id={tableName} {...getTableProps()} className={`${className} guest-table`}>
      <div className="thead">
        {headerGroups.map((headerGroup) => (
          <div className="tr" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <div key={column.id} className={`th ${column.className ? column.className : ""}`}>
                <div className="th-row">
                  {!editable && column.sort ? (
                    <div
                      className="th-sort"
                      {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <p>{column.render("Header")}</p>
                      {column.Header && (
                        <span className="m-l-5">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortArrowIcon desc="var(--sort-color-asc)" />
                            ) : (
                              <SortArrowIcon asc="var(--sort-color-desc)" />
                            )
                          ) : column.id !== "action" ? (
                            <SortArrowIcon />
                          ) : (
                            ""
                          )}
                        </span>
                      )}
                    </div>
                  ) : (
                    <p>{column.render("Header")}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="tbody" {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return <Row key={index} row={row} setShow={setShow} setEditable={setEditable} />;
        })}
      </div>
      <Warning show={show} setShow={setShow} text={`Are you sure, you want to save?`} />
    </div>
  );
};

export default GuestImagesTable;
