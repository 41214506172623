// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backup .custom-table .filename .label {
  margin: 0;
}
.backup .custom-table .filename .field {
  width: 100%;
  padding: 10px;
  height: 40px;
  margin: 0;
  max-width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/Settings/BackupCells/FileName%20Prefix/styles.scss","webpack://./src/Components/PagesComponents/Settings/BackupCells/FileName Prefix/styles.scss"],"names":[],"mappings":"AACE;EACE,SAAA;ACAJ;ADEE;EACE,WAAA;EACA,aAAA;EACA,YAAA;EACA,SAAA;EACA,gBAAA;ACAJ","sourcesContent":[".backup .custom-table .filename {\n  .label {\n    margin: 0;\n  }\n  .field {\n    width: 100%;\n    padding: 10px;\n    height: 40px;\n    margin: 0;\n    max-width: 150px;\n  }\n}\n",".backup .custom-table .filename .label {\n  margin: 0;\n}\n.backup .custom-table .filename .field {\n  width: 100%;\n  padding: 10px;\n  height: 40px;\n  margin: 0;\n  max-width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
