import { useSelector } from "react-redux";
import { CategoryCheck } from "../../ReportExport/ClassCategories/CategoryCheck";
import { truncateString } from "../../../../utils/function";

const useQuery = () => {
  const search = window.location.search;
  return new URLSearchParams(search);
};

const ClassificationTags = () => {
  const signatureList = useSelector((state) => state.analysisReports.signatureList);
  const general = useSelector((state) => state.analysisReports.general);

  const query = useQuery();
  const showNoData = query.get("state") === "not suspicious";

  const createSignaturesCategoriesArrayWithEveryCategory = () => {
    const newSignatureArray = [];

    for (const signature of signatureList) {
      for (const categorie of signature.categories) {
        newSignatureArray.push({ categories: categorie, severity: signature.severity });
      }
    }

    return CategoryCheck(newSignatureArray);
  };

  const ITEMS = createSignaturesCategoriesArrayWithEveryCategory();

  if (showNoData) {
    return (
      <div className="analysis-reports__body-block__classification__tags">
        <span className="analysis-reports__body-block__classification__text">No data</span>
      </div>
    );
  }

  return (
    (ITEMS?.length > 0 || general?.malwareNames?.length > 0) && (
      <div className="analysis-reports__body-block__classification__tags">
        {general?.malwareNames?.length > 0 ? (
          <div className="analysis-reports__body-block__classification__tags__malwarenames">
            {general?.malwareNames?.slice(0, 2)?.map((mal, i) => (
              <div
                key={i}
                className="analysis-reports__body-block__classification__border analysis-reports__body-block__classification__border--malicious">
                <span className="analysis-reports__body-block__classification__text">
                  #
                  {truncateString(mal, 10).charAt(0).toUpperCase() +
                    truncateString(mal, 10).slice(1)}
                </span>
              </div>
            ))}
          </div>
        ) : null}
        {general?.malwareNames?.length > 0 && ITEMS?.length > 0 ? (
          <div className="analysis-reports__body-block__classification__tags__separator" />
        ) : null}
        <div className="analysis-reports__body-block__classification__tags__malwarenames">
          {ITEMS?.slice(0, 7)?.map((item, index) => (
            <div
              key={index}
              className={`analysis-reports__body-block__classification__border analysis-reports__body-block__classification__border--${
                item.value
                  ? item.value >= 65
                    ? "malicious"
                    : item.value <= 40
                    ? "nothing"
                    : "suspicious"
                  : "nothing"
              }`}>
              <span className="analysis-reports__body-block__classification__text">
                {truncateString(item.header, 11)}
              </span>
            </div>
          ))}
        </div>
      </div>
    )
  );
};
export default ClassificationTags;
