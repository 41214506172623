// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-card {
  margin-top: 44px;
  height: 100%;
  position: relative;
}
.file-card__content {
  background-color: var(--file-card-main-background);
  width: 100%;
  height: 380px;
  border-radius: 0 4px 4px 4px;
}
.file-card__content--licence {
  width: 100%;
  height: 90%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  z-index: 200;
  color: white;
  text-align: center;
  padding-top: 25%;
}
.file-card__content--licence-font {
  font-weight: bolder;
  text-decoration: none;
  color: white;
}
.file-card__content--frastlci4lw {
  pointer-events: none;
}
.file-card__content--spudrapis8o {
  pointer-events: auto;
}
.file-card__information-popup {
  position: absolute;
  right: 12px;
  bottom: 75px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/Dashboard/FileCard/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,kDAAA;EACA,WAAA;EACA,aAAA;EACA,4BAAA;AACJ;AAEI;EACE,WAAA;EACA,WAAA;EACA,kBAAA;EACA,oCAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AAAN;AAEM;EACE,mBAAA;EACA,qBAAA;EACA,YAAA;AAAR;AAKI;EACE,oBAAA;AAHN;AAMI;EACE,oBAAA;AAJN;AAQE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AANJ","sourcesContent":[".file-card {\n  margin-top: 44px;\n  height: 100%;\n  position: relative;\n\n  &__content {\n    background-color: var(--file-card-main-background);\n    width: 100%;\n    height: 380px;\n    border-radius: 0 4px 4px 4px;\n\n\n    &--licence {\n      width: 100%;\n      height: 90%;\n      position: absolute;\n      background-color: rgba($color: #000000, $alpha: 0.9);\n      border-radius: 4px;\n      z-index: 200;\n      color: white;\n      text-align: center;\n      padding-top: 25%;\n\n      &-font {\n        font-weight: bolder;\n        text-decoration: none;\n        color: white;\n      }\n    }\n\n\n    &--frastlci4lw {\n      pointer-events: none;\n    }\n\n    &--spudrapis8o {\n      pointer-events: auto;\n    }\n  }\n\n  &__information-popup {\n    position: absolute;\n    right: 12px;\n    bottom: 75px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
