import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Select from "../../../Components/Select";
import Field from "../../../Components/Field";
import { getHours } from "../../../../../../utils/function";

const Sbox = () => {
  const [sbox, setSbox] = useState(true);
  const [deduplication, setDeduplication] = useState(true);
  const [hastMethod, setHastMethod] = useState();
  const [graph, setGraph] = useState(true);
  const [demand, setDemand] = useState(true);
  const [path, setPath] = useState("data/ja3/ja3fingerprint.json");

  return (
    <div className="border processing__content">
      <div className="m-b-20">
        <h3 className="m-b-15">SBOX</h3>
        <Toggle label="SBOX" setActive={setSbox} active={sbox} type />
      </div>
      <div className="m-b-20">
        <h3>Deduplication</h3>
        <Toggle
          label="Deduplication"
          active={deduplication}
          setActive={setDeduplication}
          tooltipText="Deduplicate screenshots"
          parentClassName="m-t-10 m-b-20"
          type
        />
      </div>
      <div className="m-b-20">
        <h3>VBA2graph</h3>
        <div className="flex flex-align-items-center m-t-10 m-b-20">
          <Toggle
            label="VBA2graph"
            setActive={setGraph}
            active={graph}
            tooltipText="Mac - brew install graphviz Ubuntu - sudo apt-get install graphviz Arch - sudo pacman -S graphviz+ sudo pip3 install networkx>=2.1 graphviz>=0.8.4 pydot>=1.2.4"
            parentClassName="p-r-40"
            tooltipWidth="400px"
            type
          />
          <Toggle active={demand} setActive={setDemand} label="On demand" type />
        </div>
      </div>
      <div className="m-b-20">
        <h3>Ja3</h3>
        <p className="processing__description m-b-10">
          ja3 finger print db with descriptions githunlink
        </p>
        <Field
          label="Ja3 path"
          value={path}
          onChange={(e) => setPath(e.target.value)}
          width="236px"
        />
      </div>
      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Sbox;
