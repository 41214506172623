import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Field from "../../../Components/Field";
import Tooltip from "../../../../../../Components/common/Tooltip/Tooltip";

const ReportingTab = () => {
  const [mitre, setMitre] = useState(true);
  const [localFile, setLocalFile] = useState("data/mitre_attack.json");
  const [bingraph, setBingraph] = useState(true);
  const [demand, setDemand] = useState(true);
  const [binary, setBinary] = useState(false);
  const [sbox, setSbox] = useState(false);
  const [procDump, setProcDump] = useState(false);

  return (
    <div className="border reporting__content">
      <h3>Reporting</h3>
      <p className="processing__description m-b-20">
        Enable or disable the available reporting modules [on/off]. If you add a custom reporting
        module to your Cfsbox setup, you have to add a dedicated entry in this file, or it won't be
        executed. You can also add additional options under the section of your module and they will
        be available in your Python class.
      </p>
      <h3>Mitre</h3>
      <Toggle
        label="Mitre"
        setActive={setMitre}
        active={mitre}
        parentClassName="m-b-20 m-t-10"
        type
      />
      <Field
        label="Local file"
        onChange={(e) => setLocalFile(e.target.value)}
        value={localFile}
        parentClassName="m-b-20"
        width="256px"
      />

      <h3 className="flex flex-align-items-center">
        Bingraph <Tooltip text="requires -> apt-get install python-tk" />
      </h3>
      <Toggle
        label="Bingraph"
        setActive={setBingraph}
        active={bingraph}
        parentClassName="m-b-20 m-t-10"
        type
      />
      <div className="flex flex-align-items-center m-b-20">
        <Toggle label="On demand" active={demand} setActive={setDemand} parentClassName="p-r-40" />
        <Toggle
          label="Binary"
          tooltipText="geenrate bingraphs for cape/procdumps"
          setActive={setBinary}
          active={binary}
          parentClassName="p-r-40"
        />
        <Toggle
          label="Sbox"
          active={sbox}
          setActive={setSbox}
          parentClassName="p-r-40"
          tooltipText=""
        />
        <Toggle label="ProcDump" active={procDump} setActive={setProcDump} />
      </div>

      <h3>Pcap2cert</h3>
      <Toggle label="Pcap2cert" parentClassName="m-b-20 m-t-10" type />

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default ReportingTab;
