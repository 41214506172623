// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.miniMenu__hidden {
  visibility: hidden;
}
.miniMenu__right {
  left: 0;
}
.miniMenu__left {
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/MiniMenu/styles.scss"],"names":[],"mappings":"AACI;EACE,kBAAA;AAAN;AAGI;EACE,OAAA;AADN;AAII;EACE,QAAA;AAFN","sourcesContent":[".miniMenu {\n    &__hidden {\n      visibility: hidden;\n    }\n  \n    &__right {\n      left: 0;\n    }\n  \n    &__left {\n      right: 0;\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
