import React, { useState } from "react";
import ToggleButton from "../../../../../../../Components/common/ToggleButton/ToggleButton";
import CustomTable from "../../../../../../../Components/common/Table/CustomTable";
import CustomSelect from "../../../../../../../Components/common/CustomSelect/CustomSelect";
import { settings_HTTP_Fixture, settingsSummary } from "../../../../../../../fixture";
import { settings_HTTP } from "../../../../../../../utils/constants/tableHeaders";
import "./styles.scss";

const Http = () => {
  const [showServer, setShowServers] = useState(true);
  const [delivery, setDelivery] = useState("");
  const [provider, setProvider] = useState("");
  const [format, setFormat] = useState("");
  const [table, setTable] = useState([]);

  const removerServer = () => {
    //console.log(table);
  };

  return (
    <div className="http border">
      <div className="border-bottom p-t-15 p-b-15 p-l-15">
        <h3 className="m-b-20">HTTP settings</h3>

        <div className="m-t-10 m-b-15">
          <span className="smtp__label m-b-5">Default Delivery</span>
        </div>

        <div className="m-t-10 m-b-15">
          <span className="smtp__label m-b-5">Default provider</span>
        </div>

        <div className="m-t-10 m-b-15">
          <span className="smtp__label m-b-5"> Default format</span>
        </div>
        <button className="m-t-20 m-b-10 primary-button">Apply Settings</button>
      </div>

      <div className="flex flex-align-items-center flex-justify-content-between p-l-15 p-t-15 p-b-15">
        <div className="flex flex-align-items-center">
          <h3>View and add HTTP Servers</h3>
          <ToggleButton
            rightText={!showServer ? "Disable" : "Enable"}
            active={showServer}
            onClick={setShowServers}
          />
        </div>

        {showServer && (
          <div className="flex flex-align-items-center m-b-10">
            <button className="primary-button m-r-25" onClick={removerServer}>
              Remove{" "}
            </button>
            <button className="primary-button m-r-15">Add RSYSLOG Server </button>
          </div>
        )}
      </div>
      {showServer && (
        <CustomTable
          column={settings_HTTP}
          dataset={settings_HTTP_Fixture}
          customPageSize={settings_HTTP_Fixture.length}
          paginationButtons={true}
          className=""
          refresh={false}
          setCheckbox={setTable}
          minHeight={0}
          noDataText="No HTTP servers defined."
          checkbox
        />
      )}
    </div>
  );
};

export default Http;
