// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  width: 200px;
  height: 40px;
  background-color: var(--submit-popup-background);
  border: 1px solid var(--border);
  color: var(--file-card-input-color);
  font-size: 14px;
  padding: 12px;
  margin: 1px 0;
}
.input-field:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/SubmitPopup/Input/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,gDAAA;EACA,+BAAA;EACA,mCAAA;EACA,eAAA;EACA,aAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":[".input-field {\n  width: 200px;\n  height: 40px;\n  background-color: var(--submit-popup-background);\n  border: 1px solid var(--border);\n  color: var(--file-card-input-color);\n  font-size: 14px;\n  padding: 12px;\n  margin: 1px 0;\n\n  &:focus {\n    outline: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
