// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.id-input {
  width: 176px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color) !important;
  color: var(--settings-article-value);
  font-family: Nunito, serif;
}

.react-ip-input {
  padding: 10px 8px !important;
}

.react-ip-input input {
  background: var(--settings-field-bg) !important;
  color: var(--settings-article-value);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/IpInput/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,oCAAA;EACA,+DAAA;EACA,oCAAA;EACA,0BAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,+CAAA;EACA,oCAAA;AACF","sourcesContent":[".id-input {\n  width: 176px;\n  height: 40px;\n  outline: none;\n  border-radius: 4px;\n  padding: 10px;\n  margin: 5px 0;\n  background: var(--settings-field-bg);\n  border: 1px solid var(--settings-field-border-color) !important;\n  color: var(--settings-article-value);\n  font-family: Nunito, serif;\n}\n\n.react-ip-input {\n  padding: 10px 8px !important;\n}\n\n.react-ip-input input {\n  background: var(--settings-field-bg) !important;\n  color: var(--settings-article-value);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
