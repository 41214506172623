// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-activity-map {
  width: 57%;
  z-index: 10;
  position: relative;
}
@media screen and (max-width: 1250px) {
  .network-activity-map {
    width: 100%;
  }
}

#network-activity-map-scroll-element {
  position: absolute;
  top: -106px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/AnalysisReports/NetworkActivityMap/styles.scss"],"names":[],"mappings":"AAIA;EACE,UAAA;EACA,WAJQ;EAKR,kBAAA;AAHF;AAKE;EALF;IAMI,WAAA;EAFF;AACF;;AAKA;EACE,kBAAA;EACA,WAAA;AAFF","sourcesContent":["@import \"../../../../styles/colors\";\n\n$z-index: min(10, 100);\n\n.network-activity-map {\n  width: 57%;\n  z-index: $z-index;\n  position: relative;\n\n  @media screen and (max-width: 1250px) {\n    width: 100%;\n  }\n}\n\n#network-activity-map-scroll-element {\n  position: absolute;\n  top: -106px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
