import React from "react";
import { Link } from "react-router-dom";
import LoadingIcon from "../../../../../Icon-SVG/LoadingIcon";
import RemoteConnectionIcon from "../../../../../Icon-SVG/RemoteConnectionIcon";

const SeverityStatus = (props) => {
  const progress = props.row.original.progress;
  const severity = props.row.original.severity;
  const url = props.row.original.VmDashboard.url;

  return (
    <div
      className={`severity-status severity-status__${
        severity === "not suspicious"
          ? "success"
          : severity === "malicious"
          ? "secondary"
          : severity === "suspicious"
          ? "suspicious"
          : "loading"
      }`}>
      {progress === "Running" ? (
        <Link to={url}>
          <RemoteConnectionIcon />
        </Link>
      ) : severity === "loading" ? (
        <LoadingIcon color={"var(--text-color-card)"} />
      ) : (
        severity
      )}
    </div>
  );
};

export default SeverityStatus;
