import { useState } from "react";

import { formatBytes } from "../../../utils/function";

import FolderIcon from "../../Icon-SVG/FolderIcon";

import "./styles.scss";

const FileUpload = ({ file, setFile, setFileError, allowedExtensions = [], errorMessage = "" }) => {
  const [dragActive, setDragActive] = useState(false);

  const isExtensionAllowed = (selectedFile) => {
    if (allowedExtensions.length === 0) {
      return true;
    }
    if (!allowedExtensions.includes(selectedFile.name.split(".")[1])) {
      setFileError(errorMessage);
      setFile(null);
      return false;
    }
    return true;
  };

  const fileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (!isExtensionAllowed(selectedFile)) return;
      setFileError("");
      setFile(selectedFile);
    }
  };

  const dragEnterHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const dragExitHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const dropHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const selectedFile = e.dataTransfer.files[0];
      if (!isExtensionAllowed(selectedFile)) return;
      setFileError("");
      setFile(selectedFile);
    }
  };

  return (
    <form
      className="file-upload"
      onSubmit={(e) => e.preventDefault()}
      onDragEnter={dragEnterHandler}>
      <input
        id="file-upload"
        className="file-upload__input"
        type="file"
        onChange={fileChangeHandler}
      />
      <label
        htmlFor="file-upload"
        className={`file-upload__label ${dragActive ? "file-upload__label--active" : ""}`}>
        {file ? (
          <div className="file-upload__uploaded-file">
            <span>{file.name}</span>
            <span>{formatBytes(file.size, 2)}</span>
          </div>
        ) : (
          <>
            <div className="file-upload__icon">
              <FolderIcon />
            </div>
            <p className="file-upload__info-text">
              Drag & Drop your file here or <b style={{ color: "var(--color-blue)" }}>browse</b> to
              upload
            </p>
          </>
        )}
      </label>
      {dragActive && (
        <div
          className="file-upload__drag-file-element"
          onDragEnter={dragEnterHandler}
          onDragLeave={dragExitHandler}
          onDragOver={dragEnterHandler}
          onDrop={dropHandler}></div>
      )}
    </form>
  );
};

export default FileUpload;
