// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning {
  background: var(--submit-popup-background);
  width: 410px;
  min-height: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30%;
  transition: all linear 1s;
}
.warning__icon {
  margin-top: 40px;
}
.warning h3 {
  font-size: 18px;
  margin-bottom: 10px;
}
.warning__description {
  font-size: 16px;
  text-align: center;
  color: var(--text-color-checkbox);
  margin-bottom: 30px;
}
.warning button {
  margin: 0 12px;
  width: 63px;
  height: 40px;
  font-size: 16px;
}

.warning-backdrop {
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 180px;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/DemoPopup/styles.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,eAAA;EACA,mBAAA;AAAJ;AAGE;EACE,eAAA;EACA,kBAAA;EACA,iCAAA;EACA,mBAAA;AADJ;AAIE;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAFJ;;AAMA;EACE,eAAA;EACA,aAAA;EACA,YAAA;EACA,oCAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;AAHF","sourcesContent":[".warning {\n  background: var(--submit-popup-background);\n  width: 410px;\n  min-height: 320px;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 30%;\n  transition: all linear 1s;\n\n  &__icon {\n    margin-top: 40px;\n  }\n\n  h3 {\n    font-size: 18px;\n    margin-bottom: 10px;\n  }\n\n  &__description {\n    font-size: 16px;\n    text-align: center;\n    color: var(--text-color-checkbox);\n    margin-bottom: 30px;\n  }\n\n  button {\n    margin: 0 12px;\n    width: 63px;\n    height: 40px;\n    font-size: 16px;\n  }\n}\n\n.warning-backdrop {\n  z-index: 100000;\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: fixed;\n  top: 0;\n  left: 0;\n  padding-top: 180px;\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
