// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.processing__body {
  position: relative;
  margin-top: 64px;
}
.processing__content {
  border-radius: 0 4px 4px 4px;
  background: var(--card-background-color);
  padding: 15px;
}
.processing__description {
  font-size: 12px;
  color: var(--settings-header-text);
}
.processing__description-block {
  width: 900px;
  margin: 10px 0 20px 0;
}
.processing__description-block p {
  line-height: 20px;
}
.processing .toggle-button {
  margin-left: 0;
}
.processing-static__header {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.processing-static__header h3 {
  width: 50%;
}
.processing-static__body {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.processing-static__body-item {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/Processing/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,4BAAA;EACA,wCAAA;EACA,aAAA;AADJ;AAIE;EACE,eAAA;EACA,kCAAA;AAFJ;AAII;EACE,YAAA;EACA,qBAAA;AAFN;AAIM;EACE,iBAAA;AAFR;AAOE;EACE,cAAA;AALJ;AAQE;EACE,aAAA;EACA,eAAA;EACA,oBAAA;EACA,WAAA;AANJ;AAQI;EACE,UAAA;AANN;AAUE;EACE,aAAA;EACA,eAAA;EACA,oBAAA;EACA,WAAA;AARJ;AAWE;EACE,UAAA;AATJ","sourcesContent":[".processing {\n  &__body {\n    position: relative;\n    margin-top: 64px;\n  }\n\n  &__content {\n    border-radius: 0 4px 4px 4px;\n    background: var(--card-background-color);\n    padding: 15px;\n  }\n\n  &__description {\n    font-size: 12px;\n    color: var(--settings-header-text);\n\n    &-block {\n      width: 900px;\n      margin: 10px 0 20px 0;\n\n      p {\n        line-height: 20px;\n      }\n    }\n  }\n\n  .toggle-button {\n    margin-left: 0;\n  }\n\n  &-static__header {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: stretch;\n    width: 100%;\n\n    h3 {\n      width: 50%;\n    }\n  }\n\n  &-static__body {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: stretch;\n    width: 100%;\n  }\n\n  &-static__body-item {\n    width: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
