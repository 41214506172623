// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10001;
  left: 0;
  top: 0;
  background-color: var(--scroll-bar-color);
  display: flex;
  align-items: center;
  text-align: center;
}
.Backdrop--absolute {
  position: absolute;
}
.Backdrop__message {
  position: absolute;
  width: 100%;
  padding-top: 150px;
  font-size: 20px;
  color: white;
  font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/Backdrop/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,OAAA;EACA,MAAA;EACA,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;AAAJ","sourcesContent":[".Backdrop {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  z-index: 10001;\n  left: 0;\n  top: 0;\n  background-color: var(--scroll-bar-color);\n  display: flex;\n  align-items: center;\n  text-align: center;\n\n  &--absolute {\n    position: absolute;\n  }\n\n  &__message {\n    position: absolute;\n    width: 100%;\n    padding-top: 150px;\n    font-size: 20px;\n    color: white;\n    font-weight: bolder;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
