// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.integration-backdrop {
  z-index: 1100;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}

.integration-modal {
  position: absolute;
  background: var(--analysis-report-modal-background);
  width: 35%;
  height: 100%;
  right: 0;
  top: 0;
  animation: animate 0.3s linear;
  transition: 1ms;
  padding: 20px 40px;
}
.integration-modal__content {
  position: relative;
  height: 100%;
  overflow-y: auto;
  /* width */
  /* Handle */
  /* Handle on hover */
}
.integration-modal__content::-webkit-scrollbar {
  width: 8px;
  border-radius: 40px;
}
.integration-modal__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.integration-modal__content::-webkit-scrollbar-thumb:hover {
  background: var(--header-search-text-color);
  border-radius: 10px;
}
.integration-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/IntegrationsMarket/IntegrationModal/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,YAAA;EACA,oCAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;AACF;;AAEA;EACE,kBAAA;EACA,mDAAA;EACA,UAAA;EACA,YAAA;EACA,QAAA;EACA,MAAA;EACA,8BAAA;EACA,eAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;EAEA,UAAA;EAMA,WAAA;EAMA,oBAAA;AAVJ;AADI;EACE,UAAA;EACA,mBAAA;AAGN;AACI;EACE,8BAAA;EACA,mBAAA;AACN;AAGI;EACE,2CAAA;EACA,mBAAA;AADN;AAKE;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,eAAA;AAHJ","sourcesContent":[".integration-backdrop {\n  z-index: 1100;\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: fixed;\n  top: 0;\n  left: 0;\n}\n\n.integration-modal {\n  position: absolute;\n  background: var(--analysis-report-modal-background);\n  width: 35%;\n  height: 100%;\n  right: 0;\n  top: 0;\n  animation: animate 0.3s linear;\n  transition: 1ms;\n  padding: 20px 40px;\n\n  &__content {\n    position: relative;\n    height: 100%;\n    overflow-y: auto;\n\n    /* width */\n    &::-webkit-scrollbar {\n      width: 8px;\n      border-radius: 40px;\n    }\n\n    /* Handle */\n    &::-webkit-scrollbar-thumb {\n      background: rgba($color: #000000, $alpha: 0.5);\n      border-radius: 10px;\n    }\n\n    /* Handle on hover */\n    &::-webkit-scrollbar-thumb:hover {\n      background: var(--header-search-text-color);\n      border-radius: 10px;\n    }\n  }\n\n  &__close {\n    position: absolute;\n    top: 0;\n    right: 0;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
