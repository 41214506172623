import * as React from "react";

const Win10OsIcon = ({ width = 18, height = 18 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 622 686">
    <path
      fill="#3977d7"
      d="M573.5 5.5h-25v3q-2.518-.322-3 2h-26q-7.375-.306-14 3a3484 3484 0 0 0-34 2.5q3.34 1.056 1 3.5h-14q-2.535-.905-4.5-3a32.5 32.5 0 0 1-.5-8 41.4 41.4 0 0 1 10-2q.384 3.44-1 6.5 5.3 2.628 10.5-.5 1.84.217 3.5 1a316 316 0 0 1 24-1.5 265 265 0 0 0 23-5.5q4.09.273 8 1.5l-10 1a64.3 64.3 0 0 0 16 0l4-2q-2.377-.127-4-.5.885-1.948 3-2.5l16.5-.5q8.814.007 16.5 2"></path>
    <path fill="#6da5ef" d="M573.5 5.5v1h-22v4h-6q.482-2.322 3-2v-3z"></path>
    <path fill="#90bef3" d="M561.5 6.5q-1.96 1.45-2 4h-8v-4z"></path>
    <path
      fill="#fefefe"
      d="M567.5 10.5q1.495 126.394 1 253h-286v-214a41.7 41.7 0 0 0 9-1v-4h12a72.3 72.3 0 0 0 16-1.5q3.78-.633 6-3.5h32q2.682.428 5-1-1.712-1.388 0-2.5l34-1-2-1 5-1q-1.884-.625-2-2.5h8q13.038.497 26-1a31.6 31.6 0 0 0 7.5-3q.571 1.473 2.5 2 12.586-2.39 28-2l2-5q9.343-.784 18 2 4.899-2.415 9 1 2.247-3.495 6-5.5l-1-1 33-1.5q.99-3.472 4-5h5q11.052.275 22-1"></path>
    <path
      fill="#3875d9"
      d="M573.5 6.5v-1q1.494-.129 2.5 1l.5 128.5q0 64.758-2 128.5.499-128.753-1-257"></path>
    <path
      fill="#70ace6"
      d="M519.5 10.5h8q-2.688 1.215-6 1.5-4.297 3.43-10 2.5a21.1 21.1 0 0 1 8-4"></path>
    <path
      fill="#e5f2fa"
      d="M567.5 10.5q2.466-.32 4.5 1 1.237 126.042-.5 252h-3q.495-126.606-1-253"></path>
    <path
      fill="#b0d1ef"
      d="M527.5 10.5h18v1h-5a41 41 0 0 0-9 .5q-2.21 1.065-2 3.5h-18v-1q5.703.93 10-2.5 3.312-.285 6-1.5"></path>
    <path
      fill="#5b92df"
      d="M519.5 10.5a21.1 21.1 0 0 0-8 4v1h-24q.255 1.983-1 3.5-6.982.75-14 .5 2.34-2.444-1-3.5a3484 3484 0 0 1 34-2.5q6.625-3.306 14-3"></path>
    <path
      fill="#a1c8f2"
      d="M499.5 15.5q-6.087.69-11 4.5-7.984.75-16 .5v-1q7.018.25 14-.5 1.255-1.517 1-3.5z"></path>
    <path fill="#5b94e2" d="M458.5 19.5h14v1h-28v4h-5v-4a124 124 0 0 1 19-1"></path>
    <path
      fill="#edf8fd"
      d="M540.5 11.5q-3.01 1.528-4 5l-33 1.5 1 1q-3.753 2.005-6 5.5-4.101-3.415-9-1-8.657-2.784-18-2l-2 5q-15.414-.39-28 2-1.929-.527-2.5-2a31.6 31.6 0 0 1-7.5 3 170 170 0 0 1-26 1v-1h18v-4h26q7.517.25 15-.5 3.225-1.788 2-4.5h6q8.016.25 16-.5 4.913-3.81 11-4.5h30q-.21-2.435 2-3.5a41 41 0 0 1 9-.5"></path>
    <path
      fill="#d5e4f5"
      d="M457.5 20.5h9q1.225 2.712-2 4.5-7.483.75-15 .5a169 169 0 0 1 6-2q1.668-1.148 2-3"></path>
    <path
      fill="#417cd3"
      d="M439.5 23.5v1q-12.276-.489-24 1a18.4 18.4 0 0 0-6 .5q-1.129 1.006-1 2.5-1 0-1 1h-34q13.728-1.49 28-1-.674-3.715 2-6a4.46 4.46 0 0 1 1.5 2q1.108-1.61 3-2 15.69 1.448 31.5 1"></path>
    <path
      fill="#8fbbe7"
      d="M457.5 20.5q-.332 1.852-2 3a169 169 0 0 0-6 2h-26v4h-16q0-1 1-1h7v-3q11.724-1.489 24-1h5v-4z"></path>
    <path fill="#6ba6eb" d="M415.5 25.5v3h-7q-.129-1.494 1-2.5a18.4 18.4 0 0 1 6-.5"></path>
    <path
      fill="#a6cdf8"
      d="M389.5 29.5h16v1h-14q.21 2.436-2 3.5-8.986.75-18 .5.512-1.258 2-1h8v-3q4.328.46 8-1"></path>
    <path fill="#6ca4e7" d="M373.5 29.5h16q-3.672 1.46-8 1v3h-8z"></path>
    <path
      fill="#538cde"
      d="M373.5 29.5v4q-1.488-.258-2 1h-14q-8.717-1.09-16 3.5a18.4 18.4 0 0 1-6 .5q2.8-1.89 4-5 13.509.25 27-.5 2.713-3.357 7-3.5"></path>
    <path
      fill="#e2f0fa"
      d="M397.5 30.5q.116 1.875 2 2.5l-5 1 2 1-34 1q-1.712 1.112 0 2.5-2.318 1.428-5 1 .626-1.083 2-1.5-1.321-1.062-2-2.5v-1h14q9.014.25 18-.5 2.21-1.064 2-3.5z"></path>
    <path
      fill="#87b6e7"
      d="M357.5 34.5v1a163 163 0 0 1-14 4h-10v-1h2a18.4 18.4 0 0 0 6-.5q7.283-4.59 16-3.5"></path>
    <path fill="#bdd4f1" d="M357.5 35.5q.679 1.438 2 2.5-1.374.417-2 1.5h-14q6.99-1.66 14-4"></path>
    <path
      fill="#528bd9"
      d="M333.5 38.5v1h-14q-8.474-.9-15 4h-5q-.41-2.927 2-4.5 15.992-.75 32-.5"></path>
    <path fill="#7fb3ed" d="M319.5 39.5a6039 6039 0 0 0-15 4q6.526-4.9 15-4"></path>
    <path
      fill="#acd2f5"
      d="M319.5 39.5h6q-2.22 2.867-6 3.5a72.3 72.3 0 0 1-16 1.5v-1h1a6039 6039 0 0 1 15-4"></path>
    <path fill="#9ec3f1" d="M281.5 43.5h22v1h-17v4h-5v-5"></path>
    <path
      fill="#3b77d3"
      d="M265.5 52.5a11210 11210 0 0 1-3 212v-216h-16a36.9 36.9 0 0 0-12 1 5.58 5.58 0 0 0-1 3q-1.937-.356-3 1h-19q8.715-1.485 18-1v-4q16.95-1.65 34-.5 2.305 1.665 2 4.5"></path>
    <path
      fill="#639de5"
      d="M246.5 48.5q-2.688 1.215-6 1.5 4.567.539 1 2a32.4 32.4 0 0 1-8 .5 5.58 5.58 0 0 1 1-3 36.9 36.9 0 0 1 12-1"></path>
    <path
      fill="#3575d7"
      d="M339.5 33.5q-1.2 3.11-4 5h-2q-16.008-.25-32 .5-2.41 1.573-2 4.5h-18q-1.496-.045-2 1.5a42.7 42.7 0 0 1-2.5 7.5q-.75 101.5-.5 203-1.465 4.18-1 9h-4q-.389-5.352-6-4 .119-3.249 2-6-2.622-6.74-2-14 2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q2.77.417 4-2-1.23-2.417-4-2v-12q6.434-.365 2-5 7.295-3.262-2-3v-4q3.966-.51 7 2 3.825-5.28 4.5-12a6.98 6.98 0 0 1 2.5-3.5q11.823-.508 22.5-2.5a299 299 0 0 0 30.5 1q-.255-1.983 1-3.5a18.4 18.4 0 0 1 6-.5"></path>
    <path
      fill="#bbdaf8"
      d="M257.5 263.5q.499-104.753-1-209a2672 2672 0 0 0-35 0v-1h9q8.555.188 17-1v-3h11q.499 107.253-1 214"></path>
    <path
      fill="#fefefe"
      d="M256.5 54.5v209h-218q-.495-90.607 1-181h12v-3q9.945-1.355 28-1.5 1.255-1.517 1-3.5 17.465-1.36 35-2.5 2.21-1.065 2-3.5h18a67.7 67.7 0 0 0 17-1.5q-1.255-1.517-1-3.5h30a26.8 26.8 0 0 0 10-2 14.7 14.7 0 0 0-5-1.5 159 159 0 0 0 18-1.5h8q5.66.356 11-1.5l-4-1q1.374-.417 2-1.5 17.834-.114 35 0"></path>
    <path
      fill="#4989e8"
      d="M281.5 43.5v1q-1.203 2.178-1.5 5-1 106 0 212 .574.834 1.5 1v2q-6.895-1.645-5-9-.25-101.5.5-203a42.7 42.7 0 0 0 2.5-7.5q.504-1.545 2-1.5"></path>
    <path fill="#75a8e7" d="M211.5 53.5h2q-1.602 1.401-4 1v3h-10v-4z"></path>
    <path
      fill="#c9e5ef"
      d="M221.5 54.5q-.626 1.083-2 1.5l4 1a27.9 27.9 0 0 1-11 1.5q2.928-5.005 9-4"></path>
    <path fill="#4d87da" d="M199.5 57.5v1h-32v4h-4q-2.202-1.92-2-5z"></path>
    <path fill="#97c2ec" d="M213.5 53.5h8v1q-6.072-1.005-9 4h-13v-1h10v-3q2.398.401 4-1"></path>
    <path
      fill="#b1d0f5"
      d="M175.5 58.5h10q-.917 3.412-4 5h-14v-1q5.025.247 10-.5-2.21-1.064-2-3.5"></path>
    <path
      fill="#e2ebfd"
      d="M185.5 58.5h19a159 159 0 0 1-18 1.5q2.633.303 5 1.5a26.8 26.8 0 0 1-10 2q3.083-1.588 4-5"></path>
    <path fill="#528fda" d="M281.5 44.5v218q-.926-.166-1.5-1-1-106 0-212 .297-2.822 1.5-5"></path>
    <path fill="#80b1e9" d="M175.5 58.5q-.21 2.436 2 3.5-4.975.747-10 .5v-4z"></path>
    <path
      fill="#73a7e8"
      d="M163.5 62.5h4v1h-16a66.8 66.8 0 0 0-14 1.5q-2.21 1.065-2 3.5h-31v3q-8.987.699-18 1a471 471 0 0 1 9-2 44 44 0 0 1-1-2 927 927 0 0 1 31-1.5l2-2a168.8 168.8 0 0 1 36-2.5"></path>
    <path
      fill="#bad7f5"
      d="M151.5 63.5q-.255 1.983 1 3.5a67.7 67.7 0 0 1-17 1.5q-.21-2.435 2-3.5a66.8 66.8 0 0 1 14-1.5"></path>
    <path
      fill="#377ad7"
      d="M94.5 68.5q.456.975 1 2a471 471 0 0 0-9 2h-23q3.703-1.225 8-1.5l-6-1q1.908-1.569 4.5-1.5 6.194 1.36 12.5 2 6.01-1.01 12-2"></path>
    <path
      fill="#bad6f5"
      d="M117.5 68.5q.21 2.435-2 3.5-17.535 1.14-35 2.5.255 1.983-1 3.5-18.055.145-28 1.5v3h-14v-3q-.258-1.487 1-2 12.775.49 25-1h8v-3q4.328.46 8-1h7a408 408 0 0 0 18-1v-3z"></path>
    <path fill="#79b0e9" d="M63.5 72.5h16q-3.672 1.46-8 1v3h-8z"></path>
    <path
      fill="#5a8dd7"
      d="M63.5 72.5v4q-12.225 1.49-25 1 8.213-1.483 17-1-.255-1.983 1-3.5a25 25 0 0 1 7-.5"></path>
    <path fill="#e6fdff" d="M37.5 82.5h2a8237 8237 0 0 0-1 181h-1v-181"></path>
    <path
      fill="#4687d3"
      d="M37.5 79.5v181q-.925-.166-1.5-1-.75-86.498-.5-173a68 68 0 0 1 2-7"></path>
    <path
      fill="#3a7dda"
      d="M35.5 86.5q-.25 86.502.5 173 .575.834 1.5 1v4q-1.935-.837-2.5-3a5110 5110 0 0 1 .5-175"></path>
    <path
      fill="#82b4ee"
      d="M246.5 48.5h13q.885 1.9 1.5 4l.5 23.5q-.396 94.239 0 188.5h-4v-1q1.499-106.747 1-214h-11v3a105 105 0 0 1-17 1q1.063-1.356 3-1a32.4 32.4 0 0 0 8-.5q3.567-1.461-1-2 3.312-.285 6-1.5"></path>
    <path
      fill="#75acf7"
      d="M259.5 48.5h3v216h-1q-.396-94.261 0-188.5l-.5-23.5q-.615-2.1-1.5-4"></path>
    <path fill="#def3ff" d="M256.5 54.5q1.499 104.247 1 209v1h-220v-1h219z"></path>
    <path
      fill="#d6edfe"
      d="M561.5 6.5h12v258h-292v-216h5v-4h5v4a41.7 41.7 0 0 1-9 1v214h289q1.737-125.958.5-252-2.034-1.32-4.5-1a156 156 0 0 1-22 1v-1h14q.04-2.55 2-4"></path>
    <path fill="#4984dc" d="M37.5 264.5h220v4h-222z"></path>
    <path
      fill="#3878db"
      d="M257.5 264.5h5a41.5 41.5 0 0 1 1 8 78 78 0 0 0-10-2l-6 1a25.9 25.9 0 0 0 10 1v4a90.4 90.4 0 0 1-23 0 6.85 6.85 0 0 0 1-4q2.217.393 4-1a200.6 200.6 0 0 0-40 0q1.783 1.393 4 1a6.85 6.85 0 0 0 1 4l-18 3q-12.419-4.072 1-4v-3q2.217.393 4-1a200.6 200.6 0 0 0-40 0q1.783 1.393 4 1v3a73 73 0 0 1 17 1 3.65 3.65 0 0 1-1 1.5 190 190 0 0 1-33-1.5 6.85 6.85 0 0 0 1-4q2.217.393 4-1a200.6 200.6 0 0 0-40 0q1.783 1.393 4 1a6.85 6.85 0 0 0 1 4q-9.021 1.501-18 3-12.418-4.072 1-4v-3q2.217.393 4-1a128.5 128.5 0 0 0-32 0q1.783 1.393 4 1a6.85 6.85 0 0 0 1 4 449 449 0 0 1-21 3q5.94 1.24 12 1.5a6926 6926 0 0 1 204 2.5 6341 6341 0 0 0-224 0q-2.317 1.095-2.5 4a5587 5587 0 0 0 .5 183v4q-1.47 1.898-3 4-.816-4.821-3-9a1973 1973 0 0 0 2-88.5l-1-87.5q-2.07-.75-2-3a38 38 0 0 1 2-5.5l-1-1.5a6.85 6.85 0 0 1-4 1v-8l8 2v-10h222z"></path>
    <path
      fill="#4680da"
      d="M573.5 6.5q1.499 128.247 1 257-.129 1.494 1 2.5-3.229.149-5 2.5-122.247 1.499-245 1a840 840 0 0 1-41-.5 28.5 28.5 0 0 0-9-4.5q-.465-4.82 1-9-1.895 7.355 5 9 1.602 1.401 4 1v3q142.004.5 284-1a3.65 3.65 0 0 0-1-1.5q2.822-.297 5-1.5z"></path>
    <path
      fill="#4887db"
      d="M281.5 264.5h292q-2.178 1.203-5 1.5.686.621 1 1.5a20169 20169 0 0 1-284 1v-3q-2.398.401-4-1"></path>
    <path
      fill="#3675da"
      d="m570.5 268.5 3 1.5q-2.687 3.159-2 7.5-6.107-1.957-12 .5a64.2 64.2 0 0 1-16 0 3.65 3.65 0 0 1-1-1.5 43.1 43.1 0 0 1 13-1v-3h-24v3q2.682-.428 5 1a3.65 3.65 0 0 1-1 1.5l-4 .5q-17.958-.254-36-.5a3.65 3.65 0 0 1-1-1.5 133 133 0 0 1 23-1v-3h-34v3q2.682-.428 5 1a3.65 3.65 0 0 1-1 1.5l-4 .5q-17.958-.254-36-.5a3.65 3.65 0 0 1-1-1.5 133 133 0 0 1 23-1v-3h-34v3q2.682-.428 5 1a3.65 3.65 0 0 1-1 1.5l-4 .5q-17.958-.254-36-.5a3.65 3.65 0 0 1-1-1.5 133 133 0 0 1 23-1v-3h-34v3q2.682-.428 5 1a3.65 3.65 0 0 1-1 1.5l-4 .5q-17.958-.254-36-.5a3.65 3.65 0 0 1-1-1.5 133 133 0 0 1 23-1v-3h-34v3q2.682-.428 5 1a3.65 3.65 0 0 1-1 1.5l-4 .5a3976 3976 0 0 1-44-.5l-3-1.5q-6.063 3.114-12 .5 2.853-2.476 6-1 1.255-1.517 1-3.5a8.4 8.4 0 0 1-4-.5 38.5 38.5 0 0 1 10-1.5q9.485.769 19 1 7.083-.012 13-2 122.753.499 245-1"></path>
    <path
      fill="#417ad1"
      d="M279.5 281.5h296a21.4 21.4 0 0 1-1 7v246h-1v-251a21466 21466 0 0 0-293-1v3q-1.401-1.602-1-4"></path>
    <path
      fill="#4080d4"
      d="M39.5 283.5a6341 6341 0 0 1 224 0v209q.442 3.352-1 6v-2q.25-104-.5-208-.934-6.648-3-1-.348-1.856-1.5-3a11880 11880 0 0 0-218-1"></path>
    <path fill="#4083d6" d="M573.5 283.5a21320 21320 0 0 0-292 1v4h-1v-6q146.752-.499 293 1"></path>
    <path
      fill="#7cb4f7"
      d="M281.5 284.5a21320 21320 0 0 1 292-1v5h-4v-3a21023 21023 0 0 1-288-1"></path>
    <path
      fill="#82b1e8"
      d="M39.5 283.5q109.253-.499 218 1v4h-220v182a5587 5587 0 0 1-.5-183q.183-2.905 2.5-4"></path>
    <path fill="#8fbdf0" d="M281.5 284.5a21023 21023 0 0 0 288 1v3h-288z"></path>
    <path
      fill="#eefbfd"
      d="M257.5 288.5v210h-1q.25-103.501-.5-207-5.183 1.937-10.5 0a3.65 3.65 0 0 1 1-1.5 784 784 0 0 0-56 0l4 1q-6.276 2.643-13 .5a3.65 3.65 0 0 1 1-1.5q-7.5-1-15 0 .686.621 1 1.5-6.225 2.133-12-.5l4-1q-9-1-18 0l4 1q-2.938 1.644-6.5 1.5-5.468-.946-9-3-8.34.17-.5 1.5-6.276 2.643-13 .5a3.65 3.65 0 0 1 1-1.5q-7.5-1-15 0 .686.621 1 1.5-6.225 2.133-12-.5l4-1q-9-1-18 0l4 1q-2.938 1.644-6.5 1.5-5.468-.946-9-3-8.34.17-.5 1.5-6.276 2.643-13 .5a3.65 3.65 0 0 1 1-1.5 49.2 49.2 0 0 0-14 0 9.5 9.5 0 0 0-1.5 2.5 7744 7744 0 0 0 0 176q11.734 1 23.5 1 .255 1.983-1 3.5 16.716.925 32-.5 5.078.5 8 4.5l-2 1a179.2 179.2 0 0 1 37 1q1.314 2.377 4 3l-4 1q13.764.255 27 1.5h-35q-1.201-2.312-1-5h-32v-4a169 169 0 0 0-24-1h-14q-1.96-1.451-2-4h-18v-182z"></path>
    <path
      fill="#3e7edf"
      d="M280.5 287.5v1h-1q-.926.166-1.5 1a7706 7706 0 0 0 .5 214h3q6.705 1.479 14 1v4h32v1h-42v-4h-4v3h-4v-4a271 271 0 0 1-3-26 8012 8012 0 0 0 1-178q-1.983.255-3.5-1a25 25 0 0 1-.5-7h4v-6q2.688-.201 5 1"></path>
    <path fill="#f2fefe" d="M282.5 288.5h287v4h-3v-3h-283q.356 1.937-1 3z"></path>
    <path
      fill="#fefefe"
      d="M256.5 498.5h-11v-4a455 455 0 0 0-36-1q-1.96-1.451-2-4h-26a135 135 0 0 0-13-2v-3h-5a359 359 0 0 0-27-1.5l4-1q-2.686-.623-4-3a179.2 179.2 0 0 0-37-1l2-1q-2.922-4-8-4.5-15.284 1.425-32 .5 1.255-1.517 1-3.5-11.766 0-23.5-1a7744 7744 0 0 1 0-176 9.5 9.5 0 0 1 1.5-2.5 49.2 49.2 0 0 1 14 0q-.685.621-1 1.5 6.724 2.143 13-.5-7.84-1.33.5-1.5 3.532 2.054 9 3 3.562.144 6.5-1.5l-4-1q9-1 18 0l-4 1q5.775 2.633 12 .5a3.65 3.65 0 0 0-1-1.5q7.5-1 15 0a3.65 3.65 0 0 0-1 1.5q6.724 2.143 13-.5-7.84-1.33.5-1.5 3.532 2.054 9 3 3.562.144 6.5-1.5l-4-1q9-1 18 0l-4 1q5.775 2.633 12 .5a3.65 3.65 0 0 0-1-1.5q7.5-1 15 0a3.65 3.65 0 0 0-1 1.5q6.724 2.143 13-.5l-4-1a784 784 0 0 1 56 0 3.65 3.65 0 0 0-1 1.5q5.317 1.937 10.5 0 .75 103.499.5 207M566.5 292.5q1.238 115.958 1.5 232 .274 4.297 1.5 8 .814.09 1 1-2.24.961-3 3h-22q-.332-1.852-2-3a16.9 16.9 0 0 0-8-1h-8q-5.216-1.235-11-1.5a15.5 15.5 0 0 1-5-3 72.4 72.4 0 0 0-12-.5h-7a197 197 0 0 0-20-1.5q-1.255-1.517-1-3.5h-36q.305-2.835-2-4.5-13.991-.75-28-.5h-8q.255-1.983-1-3.5a133.3 133.3 0 0 0-24-1.5h-15q.101-4.899-5-4.5a544 544 0 0 0-33-.5v-4h-32q-1.578-2.634-5-2v-209q1.356-1.063 1-3h283z"></path>
    <path fill="#3874d5" d="M574.5 288.5q2 60.991 2 123t-2 123z"></path>
    <path fill="#d9ebfe" d="M281.5 288.5h1v213q3.422-.634 5 2h-6v-215"></path>
    <path
      fill="#f6fffe"
      d="M285.5 292.5q2 48.49 2 98l-1 98a14.7 14.7 0 0 1-1.5-5 6104 6104 0 0 1 .5-191"></path>
    <path
      fill="#4788e6"
      d="M279.5 288.5q-.25 106.501.5 213 .574.834 1.5 1v1h-3a7706 7706 0 0 1-.5-214q.574-.834 1.5-1"></path>
    <path
      fill="#73acf4"
      d="M257.5 284.5q1.152 1.144 1.5 3 2.066-5.648 3 1 .75 104 .5 208-1.63-.18-2-2a5433 5433 0 0 0 0-206h-3z"></path>
    <path
      fill="#3675d9"
      d="M279.5 281.5q-.401 2.398 1 4v2q-2.312-1.201-5-1v6h-4q-.245 3.535.5 7 1.517 1.255 3.5 1a8012 8012 0 0 1-1 178 271 271 0 0 0 3 26h-4v-4h-4q-.428-5.287 2-10-3.68-7.94 0-16-3.68-7.94 0-16-3.68-7.94 0-16-3.68-7.94 0-16-3.68-7.94 0-16-3.68-7.94 0-16-3.68-7.94 0-16-3.68-7.94 0-16-3.68-7.94 0-16-3.68-7.94 0-16-3.68-7.94 0-16l-4-10q1.047-2.044 0-3.5a6.8 6.8 0 0 0 3-1.5 9.86 9.86 0 0 0-6-1 9414 9414 0 0 1-1 194v-202q4.578-2.517 10-2 .593-5.366 5.5-8 .598.342.5 1"></path>
    <path fill="#528fda" d="M279.5 288.5h2v214q-.926-.166-1.5-1-.75-106.499-.5-213"></path>
    <path
      fill="#d3e9fe"
      d="M569.5 288.5h4v249h-24v-1h23q.25-123.001-.5-246-.513 3.179-2.5 1z"></path>
    <path fill="#f6f8fe" d="M566.5 292.5h3v240q-1.226-3.703-1.5-8-.262-116.042-1.5-232"></path>
    <path fill="#8bbaee" d="M257.5 288.5h3a5433 5433 0 0 1 0 206q.37 1.82 2 2v2h-5z"></path>
    <path
      fill="#e6f4fc"
      d="M567.5 536.5q.76-2.039 3-3-.186-.91-1-1v-241q1.987 2.179 2.5-1 .75 122.999.5 246z"></path>
    <path fill="#bcd9f8" d="M47.5 470.5h8q.04 2.549 2 4h-10z"></path>
    <path fill="#7eadf1" d="M37.5 470.5h10v4h24v1h-33q-1 0-1-1z"></path>
    <path
      fill="#c9e1f9"
      d="M71.5 475.5v-1q12.063-.354 24 1v4a12.9 12.9 0 0 1-5-.5q1.862-2.23-1.5-2.5a36 36 0 0 0-9.5-1z"></path>
    <path
      fill="#3977d8"
      d="M38.5 475.5h33v4h10q7.473.83 15 1 2.688 1.214 6 1.5-.977 1.962 2 2.5-.09.814-1 1a89.5 89.5 0 0 0-12-1.5 10.3 10.3 0 0 0 4-1.5q-14.28-3.564-26 5a9.86 9.86 0 0 1-6 1q-.39-5.352-6-4v-7q-10.132.934-19-2"></path>
    <path
      fill="#99bfec"
      d="M79.5 475.5a36 36 0 0 1 9.5 1q3.362.27 1.5 2.5 2.45.74 5 .5h12v1h-11a171 171 0 0 1-15-1q-1.96-1.451-2-4"></path>
    <path fill="#65a2e9" d="M71.5 475.5h8q.04 2.549 2 4h-10z"></path>
    <path
      fill="#a2c7eb"
      d="M107.5 480.5v-1h20q-.201 2.688 1 5a61 61 0 0 1-11-.5l-2-3a32.5 32.5 0 0 0-8-.5"></path>
    <path
      fill="#508bde"
      d="M96.5 480.5h11a32.5 32.5 0 0 1 8 .5l2 3q5.478.748 11 .5h7v1q-15.547.35-31-1-2.977-.538-2-2.5-3.312-.286-6-1.5"></path>
    <path fill="#aed0f6" d="M149.5 484.5h19v3a135 135 0 0 1 13 2h-22q-5.786-1.085-10-5"></path>
    <path
      fill="#68a3eb"
      d="M135.5 485.5v-1h14q4.214 3.915 10 5a36.9 36.9 0 0 1-12-1q-2.888-4.008-8-3z"></path>
    <path
      fill="#3f7dd3"
      d="M139.5 485.5q5.112-1.008 8 3a36.9 36.9 0 0 0 12 1h24q-.626 1.083-2 1.5 1.984 1.224 2 3.5a3297 3297 0 0 1-44-6z"></path>
    <path fill="#c9dff1" d="M199.5 489.5h8q.04 2.549 2 4h-10z"></path>
    <path fill="#97c0ef" d="M189.5 489.5h10v4h-8q-.04-2.549-2-4"></path>
    <path
      fill="#5d99e0"
      d="M183.5 489.5h6q1.96 1.451 2 4h8v1h-16q-.016-2.276-2-3.5 1.374-.417 2-1.5"></path>
    <path
      fill="#bdd6f3"
      d="M199.5 494.5v-1h10a455 455 0 0 1 36 1v4h-12q.21-2.436-2-3.5a72.4 72.4 0 0 0-12-.5h-20"></path>
    <path
      fill="#528be1"
      d="M201.5 494.5h18a346 346 0 0 0 8 2q1.668 1.148 2 3a36.9 36.9 0 0 1-12-1l-2-2q-7.225-1-14-2"></path>
    <path
      fill="#7baaeb"
      d="M219.5 494.5a72.4 72.4 0 0 1 12 .5q2.21 1.064 2 3.5h24q-13.728 1.49-28 1-.332-1.852-2-3a346 346 0 0 1-8-2"></path>
    <path
      fill="#7cade0"
      d="M281.5 503.5h26q-1.96 1.451-2 4 2.398-.401 4 1h-14v-4q-7.295.479-14-1"></path>
    <path fill="#aed2f6" d="M307.5 503.5h12v5h-10q-1.602-1.401-4-1 .04-2.549 2-4"></path>
    <path fill="#6ea3e3" d="M327.5 509.5v-1h12q1.602 1.401 4 1v3a213 213 0 0 1-16-3"></path>
    <path fill="#a2c3f0" d="M339.5 508.5h9v4h24v1h-29v-4q-2.398.401-4-1"></path>
    <path fill="#d0e3fa" d="M319.5 508.5v-1q16.507-.25 33 .5 5.101-.399 5 4.5h-9v-4h-29"></path>
    <path fill="#4d8cde" d="M327.5 509.5a213 213 0 0 0 16 3v1a84 84 0 0 1-16-1z"></path>
    <path
      fill="#dde9fd"
      d="M372.5 513.5v-1a133.3 133.3 0 0 1 24 1.5q1.255 1.517 1 3.5h-6v-3a83 83 0 0 0-14-1z"></path>
    <path
      fill="#659fe6"
      d="M361.5 513.5h16q-1.006 2.02 0 3.5 6.282.264 12 1.5a85 85 0 0 1-13-.5 127 127 0 0 0-15-4.5"></path>
    <path
      fill="#9ec3ef"
      d="M377.5 513.5a83 83 0 0 1 14 1v3h14v1h-16q-5.718-1.236-12-1.5-1.006-1.48 0-3.5"></path>
    <path
      fill="#d4e8f9"
      d="M405.5 518.5v-1q14.009-.25 28 .5 2.305 1.665 2 4.5h-11q.255-1.983-1-3.5a32.5 32.5 0 0 0-8-.5z"></path>
    <path
      fill="#5b95df"
      d="M390.5 518.5h25v4h32q2.648 1.442 6 1-.21 2.436 2 3.5 6.982.749 14 .5h2v1q-16.218.81-32-2v-3q-13.01.25-26-.5-4.803-.82-9-3-7.277-.261-14-1.5"></path>
    <path fill="#94c2f2" d="M415.5 518.5a32.5 32.5 0 0 1 8 .5q1.255 1.517 1 3.5h-9z"></path>
    <path
      fill="#3877d9"
      d="M343.5 513.5h18a127 127 0 0 1 15 4.5q6.481.749 13 .5h1q6.723 1.239 14 1.5 4.197 2.18 9 3 12.99.75 26 .5v3q15.782 2.81 32 2h7q1.063 1.356 3 1v3h-4v-3a311.5 311.5 0 0 1-41-1v-3q-14.009.25-28-.5-4.461-3.675-6.5 1.5-7.912-2.544-16.5-4a20.5 20.5 0 0 1-4.5-3q-2.738.905-5.5 2l4 2a3.65 3.65 0 0 1-1 1.5 49 49 0 0 1-13 3.5v-4h-4q.416-4.422-1-8.5-9.132.188-17-2.5"></path>
    <path
      fill="#d7edf7"
      d="M463.5 522.5h8q-.255 1.983 1 3.5a197 197 0 0 1 20 1.5h-23q-2.648-1.442-6-1z"></path>
    <path
      fill="#a4caf1"
      d="M447.5 522.5h16v4q3.352-.442 6 1a98.4 98.4 0 0 1-14-.5q-2.21-1.064-2-3.5-3.352.442-6-1"></path>
    <path
      fill="#9ec3ec"
      d="M471.5 528.5v-1h28q1.702 1.461 3 3.5 2.337.315 4 1.5h-5a346 346 0 0 0-8-2 3.65 3.65 0 0 0-1-1.5 25 25 0 0 0-7-.5h-14"></path>
    <path
      fill="#e5f1fb"
      d="M499.5 527.5a72.4 72.4 0 0 1 12 .5 15.5 15.5 0 0 0 5 3q5.784.265 11 1.5h-21q-1.663-1.185-4-1.5-1.298-2.039-3-3.5"></path>
    <path fill="#5b95e8" d="M478.5 528.5h7q2.03 1.539 3 4h-7v-3q-1.937.356-3-1"></path>
    <path
      fill="#82b7f2"
      d="M485.5 528.5q3.535-.245 7 .5.686.621 1 1.5a346 346 0 0 1 8 2h-13q-.97-2.461-3-4"></path>
    <path fill="#82b5ed" d="M516.5 532.5h19v4h14v1h-22v-4q-5.807.473-11-1"></path>
    <path
      fill="#4d8ade"
      d="M502.5 532.5h14q5.193 1.473 11 1v4h24q.166.925 1 1.5a75.6 75.6 0 0 0 21 2.5q-10.885 2.046-22-1a3.65 3.65 0 0 0-1-1.5l-28-1q-5.601.132-9-4-5.784-.265-11-1.5"></path>
    <path fill="#d0e5f8" d="M535.5 532.5q4.12-.47 8 1 1.668 1.148 2 3h-10z"></path>
    <path fill="#78b1ea" d="M551.5 537.5h22v4a75.6 75.6 0 0 1-21-2.5q-.834-.575-1-1.5"></path>
    <path
      fill="#3877d9"
      d="M558.5 585.5v9h-4q.201 2.688-1 5v37q-.247 5.025.5 10 3.318 3.753 2.5 9-.662-4.42-3.5-8a91.4 91.4 0 0 0-2.5-16 14.7 14.7 0 0 0-1.5 5l-1 23a18.5 18.5 0 0 1-3.5 2v-2q1.494.129 2.5-1l.5-6.5a7584 7584 0 0 0-.5-75.5l-1.5-1.5a85 85 0 0 0-13-.5q-6.773-6.988 3-9 5.618-.053 2 3 6.444 3.687 14 3v4h4q.445 4.836-2 9 2.318 1.428 5 1"></path>
    <path
      fill="#3e7edb"
      d="M601.5 579.5h-5q.401-2.398-1-4-13.289-1.893-27 0v4h-3q2.202-1.919 2-5 3.899-.073 6.5-3l1 1q8.192-3.924 16.5 0 5.014-1.986 10 0 1.569 3.573 0 7"></path>
    <path
      fill="#558ddf"
      d="M123.5 576.5q1.692 8.397-5 13 2.578-6.68 4-14h-14v8h-1v-9q8.656-.92 16 2"></path>
    <path fill="#4c89d9" d="M68.5 574.5q1 0 1 1h-9v5a42 42 0 0 1-5 4q-.608-13.872 13-10"></path>
    <path fill="#b4d2ef" d="M271.5 575.5v33h-5v-1h4q-.491-16.27 1-32"></path>
    <path
      fill="#f6fcfd"
      d="M271.5 575.5h4v86h-4v-5h-9v-4q2.398.401 4-1v-5q1.401-1.602 1-4h4v-20q-3.239-6.592-5-14h5z"></path>
    <path
      fill="#88b9f7"
      d="M276.5 570.5h3q1.488 47.39 1 95-6.769 1.645-14 0 .618-3.71-3-4 3.842-1.9 8 0v4q5.174 1.115 5-4 1.999-21.727 2-44.5-.001-22.773-2-44.5z"></path>
    <path fill="#7cadeb" d="M595.5 575.5h-23v4h-4v-4q13.711-1.893 27 0"></path>
    <path fill="#669fe4" d="M11.5 575.5q3.7-1.701 8-.5 2.41 1.573 2 4.5-2.54-1.541-4-4z"></path>
    <path fill="#c8e1f3" d="M69.5 575.5v5q-3.867-1.131-8 0 .401 2.398-1 4v-9z"></path>
    <path fill="#a0c9ee" d="M592.5 575.5h3q1.401 1.602 1 4h5v1a41.7 41.7 0 0 1-9-1z"></path>
    <path fill="#92bdee" d="M17.5 575.5q1.46 2.459 4 4 1.427 2.128 1 5-1.402-1.602-1-4h-4z"></path>
    <path
      fill="#a2c9f3"
      d="M118.5 589.5v10h-5q-1.352-5.611 4-6v-14l-8 1q.356 1.937-1 3v-8h14q-1.422 7.32-4 14"></path>
    <path
      fill="#6fa1e4"
      d="M527.5 579.5h2l-4 5q-2.312 1.201-5 1v4h-9q3.969-6.562 12-5.5 1.32-2.034 1-4.5z"></path>
    <path
      fill="#aed6fc"
      d="M543.5 575.5q.25 43.003-.5 86-1.977 5.162-7.5 4-1 0-1-1v-3h5a31 31 0 0 0 2-2q1.5-41.488 1-83-4.407-.34-7 3h-1v-4z"></path>
    <path
      fill="#8ab6ef"
      d="M69.5 575.5h1q-.865 4.575 3.5 6 .725 1.422.5 3h-4q.401-2.398-1-4z"></path>
    <path fill="#8dbbeb" d="M271.5 570.5v5q-1.491 15.73-1 32h-4v-3q-2.872.426-5-1h5v-33z"></path>
    <path fill="#accaf1" d="M565.5 579.5h3q1 6-5 5-.634-3.422 2-5"></path>
    <path
      fill="#4f8bd9"
      d="M601.5 579.5q2.322.482 2 3 1.983-.255 3.5 1l1 8q.552 2.115 2.5 3 1.428 2.318 1 5h-1q.237-4.533-4-6v-9h-5v-5"></path>
    <path fill="#aac6ed" d="M529.5 579.5h5q-1.602 1.401-4 1v4h-5z"></path>
    <path
      fill="#3777d8"
      d="M142.5 573.5a150 150 0 0 0 3 17.5 1065 1065 0 0 0-2.5 76.5q-1.782 1.595-1.5 4h-12v-5a61 61 0 0 0 11-.5 9.5 9.5 0 0 0 1.5-2.5q.75-31.996.5-64h-15v64a605 605 0 0 1-1.5-35 483 483 0 0 1-1.5 24q1.977 5.145-.5 10l-1.5-4.5q1.57-7.22 0-14.5h-5q-1.13-5.274-4-10 .084-1.855 1.5-3a30 30 0 0 0 8.5 3 129 129 0 0 0-.5-16 9.5 9.5 0 0 0-1.5-2.5 7.25 7.25 0 0 0 2-2.5 13.1 13.1 0 0 0-7-1v3q6 .5 0 1a12.9 12.9 0 0 1-.5 5q-1.155-1.647-2.5 0a124 124 0 0 0 1-21h4v-10q6.692-4.603 5-13 1.225 3.703 1.5 8 2.088 3.513 2.5 0 7.514 1.878 15 0z"></path>
    <path
      fill="#77a6e1"
      d="M142.5 573.5v11q-7.486 1.878-15 0-2.973-16.263 13-14.5 1.984 1.224 2 3.5"></path>
    <path
      fill="#deeffd"
      d="M132.5 580.5v-5q-1.004-6.264 4.5-4a8.4 8.4 0 0 1 .5 4h4q1.115 5.175-4 5v4h-5z"></path>
    <path fill="#bfdbf6" d="M132.5 575.5v5q-1.812-1.1-4-1v-4z"></path>
    <path
      fill="#5f99dc"
      d="M8.5 575.5a21.1 21.1 0 0 0 1 9l2 1a36.9 36.9 0 0 1 1 12q-1.46-3.672-1-8h-4q-.48-7.295 1-14"></path>
    <path fill="#85b4e8" d="M563.5 584.5v5h-5v-4q2.688.201 5-1"></path>
    <path fill="#aeceef" d="M601.5 584.5h5v14q-1.465-4.18-1-9h-4z"></path>
    <path fill="#9ecafb" d="M511.5 589.5h4v5h4v4h-8z"></path>
    <path
      fill="#f1f9fd"
      d="M534.5 579.5h5v82h-5v-72h-9v4q-2.819 1.262-6 1h-4v-5h5v-4q2.688.201 5-1h5v-4q2.398.401 4-1"></path>
    <path fill="#bddcfa" d="M530.5 589.5q-1.1 1.812-1 4h-4v-4z"></path>
    <path
      fill="#3977da"
      d="m239.5 598.5-2 1h-4v4h-6v5a9.1 9.1 0 0 1-5-1q-1.937 5.317 0 10.5-2.305 1.665-2 4.5-1 0-1 1-1.731 9.73-.5 20a24.3 24.3 0 0 0 2.5 6 14.9 14.9 0 0 1-5-5q-1.794.519 0 1.5-2.065 1.862-4 .5a412 412 0 0 0 5.5-28q1.737-.747 2.5-2.5a11.2 11.2 0 0 1-1-4.5 19 19 0 0 1-6-1q-1.721.476-2.5 2a1192 1192 0 0 1-1.5 49 577 577 0 0 0-1-48v-5h-4v-5h-5v-4q-12.222-1.822-24 0v1q-3.456.995-7 2-1.393-1.783-1-4 6.29.485 11.5-3a32.8 32.8 0 0 0-1.5-8 28.2 28.2 0 0 0 7-2 31.8 31.8 0 0 0 13 2q-.119 3.249-2 6l1 2a54 54 0 0 1 5.5-2 85 85 0 0 0 17.5 2q8.619-.04 16.5-2 1.026 1.926-.5 3.5 2.337.315 4 1.5"></path>
    <path
      fill="#bbd8f7"
      d="M8.5 575.5h9v5a5.58 5.58 0 0 1-3-1q-2 7.5 0 15a6.85 6.85 0 0 0 4 1v18h4v9q-1.268-4.769-5-8v-15h-5v-2a36.9 36.9 0 0 0-1-12l-2-1a21.1 21.1 0 0 1-1-9"></path>
    <path
      fill="#3877d7"
      d="M68.5 574.5q-13.608-3.872-13 10v9q-3.312 2.091-4.5 6a98.4 98.4 0 0 0-.5 14q-4.35-.138-5 4-.483-5.775 4-9.5-3.394-6.096 0-12.5 1.42-2.85 4.5-4a80.5 80.5 0 0 0-.5-20q6.7-2.46 14-1.5 3.072 1.839 1 4.5"></path>
    <path
      fill="#3876db"
      d="M376.5 623.5h-1q-3.225-5.687-4-12v-8h-3q.401-2.398-1-4-5.193-1.473-11-1-.23 9.554 1 19 2.188-.1 4 1v12a39 39 0 0 1-2.5-5q-1-2-2 0a42.8 42.8 0 0 0-1.5-9 44 44 0 0 1-2 3v-6h-5q.401-2.398-1-4-1.602-1.401-4-1 1-6-5-5v-4q-12.712-1.882-25 0h-4a17.9 17.9 0 0 0-5 3v-4h5v-7q5.611 1.352 6-4h10q.301 5.775 5.5 3a4.93 4.93 0 0 0 .5-3h10q-.258 2.987 2 5-7.239 2.761 1 5.5-8.61 1.717-1 4.5a9.1 9.1 0 0 1 1 5h3q.241-4.542-2-8.5l1-1.5q4.296 3.903 8.5 0-.8-4.91.5-10h8v4h4q-.091 3.825-4 4.5 2.577.519 5 1.5l2-2q3.988 3.246 9 4l2 8q-2.988-.258-5 2a6.8 6.8 0 0 1-1.5-3 92 92 0 0 1 1.5 17"></path>
    <path fill="#6097e8" d="M557.5 594.5v5h-4q1.201-2.312 1-5z"></path>
    <path fill="#85afe3" d="M606.5 593.5q4.237 1.467 4 6-2.398.401-4-1z"></path>
    <path
      fill="#4382d6"
      d="M70.5 575.5q7.763 5.805 7 16a34.5 34.5 0 0 1 5.5 6 20.56 20.56 0 0 1-1.5 8.5q5.16 10.062 4 21.5h-1v-4q1.178-6.21-3.5-10-.269-5.288-1.5-10v-9q-2.188.1-4-1 .354-4.664-1-9a4.93 4.93 0 0 0-.5-3q-4.365-1.425-3.5-6"></path>
    <path fill="#6098e0" d="M464.5 598.5q10.78-.487 21 1h-23v4h-4v-5z"></path>
    <path fill="#8fbaec" d="M175.5 599.5h5v4h-5v-4"></path>
    <path fill="#7faeee" d="M175.5 599.5q11.778-1.822 24 0v4h-5v-4h-19"></path>
    <path fill="#6ea0e7" d="M313.5 599.5q12.288-1.882 25 0v4h-5v-4h-20"></path>
    <path
      fill="#ddeefb"
      d="M467.5 599.5h14q1.602 1.401 4 1a41.5 41.5 0 0 1 1 8h-24q.634 3.422-2 5h-2q2.153-1.986 3-5a22.1 22.1 0 0 0 6-3q-.686-1.197-2-1 2.202-1.919 2-5"></path>
    <path fill="#80acee" d="M12.5 599.5h5v8q-1.402-1.602-1-4h-4z"></path>
    <path
      fill="#90baec"
      d="M239.5 598.5h3q7.295-.479 14 1h-14v4q-2.398-.401-4 1h-5q.401 2.398-1 4v-5h5v-4z"></path>
    <path fill="#88bbea" d="M256.5 599.5h5v4h-5z"></path>
    <path
      fill="#dcecf9"
      d="M314.5 599.5h19v4h5q1.526 3.617 5 5 2.398-.401 4 1v4h-4v-4q-3.061.531-5.5 0-.868 2.142.5 4h-5v-5h-19a71 71 0 0 1 4-2 3.65 3.65 0 0 0-1-1.5 85 85 0 0 0-13-.5q2.128-1.426 5-1h5z"></path>
    <path fill="#9fc6eb" d="M367.5 599.5v9h4v5h-4a105 105 0 0 1-1-9q-4.18-1.465-9-1v-4z"></path>
    <path fill="#deeffd" d="M180.5 599.5h14v4h5v1h-9q-.1 2.188 1 4h-20v-4q2.398.401 4-1h5z"></path>
    <path fill="#508ac7" d="M237.5 599.5v4h-4v-4z"></path>
    <path
      fill="#699adc"
      d="M309.5 599.5v4q-2.872-.426-5 1-1.152 1.145-1.5 3-1.006 1.129-2.5 1v-4q1.757-1.267 4-2a17.9 17.9 0 0 1 5-3"></path>
    <path fill="#9ec3ef" d="M309.5 599.5h5v4h-5z"></path>
    <path fill="#8ec1f0" d="M395.5 599.5h10v5h-1q-4.18-1.465-9-1z"></path>
    <path
      fill="#98c4ea"
      d="M439.5 624.5q-1.356 1.063-1 3h-5v-4q2.466.32 4.5-1 .747-4.975.5-10 1.356-1.063 1-3h3v-6h-8v-4h9a72.4 72.4 0 0 1-.5 12q-4.714 5.442-3.5 13"></path>
    <path fill="#478cd8" d="M486.5 599.5h5v4q-4.57.476-5-4"></path>
    <path
      fill="#4982da"
      d="M395.5 599.5v12q-1.98 2.162-5 2v14h-4q.401 2.398-1 4v-10h4q-.862-9.31 0-17.5 1.89-1.67 3.5-.5a51 51 0 0 0 2.5-4"></path>
    <path
      fill="#c4def4"
      d="M434.5 603.5h8v6h-3q.356 1.937-1 3a25 25 0 0 0-.5-7q-1.517-1.255-3.5-1-.06 7.039-1 14-2.188-.1-4 1v-6q4.196-.7 4-5v-4q0-1 1-1"></path>
    <path fill="#c5d8f3" d="M467.5 599.5q.202 3.081-2 5h-7v5h-5v-1h4v-5h5v-4z"></path>
    <path fill="#cae0fa" d="M357.5 603.5q4.82-.465 9 1h-4v8q-1.356-1.063-1-3-2.188.1-4-1z"></path>
    <path fill="#f5fcfe" d="M404.5 604.5h1v4q-2.237 2.12-2 4.5-3.553 1.284-7 2.5v-11z"></path>
    <path fill="#5596e1" d="m429.5 603.5 4 1v4h-4z"></path>
    <path
      fill="#427dda"
      d="M453.5 603.5v5q-6.792 5.22-5 14-2.145-7.464 1-15-1.783-1.393-4-1-1.784 6.525 0 13-2.318 1.428-5 1 .452 3.839-1 7v-3q-1.214-7.558 3.5-13 .749-5.979.5-12h-9v4q-1 0-1 1l-4-1q.309-3.074 2.5-1 2.115-4.543 7.5-4 4.494.75 8.5 0 3.002 1.834 2.5 4.5a4.93 4.93 0 0 0 3 .5"></path>
    <path fill="#89b7f9" d="M199.5 604.5v-1h5v5h-1v-4z"></path>
    <path
      fill="#ddedfb"
      d="M256.5 599.5v4h5q2.128 1.426 5 1v4q1.761 7.408 5 14-1.356-1.063-1-3-2.188.1-4-1v-5h-5v-4q-9.217-1.485-19-1 1.063-1.356 3-1v-3h-7q1.602-1.401 4-1v-4z"></path>
    <path fill="#b1d3f9" d="M338.5 603.5q6-1 5 5-3.474-1.383-5-5"></path>
    <path
      fill="#7db0eb"
      d="M481.5 599.5h5q.43 4.476 5 4v5h-5a41.5 41.5 0 0 0-1-8q-2.398.401-4-1"></path>
    <path
      fill="#67a0e6"
      d="M511.5 589.5v9h8v-4q3.181.262 6-1h4q2.039-.76 3-3 1 35.745 2 71v3q-2.466.32-4.5-1a2311 2311 0 0 1-.5-68q-3.687-.41-5 3a49.9 49.9 0 0 1-14 1q-.469-5.313 1-10"></path>
    <path fill="#bfd9fc" d="M199.5 604.5h4v4h-4z"></path>
    <path fill="#66a0e6" d="M232.5 603.5v5h-5v-5z"></path>
    <path fill="#639ee7" d="M367.5 599.5q1.401 1.602 1 4h3v5h-4z"></path>
    <path fill="#a0bdf1" d="M453.5 603.5h4v5h-4z"></path>
    <path
      fill="#f7fbfd"
      d="M165.5 604.5v5h5v4q-2.286 1.011-4 3a485 485 0 0 0-1 44q-1.983.255-3.5-1-.75-27.495-.5-55z"></path>
    <path
      fill="#3676da"
      d="M492.5 655.5q.485-9.285-1-18h-5v-5q-6.001.435-9-4.5-6.363.393-10-4.5-1.602-1.401-4-1v-13q9.783-.485 19 1v3h9v-14h-6q-10.22-1.487-21-1 .866-3.228 3.5-1 2.994-2.339 7-2 6.284.47 12.5 1.5 1.569 2.308 4 .5l2 1a23.8 23.8 0 0 0-1 9h15v48z"></path>
    <path
      fill="#f1f9fd"
      d="M17.5 580.5q-1.116 5.175 4 5-.402 2.398 1 4v10h4v19h5v9q-1.39 7.221 5 10-.47 5.313 1 10v4h4v-9q1.401-1.602 1-4 1.983.255 3.5-1a61 61 0 0 0 .5-11v-3h4l1-10v-5h4v-14q1.427-2.128 1-5h4v-5q1.401-1.602 1-4 4.133-1.131 8 0 1.401 1.602 1 4 1.2 2.313 1 5h3a85 85 0 0 0 .5 13q1.574 2.41 4.5 2v14q-1.115 5.175 4 5h1v4q-.247 5.025.5 10 1.28 2.437 3 1 .697 6.522 1.5 13h5v-15a44 44 0 0 0 4.5-4q.747-4.975.5-10v-4q2.313 1.201 5 1v-20h4v-16q1.356-1.063 1-3l8-1v14q-5.352.389-4 6v9h-5v19q-2.466-.32-4.5 1a32.5 32.5 0 0 0-.5 8v6h-5v19h-14v-10h-4q.25-9.014-.5-18-2.034-1.32-4.5-1v-19h-5v-15q-5.07-2.769-11-3v17h-4v20h-4a68.2 68.2 0 0 0-1 14q-1.983-.255-3.5 1a40.8 40.8 0 0 0-1.5 14h-13v-20h-4a67.4 67.4 0 0 0-1.5-13q-1.517-1.255-3.5-1 .402-2.398-1-4v-10h-4v-18a6.85 6.85 0 0 1-4-1q-2-7.5 0-15a5.58 5.58 0 0 0 3 1"></path>
    <path fill="#63a3e2" d="M61.5 603.5h8v5h-8v3q0 1-1 1-.465-4.82 1-9"></path>
    <path fill="#b6d4f1" d="M55.5 599.5v9h-4q-.24-2.549.5-5 2.325-1.636 3.5-4"></path>
    <path
      fill="#68a1e8"
      d="M55.5 593.5v6q-1.175 2.364-3.5 4a12.9 12.9 0 0 0-.5 5v5h-1q-.25-7.018.5-14 1.188-3.909 4.5-6"></path>
    <path
      fill="#a7c8f1"
      d="M70.5 584.5h4q1.354 4.336 1 9v6h4v5q-2.926.41-4.5-2a85 85 0 0 1-.5-13h-3q.2-2.687-1-5"></path>
    <path fill="#5f9fe0" d="M75.5 593.5q1.812 1.1 4 1v5h-4z"></path>
    <path fill="#b6d2f1" d="M103.5 594.5h5v5h-4v4h-1z"></path>
    <path fill="#7fafe7" d="M107.5 583.5h1v11h-4q2.694-5.094 3-11"></path>
    <path fill="#5592e1" d="M107.5 583.5q-.306 5.906-3 11h-1q-1.485-7.106 4-11"></path>
    <path fill="#5895e0" d="M22.5 587.5q1.92 2.202 5 2v9q-1.402-1.602-1-4-2.188.1-4-1v-6"></path>
    <path fill="#90bced" d="M60.5 580.5v9h-4q.427 2.872-1 5v-10a42 42 0 0 0 5-4"></path>
    <path fill="#c5dbf9" d="M17.5 580.5h4q-.402 2.398 1 4v5q-1.402-1.602-1-4-5.116.175-4-5"></path>
    <path fill="#80adf3" d="M405.5 603.5q2.688-.201 5 1v13h-1v-9h-4v-5"></path>
    <path fill="#8ab7ef" d="M103.5 603.5v10h-4v9h-1q-.095-7.055 1-14h3q-.426-2.872 1-5"></path>
    <path fill="#bbd8ed" d="M112.5 608.5q.476 4.57-4 5v-5z"></path>
    <path
      fill="#b0cdf3"
      d="M165.5 603.5q1.401 1.602 1 4h3q.388-5.352 6-4-1.602 1.401-4 1v4h4v5h-5v-4h-5v-6"></path>
    <path
      fill="#4882d7"
      d="M227.5 608.5v5h-4v9h-3q-.305-2.835 2-4.5-1.937-5.183 0-10.5a9.1 9.1 0 0 0 5 1"></path>
    <path
      fill="#427fd4"
      d="M300.5 604.5v4q-1 0-1 1h-4v4q-1.426 2.128-1 5h-4v29h4q-.401 2.398 1 4v4q-1.356-1.063-1-3-4.188-.183-6-4-.25-17.507.5-35a22.2 22.2 0 0 1 2.5-6q3.894 1.07 8 0-.356-1.937 1-3"></path>
    <path
      fill="#94baef"
      d="M357.5 608.5q1.812 1.1 4 1-.356 1.937 1 3v6h-1v-5q-4.455-.369-4-5"></path>
    <path
      fill="#7da7e1"
      d="M486.5 608.5q-.442 4.472-4 2-9.217-1.485-19-1v13q2.398-.401 4 1h-5v-1a47 47 0 0 1-2-9q2.634-1.578 2-5z"></path>
    <path fill="#96c0eb" d="M557.5 594.5h1v9h-4v33h-1v-37h4z"></path>
    <path fill="#71a1eb" d="M204.5 608.5h4v5q-2.188.1-4-1z"></path>
    <path fill="#8fb6e8" d="M242.5 608.5q9.783-.485 19 1h-19v4h-5v-5z"></path>
    <path fill="#87b9e7" d="M299.5 609.5v4h-4v-4z"></path>
    <path fill="#5e97e3" d="M256.5 609.5h5v4h-5z"></path>
    <path fill="#8bb9ef" d="M429.5 608.5h4q.196 4.3-4 5z"></path>
    <path
      fill="#3b78d1"
      d="M532.5 574.5a85 85 0 0 1 13 .5l1.5 1.5a7584 7584 0 0 1 .5 75.5l-.5 6.5q-1.006 1.129-2.5 1 .497-42.258-1-84h-9v4h-7q2.128-1.426 5-1z"></path>
    <path
      fill="#cce3f8"
      d="M70.5 608.5h-1v-9h-8v4q-1.465 4.18-1 9v5q-2.313 1.201-5 1v-6h4v-17q5.93.231 11 3z"></path>
    <path fill="#9cc6ed" d="M69.5 603.5h-8v-4h8z"></path>
    <path fill="#6f9fe0" d="M175.5 609.5v-1h16q1.063 1.356 3 1v4q-5.174 1.115-5-4z"></path>
    <path
      fill="#72a0e7"
      d="M314.5 608.5h19v5q-3.921.334-5-3.5a98.4 98.4 0 0 0-14-.5v4h-5v-5z"></path>
    <path fill="#5d9eee" d="M486.5 608.5h5v5h-9v-3q3.558 2.472 4-2"></path>
    <path fill="#aacdf0" d="M22.5 593.5q1.812 1.1 4 1-.402 2.398 1 4v15h4v5h-5v-19h-4z"></path>
    <path fill="#cbddfb" d="M70.5 613.5h5v9q-1.401-1.602-1-4-2.188.1-4-1z"></path>
    <path fill="#7cb3ea" d="M79.5 603.5q1.231 4.712 1.5 10 4.678 3.79 3.5 10h-1v-5h-4v-15"></path>
    <path fill="#bed5fa" d="M227.5 608.5h5q-1.602 1.401-4 1a41.7 41.7 0 0 1-1 9h-4v-5h4z"></path>
    <path
      fill="#5087d3"
      d="M113.5 599.5h1a124 124 0 0 1-1 21q-1.46 1.937-3 0a247 247 0 0 1-2 7v-9q1.812-1.1 4-1 .694-4.473 1-9z"></path>
    <path fill="#94c7f7" d="M233.5 613.5h4v5h-4z"></path>
    <path
      fill="#578ee2"
      d="M367.5 599.5h-10v9q-.455 4.631 4 5v5q-1.812-1.1-4-1a124 124 0 0 1-1-19q5.807-.473 11 1"></path>
    <path fill="#95c3f0" d="M453.5 608.5v15h-5v-1q-1.792-8.78 5-14"></path>
    <path fill="#88b7ea" d="M112.5 608.5h1a105 105 0 0 1-1 9q-2.188-.1-4 1v-5q4.476-.43 4-5"></path>
    <path fill="#b4d7fb" d="M170.5 613.5v5h-4v43h-1v-1a485 485 0 0 1 1-44q1.714-1.989 4-3"></path>
    <path
      fill="#3675dd"
      d="M237.5 649.5q0-2-2-2a177 177 0 0 1-1-26q1.983-.255 3.5 1 1.679-6.837 5.5-12 5.097-.48 10 1l2 3q-4.509 1.872-9 0-2.417 1.23-2 4 7.272.211 14 0 5.476 3.204 1 8 2.86 9.676 2 20-2.746-.828-5 0a16.9 16.9 0 0 0-1 8h-12q.415-7.108-6-5"></path>
    <path fill="#679dd6" d="M262.5 613.5h4v10q-.517-5.542-4-10"></path>
    <path
      fill="#d1e4f6"
      d="M395.5 603.5q4.82-.465 9 1h-8v11q3.447-1.216 7-2.5-.237-2.38 2-4.5h4v6q-4.5-.5-4 4h-5v4h-5v-3h-4a28.3 28.3 0 0 1 4-8z"></path>
    <path
      fill="#d4e7f9"
      d="M558.5 603.5a66.8 66.8 0 0 1-2 16 508 508 0 0 1 2 20q-1.451-1.96-4-2v-34z"></path>
    <path fill="#74a6eb" d="M27.5 608.5q2.927-.41 4.5 2a61 61 0 0 1 .5 11v6h-1v-14h-4z"></path>
    <path fill="#9cc8ee" d="M194.5 613.5q3.422-.634 5 2v3h-4q0-1-1-1z"></path>
    <path fill="#dbe6fb" d="M103.5 603.5h1v16q-2.687.201-5-1v-5h4z"></path>
    <path
      fill="#f8fdfe"
      d="M238.5 604.5h7v3q-1.937-.356-3 1h-5v5h-4q-2.427 4.007-2 9h-4a156 156 0 0 0 1 23q.468 4.812-3.5 3-.75-14.991-.5-30h3a41.7 41.7 0 0 0 1-9q2.398.401 4-1 1.401-1.602 1-4z"></path>
    <path
      fill="#3e7bda"
      d="M410.5 604.5q2.648 3.935 2 9h3a218 218 0 0 1 1.5 14q-.317-10.4 9.5-12-.329 1.762 1 3a624 624 0 0 1 2-10v12q-5.714 4.452-5 12v3q-1.452 3.161-1 7h-4v-5h-4a72.4 72.4 0 0 0-.5-12q-1.93-4.379-4.5-8z"></path>
    <path
      fill="#b3d7f0"
      d="M458.5 613.5h2a47 47 0 0 0 2 9q-1.983-.255-3.5 1-2.526-5.091-.5-10"></path>
    <path fill="#6198df" d="M50.5 613.5q-3.394 4.41-4 10v3h-1v-9q.65-4.138 5-4"></path>
    <path fill="#99c3ea" d="M50.5 613.5h1l-1 10h-4q.606-5.59 4-10"></path>
    <path
      fill="#3b79d7"
      d="M256.5 609.5v4h6q3.483 4.458 4 10v24h-5v4q-5.174-1.115-5 4v1h-13q-1.201-2.312-1-5h-5v-2q6.415-2.108 6 5h12a16.9 16.9 0 0 1 1-8q2.254-.828 5 0 .86-10.324-2-20 4.476-4.796-1-8-6.728.211-14 0-.417-2.77 2-4 4.491 1.872 9 0l-2-3a25.9 25.9 0 0 0-10-1q-3.821 5.163-5.5 12-1.517-1.255-3.5-1-.455 13.127 1 26-2.96-13.913-2-29h4v-5h5v-4z"></path>
    <path
      fill="#6398dd"
      d="M347.5 609.5q1.401 1.602 1 4h5v6q.25 15.508-.5 31-2.034 1.32-4.5 1v-4h4q.25-14.009-.5-28-1.517-1.255-3.5-1-1.426-2.128-1-5z"></path>
    <path
      fill="#7eaef7"
      d="M543.5 575.5a1765 1765 0 0 1 1 84v2q.32 2.466-1 4.5-4.3 1.201-8-.5 5.523 1.162 7.5-4 .75-42.997.5-86"></path>
    <path fill="#bbd2ec" d="M79.5 618.5h4v5q-5.115.175-4-5"></path>
    <path
      fill="#f5fcfd"
      d="M199.5 604.5v4h5v10a256 256 0 0 1 2.5 10q.75 15.492.5 31h-7a485 485 0 0 0-1-44q-1.578-2.634-5-2v-4q-1.937.356-3-1-1.1-1.812-1-4z"></path>
    <path fill="#92c3f1" d="M295.5 613.5v9q-2.687-.201-5 1v-5h4q-.426-2.872 1-5"></path>
    <path fill="#719cdf" d="M309.5 613.5a653 653 0 0 0-5 9v-9z"></path>
    <path
      fill="#3676dc"
      d="M333.5 613.5q1.602 1.401 4 1a189 189 0 0 1 0 37h-4v4h-18v-3h-6v-1q-1.025-2.514-4-2a305 305 0 0 1-1-26v-1a653 653 0 0 1 5-9h5v-4a98.4 98.4 0 0 1 14 .5q1.079 3.834 5 3.5"></path>
    <path fill="#5e99e6" d="M371.5 611.5q.775 6.313 4 12h-3q0-1-1-1v-11"></path>
    <path fill="#8bbce8" d="M405.5 618.5v5h-9v5h-1v-6h5v-4z"></path>
    <path fill="#9dc5f7" d="M395.5 611.5a28.3 28.3 0 0 0-4 8v8h-1v-14q3.02.162 5-2"></path>
    <path fill="#8babd5" d="M530.5 589.5h4v72q-1-35.255-2-71-.961 2.24-3 3-.1-2.188 1-4"></path>
    <path fill="#bfddf8" d="M271.5 570.5h5v91q.174 5.115-5 4v-4h4v-86h-4z"></path>
    <path fill="#588dde" d="M279.5 570.5q1.999 23.229 2 47.5 0 24.006-1 47.5.488-47.61-1-95"></path>
    <path fill="#f2fcfd" d="M391.5 619.5h4v13h-4v-13"></path>
    <path fill="#85b2ef" d="M60.5 617.5v6h-4q.401 2.398-1 4v-9q2.687.201 5-1"></path>
    <path
      fill="#b8d6f8"
      d="M304.5 618.5v5h-4v19q5.115-.175 4 5-2.466.32-4.5-1-1-12 0-24 .168-4.42 4.5-4"></path>
    <path
      fill="#4883d9"
      d="M69.5 608.5h1v15h4v16q-.925-.166-1.5-1-4.113-14.093-4.5-29-1.936-.356-3 1-1.602 1.401-4 1v-3z"></path>
    <path fill="#96c2f0" d="M266.5 623.5h4v18h-4z"></path>
    <path fill="#76b0e1" d="M372.5 623.5h4v4h-4z"></path>
    <path fill="#5d97e4" d="M405.5 623.5v19q-1.239-7.224-1.5-15-3.572 1.402-7.5 1v-5z"></path>
    <path fill="#6ea4e0" d="M429.5 620.5v7h-4v5h-1q-.714-7.548 5-12"></path>
    <path fill="#588fde" d="M448.5 623.5h5v4q0 5 5 5h4v5q-4.2-3.606-10-5v-5h-4z"></path>
    <path fill="#aed5f9" d="M362.5 618.5q1.401 1.602 1 4h4v13q-1.356-1.063-1-3v-5h-4z"></path>
    <path
      fill="#8bb3ea"
      d="M410.5 617.5q2.57 3.621 4.5 8 .749 5.979.5 12h-1v-10h-3a77 77 0 0 1-1-10"></path>
    <path fill="#abd1e9" d="M462.5 623.5h5v4h-5z"></path>
    <path
      fill="#3a77d8"
      d="M65.5 610.5q1.064-1.356 3-1 .387 14.907 4.5 29 .575.834 1.5 1 1.356 1.063 1 3h4v10a32.4 32.4 0 0 0 .5 8 21.7 21.7 0 0 1 4 7 62.8 62.8 0 0 1 15.5-1v-14q2.466.32 4.5-1 .749-6.982.5-14h4v-10a247 247 0 0 0 2-7q1.54 1.937 3 0a589 589 0 0 0-5 23h-3a41 41 0 0 1-.5 9q-1.665 2.305-4.5 2-.746 6.389 2 12a34.6 34.6 0 0 1-11 2.5l6 2q-9.608 2.18-17-3.5-2.043-2.268-1-4.5-.075-12.045-6-22.5-2.014 2.103-2.5-1a256 256 0 0 1-3.5-25.5q-.162-2.522-2-3.5"></path>
    <path
      fill="#79aae2"
      d="M467.5 623.5q3.637 4.893 10 4.5 2.999 4.935 9 4.5v5q-1.401-1.602-1-4a34 34 0 0 1-8-1.5q-1.255-1.517-1-3.5-4.82.465-9-1z"></path>
    <path
      fill="#69a0e6"
      d="M131.5 599.5v4h-3v58h4v4a9.86 9.86 0 0 0 6-1 5.58 5.58 0 0 1-1-3h4v-58h-4v-4h5q.25 32.004-.5 64a9.5 9.5 0 0 1-1.5 2.5 61 61 0 0 1-11 .5q.11-2.29-2-3v-64z"></path>
    <path fill="#ddf1fd" d="M137.5 603.5v58h-1v-57h-3q-.951 28.852 2 57h-3v-58z"></path>
    <path fill="#90b6e9" d="M131.5 599.5h6v4h-5v58h-4v-58h3z"></path>
    <path fill="#9cc4f3" d="M137.5 603.5h4v58h-4z"></path>
    <path fill="#f8fefe" d="M136.5 661.5h-1q-2.951-28.148-2-57h3z"></path>
    <path
      fill="#bcdcf8"
      d="M348.5 618.5q1.983-.255 3.5 1 .75 13.991.5 28h-4v4h-1a55 55 0 0 1 1-12h3v-17h-3z"></path>
    <path
      fill="#f0f8fd"
      d="M366.5 604.5q.306 4.527 1 9h4v9a19.5 19.5 0 0 0 1 7.5q1.284 2.392 4 2.5v10q.306 4.527 1 9 3.63-.312 7 1a21.1 21.1 0 0 1 1 9h-9v-6h-4v-3a85 85 0 0 0-.5-13q-1.573-2.41-4.5-2v-15h-4q.401-2.398-1-4v-14z"></path>
    <path fill="#7db5e9" d="M32.5 627.5h4v5h-4z"></path>
    <path
      fill="#a3c8f2"
      d="M108.5 627.5v5q-1.812 1.1-4 1 .356 1.937-1 3a32.5 32.5 0 0 1 .5-8q2.034-1.32 4.5-1"></path>
    <path
      fill="#c3e3f8"
      d="M295.5 622.5q.509 7.196-3 13.5 3.646 4.613 3 10.5a26 26 0 0 1-5-5v-18q2.313-1.201 5-1"></path>
    <path
      fill="#99c2ee"
      d="M338.5 618.5h5q.25 14.009-.5 28-1.517 1.255-3.5 1h-1v-5h4q.25-9.513-.5-19-2.778-1.793-3.5-5"></path>
    <path
      fill="#b2d6f4"
      d="M371.5 622.5q1 0 1 1v4h4v5q-2.716-.108-4-2.5a19.5 19.5 0 0 1-1-7.5"></path>
    <path
      fill="#f0f9fd"
      d="M409.5 614.5v3a61 61 0 0 0 .5 11l1.5 1.5q-2.183 3.024-6 2.5v-14q-.5-4.5 4-4"></path>
    <path
      fill="#3775dc"
      d="M452.5 632.5q5.8 1.394 10 5h5q1.602 1.401 4 1v4h5q-1.557 6.443 0 13-8.715 1.485-18 1v-4q2.179-1.987-1-2.5a66 66 0 0 1-10-1.5q-2.5 1.5-4 4-9.909-1.223-6.5-11 1.573-2.41 4.5-2v-4a8.4 8.4 0 0 0 4-.5q-2-1 0-2l-4-2a32.8 32.8 0 0 0 3.5-5.5q2.25 1.25 3.5 3.5a4.93 4.93 0 0 0 3 .5q-.356 1.937 1 3"></path>
    <path fill="#88bbe3" d="M453.5 627.5h5v5q-5 0-5-5"></path>
    <path fill="#b9dbfa" d="M31.5 627.5h1v5h4v5q-6.39-2.779-5-10"></path>
    <path fill="#689be7" d="M361.5 618.5h1v9h4v5h-4l-1-2z"></path>
    <path fill="#a3cce5" d="M429.5 627.5v5h-4v-5z"></path>
    <path
      fill="#77a3ec"
      d="M439.5 624.5v3q-.261 4.895-5 5 .46 4.328-1 8v-13h5q-.356-1.937 1-3"></path>
    <path
      fill="#bdd7f3"
      d="M539.5 661.5v-82h-4q2.593-3.34 7-3 .5 41.512-1 83a31 31 0 0 1-2 2"></path>
    <path
      fill="#4580d7"
      d="M85.5 627.5q4.133 1.131 8 0v15q-2.188.1-4-1a70.4 70.4 0 0 1-4-14"></path>
    <path
      fill="#b3d6f4"
      d="M98.5 622.5h1a50.4 50.4 0 0 1-.5 10 44 44 0 0 1-4.5 4q1.075-7.494 4-14"></path>
    <path
      fill="#3979d9"
      d="M405.5 642.5h4v3q-.384 5.654 1 11a28.3 28.3 0 0 0 4 8q-3.375.478-6-1.5l1-1q-.964-7.176-2-14.5-2.433-2.908-5.5-1a4.93 4.93 0 0 1-.5-3q-3.081-.202-5 2v-17q3.928.402 7.5-1 .261 7.776 1.5 15"></path>
    <path fill="#e3f4fb" d="M157.5 603.5h8v1h-4q-.25 27.505.5 55 1.517 1.255 3.5 1v1h-8z"></path>
    <path
      fill="#dff4fe"
      d="M199.5 615.5a485 485 0 0 1 1 44h7q.25-15.508-.5-31a256 256 0 0 0-2.5-10q1.983.255 3.5-1 .75 21.995.5 44h-9v-46"></path>
    <path
      fill="#c5dbf3"
      d="M409.5 617.5h1q.174 5.04 1 10h3v5q-1.983-.255-3.5 1a72.4 72.4 0 0 0-.5 12h-1v-3q1.352-5.611-4-6v-4q3.817.524 6-2.5l-1.5-1.5a61 61 0 0 1-.5-11"></path>
    <path
      fill="#f0fcfd"
      d="M438.5 612.5a50.5 50.5 0 0 1-.5 10q-2.034 1.32-4.5 1v13h-4v-17q1.812-1.1 4-1 .94-6.961 1-14 1.983-.255 3.5 1 .745 3.465.5 7"></path>
    <path fill="#95beeb" d="M276.5 572.5q1.999 21.727 2 44.5-.001 22.773-2 44.5z"></path>
    <path
      fill="#6a9dda"
      d="M304.5 623.5a305 305 0 0 0 1 26q2.975-.514 4 2h-5v-4q1.115-5.175-4-5v-19z"></path>
    <path
      fill="#ecf7fc"
      d="M592.5 575.5v4a41.7 41.7 0 0 0 9 1v9h4q-.465 4.82 1 9 1.602 1.401 4 1v33h-3a45 45 0 0 0-1 10q-1.465 4.18-1 9h-4v5a51 51 0 0 0-10.5 4l-3.5-.5q2.39-1.423 4-3.5-1-6 5-5v-5q1.401-1.602 1-4h4v-48h-5v-5h-5v-5h-4v-5h-10v5q-2.398-.401-4 1-1.356 1.063-1 3-2.398-.401-4 1-1.465 4.18-1 9h-4q-1.935 21.71 0 43 1.602 1.401 4 1v10h5v5h5q.332 1.852 2 3a16.9 16.9 0 0 0 8 1v4h-14q.101-4.899-5-4.5-2.25-3.25-5.5-5.5a4.93 4.93 0 0 1-.5-3h-4v-13a508 508 0 0 0-2-20 66.8 66.8 0 0 0 2-16v-14h5v-5q6 1 5-5h4v-4z"></path>
    <path
      fill="#6ea3e0"
      d="M567.5 603.5a41 41 0 0 1-.5 9l-2 2a163 163 0 0 0-.5 18h-1a239 239 0 0 1 1-29z"></path>
    <path
      fill="#4985d8"
      d="M591.5 589.5h5v5a74.8 74.8 0 0 0 3 23 2648 2648 0 0 1-3 29v5q-6-1-5 5h-18v-4q1.411 3.938 3.5 0a4.46 4.46 0 0 1 1.5 2 36.9 36.9 0 0 0 12 1l1-8h4a1929 1929 0 0 0 0-56q-2.975.514-4-2"></path>
    <path fill="#73a9df" d="M597.5 599.5h4v43h-4q-.654-4.296 2.5-7 .75-15.992.5-32h-3z"></path>
    <path
      fill="#589be7"
      d="M596.5 594.5h1v9h3q.25 16.008-.5 32-3.154 2.704-2.5 7 .401 2.398-1 4a2648 2648 0 0 0 3-29 74.8 74.8 0 0 1-3-23"></path>
    <path
      fill="#5492e3"
      d="M568.5 599.5v47h-1v-4q-.171-5.567-3-10-.249-9.014.5-18l2-2q.747-4.473.5-9-.401-2.398 1-4"></path>
    <path
      fill="#3876dc"
      d="M577.5 584.5h14v5q1.025 2.514 4 2a1929 1929 0 0 1 0 56h-4l-1 8a36.9 36.9 0 0 1-12-1 4.46 4.46 0 0 0-1.5-2q-2.089 3.938-3.5 0 0-1-1-1 1.115-5.175-4-5v-52q3.745-.944 4-5 1.401-1.602 1-4 1.602-1.401 4-1"></path>
    <path
      fill="#b0d1f5"
      d="M568.5 589.5v10q-1.401 1.602-1 4h-3a239 239 0 0 0-1 29v9q-1.935-21.29 0-43h4q-.465-4.82 1-9"></path>
    <path fill="#c4dcf9" d="M597.5 594.5h4v5h-4z"></path>
    <path fill="#6794e1" d="M568.5 589.5h4q-.255 4.056-4 5z"></path>
    <path fill="#aed1f2" d="M573.5 585.5q.401 2.398-1 4h-4q1.602-1.401 4-1-.356-1.937 1-3"></path>
    <path fill="#b9d3f6" d="M587.5 584.5h-10v-5h10z"></path>
    <path fill="#abc6ed" d="M462.5 632.5h5v5h-5z"></path>
    <path fill="#82b4ec" d="M23.5 632.5h3a76 76 0 0 1 1 15h-1v-11h-3z"></path>
    <path
      fill="#8fbaeb"
      d="M45.5 626.5h1a61 61 0 0 1-.5 11q-1.517 1.255-3.5 1 .401 2.398-1 4v-10h4z"></path>
    <path
      fill="#95b7e3"
      d="M204.5 612.5q1.812 1.1 4 1a577 577 0 0 1 1 48q.401 2.398-1 4h-9v-4h9q.25-22.005-.5-44-1.517 1.255-3.5 1z"></path>
    <path
      fill="#eff8fc"
      d="M465.5 604.5q1.314-.197 2 1a22.1 22.1 0 0 1-6 3q-.847 3.014-3 5-2.026 4.909.5 10 1.517-1.255 3.5-1v5h5q4.18 1.465 9 1-.255 1.983 1 3.5a34 34 0 0 0 8 1.5q-.401 2.398 1 4 .263 3.516 3.5 5 2.256 8.235-3.5 14h-3a228 228 0 0 1-1.5-15q-5.74-6.32-14.5-5v-4h-9v-5h-5v-18h5v-5z"></path>
    <path fill="#679de2" d="M98.5 622.5q-2.925 6.506-4 14v1h-1v-10q1.426-2.128 1-5z"></path>
    <path
      fill="#4b86d5"
      d="M376.5 623.5q1.051 6.34 2.5 13a91 91 0 0 1 6.5-1v7q-8.11.87-9-7v-12"></path>
    <path
      fill="#d1e7fb"
      d="M424.5 632.5h5v4h4v6h-4v14h-5q1.419-2.146 3.5-4a33.2 33.2 0 0 0-.5-14q-.962 2.24-3 3v-9"></path>
    <path fill="#bad1f0" d="M610.5 632.5a3718 3718 0 0 0-4 10 45 45 0 0 1 1-10z"></path>
    <path
      fill="#3c7bd8"
      d="M12.5 603.5h4q-.402 2.398 1 4v16h4q.416 7.1-1 14 1.753 2.576 5 2-.356 1.937 1 3v5q-2.593-2.18-3-6l-1-1a6.8 6.8 0 0 0-1.5 3l-2.5-5a18.35 18.35 0 0 0 2-9q-1.983.255-3.5-1-.255-9.539-1.5-19-3.717-1.833-3-6"></path>
    <path fill="#5192d8" d="M170.5 618.5a487 487 0 0 0-1 43h-3v-43z"></path>
    <path fill="#82b3ed" d="M220.5 622.5h3v24q-3.414-.385-3.5-4-.75-9.487-.5-19 0-1 1-1"></path>
    <path
      fill="#5791dd"
      d="M17.5 614.5q3.732 3.231 5 8v1q-.465 4.82 1 9v4h3v6q-1.356-1.063-1-3-3.247.576-5-2a53.8 53.8 0 0 0 1-14h-4z"></path>
    <path
      fill="#76aeec"
      d="M70.5 617.5q1.812 1.1 4 1-.401 2.398 1 4v14q5.352.389 4 6h-4q.356-1.937-1-3v-16h-4z"></path>
    <path fill="#b4d4ef" d="M232.5 623.5v18h-4a57 57 0 0 1 1.5-17l1 3q.315-2.337 1.5-4"></path>
    <path
      fill="#bbd9f9"
      d="M266.5 618.5q1.812 1.1 4 1-.356 1.937 1 3v20h-4q.401 2.398-1 4v-5h4v-18h-4z"></path>
    <path fill="#97bee6" d="M554.5 637.5q2.549.04 4 2v7q-1.401-1.602-1-4h-3z"></path>
    <path
      fill="#6491db"
      d="M167.5 598.5q-.393 2.217 1 4 3.544-1.005 7-2v3q-5.612-1.352-6 4h-3q.401-2.398-1-4v-4h-9v66h9v1h-10v-68z"></path>
    <path fill="#9cc2f2" d="M165.5 603.5h-9v-4h9z"></path>
    <path fill="#c9d9f5" d="M367.5 637.5q2.927-.41 4.5 2 .749 6.481.5 13h-1v-10h-4z"></path>
    <path
      fill="#f2fcfd"
      d="M390.5 643.5v3q-2.39 1.272-3.5 4a49.2 49.2 0 0 1 0-14q4.566 1.954 3.5 7"></path>
    <path fill="#97c3f1" d="M563.5 632.5h1q2.829 4.433 3 10-2.398.401-4-1z"></path>
    <path
      fill="#94baf0"
      d="M610.5 599.5h1q.25 21.506-.5 43-3.781 3.996-5.5 9-.465-4.82 1-9 1.976-4.961 4-10z"></path>
    <path
      fill="#4d86da"
      d="M32.5 621.5q2.71 2.004 4.5 5 .547 7.315 3 14 .417-1.374 1.5-2v4q-1.401 1.602-1 4h-3q.473-5.807-1-11v-8h-4z"></path>
    <path
      fill="#aac7ed"
      d="M84.5 627.5h1a70.4 70.4 0 0 0 4 14v5h4v-9h1v14h-5a773 773 0 0 1-1.5-13q-1.72 1.437-3-1a50.4 50.4 0 0 1-.5-10"></path>
    <path
      fill="#5f95e2"
      d="M333.5 613.5h5v5q.722 3.207 3.5 5 .75 9.487.5 19h-4v5q.401 2.398-1 4a189 189 0 0 0 0-37q-2.398.401-4-1"></path>
    <path fill="#7ebae5" d="M395.5 637.5v5h-4v-5z"></path>
    <path fill="#7cb1ea" d="M405.5 636.5q5.352.389 4 6h-4z"></path>
    <path fill="#8ec0f5" d="M472.5 637.5h4v4-4z"></path>
    <path
      fill="#dbebf7"
      d="M22.5 623.5q1.402 1.602 1 4 1.983-.255 3.5 1a67.4 67.4 0 0 1 1.5 13h4v6h-5a76 76 0 0 0-1-15h-3q-1.465-4.18-1-9"></path>
    <path
      fill="#f2fcfe"
      d="M414.5 632.5v15q-2.188.1-4-1v-1a72.4 72.4 0 0 1 .5-12q1.517-1.255 3.5-1"></path>
    <path fill="#afc9ed" d="M103.5 642.5q.255 6.053-4 10v9h-1v-19z"></path>
    <path
      fill="#7ab4eb"
      d="M424.5 635.5v8q-1.401 1.602-1 4h-4q.334-3.921-3.5-5a12.9 12.9 0 0 1-.5-5h4v5h4q-.452-3.839 1-7"></path>
    <path
      fill="#e1f1fb"
      d="M233.5 613.5v5q-1.426 2.128-1 5-1.185 1.663-1.5 4l-1-3a57 57 0 0 0-1.5 17h4v10a219 219 0 0 1-2.5-6 25.2 25.2 0 0 0 0 10 36 36 0 0 0 4.5 3.5l-1 1q2.337.315 4 1.5h-5q-1.144-1.152-3-1.5l1-1q-2.926-2.601-3-6.5h-4v-34h1q-.25 15.009.5 30 3.968 1.812 3.5-3a156 156 0 0 1-1-23h4q-.427-4.993 2-9"></path>
    <path fill="#86bcf7" d="M290.5 641.5a26 26 0 0 0 5 5v5q-1.401-1.602-1-4h-4z"></path>
    <path fill="#7ab1ed" d="M367.5 642.5h4v5q-1.812-1.1-4-1z"></path>
    <path fill="#7aa9e8" d="M433.5 642.5v4q-2.188-.1-4 1v-5z"></path>
    <path
      fill="#83addf"
      d="M36.5 635.5q1.473 5.193 1 11h3q-.401-2.398 1-4v5h-4q-1.47-4.687-1-10z"></path>
    <path fill="#6699db" d="M89.5 641.5q1.812 1.1 4 1v4h-4z"></path>
    <path
      fill="#669dea"
      d="M108.5 632.5v5h-4a98.4 98.4 0 0 1-.5 14q-2.034 1.32-4.5 1 4.255-3.947 4-10v-6q1.356-1.063 1-3 2.188.1 4-1"></path>
    <path
      fill="#7eadeb"
      d="M376.5 635.5q.89 7.87 9 7v4a12.9 12.9 0 0 1-5-.5 19.6 19.6 0 0 0-4-3.5z"></path>
    <path
      fill="#5993dc"
      d="M395.5 628.5h1v17q-2.312 1.201-5 1a61 61 0 0 1-.5 11 9.2 9.2 0 0 1-4.5 2v-3h4v-13q1 0 1-1h4v-14"></path>
    <path
      fill="#4888e5"
      d="M194.5 617.5q1 0 1 1v1a56.5 56.5 0 0 1 2 17 113.3 113.3 0 0 0 0 19l-2 4a13.14 13.14 0 0 1 2 6 44 44 0 0 1-2-1 553 553 0 0 1-1-47"></path>
    <path
      fill="#f4fbfd"
      d="M347.5 613.5q-.426 2.872 1 5v4h3v17h-3a55 55 0 0 0-1 12h-4v5h-5q.694-4.473 1-9 1.983.255 3.5-1 .75-13.991.5-28h-5v-5q-1.368-1.858-.5-4 2.439.531 5.5 0v4z"></path>
    <path
      fill="#c6def4"
      d="M414.5 637.5h1a12.9 12.9 0 0 0 .5 5q3.834 1.079 3.5 5h4q-.401-2.398 1-4v8h-14v-5q1.812 1.1 4 1z"></path>
    <path
      fill="#c6e0f8"
      d="M50.5 646.5v5h-4v6q.401 2.398-1 4-.465-7.15 1.5-14 1.517-1.255 3.5-1"></path>
    <path
      fill="#f0f9fd"
      d="M304.5 604.5a85 85 0 0 1 13 .5q.686.621 1 1.5a71 71 0 0 0-4 2h-5v5h-5v5q-4.332-.42-4.5 4-1 12 0 24 2.034 1.32 4.5 1v4h5v5h5q1.663 1.185 4 1.5 1.255 1.517 1 3.5 5.09-.657 9 2.5-.834.575-1 1.5h-13q.255-1.983-1-3.5a41 41 0 0 0-9-.5q1.115-5.175-4-5v-5h-5v-5q.646-5.887-3-10.5 3.509-6.304 3-13.5v-9h4v-4q0-1 1-1 1.494.129 2.5-1 .348-1.855 1.5-3"></path>
    <path
      fill="#5993df"
      d="M467.5 637.5h5v4h4q2.313 1.201 5 1v10q-3.689-.285-5 3-1.557-6.557 0-13h-5v-4q-2.398.401-4-1"></path>
    <path fill="#a5c5ec" d="M27.5 647.5h5v14h-1v-10h-4z"></path>
    <path
      fill="#5692e2"
      d="M219.5 623.5q-.25 9.513.5 19 .086 3.615 3.5 4v8q-2.634-1.578-2-5a24.3 24.3 0 0 1-2.5-6q-1.231-10.27.5-20"></path>
    <path fill="#b1d0f8" d="M567.5 646.5h1v5h4q1 0 1 1v4h14q-4.687 1.469-10 1h-5v-5h-5z"></path>
    <path fill="#afd3ee" d="M37.5 647.5h4v4h-4z"></path>
    <path fill="#87b3e6" d="M233.5 618.5q-.96 15.087 2 29 2 0 2 2v2h-5v-28q-.426-2.872 1-5"></path>
    <path fill="#72a8e2" d="M266.5 647.5v4h-5v-4z"></path>
    <path
      fill="#b8d6f2"
      d="M390.5 627.5h1v5h4v5h-4v5q0 1-1 1 1.066-5.046-3.5-7a49.2 49.2 0 0 0 0 14q1.11-2.728 3.5-4v10h-4v5h-1a21.1 21.1 0 0 0-1-9 15.4 15.4 0 0 0-7-1 105 105 0 0 1-1-9 19.6 19.6 0 0 1 4 3.5 12.9 12.9 0 0 0 5 .5v-15q1.401-1.602 1-4z"></path>
    <path fill="#6392c9" d="M568.5 646.5q5.115-.175 4 5h-4z"></path>
    <path
      fill="#3979dc"
      d="M362.5 632.5h4q-.356 1.937 1 3v11q2.188-.1 4 1v5a101 101 0 0 1 0 11.5 12.6 12.6 0 0 0 4 2.5q-3.793 1.423-7-2-3.384 5.701-10 7-4.5-.5-5-5 .513-3.55 1.5-7a8.4 8.4 0 0 1 .5 4q4.244-.291 8-2a80.5 80.5 0 0 1 2-22.5q-.228-3.974-3-6.5"></path>
    <path
      fill="#3a78da"
      d="M448.5 622.5v5h4v5q-1.356-1.063-1-3a4.93 4.93 0 0 1-3-.5q-1.25-2.25-3.5-3.5a32.8 32.8 0 0 1-3.5 5.5l4 2q-2 1 0 2a8.4 8.4 0 0 1-4 .5v4q-2.927-.41-4.5 2-3.409 9.777 6.5 11 1.5-2.5 4-4a66 66 0 0 0 10 1.5q3.179.513 1 2.5-4.842-1.37-10-1v10h4a9.2 9.2 0 0 0 2 4.5 138 138 0 0 0 27-.5q-2.818 2.108-1 5-4.5 2-9 0a6.8 6.8 0 0 1 3-1.5 103.4 103.4 0 0 0-23-1.5 9.86 9.86 0 0 1-1 6q-2.012-2.258-5-2 .393-2.217-1-4-5.664-7.131-10.5 0a202 202 0 0 0-1.5-20q-2.125 3.888-3 8v-8q1.812-1.1 4-1v-6q1.46-3.672 1-8 4.739-.105 5-5 1.452-3.161 1-7 2.682.428 5-1-1.784-6.475 0-13 2.217-.393 4 1-3.145 7.536-1 15"></path>
    <path
      fill="#a2c8f4"
      d="M75.5 632.5q2.466-.32 4.5 1 .75 8.986.5 18h4v5q-3.806-.496-5-4v-10q1.352-5.611-4-6z"></path>
    <path fill="#6d9ed8" d="M195.5 619.5v-1h4v43a35911 35911 0 0 1-2-41 44 44 0 0 0-2-1"></path>
    <path
      fill="#4e88d6"
      d="M553.5 636.5h1v6h3q-.401 2.398 1 4v6q-.5 4.5 4 4v3a744 744 0 0 1-6-4q.818-5.247-2.5-9a50.5 50.5 0 0 1-.5-10"></path>
    <path
      fill="#488be0"
      d="M170.5 613.5h5q-1.602 1.401-4 1v17q-1.981 16.308 0 33a20.8 20.8 0 0 1-6 2v-5h4a487 487 0 0 1 1-43z"></path>
    <path
      fill="#4d8adb"
      d="M195.5 619.5q.975.456 2 1a35911 35911 0 0 0 2 41v4h9q-5.348 1.93-11 0a13.14 13.14 0 0 0-2-6l2-4a113.3 113.3 0 0 1 0-19 56.5 56.5 0 0 0-2-17"></path>
    <path fill="#72a2de" d="M237.5 651.5h5q-.201 2.688 1 5h-6z"></path>
    <path fill="#619de7" d="M261.5 651.5v4h-5q-.174-5.115 5-4"></path>
    <path fill="#b6dbf3" d="M295.5 651.5h5v5q-2.872.426-5-1z"></path>
    <path
      fill="#c9dff8"
      d="M338.5 647.5h1a105 105 0 0 1-1 9h4v4h-4q.174-5.115-5-4v-5h4q1.401-1.602 1-4"></path>
    <path
      fill="#91bded"
      d="M55.5 632.5v4q-1.983-.255-3.5 1a390 390 0 0 1-1.5 19q-2.188-.1-4 1v-6h4v-5a68.2 68.2 0 0 1 1-14z"></path>
    <path fill="#80a7d5" d="M309.5 652.5h1a2.43 2.43 0 0 0 .5 2 19.6 19.6 0 0 1 3.5 2h-5z"></path>
    <path
      fill="#5688d3"
      d="M310.5 652.5h5v3h18v1h-19a19.6 19.6 0 0 0-3.5-2 2.43 2.43 0 0 1-.5-2"></path>
    <path fill="#a1d1f9" d="M347.5 651.5v5h-4v-5z"></path>
    <path
      fill="#a8cef6"
      d="M486.5 637.5h5v19q-2.687-.201-5 1v-1q5.756-5.765 3.5-14-3.237-1.484-3.5-5"></path>
    <path fill="#6ea2e8" d="M605.5 651.5q1.115 5.175-4 5v-5z"></path>
    <path
      fill="#3877d9"
      d="M288.5 648.5q1.812 3.817 6 4-.356 1.937 1 3 2.128 1.426 5 1v5h4q3.19.939 5 4 12.236 1.98 24 0 2.322-.482 2-3h3v4a637 637 0 0 0-23 2 4.46 4.46 0 0 0 2 1.5 24.4 24.4 0 0 1-5.5 2.5q-.948-3.047-3.5-5-2.851 2.153-1 5-3.967 6.503-4-1a372 372 0 0 1-22 2v-5a61 61 0 0 0-11 .5q1.32 2.034 1 4.5h-12q.32-2.466-1-4.5a42.5 42.5 0 0 0-13 0l5 1q-1.65 1.534-4 1.5a39.3 39.3 0 0 1-9-4q-3.882 1.626-6.5 5a6.2 6.2 0 0 1-3.5-2q7.19-4.143 1-9h4q1.1 1.812 1 4h4a45.6 45.6 0 0 0 19 0q4.57.476 5-4h2q3.618.29 3 4 7.231 1.645 14 0 1-23.494 1-47.5-.001-24.271-2-47.5h-13v33h-5v-4h-5q-6.705-1.479-14-1a81 81 0 0 1 3-2.5l11-1a9.5 9.5 0 0 0 2.5-1.5q-1.04-8.66.5-17.5.785-5.558 4-10 4.526-.25 8 3 5.882.304 11.5 1.5a2552 2552 0 0 0 0 69 34.8 34.8 0 0 1 2 10l1 1q1.063-1.321 2.5-2"></path>
    <path fill="#e5f9fc" d="M329.5 656.5h4q5.174-1.115 5 4v1h-5q-5.662-.203-4-5"></path>
    <path
      fill="#a9cefa"
      d="M481.5 652.5v4q-12.222 1.822-24 0h1q9.285.485 18-1 1.311-3.285 5-3"></path>
    <path
      fill="#84b7f4"
      d="M458.5 652.5v4h-2q-8.303-8.53-6.5 4 1.738-1.6 2.5 1h-4v-10q5.158-.37 10 1"></path>
    <path
      fill="#88b3ea"
      d="M223.5 652.5h4q.074 3.899 3 6.5l-1 1q1.856.348 3 1.5h-4q-1.426-2.128-1-5-2.975.514-4-2z"></path>
    <path fill="#a2c8e7" d="M372.5 655.5h4v5q-1.812-1.1-4-1z"></path>
    <path fill="#83b3f1" d="M601.5 657.5v4h-4v-4z"></path>
    <path
      fill="#5691e0"
      d="M55.5 636.5v6h-3a333 333 0 0 1-1 19h-4v5h-16v-5h1v4h13v-4q1.401-1.602 1-4 1.812-1.1 4-1a390 390 0 0 0 1.5-19q1.517-1.255 3.5-1"></path>
    <path
      fill="#eff9fb"
      d="M232.5 651.5h5v5h5q-.305 2.835 2 4.5a17.8 17.8 0 0 0 7 1.5v3h-14v-4q-1.663-1.185-4-1.5l1-1a36 36 0 0 1-4.5-3.5 25.2 25.2 0 0 1 0-10 219 219 0 0 0 2.5 6"></path>
    <path
      fill="#a6cbf2"
      d="M261.5 651.5h5q-1.602 1.401-4 1v4h9v5q-4.158-1.9-8 0h-2v-5h-5v-1h5z"></path>
    <path
      fill="#92b9e6"
      d="M409.5 645.5h1v6h4q.009 4.763 1 10-2.57-1.703-2-5h-3q-1.384-5.346-1-11"></path>
    <path
      fill="#eef8fc"
      d="M424.5 656.5v5h-9q-.991-5.237-1-10h10v-10q2.038-.76 3-3a33.2 33.2 0 0 1 .5 14q-2.081 1.854-3.5 4"></path>
    <path
      fill="#4f88d7"
      d="M491.5 637.5q1.485 8.715 1 18a19.2 19.2 0 0 0-1 6h-5v-4q2.313-1.201 5-1z"></path>
    <path fill="#a8c8f5" d="M300.5 656.5q5.115-.175 4 5h-4z"></path>
    <path
      fill="#cce0f7"
      d="M314.5 656.5h15q-1.662 4.797 4 5v4h-6q.166-.925 1-1.5-3.91-3.157-9-2.5.255-1.983-1-3.5-2.337-.315-4-1.5"></path>
    <path fill="#8dbbf1" d="M342.5 656.5h1v5h-5v-1h4z"></path>
    <path
      fill="#ddedf9"
      d="M467.5 637.5v-1q8.76-1.32 14.5 5 .504 7.548 1.5 15h3v5h-10v4q-9.513.25-19-.5-5.326-2.515-4-8.5h4q11.778 1.822 24 0v-14q-2.687.201-5-1h-4v-4z"></path>
    <path
      fill="#d1e5fa"
      d="M587.5 656.5h4q-1.61 2.077-4 3.5l3.5.5a51 51 0 0 1 10.5-4v1h-4v4h-6v4h-4v-4a16.9 16.9 0 0 1-8-1q-1.668-1.148-2-3 5.313.469 10-1"></path>
    <path
      fill="#5891df"
      d="M79.5 652.5q1.194 3.504 5 4v9h14v-4h1v5a62.8 62.8 0 0 0-15.5 1 21.7 21.7 0 0 0-4-7 32.4 32.4 0 0 1-.5-8"></path>
    <path
      fill="#d0e4f9"
      d="M242.5 656.5h19v5h-5v4a45.6 45.6 0 0 1-19 0h14v-3a17.8 17.8 0 0 1-7-1.5q-2.305-1.665-2-4.5"></path>
    <path
      fill="#659ce3"
      d="M371.5 652.5h1v7q2.188-.1 4 1v5h9v-4h1v5h-11a12.6 12.6 0 0 1-4-2.5 101 101 0 0 0 0-11.5"></path>
    <path fill="#80aee5" d="M232.5 661.5h5v4h-4q.1-2.188-1-4"></path>
    <path fill="#70a4d9" d="M333.5 661.5h5v1h-3q.322 2.518-2 3z"></path>
    <path fill="#98c9f8" d="M32.5 661.5h13v4h-13z"></path>
    <path fill="#9cc9f5" d="M84.5 661.5h14v4h-14z"></path>
    <path fill="#a3cff6" d="M132.5 661.5h5a5.58 5.58 0 0 0 1 3 9.86 9.86 0 0 1-6 1z"></path>
    <path fill="#aed0fa" d="M156.5 603.5h1v58h8v4h-9z"></path>
    <path fill="#75afe8" d="M261.5 661.5q-.43 4.476-5 4v-4z"></path>
    <path fill="#a7d2f8" d="M376.5 661.5h9v4h-9z"></path>
    <path
      fill="#8cb6ef"
      d="M558.5 652.5h4a4.93 4.93 0 0 0 .5 3q3.25 2.25 5.5 5.5 5.101-.399 5 4.5h18v1h-24q.393-2.217-1-4-2.49-1.008-4-3v-3q-4.5.5-4-4"></path>
    <path fill="#64a4e9" d="M596.5 661.5q0 5-5 5v-5z"></path>
    <path
      fill="#99c3f7"
      d="M304.5 661.5a41 41 0 0 1 9 .5q1.255 1.517 1 3.5h19q-11.764 1.98-24 0-1.81-3.061-5-4"></path>
    <path
      fill="#6e9fe7"
      d="M410.5 656.5h3q-.57 3.297 2 5v4h9v-4q5.115.175 4-5h1q.426 4.915-1 9.5l-7 .5q-4.154-.043-7-2a28.3 28.3 0 0 1-4-8"></path>
    <path fill="#b3d3f7" d="M415.5 661.5h9v4h-9z"></path>
    <path fill="#9bb8f2" d="M424.5 656.5h4q1.115 5.175-4 5z"></path>
    <path
      fill="#9cc5f3"
      d="M456.5 656.5h-3q-1.326 5.985 4 8.5 9.487.75 19 .5v-4h6q-1.1 1.812-1 4a138 138 0 0 1-27 .5 9.2 9.2 0 0 1-2-4.5q-.762-2.6-2.5-1-1.803-12.53 6.5-4"></path>
    <path
      fill="#3876d5"
      d="M155.5 602.5v64h10a20.8 20.8 0 0 0 6-2q-1.981-16.692 0-33 .085 20.028.5 40l-1 2a77 77 0 0 0-3.5-6q-3.517 3.126-8 4-.369-4.455-5-4-.496-32.76 1-65"></path>
  </svg>
);

export default Win10OsIcon;
