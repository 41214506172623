import React, { useState } from "react";
import TabButton from "../../../../../Components/common/TabButton/TabButton";
import Main from "./Tabs/Main";
import Server from "./Tabs/Server";
import Processing from "./Tabs/Processing";
import Timeout from "./Tabs/Timeout";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";
import "./styles.scss";

const CFSBOX = () => {
  const [active, setActive] = useState("Main");

  return (
    <div className="cfsbox">
      <h1>CFSBOX</h1>
      <div className="cfsbox__body">
        <DemoDiv
          styleClass={
            active === "Main" ? "cfs" : active === "Results Server" ? "resultserver" : active
          }
        />
        <div className="button-block">
          <TabButton title="Main" onClick={setActive} active={active} />
          <TabButton title="Results Server" onClick={setActive} active={active} />
          <TabButton title="Processing" onClick={setActive} active={active} />
          <TabButton title="Timeout" onClick={setActive} active={active} />
        </div>
        <div style={{ background: "var(--card-background-color)" }}>
          {active === "Main" ? (
            <Main />
          ) : active === "Results Server" ? (
            <Server />
          ) : active === "Processing" ? (
            <Processing />
          ) : (
            <Timeout />
          )}
        </div>
      </div>
    </div>
  );
};

export default CFSBOX;
