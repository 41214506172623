import React, { useState } from "react";
import TabButton from "../../../../../Components/common/TabButton/TabButton";
import Main from "./Tabs/Main/Main";
import Search from "./Tabs/Search/Search";
import Email from "./Tabs/Email/Email";
import Auxilary from "./Tabs/Auxilary/Auxilary";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";
import "./styles.scss";

const WebServiceConfiguration = () => {
  const [active, setActive] = useState("Main");
  return (
    <div className="web-config">
      <div className="flex flex-align-items-center">
        <h1>Webservice Configuration</h1>
      </div>
      <div className="web-config__body">
        <DemoDiv styleClass={active} />
        <div className="button-block">
          <TabButton title="Main" onClick={setActive} active={active} />
          <TabButton title="Search" onClick={setActive} active={active} />
          <TabButton title="Email" onClick={setActive} active={active} />
          <TabButton title="Auxilary" onClick={setActive} active={active} />
        </div>
        {active === "Main" ? (
          <Main />
        ) : active === "Search" ? (
          <Search />
        ) : active === "Auxilary" ? (
          <Auxilary />
        ) : (
          <Email />
        )}
      </div>
    </div>
  );
};

export default WebServiceConfiguration;
