import React from "react";
import IPut from "iput";
import Tooltip from "../Tooltip/Tooltip";
import "./styles.scss";

const IpInput = ({
  parentClassName,
  label,
  tooltipText,
  onChange,
  width,
  placeholder = "192.168.122.0",
}) => {
  return (
    <div className={parentClassName}>
      <p className="settings__label">
        {label}
        {tooltipText && <Tooltip text={tooltipText} />}
      </p>
      <IPut className="id-input" style={{ width }} onChange={onChange} defaultValue={placeholder} />
    </div>
  );
};

export default IpInput;
