import React, { useState } from "react";
import Select from "../../../Components/Select";
import Toggle from "../../../Components/Toggle";
import Tooltip from "../../../../../../Components/common/Tooltip/Tooltip";
import Field from "../../../Components/Field";
import IpInput from "../../../../../../Components/common/IpInput/IpInput";

const RoutingTab = () => {
  const [internet, setInternet] = useState("internet");
  const [internetMain, setInternetMain] = useState("ens33");
  const [main, setMain] = useState("main");
  const [autoRt, setAutoRt] = useState(false);
  const [drop, setDrop] = useState(false);

  const [inetsim, setInetsim] = useState(false);
  const [server, setServer] = useState("192.168.1.2");
  const [dnsPort, setDnsPort] = useState("53");
  const [Interface, setInterface] = useState("virbr1");
  const [ports, setPorts] = useState("80:8080");
  const [tor, setTor] = useState("");
  const [dnsTorPort, setDnsTorPort] = useState("5353");
  const [proxyPort, setProxyPort] = useState("9040");
  const [selectInterface, setSelectInterface] = useState({});

  return (
    <div className="border routing__content">
      <h3>Routing</h3>
      <Field
        width="131px"
        value={internet}
        onChange={(e) => setInternet(e.target.value)}
        placeholder="Internet"
        parentClassName="m-b-20 m-t-10"
        label="Internet"
        tooltipWidth={"300px"}
        tooltipText={`Default network routing mode; " none", " internet", or "
              vpn_name". In none mode we don't do any special routing - the VM doesn't have any network access (this has been the default actually for quite a while). In internet mode by default all the VMs will be routed through the network interface configured below (the "
              dirty
              line"). And in VPN mode by default the VMs will be routed through the VPN identified by the given name of the VPN (as per vpn.conf). Note that just like enabling VPN configuration setting this option to anything other than "
              none" requires one to run utils/rooter.py as root next to the SBOX instance (as it's required for setting up the routing).`}
      />
      <div className="flex flex-align-items-center m-b-20">
        <Field
          width="131px"
          value={internetMain}
          onChange={(e) => setInternetMain(e.target.value)}
          placeholder="ens33"
          parentClassName="p-r-20"
          label="Internet"
          tooltipText={`Network interface that allows a VM to connect to the entire internet, the "dirty line" so to say. Note that, just like with the VPNs, this will allow malicious traffic through your network. So think twice before enabling it. (For example, to route all VMs through eth0 by default: "internet = eth0").`}
        />
        <Field
          width="131px"
          value={main}
          onChange={(e) => setMain(e.target.value)}
          placeholder="main"
          label="RT table"
          tooltipText={
            'Routing table name/id for "dirty line" interface. If "dirty line" is also default gateway in the system you can leave "main" value. Otherwise add new routing table by adding "< id > < name >" line to /etc/iproute2/rt_tables (e.g., "200 eth0"). ID and name must be unique across the system (refer to /etc/iproute2/rt_tables for existing names and IDs).'
          }
        />
      </div>
      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          label="Auto RT"
          active={autoRt}
          setActive={setAutoRt}
          parentClassName="p-r-40"
          tooltipText={`To route traffic through multiple network interfaces SBOX uses Policy Routing with separate routing table for each output interface (VPN or "dirty line"). If this option is enabled SBOX on start will try to automatically initialise routing tables by copying routing entries from main routing table to the new routing tables. Depending on your network/vpn configuration this might not be sufficient. In such case you would need to initialise routing tables manually. Note that enabling this option won't affect main routing table.`}
          type
        />
        <Toggle
          label="Drop"
          active={drop}
          setActive={setDrop}
          tooltipText="The drop route basically drops any outgoing network (except for SBOX traffic) whereas the regular none route still allows a VM to access its own subnet (e.g., 192.168.122.1/24). It is disabled by default as it does require the optional rooter to run (unlike the none route, where literally nothing happens). One can either explicitly enable the drop route or if the rooter is enabled anyway, it is automatically enabled."
          type
        />
      </div>

      <h3 className="flex flex-align-items-center m-b-10">
        Inetsim
        <Tooltip text="Inetsim quick deploy, chose your vm manager if is not kvm wget https://googledrive.com/host/0B6fULLT_NpxMQ1Rrb1drdW42SkE/remnux-6.0-ova-public.ova tar xvf remnux-6.0-ova-public.ova qemu-img convert -O qcow2 REMnuxV6-disk1.vmdk remnux.qcow2" />
      </h3>

      <Toggle
        active={inetsim}
        setActive={setInetsim}
        parentClassName="m-b-20"
        label="Inetsion"
        type
      />

      <div className="flex flex-align-items-center m-b-10">
        <IpInput
          label="Server"
          width="131px"
          value={server}
          onChange={(value) => setServer(value)}
          parentClassName="p-r-20"
          placeholder="192.168.1.2"
        />
        <Field
          label="DNS port"
          type="number"
          onChange={(e) => setDnsPort(e.target.value)}
          value={dnsPort}
          width="131px"
          parentClassName="p-r-20"
        />
        <Field
          label="Interface"
          value={Interface}
          onChange={(e) => setInterface(e.target.value)}
          width="131px"
          parentClassName="p-r-20"
        />
        <Field
          label="Ports"
          placeholder="80:8080"
          onChange={(e) => setPorts(e.target.value)}
          value={ports}
          width="131px"
          tooltipText='Redirect TCP ports (should we also support UDP?). If specified, this should represent whitespace-separated src:dst pairs. E.g., "80:8080 443:8080" will redirect all 80/443 traffic to 8080 on the specified InetSim host.'
        />
      </div>

      <h3>Tor</h3>
      <Toggle active={tor} setActive={setTor} parentClassName="m-b-20 m-t-10" label="Tor" type />
      <div className="flex flex-align-items-center m-b-20">
        <Field
          width={"131px"}
          type={"number"}
          value={dnsTorPort}
          onChange={(e) => setDnsTorPort(e.target.value)}
          parentClassName="p-r-20"
          label="DNS port"
        />
        <Field
          width={"131px"}
          type={"number"}
          value={proxyPort}
          onChange={(e) => setProxyPort(e.target.value)}
          parentClassName="p-r-20"
          label="Proxy port"
        />
        <Select
          width={"131px"}
          setSelect={setSelectInterface}
          select={selectInterface}
          label="Interface"
        />
      </div>

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default RoutingTab;
