// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-card-button {
  width: 73px;
  height: 55px;
  padding: 4px;
  text-align: center;
  color: var(--file-card-icons-color);
  cursor: pointer;
  margin-right: 12px;
  background-color: var(--file-card-button-bg-color);
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
}
.file-card-button path,
.file-card-button circle {
  stroke: var(--file-card-icons-color);
}
.file-card-button:hover {
  background-color: var(--file-card-button-bg-color-hover);
  box-shadow: 0 0 5px 2px var(--file-card-button-shadow-color);
  color: var(--file-card-icons-color-hover);
}
.file-card-button:hover path, .file-card-button:hover circle {
  stroke: var(--file-card-icons-color-hover);
}`, "",{"version":3,"sources":["webpack://./src/Components/UI/FileCardButton/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,mCAAA;EACA,eAAA;EACA,kBAAA;EACA,kDAAA;EACA,kBAAA;EACA,uCAAA;AACF;AACE;;EAEE,oCAAA;AACJ;AAEE;EACE,wDAAA;EACA,4DAAA;EACA,yCAAA;AAAJ;AAGE;EAEE,0CAAA;AAFJ","sourcesContent":[".file-card-button {\n  width: 73px;\n  height: 55px;\n  padding: 4px;\n  text-align: center;\n  color: var(--file-card-icons-color);\n  cursor: pointer;\n  margin-right: 12px;\n  background-color: var(--file-card-button-bg-color);\n  border-radius: 5px;\n  transition: box-shadow 0.3s ease-in-out;\n\n  path,\n  circle {\n    stroke: var(--file-card-icons-color);\n  }\n\n  &:hover {\n    background-color: var(--file-card-button-bg-color-hover);\n    box-shadow: 0 0 5px 2px var(--file-card-button-shadow-color);\n    color: var(--file-card-icons-color-hover);\n  }\n\n  &:hover path,\n  &:hover circle {\n    stroke: var(--file-card-icons-color-hover);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
