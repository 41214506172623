import React from "react";
import CustomSelect from "../../../../common/CustomSelect/CustomSelect";
import "./styles.scss";

const Local = (props) => {
  const local = props.local || props.row?.original?.location;
  return (
    <>
      <CustomSelect data={local} color={"var(--settings-text-table)"} />
    </>
  );
};

export default Local;
