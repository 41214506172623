import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axiosApi from "../../utils/axios";
import {
  requestTimePeriod,
  successTimePeriod,
  failureTimePeriod,
  updateTimePeriodRequest,
  updateTimePeriodSuccess,
  updateTimePeriodFailure,
  requestGetMailingConfigs,
  successGetMailingConfigs,
  failureGetMailingConfigs,
  requestSetMailingConfigs,
  successSetMailingConfigs,
  failureSetMailingConfigs,
  requestResetMailingConfigs,
  successResetMailingConfigs,
  failureResetMailingConfigs,
  requestSetMaliciousSubmissionMailContent,
  requestResetMaliciousSubmissionMailContent,
  successSetMaliciousSubmissionMailContent,
  failureSetMaliciousSubmissionMailContent,
  failureResetMaliciousSubmissionMailContent,
  successResetMaliciousSubmissionMailContent,
  requestGetMaliciousSubmissionMailContent,
  successGetMaliciousSubmissionMailContent,
  dumpDatabaseRequest,
  requestDumpStatus,
  updateDumpStatus,
  requestRestoreStatus,
  updateRestoreStatus,
  requestUpdateMailingActive,
  successUpdateMailingActive,
  failureUpdateMailingActive,
  requestGetSyslogConfig,
  requestSetSyslogConfig,
  successSetSyslogConfig,
  failureSetSyslogConfig,
  successGetSyslogConfig,
  failureGetSyslogConfig,
  successDeleteSyslogConfig,
  failureDeleteSyslogConfig,
  requestDeleteSyslogConfig,
  requestGetMailRecipients,
  requestAddMailRecipients,
  requestDeleteMailRecipients,
  successGetMailRecipients,
  failureGetMailRecipients,
  successAddMailRecipients,
  failureAddMailRecipients,
  successDeleteMailRecipients,
  failureDeleteMailRecipients,
  requestSetTelegramConfig,
  successSetTelegramConfig,
  failureSetTelegramConfig,
  requestGetTelegramConfig,
  successGetTelegramConfig,
  failureGetTelegramConfig,
  requestSetSlackConfig,
  successSetSlackConfig,
  failureSetSlackConfig,
  requestGetSlackConfig,
  successGetSlackConfig,
  failureGetSlackConfig,
  RequestCustomSignatureUpload,
  SuccessCustomSignatureUpload,
  FailureCustomSignatureUpload,
  SetCustomSignatureFile,
  RequestCustomSignatures,
  SuccessCustomSignatures,
  FailureCustomSignatures,
  RequestDeleteCustomSignature,
  SuccessDeleteCustomSignature,
  FailureDeleteCustomSignature,
  RequestEditCustomSignature,
  SuccessEditCustomSignature,
  FailureEditCustomSignature,
} from "../actions/settingsActions";
import { addNotification } from "../actions/notifierActions";
import { customFormData } from "../../utils/customFormData";

export function* timePeriod() {
  try {
    const response = yield call(axiosApi.get, "/api/settings/dataRetention/timePeriod");
    yield put(successTimePeriod(response?.data));
  } catch (error) {
    yield put(failureTimePeriod(error));
  }
}

export function* updateTimePeriod({ payload: data }) {
  const formData = customFormData(data);
  try {
    const response = yield call(axiosApi.put, "/api/settings/dataRetention/timePeriod", formData);
    yield put(updateTimePeriodSuccess(Number(response?.data?.updated_time_period)));
    yield put(addNotification({ message: response.data.message, options: { variant: "success" } }));
  } catch (error) {
    yield put(updateTimePeriodFailure(error));
    yield put(addNotification({ message: "Error", options: { variant: "error" } }));
  }
}

export function* getMailingConfigs() {
  try {
    const response = yield axiosApi.get("/api/settings/mailing");
    yield put(successGetMailingConfigs(response.data));
  } catch (error) {
    yield put(failureGetMailingConfigs(error));
  }
}

export function* setMailingConfigs({ payload: data }) {
  const formData = customFormData(data.server);
  formData.append("server", data.server);
  formData.append("sender_email", data.senderEmail);
  formData.append("sender_password", data.senderPassword);
  formData.append("port", data.port);
  formData.append("security", data.security);

  try {
    const response = yield axiosApi.post("/api/settings/mailing", formData);

    yield put(successSetMailingConfigs(response.data));
    yield put(
      addNotification({ message: "Changed your mailing configs", options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(
      addNotification({ message: error.response.data.message, options: { variant: "error" } }),
    );

    yield put(failureSetMailingConfigs(error));
  }
}

export function* resetMailingConfigs() {
  const formData = customFormData({ reset: true });
  formData.append("reset", "true");

  try {
    const response = yield axiosApi.put("/api/settings/mailing", formData);

    yield put(successResetMailingConfigs(response.data));
    yield put(
      addNotification({ message: "Reset your mailing configs", options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(failureResetMailingConfigs(error));
  }
}

export function* getMailRecipients() {
  try {
    const response = yield axiosApi.get("/api/settings/mailing/recipients");

    yield put(successGetMailRecipients(response.data));
  } catch (error) {
    yield put(failureGetMailRecipients(error));
  }
}

export function* addMailRecipients({ payload: data }) {
  const formData = customFormData(data.recipient);
  formData.append("recipient", data.recipient);

  try {
    const response = yield axiosApi.post("/api/settings/mailing/recipients", formData);

    yield put(successAddMailRecipients(response.data));
    yield put(
      addNotification({
        message: "Successfully added the recipient",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(
      addNotification({
        message: "Adding the recipient failed",
        options: { variant: "error" },
      }),
    );

    yield put(failureAddMailRecipients(error));
  }
}

export function* deleteMailRecipients({ payload: data }) {
  const formData = customFormData(data.recipient);
  formData.append("recipient", data.recipient);

  try {
    const response = yield axiosApi.delete("/api/settings/mailing/recipients", { data: formData });

    yield put(successDeleteMailRecipients(response.data));
    yield put(
      addNotification({
        message: "Successfully deleted the recipient",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(
      addNotification({
        message: "Deleting the recipient failed",
        options: { variant: "error" },
      }),
    );

    yield put(failureDeleteMailRecipients(error));
  }
}

export function* updateMailingActive({ payload: data }) {
  const formData = customFormData({});

  if (data.active) {
    formData.append("active", "True");
  } else {
    formData.append("active", "False");
  }

  try {
    const response = yield axiosApi.put("/api/settings/mailing/active", formData);

    yield put(successUpdateMailingActive(response.data));
  } catch (error) {
    yield put(failureUpdateMailingActive(error));
  }
}

export function* getMaliciousSubmissionMailContent() {
  try {
    const response = yield axiosApi.get("/api/settings/mailing/mailContent");

    yield put(successGetMaliciousSubmissionMailContent(response.data));
  } catch (error) {
    yield put(failureResetMaliciousSubmissionMailContent(error));
  }
}

export function* resetMaliciousSubmissionMailContent() {
  try {
    const response = yield axiosApi.put("/api/settings/mailing/mailContent");

    yield put(successResetMaliciousSubmissionMailContent(response.data));
    yield put(
      addNotification({ message: "Reset your mailing configs", options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(failureResetMaliciousSubmissionMailContent(error));
  }
}

export function* setMaliciousSubmissionMailContent({ payload: data }) {
  const formData = customFormData(data.subject);
  formData.append("subject", data.subject);
  formData.append("body", data.body);

  try {
    const response = yield axiosApi.post("/api/settings/mailing/mailContent", formData);

    yield put(successSetMaliciousSubmissionMailContent(response.data));
    yield put(
      addNotification({
        message: "Changed your malicious submission notification settings",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(failureSetMaliciousSubmissionMailContent(error));
  }
}

export function* dumpDatabase() {
  try {
    yield call(axiosApi.delete, "/api/settings/dump/clear-submissions");
    const response = yield call(axiosApi.post, "/api/settings/dump");
    yield put(addNotification({ message: response.data.message, options: { variant: "success" } }));
    yield call(dumpStatus);
  } catch (error) {
    yield put(
      addNotification({ message: error.response.data.message, options: { variant: "error" } }),
    );
  }
}

export function* dumpStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/settings/dump/status");
    yield put(updateDumpStatus(response.data));
  } catch (error) {
    yield put(
      addNotification({ message: error.response.data.message, options: { variant: "error" } }),
    );
  }
}

export function* restoreStatus() {
  try {
    const response = yield call(axiosApi.get, "/api/settings/restore/status");
    yield put(updateRestoreStatus(response.data));
  } catch (error) {
    yield put(
      addNotification({ message: error.response.data.message, options: { variant: "error" } }),
    );
  }
}

export function* getSyslogConfig() {
  try {
    const response = yield axiosApi.get("/api/settings/syslog/config");
    yield put(successGetSyslogConfig(response.data));
  } catch (error) {
    yield put(failureGetSyslogConfig(error));
    yield put(
      addNotification({
        message: "Getting syslog configurations failed",
        options: { variant: "error" },
      }),
    );
  }
}

export function* setSyslogConfig({ payload: data }) {
  const formData = customFormData(data.server);
  formData.append("server", data.server);
  formData.append("port", data.port);
  formData.append("connection", data.connection);

  try {
    const response = yield axiosApi.post("/api/settings/syslog/config", formData);

    yield put(successSetSyslogConfig(response.data));
    yield put(
      addNotification({
        message: "Changed your syslog configurations",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(failureSetSyslogConfig(error));
    yield put(
      addNotification({ message: error.response.data.message, options: { variant: "error" } }),
    );
  }
}

export function* deleteSyslogConfig() {
  try {
    const response = yield axiosApi.delete("/api/settings/syslog/config");
    yield put(successDeleteSyslogConfig());
  } catch (error) {
    yield put(failureDeleteSyslogConfig(error));
    yield put(
      addNotification({
        message: "Deleting syslog configurations failed",
        options: { variant: "error" },
      }),
    );
  }
}

export function* setTelegramConfig({ payload: cid }) {
  try {
    const response = yield axiosApi.get(`/api/settings/settelegramnotification/${cid}`);

    yield put(successSetTelegramConfig(response.data));
    yield put(
      addNotification({
        message: "Added Success",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(failureSetTelegramConfig(error));
  }
}

export function* getTelegramConfig() {
  try {
    const response = yield axiosApi.get(`/api/settings/gettelegramnotification`);

    yield put(successGetTelegramConfig(response.data));
  } catch (error) {
    yield put(failureGetTelegramConfig(error));
  }
}

export function* setSlackConfig({ payload: data }) {
  try {
    const response = yield call(axiosApi.post, "/api/settings/setslacknotification", data);

    yield put(successSetSlackConfig(response.data));
    yield put(
      addNotification({
        message: "Added Success",
        options: { variant: "success" },
      }),
    );
  } catch (error) {
    yield put(failureSetSlackConfig(error));
  }
}

export function* getSlackConfig() {
  try {
    const response = yield axiosApi.get(`/api/settings/getslacknotification`);

    yield put(successGetSlackConfig(response.data));
  } catch (error) {
    yield put(failureGetSlackConfig(error));
  }
}

export function* customSignatureUpload({ payload }) {
  const { file, signatureType } = payload;
  try {
    const formData = customFormData({});
    formData.append("file", file);
    yield call(axiosApi.post, `/api/settings/custom-signatures/${signatureType}`, formData);
    yield put(SuccessCustomSignatureUpload());
    yield put(
      addNotification({
        message: "Uploaded custom signature successfully",
        options: { variant: "success" },
      }),
    );
    yield put(SetCustomSignatureFile(null));
    yield call(customSignatures, { payload: signatureType });
  } catch (e) {
    yield put(FailureCustomSignatureUpload());
    yield put(
      addNotification({
        message: "Uploading custom signature failed",
        options: { variant: "error" },
      }),
    );
  }
}

export function* customSignatures({ payload: signatureType }) {
  try {
    const response = yield call(axiosApi.get, `/api/settings/custom-signatures/${signatureType}`);
    yield put(SuccessCustomSignatures(response.data));
  } catch (e) {
    yield put(FailureCustomSignatures());
  }
}

export function* deleteCustomSignature({ payload }) {
  const { customSignatureId, signatureType } = payload;
  try {
    yield call(
      axiosApi.delete,
      `/api/settings/custom-signatures/${signatureType}/${customSignatureId}`,
    );
    yield put(SuccessDeleteCustomSignature());
    yield put(
      addNotification({
        message: "Deleted custom signature successfully",
        options: { variant: "success" },
      }),
    );
    yield call(customSignatures, { payload: signatureType });
  } catch (e) {
    yield put(FailureDeleteCustomSignature());
    yield put(
      addNotification({
        message: "Deleting custom signature failed",
        options: { variant: "error" },
      }),
    );
  }
}

export function* editCustomSignature({ payload }) {
  const { customSignatureId, signatureType } = payload;
  try {
    yield call(
      axiosApi.put,
      `/api/settings/custom-signatures/${signatureType}/${customSignatureId}`,
    );
    yield put(SuccessEditCustomSignature());
    yield put(
      addNotification({
        message: "Edited custom signature successfully",
        options: { variant: "success" },
      }),
    );
    yield call(customSignatures, { payload: signatureType });
  } catch (e) {
    yield put(FailureEditCustomSignature());
    yield put(
      addNotification({
        message: "Editing custom signature failed",
        options: { variant: "error" },
      }),
    );
  }
}

const settingsSagas = [
  takeEvery(requestTimePeriod, timePeriod),
  takeEvery(updateTimePeriodRequest, updateTimePeriod),
  takeEvery(requestGetMailingConfigs, getMailingConfigs),
  takeEvery(requestSetMailingConfigs, setMailingConfigs),
  takeEvery(requestResetMailingConfigs, resetMailingConfigs),
  takeEvery(requestGetMailRecipients, getMailRecipients),
  takeEvery(requestAddMailRecipients, addMailRecipients),
  takeEvery(requestDeleteMailRecipients, deleteMailRecipients),
  takeEvery(requestUpdateMailingActive, updateMailingActive),
  takeEvery(requestGetMaliciousSubmissionMailContent, getMaliciousSubmissionMailContent),
  takeEvery(requestSetMaliciousSubmissionMailContent, setMaliciousSubmissionMailContent),
  takeEvery(requestResetMaliciousSubmissionMailContent, resetMaliciousSubmissionMailContent),
  takeEvery(dumpDatabaseRequest, dumpDatabase),
  takeEvery(requestDumpStatus, dumpStatus),
  takeEvery(requestRestoreStatus, restoreStatus),
  takeEvery(requestGetSyslogConfig, getSyslogConfig),
  takeEvery(requestSetSyslogConfig, setSyslogConfig),
  takeEvery(requestDeleteSyslogConfig, deleteSyslogConfig),
  takeEvery(requestSetTelegramConfig, setTelegramConfig),
  takeEvery(requestGetTelegramConfig, getTelegramConfig),
  takeEvery(requestSetSlackConfig, setSlackConfig),
  takeEvery(requestGetSlackConfig, getSlackConfig),
  takeLatest(RequestCustomSignatureUpload, customSignatureUpload),
  takeLatest(RequestCustomSignatures, customSignatures),
  takeLatest(RequestDeleteCustomSignature, deleteCustomSignature),
  takeLatest(RequestEditCustomSignature, editCustomSignature),
];

export default settingsSagas;
