// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user__card-inner {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0 0 15px 0;
}
.user__card-inner__img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  margin-top: -75px;
}
.user__card-inner__text-name {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  color: var(--profile-name-text-color);
  margin: 20px 0 0 0;
}
.user__card-inner__text-mail {
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  text-align: center;
  color: var(--profile-text-color);
  margin: 10px 0 0 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/UserCard/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADJ;AAIE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;AAFJ;AAKE;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,qCAAA;EACA,kBAAA;AAHJ;AAME;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gCAAA;EACA,kBAAA;AAJJ","sourcesContent":["@import \"../../../styles/colors\";\n\n.user__card {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  &-inner {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    margin: 0 0 15px 0;\n  }\n\n  &-inner__img {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 150px;\n    margin-top: -75px;\n  }\n\n  &-inner__text-name {\n    font-size: 20px;\n    font-style: normal;\n    font-weight: bold;\n    line-height: 16px;\n    text-align: center;\n    color: var(--profile-name-text-color);\n    margin: 20px 0 0 0;\n  }\n\n  &-inner__text-mail {\n    font-size: 18px;\n    font-style: normal;\n    line-height: 22px;\n    text-align: center;\n    color: var(--profile-text-color);\n    margin: 10px 0 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
