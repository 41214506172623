// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-message {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 80px !important;
}
.download-message p {
  margin-top: 30px;
  font-size: 16px;
  color: white;
}
.download-message__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.download-message__header span {
  color: white;
}
.download-message__file-name {
  font-weight: bold;
}
.download-message__progress {
  width: 100%;
  height: 20px;
  border-radius: 3px;
  background-color: var(--settings-background-page);
}`, "",{"version":3,"sources":["webpack://./src/Components/common/DownloadMessage/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,2BAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,YAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iDAAA;AAHJ","sourcesContent":[".download-message {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  margin-top: 80px !important;\n\n  & p {\n    margin-top: 30px;\n    font-size: 16px;\n    color: white;\n  }\n\n  &__header {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 12px;\n\n    span {\n      color: white;\n    }\n  }\n\n  &__file-name {\n    font-weight: bold;\n  }\n\n  &__progress {\n    width: 100%;\n    height: 20px;\n    border-radius: 3px;\n    background-color: var(--settings-background-page);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
