import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Field from "../../../Components/Field";

const Detections = () => {
  const [detections, setDetections] = useState(false);
  const [behavior, setBehavior] = useState(false);
  const [yara, setYara] = useState(true);
  const [suricata, setSuricata] = useState(true);
  const [virustotal, setVirustotal] = useState(false);
  const [clamav, setClamav] = useState(true);
  const [dropped, setDropped] = useState(true);
  const [buffer, setBuffer] = useState("");
  const [procDump, setProcDump] = useState(true);

  const [procMemory, setProcMemory] = useState(true);
  const [strings, setStrings] = useState(false);
  const [procMon, setProcMon] = useState(true);
  const [memory, setMemory] = useState(false);
  const [usage, setUsage] = useState(true);

  return (
    <div className="border processing__content">
      <h3 className="m-b-10">Detections</h3>
      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          active={detections}
          label="Detections"
          setActive={setDetections}
          parentClassName="p-r-40"
          type
        />
        <Toggle
          active={behavior}
          label="Behavior"
          setActive={setBehavior}
          parentClassName="p-r-40"
          tooltipText="Signatures"
        />
        <Toggle active={yara} label="Yara" setActive={setYara} parentClassName="p-r-40" />
        <Toggle
          active={suricata}
          label="Suricata"
          setActive={setSuricata}
          parentClassName="p-r-40"
        />
        <Toggle
          active={virustotal}
          label="Virustotal"
          setActive={setVirustotal}
          parentClassName="p-r-40"
        />
        <Toggle active={clamav} label="CLAMAV" setActive={setClamav} parentClassName="p-r-40" />
      </div>
      <h3 className="m-b-10">Dropped</h3>
      <Toggle
        active={dropped}
        label="Dropped"
        setActive={setDropped}
        parentClassName="p-r-40 m-b-20"
        type
      />
      <Field
        value={buffer}
        onChange={(e) => setBuffer(e.target.value)}
        tooltipText="Amount of text to carve from plaintext files (bytes)"
        label="Buffer"
        placeholder="3144704"
        parentClassName="m-b-10"
        type="number"
      />
      <div className="m-b-20">
        <h3 className="m-b-10">ProcDump</h3>
        <Toggle
          active={procDump}
          setActive={setProcDump}
          label="ProcDump"
          tooltipText="It is intended in SBOX for procdump to replace procmemory..."
          type
        />
      </div>
      <h3>Procmemory</h3>

      <div className="flex flex-align-items-center m-t-10 m-b-20">
        <Toggle
          active={procMemory}
          setActive={setProcMemory}
          label="Procmemory"
          type
          parentClassName="p-r-40"
        />
        <Toggle active={strings} setActive={setStrings} label="Strings" />
      </div>

      <div className="flex flex-align-items-center m-b-20">
        <Toggle
          active={procMon}
          setActive={setProcMon}
          label="Procmon"
          type
          parentClassName="p-r-40"
        />
        <Toggle
          active={memory}
          setActive={setMemory}
          label="Memory"
          type
          parentClassName="p-r-40"
        />
        <Toggle active={usage} setActive={setUsage} label="Usage" type />
      </div>

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Detections;
