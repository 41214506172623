// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload {
  border: 1px dashed var(--border);
  border-radius: 3px;
  height: 10rem;
  width: 20rem;
  text-align: center;
  position: relative;
}
.file-upload__input {
  display: none;
}
.file-upload__label {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.file-upload__label p {
  padding: 0 45px;
  color: white !important;
}
.file-upload__label:hover {
  background-color: rgba(255, 255, 255, 0.03);
}
.file-upload__label--active {
  background-color: rgba(255, 255, 255, 0.03);
}
.file-upload__icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--drag-and-drop-icon-background);
}
.file-upload__drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.file-upload__uploaded-file {
  display: flex;
  gap: 10px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/FileUpload/styles.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AAAJ;AAEI;EACE,eAAA;EACA,uBAAA;AAAN;AAGI;EACE,2CAAA;AADN;AAII;EACE,2CAAA;AAFN;AAME;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sDAAA;AAJJ;AAOE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;AALJ;AAQE;EACE,aAAA;EACA,SAAA;EACA,YAAA;AANJ","sourcesContent":[".file-upload {\n  border: 1px dashed var(--border);\n  border-radius: 3px;\n  height: 10rem;\n  width: 20rem;\n  text-align: center;\n  position: relative;\n\n  &__input {\n    display: none;\n  }\n\n  &__label {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 4px;\n    cursor: pointer;\n\n    p {\n      padding: 0 45px;\n      color: white !important;\n    }\n\n    &:hover {\n      background-color: rgba(255, 255, 255, 0.03);\n    }\n\n    &--active {\n      background-color: rgba(255, 255, 255, 0.03);\n    }\n  }\n\n  &__icon {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: var(--drag-and-drop-icon-background);\n  }\n\n  &__drag-file-element {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border-radius: 1rem;\n    top: 0px;\n    right: 0px;\n    bottom: 0px;\n    left: 0px;\n  }\n\n  &__uploaded-file {\n    display: flex;\n    gap: 10px;\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
