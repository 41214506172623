import React, { useState } from "react";
import TextField from "../../../../common/TextField/TextField";
import "./styles.scss";

const RemoteUrl = (props) => {
  const URL = props.remote_url || props.row?.original?.remote_url;
  const [state, setState] = useState(URL);
  return (
    <div className="url">
      <TextField onChange={(e) => setState(e.target.value)} value={state} />
    </div>
  );
};

export default RemoteUrl;
