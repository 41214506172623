import React, { useState } from "react";
import TabButton from "../../../../../Components/common/TabButton/TabButton";
import Backup from "./Tabs/Backup/Backup";
import Restore from "./Tabs/Restore/Restore";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";

const ApplianceBackupRestore = () => {
  const [active, setActive] = useState("Backup");

  return (
    <div className="backup">
      <h1>Appliance Backup & Restore</h1>
      <div className="backup__body">
        <DemoDiv styleClass={active} />
        <div className="button-block">
          <TabButton title="Backup" onClick={setActive} active={active} />
          <TabButton title="Restore" onClick={setActive} active={active} />
        </div>
        {active === "Backup" ? <Backup /> : <Restore />}
      </div>
    </div>
  );
};

export default ApplianceBackupRestore;
