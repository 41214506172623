import containerSlice from "../slices/containerSlice";

export const {
  RequestContainerService,
  SuccessContainerService,
  FailureContainerService,
  RequestLiveResponsibles,
  SuccessLiveResponsibles,
  FailureLiveResponsibles,
  RequestContainerServices,
  SuccessContainerServices,
  FailureContainerServices,
  RequestPodUpdate,
  SuccessPodUpdate,
  FailurePodUpdate,
  RequestPodRestart,
  SuccessPodRestart,
  FailurePodRestart,
} = containerSlice.actions;
