import React from "react";
import CustomTable from "../../../../../Components/common/Table/CustomTable";
import { settingsYaraRules } from "../../../../../utils/constants/tableHeaders";
import { settingYaraRulesFixture } from "../../../../../fixture";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";
import "./styles.scss";

const YaraRules = () => {
  return (
    <>
      <div className="flex flex-align-items-center  flex-justify-content-between">
        <h1>Yara Rules</h1>
        {/** 
        <button className="primary-button">Upload Yara files</button>*/}
      </div>

      <div className="border yara-rules-table">
        <DemoDiv styleClass={"yararules"} />
        <h3>Yara Rules</h3>
        <CustomTable
          column={settingsYaraRules}
          dataset={settingYaraRulesFixture}
          refresh={false}
          customPageSize={settingYaraRulesFixture.length}
          paginationButtons={true}
          tableName={"yara-rules"}
          className={""}
        />
        <button className="yara-rules-table__button primary-button">Delete File</button>
      </div>
    </>
  );
};

export default YaraRules;
