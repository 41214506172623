import { useState } from "react";

import ContainerServicesSidebar from "./components/ContainerServicesSidebar";
import ContainerServicesBody from "./components/ContainerServicesBody";

import "./styles.scss";

const ContainerServicesPage = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedResponsible, setSelectedResponsible] = useState(null);

  const selectItemHandler = (product, responsible) => {
    setSelectedProduct(product);
    setSelectedResponsible(responsible);
  };

  return (
    <div className="services-container">
      <div className="services-container__header">
        <h1>C. Services</h1>
        <p>
          You can select one of the live services from sidebar to list containers belong to that
          service
        </p>
      </div>
      <div className="services">
        <ContainerServicesSidebar
          onItemSelect={selectItemHandler}
          selectedResponsible={selectedResponsible}
        />
        <ContainerServicesBody
          selectedProduct={selectedProduct}
          selectedResponsible={selectedResponsible}
        />
      </div>
    </div>
  );
};

export default ContainerServicesPage;
