import mailGatewaySlice from "../slices/mailGatewaySlice";

export const {
  RequestDistinctHosts,
  SuccessDistinctHosts,
  FailureDistinctHosts,
  RequestDistinctLiveHosts,
  SuccessDistinctLiveHosts,
  FailureDistinctLiveHosts,
  RequestAnalyzedEmails,
  SuccessAnalyzedEmails,
  FailureAnalyzedEmails,
  RequestBlockedEmails,
  SuccessBlockedEmails,
  FailureBlockedEmails,
  RequestUnblockedEmails,
  SuccessUnblockedEmails,
  FailureUnblockedEmails,
  RequestReleaseEmail,
  SuccessReleaseEmail,
  FailureReleaseEmail,
  RequestSetMailTransferSettings,
  SuccessSetMailTransferSettings,
  FailureSetMailTransferSettings,
  RequestGetMailTransferSettings,
  SuccessGetMailTransferSettings,
  FailureGetMailTransferSettings,
  RequestGetWhitelistSettings,
  SuccessGetWhitelistSettings,
  FailureGetWhitelistSettings,
  RequestSetWhitelistSettings,
  SuccessSetWhitelistSettings,
  FailureSetWhitelistSettings,
} = mailGatewaySlice.actions;
