// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analysis-reports__table {
  width: 100%;
  position: relative;
  border-radius: 0 4px 4px 4px;
  margin: 6vh 0 1vh 0;
}
.analysis-reports__table__information-popup {
  position: absolute;
  right: 29px;
  top: -29.5px;
}

.spinner-block {
  display: flex;
  background: var(--attack-map-bg);
  height: 500px;
  margin: auto;
}

.bottom-border-tab {
  border-bottom: 1px solid var(--analysis-reports-table-header) !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/AnalysisReports/Content/styles.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,kBAAA;EACA,4BAAA;EACA,mBAAA;AADF;AAGE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AADJ;;AAKA;EACE,aAAA;EACA,gCAAA;EACA,aAAA;EACA,YAAA;AAFF;;AAKA;EACE,wEAAA;AAFF","sourcesContent":["@import \"../../../styles/colors\";\n\n.analysis-reports__table {\n  width: 100%;\n  position: relative;\n  border-radius: 0 4px 4px 4px;\n  margin: 6vh 0 1vh 0;\n\n  &__information-popup {\n    position: absolute;\n    right: 29px;\n    top: -29.5px;\n  }\n}\n\n.spinner-block {\n  display: flex;\n  background: var(--attack-map-bg);\n  height: 500px;\n  margin: auto;\n}\n\n.bottom-border-tab {\n  border-bottom: 1px solid var(--analysis-reports-table-header) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
