import React, { useState } from "react";
import TabButton from "../../../../../Components/common/TabButton/TabButton";
import Kvm from "./Tabs/KVM";
import Images from "./Tabs/Images";
import DemoDiv from "../../../../../Components/common/DemoDiv/DemoDiv";
import "./styles.scss";

const GuestImages = () => {
  const [active, setActive] = useState("Analysis Images (KVM)");

  return (
    <div className="guest-images">
      <h1>Guest Images</h1>
      <div className="guest-images__body">
        <DemoDiv styleClass={active === "Analysis Images (KVM)" ? "analysisImage" : "kvm"} />
        <div className="button-block">
          <TabButton title="Analysis Images (KVM)" onClick={setActive} active={active} />
          <TabButton title="KVM" onClick={setActive} active={active} />
        </div>
        {active === "Analysis Images (KVM)" ? <Images /> : <Kvm />}
      </div>
    </div>
  );
};
export default GuestImages;
