// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectlogo {
  display: flex;
  border: 1px solid var(--dark-theme-element);
  padding: 10px;
  width: 280px;
}
.selectlogo--img {
  border: none;
  width: 100%;
  height: 100%;
  margin-right: 20px;
}
.selectlogo input {
  background: transparent;
  color: white;
  height: 32px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 3px;
}
.selectlogo tr {
  color: white;
  width: 100px;
}
.selectlogo input[type=file] {
  display: none;
}
.selectlogo--img-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px;
  font-weight: 600;
  color: var(--header-search-text-color);
  background: var(--dark-theme-element);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.selectlogo--img-upload:hover {
  background-color: var(--dark-theme-element) 80;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/ReportExport/SelectLogo/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2CAAA;EACA,aAAA;EACA,YAAA;AACJ;AACI;EACI,YAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACR;AAEI;EACI,uBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AAAR;AAGI;EACI,YAAA;EACA,YAAA;AADR;AAII;EACI,aAAA;AAFR;AAKI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EAEA,gBAAA;EACA,sCAAA;EACA,qCAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,gFAAA;AAJR;AAOI;EACI,8CAAA;AALR","sourcesContent":[".selectlogo {\n    display: flex;\n    border: 1px solid var(--dark-theme-element);\n    padding: 10px;\n    width: 280px;\n\n    &--img {\n        border: none;\n        width: 100%;\n        height: 100%;\n        margin-right: 20px;\n    }\n\n    input {\n        background: transparent;\n        color: white;\n        height: 32px;\n        border: none;\n        border-radius: 5px;\n        cursor: pointer;\n        padding: 5px 3px;\n    }\n\n    tr {\n        color: white;\n        width: 100px;\n    }\n\n    input[type=\"file\"] {\n        display: none;\n    }\n\n    &--img-upload {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        gap: 10px;\n        padding: 5px;\n\n        font-weight: 600;\n        color: var(--header-search-text-color);\n        background: var(--dark-theme-element);\n        border-radius: 4px;\n        border: none;\n        cursor: pointer;\n        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);\n    }\n\n    &--img-upload:hover {\n        background-color: var(--dark-theme-element)80;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
