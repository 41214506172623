const MobileIcon = ({ color = "var(--file-card-icons-color)", width = 24, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      style={{ marginTop: "2.5px", marginBottom: "1.5px" }}>
      <path
        stroke={color}
        d="M15.5,0h-7c-2.481,0-4.5,2.019-4.5,4.5v15c0,2.481,2.019,4.5,4.5,4.5h7c2.481,0,4.5-2.019,4.5-4.5V4.5c0-2.481-2.019-4.5-4.5-4.5Zm-.693,1l-.325,.974c-.205,.614-.777,1.026-1.423,1.026h-2.117c-.646,0-1.218-.412-1.423-1.026l-.325-.974h5.613Zm4.193,18.5c0,1.93-1.57,3.5-3.5,3.5h-7c-1.93,0-3.5-1.57-3.5-3.5V4.5c0-1.81,1.387-3.287,3.151-3.465l.418,1.255c.341,1.022,1.294,1.709,2.372,1.709h2.117c1.078,0,2.031-.687,2.372-1.709l.418-1.255c1.764,.178,3.151,1.654,3.151,3.465v15Z"
      />
      <path
        stroke={color}
        d="M13.5,20h-3c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5h3c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5Z"
      />
    </svg>
  );
};

export default MobileIcon;
