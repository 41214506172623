// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatgpt-container {
  flex: 1 1;
  padding: 30px 30px;
  min-height: 200px;
  background: var(--matrix-table-color);
  margin: 0 0 30px 0;
  border: 1px solid var(--border);
  border-radius: 4px 4px 4px 4px;
  color: var(--header-search-text-color);
  position: relative;
  margin-bottom: 0;
}
.chatgpt-container__button-block {
  position: absolute;
  display: flex;
  align-items: center;
  right: -14px;
  top: 6px;
}
.chatgpt-container__content {
  padding-right: 10px;
  height: 417px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  /* width */
  /* Handle */
  /* Handle on hover */
}
.chatgpt-container__content::-webkit-scrollbar {
  width: 8px;
  border-radius: 40px;
}
.chatgpt-container__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.chatgpt-container__content::-webkit-scrollbar-thumb:hover {
  background: var(--header-search-text-color);
  border-radius: 10px;
}
.chatgpt-container__content__no-data {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  height: 20px;
  width: 21px;
  animation: spin 1s linear infinite;
  margin: -5px 15px 0 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/PagesComponents/AnalysisReports/MemoryBlock/Tabs/ChatGPT/styles.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,kBAAA;EACA,iBAAA;EACA,qCAAA;EACA,kBAAA;EACA,+BAAA;EACA,8BAAA;EACA,sCAAA;EACA,kBAAA;EACA,gBAAA;AADF;AAGE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,QAAA;AADJ;AAIE;EACE,mBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EAEA,UAAA;EAMA,WAAA;EAMA,oBAAA;AAbJ;AAEI;EACE,UAAA;EACA,mBAAA;AAAN;AAII;EACE,8BAAA;EACA,mBAAA;AAFN;AAMI;EACE,2CAAA;EACA,mBAAA;AAJN;AAOI;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AALN;;AAUA;EACE,YAAA;EACA,WAAA;EACA,kCAAA;EACA,mCAAA;AAPF","sourcesContent":["@import \"../../../../../../styles/colors.scss\";\n\n.chatgpt-container {\n  flex: 1;\n  padding: 30px 30px;\n  min-height: 200px;\n  background: var(--matrix-table-color);\n  margin: 0 0 30px 0;\n  border: 1px solid var(--border);\n  border-radius: 4px 4px 4px 4px;\n  color: var(--header-search-text-color);\n  position: relative;\n  margin-bottom: 0;\n\n  &__button-block {\n    position: absolute;\n    display: flex;\n    align-items: center;\n    right: -14px;\n    top: 6px;\n  }\n\n  &__content {\n    padding-right: 10px;\n    height: 417px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    overflow-y: auto;\n\n    /* width */\n    &::-webkit-scrollbar {\n      width: 8px;\n      border-radius: 40px;\n    }\n\n    /* Handle */\n    &::-webkit-scrollbar-thumb {\n      background: rgba($color: #000000, $alpha: 0.5);\n      border-radius: 10px;\n    }\n\n    /* Handle on hover */\n    &::-webkit-scrollbar-thumb:hover {\n      background: var(--header-search-text-color);\n      border-radius: 10px;\n    }\n\n    &__no-data {\n      height: 200px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n}\n\n.spinner {\n  height: 20px;\n  width: 21px;\n  animation: spin 1s linear infinite;\n  margin: -5px 15px 0 15px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
