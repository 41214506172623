// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restore {
  padding: 20px 0;
}
.restore__content {
  padding: 0 20px;
}
.restore .label {
  height: 40px;
  margin: 0 0 20px 0;
}
.restore .field {
  height: 40px;
  max-width: 310px;
  margin: 0;
  color: var(--settings-text-table) !important;
}
.restore p {
  color: var(--settings-header-text);
  font-family: Nunito, serif;
  font-size: 12px;
}
.restore .toggle-button__text {
  color: var(--settings-article-value);
}
.restore .toggle-button {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/ApplianceBackupRestore/Tabs/Restore/styles.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;AAGE;EACE,eAAA;AADJ;AAIE;EACE,YAAA;EACA,kBAAA;AAFJ;AAKE;EACE,YAAA;EACA,gBAAA;EACA,SAAA;EACA,4CAAA;AAHJ;AAME;EACE,kCAAA;EACA,0BAAA;EACA,eAAA;AAJJ;AAOE;EACE,oCAAA;AALJ;AAQE;EACE,cAAA;AANJ","sourcesContent":["@import \"../../../../../../../styles/colors\";\n\n.restore {\n  padding: 20px 0;\n\n  &__content {\n    padding: 0 20px;\n  }\n\n  .label {\n    height: 40px;\n    margin: 0 0 20px 0;\n  }\n\n  .field {\n    height: 40px;\n    max-width: 310px;\n    margin: 0;\n    color: var(--settings-text-table) !important;\n  }\n\n  p {\n    color: var(--settings-header-text);\n    font-family: Nunito, serif;\n    font-size: 12px;\n  }\n\n  .toggle-button__text {\n    color: var(--settings-article-value);\n  }\n\n  .toggle-button {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
