import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Button, Modal } from "@mui/material";
import FolderIcon from "../../Icon-SVG/FolderIcon";
import StorageIcon from "../../Icon-SVG/StorageIcon";
import FileTypeIcon from "../../Icon-SVG/FileTypeIcon";
import Warning from "../../common/Warning/Warning";
import { logoutRequest, timeoutCheckRequest } from "../../../store/actions/usersActions";
import Login from "../../../CloudModel/CloudPages/Login/Login";
import "./styles.scss";

const DragAndDrop = ({ selectedFiles = null, setSelectedFiles }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const users = useSelector((state) => state?.users.user);
  const fileListRef = useRef(null);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  useEffect(() => {
    if (users) {
      setIsLoginOpen(false);
    }
  }, [users]);

  const onUploadFiles = (files) => {
    const maxSize = 1024 * 1024 * 1024; // 1024 MB
    if (files.size > maxSize) {
      setError("File size is too big");
      setShow(true);
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        setError("File size is bigger than 1024 MB");
        setShow(true);
        return;
      }
    }

    const fileList = Array.from(files);

    const newFiles = fileList.map((file) => ({
      file: file,
      fileType: file.name.split(".").pop(),
    }));

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleCloudCheck = (e) => {
    if (!users) {
      e.preventDefault();
      setIsLoginOpen(true);
    }
  };

  const handleDrop = (e) => {
    dispatch(timeoutCheckRequest());
    if (!users?.username) {
      dispatch(logoutRequest("timeout"));
    }

    e.preventDefault();
    if (!e.dataTransfer) return;
    const files = e.dataTransfer.files;
    onUploadFiles(files);
  };

  const browseFiles = (e) => {
    if (!users) {
      setIsLoginOpen(true);
      e.preventDefault();
      return;
    }

    dispatch(timeoutCheckRequest());
    if (!users?.username) {
      dispatch(logoutRequest("timeout"));
    }

    if (!e.target.files) return;
    const files = e.target.files;
    onUploadFiles(files);
  };

  const removeFile = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  return (
    <>
      <Grid container className="drag-and-drop" onClick={(e) => handleCloudCheck(e)}>
        <div className="drag-and-drop__main">
          <Grid item xs={12} md={12} style={{ height: "100%" }}>
            <Paper
              elevation={4}
              style={{
                background: "transparent",
                boxShadow: "0 0 0 0 rgba(1, 1, 1, 0)",
                height: "100%",
              }}>
              <div
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e)}
                className="drag-and-drop__dropzone"
                style={{ width: "100%" }}>
                {!selectedFiles || selectedFiles.length === 0 ? (
                  <div className="drag-and-drop__dropzone__wrapper">
                    <Button
                      component="label"
                      style={{ backgroundColor: "transparent" }}
                      className="drag-and-drop__dropzone__browse"
                      onClick={(e) => handleCloudCheck(e)}>
                      <div className="drag-and-drop__image">
                        <FolderIcon width="24" height="24" />
                      </div>

                      <h3 className="drag-and-drop__text">
                        Drag & Drop your file here or{" "}
                        <b style={{ color: "var(--color-blue)" }}>browse</b> to upload
                      </h3>

                      <input
                        type="file"
                        accept="*"
                        multiple
                        onClick={(e) => handleCloudCheck(e)}
                        onChange={(e) => browseFiles(e)}
                        style={{ display: "none" }}
                      />
                    </Button>
                  </div>
                ) : (
                  <>
                    <table style={{ width: "100%" }} className="drag-and-drop__files-table">
                      {Object.keys(selectedFiles).map((file, index) => (
                        <tbody
                          key={index}
                          className="drag-and-drop__text--file"
                          style={{ width: "100%" }}>
                          <tr>
                            <td className="drag-and-drop__text--file--name">
                              <b className="drag-and-drop__text--file--name--icon">
                                <FolderIcon width="24" height="24" />
                              </b>
                              <b className="drag-and-drop__text--file--name--file">
                                {selectedFiles[file].file.name}
                              </b>
                            </td>
                            <td className="drag-and-drop__text--file--size">
                              <StorageIcon width="24" height="24" />
                              <b className="drag-and-drop__text--file--size--text">
                                {selectedFiles[file].file.size + " Byte"}
                              </b>
                            </td>
                            <td className="drag-and-drop__text--file--type">
                              <FileTypeIcon width="24" height="24" />
                              <b className="drag-and-drop__text--file--type--text">
                                {selectedFiles[file].file.type.split("/").length > 1
                                  ? selectedFiles[file].file.type.split("/")[1]
                                  : selectedFiles[file].file.type || "n/a"}
                              </b>
                            </td>
                            <td className="drag-and-drop__text--file--button">
                              <div
                                className="drag-and-drop__text--file--button--remove"
                                onClick={(e) => {
                                  removeFile(e, index);
                                }}>
                                x
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <div className="drag-and-drop__button-wrapper">
                      <Button
                        component="label"
                        className="drag-and-drop__button"
                        style={{
                          color: "var(--color-blue)",
                          boxShadow:
                            "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 10px 50px 0 rgba(0, 0, 0, 0.19)",
                          backgroundColor: "transparent",
                        }}
                        onClick={(e) => handleCloudCheck(e)}>
                        BROWSE
                        <input
                          type="file"
                          multiple
                          accept="*"
                          style={{ display: "none" }}
                          onClick={(e) => handleCloudCheck(e)}
                          onChange={(e) => browseFiles(e)}
                        />
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Paper>
          </Grid>
        </div>
        <Warning
          onClickOk={() => setShow(false)}
          show={show}
          setShow={setShow}
          text={error}
          article="Warning"
          cancelDisable={true}
          textOK="Okay"
        />
      </Grid>

      {/* Cloud Modal Login */}
      <Modal open={isLoginOpen} onClose={() => setIsLoginOpen(false)}>
        <div className="cloudmodal-login">
          <Login />
        </div>
      </Modal>
    </>
  );
};

export default DragAndDrop;
