import React from "react";

import { parseISO, parse, differenceInMilliseconds } from "date-fns";

const getSubmitType = (submitType) => {
  if (submitType.split("-").length >= 3) {
    if (submitType.includes("Artifact")) {
      return submitType.replace("Artifact", "Art.");
    } else if (submitType.includes("Resubmit")) {
      return submitType.replace("Resubmit", "Resub.");
    } else if (submitType.includes("Mail Gateway")) {
      return submitType.replace("Mail Gateway", "Mail G.");
    } else if (submitType.includes("Network Defender")) {
      return submitType.replace("Network Defender", "Network D.");
    }
  }
  return submitType;
};

const calculateCompletionTime = (timeObj) => {
  if (!timeObj?.time || !timeObj?.completeTime) {
    return false;
  }
  const startTime = timeObj.startTime || timeObj.time;

  const completeTime = timeObj.completeTime;

  let completeDate;
  if (completeTime.includes("T")) {
    completeDate = parseISO(completeTime);
  } else {
    completeDate = parse(completeTime, "yyyy/MM/dd  (HH:mm:ss)", new Date());
  }

  const startDate = parse(startTime, "yyyy/MM/dd  (HH:mm:ss)", new Date());

  const difference = differenceInMilliseconds(completeDate, startDate);

  const minutes = Math.floor(difference / (1000 * 60));

  return minutes;
};

const Time = (props) => {
  const time = props?.row?.original.time;
  const progress = props?.row?.original?.progress;
  const isStatic = props?.row?.original?.isStatic;

  const completionTime = calculateCompletionTime(time);
  return (
    <div>
      <span className="dashboard__table__time" title={time?.time}>
        <p style={{ cursor: "pointer" }}>
          {time.time?.length > 70
            ? `${time.time?.substring(0, 55)}.........${time.time.substring(time.time.length - 10)}`
            : time.time}
        </p>
        <p
          style={{ fontStyle: " bold", color: "#9B9B9B", fontSize: "11px" }}
          title={time?.submitType}>
          {time.submitType && getSubmitType(time.submitType)?.length > 70
            ? `${getSubmitType(time.submitType)?.substring(0, 55)}.........${getSubmitType(
                time.submitType,
              ).substring(getSubmitType(time.submitType).length - 10)}`
            : getSubmitType(time.submitType)}
        </p>
        <p style={{ fontStyle: " bold", color: "#9B9B9B" }} title={time?.submitInfo}>
          {time.submitInfo && time.submitInfo?.length > 70
            ? `${time.submitInfo?.substring(0, 55)}.........${time.submitInfo.substring(
                time.submitInfo.length - 10,
              )}`
            : time.submitInfo}
        </p>
        {time.completeTime && progress === "Completed" ? (
          <p style={{ color: "#9B9B9B" }}>
            {completionTime === 0 && !isStatic
              ? "Duplicate submission"
              : `Completed in ${completionTime} mins`}
          </p>
        ) : null}
      </span>
    </div>
  );
};

export default Time;
