import React, { useState } from "react";
import CheckBox from "../../../../common/CheckBox/CheckBox";

const Row = ({ row, index }) => {
  const [rsyslog, setRsyslog] = useState(row.rsyslog || false);
  const [smtp, setSmtp] = useState(row.smtp || false);
  const [snmp, setSnmp] = useState(row.snmp || false);
  const [http, setHttp] = useState(row.http || false);

  return (
    <div className="summary__table-body-item">
      <p>{row.title}</p>
      <p>{row.protocol}</p>
      <p>
        <CheckBox id={index + "1"} checked={rsyslog} setChecked={setRsyslog} />
      </p>
      <p>
        <CheckBox id={index + "2"} checked={smtp} setChecked={setSmtp} />
      </p>
      <p>
        <CheckBox id={index + "3"} checked={snmp} setChecked={setSnmp} />
      </p>
      <p>
        <CheckBox id={index + "4"} checked={http} setChecked={setHttp} />
      </p>
    </div>
  );
};

export default Row;
