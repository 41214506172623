import React, { useState } from "react";
import Toggle from "../../../Components/Toggle";
import Field from "../../../Components/Field";

const Main = () => {
  const [terminateProcesses, setTerminateProcesses] = useState(false);
  const [machinesCount, setMachinesCount] = useState("10");
  const [vmstartup, setVmstartup] = useState("5");

  return (
    <div className="border cfsbox__content">
      <Toggle
        parentClassName="m-b-30 m-t-10"
        active={terminateProcesses}
        setActive={setTerminateProcesses}
        label="Terminate processes"
        tooltipText="When the timeout of an analysis is hit, the VM is just killed by default. For some long-running setups it might be interesting to terminate the moinitored processes before killing the VM so that connections are closed."
      />
      <div className="flex flex-align-items-center m-b-20">
        <Field
          width="156px"
          parentClassName="p-r-40"
          onChange={(e) => setMachinesCount(e.target.value)}
          value={machinesCount}
          label="Max machines count"
          type="number"
          tooltipText="Limit the number of concurrently executing analysis machines. This may be useful on systems with limited resources. Set to 0 to disable any limits."
        />
        <Field
          width="156px"
          onChange={(e) => setVmstartup(e.target.value)}
          value={vmstartup}
          label="Max vmstartup count"
          type="number"
          tooltipText="Limit the amount of VMs that are allowed to start in parallel. Generally speaking starting the VMs is one of the more CPU intensive parts of the actual analysis. This option tries to avoid maxing out the CPU completely."
        />
      </div>

      <div className="flex flex-align-items-center m-b-10">
        <button className="m-r-15 default-button">Default</button>
        <button className="m-l-15 primary-button">Save</button>
      </div>
    </div>
  );
};

export default Main;
