import { useState } from "react";

import ExpandOrShrinkIcon from "../../UI/DropDown/ExpandOrShrinkIcon";

import "./styles.scss";

const Dropdown = ({ title, selectedItem, items, onItemSelect }) => {
  const [open, setOpen] = useState(true);
  return (
    <div className="dropdown">
      <div className="dropdown__title" onClick={() => setOpen((state) => !state)}>
        {title}({items?.length}) <ExpandOrShrinkIcon expanded={open} collapsed={false} />
      </div>
      {open && items.length > 0 ? (
        <div className="dropdown__items">
          {items.map((item, i) => (
            <div className="dropdown__item" onClick={() => onItemSelect(title, item)} key={i}>
              <p className="dropdown__item__name">{item}</p>
              {selectedItem === item ? <div className="dropdown__item__active" /> : null}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
