import { useState } from "react";
import { useDispatch } from "react-redux";

import axiosApi from "../axios";

import { addNotification } from "../../store/actions/notifierActions";

const useDownloadSample = (fileHash) => {
  const dispatch = useDispatch();

  const [downloadOptions, setDownloadOptions] = useState({
    loaded: 0,
    total: 0,
    progress: 0,
    downloadStarted: false,
  });
  const [fileName, setFileName] = useState("");

  const downloadSampleHandler = async () => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total, progress } = progressEvent;
        setDownloadOptions({
          loaded,
          total,
          progress: progress * 100,
          downloadStarted: loaded && progress > 0 ? true : false,
        });
      },
    };
    try {
      const _ = await axiosApi.get(`api/files/check-sample/${fileHash}`);
      const response = await axiosApi.get(`api/files/download-sample/${fileHash}`, {
        responseType: "blob",
        ...options,
      });
      const fileName =
        response.headers["content-disposition"].split("filename=")[1] || "sample.zip";
      setFileName(fileName);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      setDownloadOptions((options) => ({ ...options, downloadStarted: false }));
      dispatch(
        addNotification({
          message: err.response.status === 404 ? "Sample not found" : "Error",
          options: { variant: "error" },
        }),
      );
    } finally {
      setTimeout(() => {
        setDownloadOptions((options) => ({ ...options, downloadStarted: false }));
      }, 1500);
    }
  };

  return { downloadOptions, fileName, downloadSampleHandler };
};

export default useDownloadSample;
