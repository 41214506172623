// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsyslog {
  border-radius: 0 4px 4px 4px;
}
.rsyslog__label {
  color: var(--text-color-card);
  font-size: 12px;
  font-family: Nunito, serif;
}
.rsyslog .select-box--box {
  margin-top: 5px;
}
.rsyslog .label {
  color: var(--text-color-card) !important;
}
.rsyslog .custom-table {
  padding-bottom: 30px;
}
.rsyslog .custom-table td {
  padding: 10px 15px;
}
.rsyslog .custom-table th {
  padding: 10px 15px;
  background: var(--settings-table-header);
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/Notifications/Tabs/RSYSLOG/styles.scss"],"names":[],"mappings":"AAEA;EACE,4BAAA;AADF;AAGE;EACE,6BAAA;EACA,eAAA;EACA,0BAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,wCAAA;AAHJ;AAME;EACE,oBAAA;AAJJ;AAMI;EACE,kBAAA;AAJN;AAOI;EACE,kBAAA;EACA,wCAAA;EACA,YAAA;AALN","sourcesContent":["@import \"../../../../../../../styles/colors\";\n\n.rsyslog {\n  border-radius: 0 4px 4px 4px;\n\n  &__label {\n    color: var(--text-color-card);\n    font-size: 12px;\n    font-family: Nunito, serif;\n  }\n\n  .select-box--box {\n    margin-top: 5px;\n  }\n\n  .label {\n    color: var(--text-color-card) !important;\n  }\n\n  .custom-table {\n    padding-bottom: 30px;\n\n    td {\n      padding: 10px 15px;\n    }\n\n    th {\n      padding: 10px 15px;\n      background: var(--settings-table-header);\n      border: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
