// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MalwareChart {
  text-align: center;
  width: 210px;
}
.MalwareChart__chartIcon {
  position: absolute;
  margin-top: 50px;
  margin-left: 90px;
}
.MalwareChart__numbers-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.MalwareChart__numbers-container span {
  font-size: 14px;
  padding-left: 8px;
  border-left: 1px solid #8992ab;
  color: #8992ab;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/MalwareChart/styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,YAAA;AADF;AAGE;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAIE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;AAFJ;AAII;EACE,eAAA;EACA,iBAAA;EACA,8BAAA;EACA,cAAA;AAFN","sourcesContent":["@import \"../../../styles/colors\";\n\n.MalwareChart {\n  text-align: center;\n  width: 210px;\n\n  &__chartIcon {\n    position: absolute;\n    margin-top: 50px;\n    margin-left: 90px;\n  }\n\n  &__numbers-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n\n    span {\n      font-size: 14px;\n      padding-left: 8px;\n      border-left: 1px solid #8992ab;\n      color: #8992ab;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
