// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications__body {
  width: 100%;
  position: relative;
  border-radius: 0 4px 4px 4px;
  margin: 6vh 0 1vh 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Settings/Content/Tabs/Notifications/styles.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,kBAAA;EACA,4BAAA;EACA,mBAAA;AAAJ","sourcesContent":[".notifications {\n  &__body {\n    width: 100%;\n    position: relative;\n    border-radius: 0 4px 4px 4px;\n    margin: 6vh 0 1vh 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
