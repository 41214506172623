import { call, put, takeLatest } from "redux-saga/effects";
import {
  RequestDistinctHosts,
  SuccessDistinctHosts,
  FailureDistinctHosts,
  RequestDistinctLiveHosts,
  SuccessDistinctLiveHosts,
  FailureDistinctLiveHosts,
  RequestAnalyzedEmails,
  SuccessAnalyzedEmails,
  FailureAnalyzedEmails,
  RequestBlockedEmails,
  SuccessBlockedEmails,
  FailureBlockedEmails,
  RequestUnblockedEmails,
  SuccessUnblockedEmails,
  FailureUnblockedEmails,
  RequestReleaseEmail,
  SuccessReleaseEmail,
  FailureReleaseEmail,
  RequestSetMailTransferSettings,
  SuccessSetMailTransferSettings,
  FailureSetMailTransferSettings,
  RequestGetMailTransferSettings,
  SuccessGetMailTransferSettings,
  FailureGetMailTransferSettings,
  RequestGetWhitelistSettings,
  SuccessGetWhitelistSettings,
  FailureGetWhitelistSettings,
  RequestSetWhitelistSettings,
  SuccessSetWhitelistSettings,
  FailureSetWhitelistSettings,
} from "../actions/mailGatewayActions";
import { addNotification } from "../actions/notifierActions";

import axiosApi from "../../utils/axios";

export function* distinctHosts() {
  try {
    const response = yield call(axiosApi.get, "/api/mail-gateway/hosts");
    const mappedData = response?.data?.map((item) => ({ id: item, value: item }));
    yield put(SuccessDistinctHosts(mappedData));
  } catch (e) {
    yield put(FailureDistinctHosts());
  }
}

export function* distinctLiveHosts() {
  try {
    const response = yield call(axiosApi.get, "/api/mail-gateway/live-hosts");
    const mappedData = response?.data ? response.data : [];

    yield put(SuccessDistinctLiveHosts(mappedData));
  } catch (e) {
    yield put(FailureDistinctLiveHosts());
  }
}

export function* analyzedEmails({ payload }) {
  const { host, timeFilter } = payload;
  try {
    const response = yield call(
      axiosApi.get,
      `/api/mail-gateway/analyzed-emails/${host}?filterByLast=${timeFilter.id}`,
    );
    yield put(SuccessAnalyzedEmails(response.data));
  } catch (e) {
    yield put(FailureAnalyzedEmails());
  }
}

export function* blockedEmails({ payload }) {
  const { host, timeFilter } = payload;
  try {
    const response = yield call(
      axiosApi.get,
      `/api/mail-gateway/blocked-emails/${host}?filterByLast=${timeFilter.id}`,
    );
    yield put(SuccessBlockedEmails(response.data));
  } catch (e) {
    yield put(FailureBlockedEmails());
  }
}

export function* unblockedEmails({ payload }) {
  const { host, timeFilter } = payload;
  try {
    const response = yield call(
      axiosApi.get,
      `/api/mail-gateway/unblocked-emails/${host}?filterByLast=${timeFilter.id}`,
    );
    yield put(SuccessUnblockedEmails(response.data));
  } catch (e) {
    yield put(FailureUnblockedEmails());
  }
}

export function* releaseEmail({ payload }) {
  const { emailId, host } = payload;

  let parsedData = {};
  const storedData = localStorage.getItem("blockedEmails");
  if (storedData) {
    parsedData = JSON.parse(storedData);
  }

  try {
    yield call(axiosApi.post, `/api/mail-gateway/release-email/${emailId}/${host}`);
    yield put(SuccessReleaseEmail());
    yield call(blockedEmails, { payload: { host, timeFilter: parsedData } });
    yield put(
      addNotification({ message: "Release email successful", options: { variant: "success" } }),
    );
  } catch (e) {
    yield put(FailureReleaseEmail());
    yield put(
      addNotification({
        message: e?.response?.data?.message,
        options: { variant: "error" },
        time: 4000,
      }),
    );
  }
}

export function* setMailTransferSettings({ payload }) {
  const { hostname, settings } = payload;
  try {
    const response = yield call(
      axiosApi.post,
      `/api/mail-gateway/settings/mail-transfer/${hostname}`,
      settings,
    );
    yield put(SuccessSetMailTransferSettings(response.data));
    yield put(
      addNotification({
        message: "Settings updated successfully",
        options: { variant: "success" },
      }),
    );
    yield call(getMailTransferSettings, { payload: { hostname } });
  } catch (e) {
    yield put(FailureSetMailTransferSettings());
    yield put(
      addNotification({
        message: e?.response?.data?.message || "Failed to update settings",
        options: { variant: "error" },
      }),
    );
  }
}

export function* getMailTransferSettings({ payload }) {
  const { hostname } = payload;
  try {
    const response = yield call(
      axiosApi.get,
      `/api/mail-gateway/settings/mail-transfer/${hostname}`,
    );
    yield put(SuccessGetMailTransferSettings(response.data));
  } catch (e) {
    yield put(FailureGetMailTransferSettings());
    yield put(
      addNotification({
        message: e?.response?.data?.message || "Failed to fetch settings",
        options: { variant: "error" },
      }),
    );
  }
}

export function* getWhitelistSettings({ payload }) {
  const { hostname } = payload;
  try {
    const response = yield call(axiosApi.get, `/api/mail-gateway/settings/whitelist/${hostname}`);
    yield put(SuccessGetWhitelistSettings(response.data));
  } catch (e) {
    yield put(FailureGetWhitelistSettings());
    yield put(
      addNotification({
        message: e?.response?.data?.message || "Failed to fetch settings",
        options: { variant: "error" },
      }),
    );
  }
}

export function* setWhitelistSettings({ payload }) {
  const { hostname, settings } = payload;
  try {
    const response = yield call(
      axiosApi.post,
      `/api/mail-gateway/settings/whitelist/${hostname}`,
      settings,
    );
    yield put(SuccessSetWhitelistSettings());
    yield call(getWhitelistSettings, { payload: { hostname } });
  } catch (e) {
    yield put(FailureSetWhitelistSettings());
    yield put(
      addNotification({
        message: e?.response?.data?.message || "Failed to update settings",
        options: { variant: "error" },
      }),
    );
  }
}

const mailGatewaySagas = [
  takeLatest(RequestDistinctHosts, distinctHosts),
  takeLatest(RequestDistinctLiveHosts, distinctLiveHosts),
  takeLatest(RequestAnalyzedEmails, analyzedEmails),
  takeLatest(RequestBlockedEmails, blockedEmails),
  takeLatest(RequestUnblockedEmails, unblockedEmails),
  takeLatest(RequestReleaseEmail, releaseEmail),
  takeLatest(RequestSetMailTransferSettings, setMailTransferSettings),
  takeLatest(RequestGetMailTransferSettings, getMailTransferSettings),
  takeLatest(RequestGetWhitelistSettings, getWhitelistSettings),
  takeLatest(RequestSetWhitelistSettings, setWhitelistSettings),
];

export default mailGatewaySagas;
