const YaraIcon = ({ color = "var(--border)", width = 24, height = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}>
      <path
        fill={color}
        d="M23,12c0,.27-.11,.52-.29,.71l-1.83,1.83c-.57,.57-.88,1.32-.88,2.12v2.34c0,2.76-2.24,5-5,5h-1c-.55,0-1-.45-1-1s.45-1,1-1h1c1.65,0,3-1.35,3-3v-2.34c0-1.34,.52-2.59,1.46-3.54l1.12-1.12-1.12-1.12c-.94-.94-1.46-2.2-1.46-3.54v-2.34c0-1.65-1.35-3-3-3h-1c-.55,0-1-.45-1-1s.45-1,1-1h1c2.76,0,5,2.24,5,5v2.34c0,.8,.31,1.56,.88,2.12l1.83,1.83c.19,.19,.29,.44,.29,.71ZM6,7.34v-2.34c0-1.65,1.35-3,3-3h1c.55,0,1-.45,1-1s-.45-1-1-1h-1c-2.76,0-5,2.24-5,5v2.34c0,.8-.31,1.56-.88,2.12l-1.83,1.83c-.19,.19-.29,.44-.29,.71s.11,.52,.29,.71l1.83,1.83c.57,.57,.88,1.32,.88,2.12v2.34c0,2.76,2.24,5,5,5h1c.55,0,1-.45,1-1s-.45-1-1-1h-1c-1.65,0-3-1.35-3-3v-2.34c0-1.34-.52-2.59-1.46-3.54l-1.12-1.12,1.12-1.12c.94-.94,1.46-2.2,1.46-3.54Z"
      />
    </svg>
  );
};

export default YaraIcon;
