import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dropdown from "../../../Components/common/Dropdown/Dropdown";

import { RequestLiveResponsibles } from "../../../store/actions/containerActions";

const SIDEBAR_ITEMS = [
  {
    key: "mailGateway",
    title: "Mail Gateway",
  },
  {
    key: "networkDefender",
    title: "Network Defender",
  },
];

const ContainerServicesSidebar = ({ onItemSelect, selectedResponsible }) => {
  const liveResponsibles = useSelector((state) => state.container.liveResponsibles);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RequestLiveResponsibles());
  }, [dispatch]);

  useEffect(() => {
    onItemSelect(null, null);
    for (const item of SIDEBAR_ITEMS) {
      if (liveResponsibles?.[item.key] && liveResponsibles?.[item.key].length > 0) {
        onItemSelect(item.title, liveResponsibles[item.key][0]);
        break;
      }
    }
  }, [liveResponsibles]);

  return (
    <div className="services-sidebar">
      {SIDEBAR_ITEMS.map((item) => (
        <Dropdown
          key={item.key}
          title={item.title}
          selectedItem={selectedResponsible}
          items={liveResponsibles?.[item.key]}
          onItemSelect={onItemSelect}
        />
      ))}
    </div>
  );
};

export default ContainerServicesSidebar;
