import { call, put, takeLatest } from "redux-saga/effects";
import {
  RequestContainerService,
  SuccessContainerService,
  FailureContainerService,
  RequestLiveResponsibles,
  SuccessLiveResponsibles,
  FailureLiveResponsibles,
  RequestContainerServices,
  SuccessContainerServices,
  FailureContainerServices,
  RequestPodUpdate,
  SuccessPodUpdate,
  FailurePodUpdate,
  RequestPodRestart,
  SuccessPodRestart,
  FailurePodRestart,
} from "../actions/containerActions";
import { addNotification } from "../actions/notifierActions";

import axiosApi from "../../utils/axios";

export function* containerService({ payload: responsible }) {
  try {
    const response = yield call(axiosApi.get, `/api/containers/service/${responsible}`);
    yield put(SuccessContainerService(response.data));
  } catch (e) {
    yield put(FailureContainerService());
  }
}

export function* liveResponsibles() {
  try {
    const response = yield call(axiosApi.get, "/api/containers/live-responsibles");
    const mailGatewayResponsibles = [];
    const networkDefenderResponsibles = [];
    response.data.forEach((responsible) => {
      if (responsible?.startsWith("pf-")) mailGatewayResponsibles.push(responsible);
      else if (responsible?.startsWith("sq-")) networkDefenderResponsibles.push(responsible);
    });
    yield put(
      SuccessLiveResponsibles({
        mailGateway: mailGatewayResponsibles,
        networkDefender: networkDefenderResponsibles,
      }),
    );
  } catch (e) {
    yield put(FailureLiveResponsibles());
  }
}

export function* containerServices() {
  try {
    const response = yield call(axiosApi.get, "/api/containers/services");
    yield put(SuccessContainerServices(response.data));
  } catch (e) {
    yield put(FailureContainerServices());
  }
}

export function* podUpdate({ payload }) {
  const { responsible, imageName, podName } = payload;
  try {
    yield call(axiosApi.get, `/api/containers/update/${responsible}/${imageName}/${podName}`);
    yield put(SuccessPodUpdate());
    yield put(
      addNotification({
        message: "Update request is successful",
        options: { variant: "success" },
      }),
    );
    yield call(containerService, { payload: responsible });
  } catch (e) {
    yield put(FailurePodUpdate());
    yield put(
      addNotification({
        message: e?.response?.data?.message,
        options: { variant: "error" },
        time: 3000,
      }),
    );
  }
}

export function* podRestart({ payload }) {
  const { responsible, podName } = payload;
  try {
    yield call(axiosApi.get, `/api/containers/restart/${responsible}/${podName}`);
    yield put(SuccessPodRestart());
    yield put(
      addNotification({
        message: "Restart request is successful",
        options: { variant: "success" },
      }),
    );
    yield call(containerService, { payload: responsible });
  } catch (e) {
    yield put(FailurePodRestart());
    yield put(
      addNotification({
        message: e?.response?.data?.message,
        options: { variant: "error" },
        time: 3000,
      }),
    );
  }
}

const containerSagas = [
  takeLatest(RequestContainerService, containerService),
  takeLatest(RequestLiveResponsibles, liveResponsibles),
  takeLatest(RequestContainerServices, containerServices),
  takeLatest(RequestPodUpdate, podUpdate),
  takeLatest(RequestPodRestart, podRestart),
];

export default containerSagas;
